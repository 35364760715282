import React, { Component } from 'react'
import Table from './Table'
import Styles from './Styles'
import TrashCan from './style/delete.png'
import Edit from './style/edit.png'
import AddUserListBox from './AddUserListBox'
import Select from 'react-select'
import Reset from './style/reset_btn.png'
import Exit from './style/exit.png'

//BOOTSRAP:
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class UserGroupTable extends Component {
    constructor() {
        super()
        this.state = {
            editGroup: false, // {Boolean} true: editing a User Group & false: creating a new User Group
            index: 0, // {Number} index of the User Group being edited
            userList: [], // {Array of JS objects (name, id)}
            leader: '', // {string} name of the group leader
            name: '', // {string} name of the group
            userListBox: [], // {Array of Objects {label: name, value: id}} list of all the users sent to the UserListBox
            dialogTitle: 'Create Team', // {String} User Group title text
            inputText: null, // {label: string, value: string}
            selectedUsers: [], // {Array of objects} {value: UID, label: name}
        }
        this.handleUserGroupAction = this.handleUserGroupAction.bind(this)
        this.showUserGroupBox = this.showUserGroupBox.bind(this)
        this.hideUserGroupBox = this.hideUserGroupBox.bind(this)
        this.showUserBoxModal = this.showUserBoxModal.bind(this)
        this.hideUserBoxModal = this.hideUserBoxModal.bind(this)
        this.removeUser = this.removeUser.bind(this)
        this.handleNameChange = this.handleNameChange.bind(this)
        this.handleLeaderChange = this.handleLeaderChange.bind(this)
        this.populateUserGroupBox = this.populateUserGroupBox.bind(this)
        this.addUserOptions = this.addUserOptions.bind(this)
        this.handleUserListChange = this.handleUserListChange.bind(this)
    }
    /************************** User Group Functions ***************************************/
    handleUserListChange(event) {
        this.setState({selectedUsers: event})
    }
    handleNameChange(event) {
        this.setState({name: event.target.value})
    }
    handleLeaderChange(event) {
        this.setState({leader: event.target.value})
    }
    handleUserGroupAction() {
        if (this.state.name.length > 0 && this.state.leader.length > 0) {
            // send data {group name, leader, and users}
            if (this.state.editGroup) {
                this.props.onEditUserGroup({
                    name: this.state.name,
                    leader: this.state.leader,
                    // new users and existing users
                    users: this.state.userList,
                    index: this.state.index,
                })
            } else {
                // assume a new user group is being created
                this.props.onAddUserGroup({
                    name: this.state.name,
                    leader: this.state.leader,
                    users: this.state.userList
                })
            }
            // hide the Create User Group Box
            this.hideUserGroupBox();
        } else {
            alert('Please enter a valid Group Name and Group Leader')
        }
    }
    // Description: On edit User Group, populate the group box with the exisiting users
    //              and set the value of the group leader
    // Parameter: {Object (User Group fields)} exisiting User Group that is being edited
    populateUserGroupBox(data) {
        // create the html tags for the selected user list
        /* values needed to add options to the selectUserForTask */
        var selectGroupLeader = document.getElementById('editLeaderUserGroup')
        selectGroupLeader.innerHTML = '';
        var currentLeader = {text: data.leader, value: data.leader};
        selectGroupLeader.options.add( new Option(currentLeader.text, currentLeader.value));
        let selectedUsers = data.users;
        for (let i = 0; i < selectedUsers.length; i++) {
            selectedUsers[i].get().then((query) => {
                let UID = selectedUsers[i].id;
                let uData = query.data();
                let fullName = uData.firstName + ' ' + uData.lastName;
                let currUser = {
                    name: fullName,
                    id: UID
                }
                // update the state of userList
                this.state.userList.push(currUser);

                //Get the default from the firebase collection and add that as the first option
                var currOpt = { text: fullName, value: fullName}                
                 // add the current user to the task user dropdown
                if(currentLeader.text !== fullName)
                {
                    selectGroupLeader.options.add( new Option(currOpt.text, currOpt.value));
                }
                var item = document.createElement("li"); // create the list item
                var userName = document.createElement("span"); // add the userName to the li
                userName.innerHTML = fullName;
                var minusSign = document.createElement('span')
                minusSign.innerHTML = '-';
                minusSign.setAttribute('class', 'selectedUserMinusSign');
                minusSign.addEventListener('click', this.removeUser)
                item.appendChild(userName); // add the description to the element
                item.appendChild(minusSign)
                document.getElementById('editUserGroupList').appendChild(item);
            })
            .catch((error) => console.log('Error getting data from User doc Reference: ', error))
        }
        this.props.onEditBreadCrumbs({
            type: 'APPEND_BREADCRUMB',
            breadCrumbs: " > Edit Team"
        })
        // display dialog
        const ugDialog = document.getElementById('addUserGroupDialog');
        ugDialog.style.left = (this.props.toggleMenu) ? '130px' : '330px'
        ugDialog.showModal();
        this.setState({
            name: data.name,
            leader: data.leader,
            userList: this.state.userList
        })
    }
    // Description: Display the User Group Box and append the breadcrumbs
    showUserGroupBox() {
        this.props.onEditBreadCrumbs({
            type: 'APPEND_BREADCRUMB',
            breadCrumbs: " > Create Team",
        })
        // display dialog
        const ugDialog = document.getElementById('addUserGroupDialog');
        ugDialog.style.left = (this.props.toggleMenu) ? '130px' : '330px'
        ugDialog.showModal();
    }
    // Description: Close the User Group Box and clear the select drop down
    //              menu and clear the user list
    hideUserGroupBox() {
        const ugDialog = document.getElementById('addUserGroupDialog');
        ugDialog.close();
        document.getElementById('editUserGroupList').innerHTML = '';
        document.getElementById('editLeaderUserGroup').innerHTML = '';
        this.props.onEditBreadCrumbs({
            type: 'REMOVE_BREADCRUMB',
            breadCrumbs: "Users"
        })
        // clear all data and update state
        this.setState({ 
            editGroup: false,
            leader: '',
            name: '',
            userList: [],
            dialogTitle: 'Create Team'
         })
    }
    // this.props.userList.filter((user) => {
    //     if (user.companyID === this.props.companyID) {
    //         var fullName = user.firstName + " " + user.lastName
    //         return {'value': user.id, 'label': fullName}
    //     }   
    // })

    /********************************* User List Functions ****************************************/
    // Description: On User List view, add all the users to the User List Box
    addUserOptions() {
        let userListBox = []
        // Add all the user options to the list
        for (let user of this.props.userList) {
            // iterate through the entire UserList
            if (user.companyID === this.props.companyID) {
                var fullName = user.firstName + " " + user.lastName;
                userListBox.push({'value': user.id, 'label': fullName});
            }
        }
        this.setState({ userListBox: userListBox });
    }
    // Description: On click, remove the user from the User Group and remove
    //              the user from the html leader drop down menu
    // Parameter: {Object HTML of the List Item} List Item stores the name of the user
    removeUser(row) {
        const res = window.confirm('Are you sure you want to remove the user from the team?')
        if (res) {
            // remove the item for the list
            let username = row.path[1].textContent.split('-'); // get the users name
            username.pop(); // get the name of the user
            username = username.toString();
            let name; // name of the current selected user
            for (let i = 0; i < this.state.userList.length; i++) {
                name = this.state.userList[i].name;
                if (name === username) {
                    var editLeaderUserGroup = document.getElementById('editLeaderUserGroup')
                    editLeaderUserGroup.remove(i);
                    this.state.userList.splice(i, 1);
                    break;
                }
            }
            // remove the list item from the list
            row.path[1].parentNode.removeChild(row.path[1])
        }
    }
    // Description: Display the User List Box
    showUserBoxModal() {
        this.addUserOptions()
        const UserBoxDialog = document.getElementById('UserBoxDialog')
        UserBoxDialog.showModal();
        
    }
    // Description: Close the User List Box and add the selected users to the
    //              User Group Box and populate the leader drop down menu
    // Parameter: {Object (Array of Objects (String name))}
    hideUserBoxModal(row) {
        // add items to the DOM
        if (row !== undefined && row.hasOwnProperty('selectedUsers')) {
			let selectedUsers = row.selectedUsers;
			if (selectedUsers !== undefined && selectedUsers.length > 0) {
				// text fields
				let fullName;
				// create the html tags for the selected user list
				var item; // the list item
				var userName; // the span element to store name of the user
				var minusSign; // the remove user from plan icon
				/* values needed to add options to the selectUserForTask */
				var selectGroupLeader = document.getElementById('editLeaderUserGroup')
				let currOpt;
				for (let i = 0; i < selectedUsers.length; i++) {
                    // assume its a duplicate
                    if (this.state.userList.findIndex((user) => (user.id === selectedUsers[i].id)) !== -1) continue
                    // update the state of userList
                    this.state.userList.push(selectedUsers[i]);
					fullName = selectedUsers[i].name;
					currOpt = { text: fullName, value: fullName }
					// add the current user to the task user dropdown
					selectGroupLeader.options.add( new Option(currOpt.text, currOpt.value, currOpt.selected) );
					item = document.createElement("li"); // create the list item
					userName = document.createElement("span"); // add the userName to the li
					userName.innerHTML = fullName;
					minusSign = document.createElement('span')
					minusSign.innerHTML = '-';
					minusSign.setAttribute('class', 'selectedUserMinusSign');
					minusSign.addEventListener('click', this.removeUser)
					item.appendChild(userName); // add the description to the element
					item.appendChild(minusSign)
					document.getElementById('editUserGroupList').appendChild(item);
				}
			}
        }
        if (this.state.userList.length > 0 && this.state.leader === '') {
            // assume users were just added and the leader was not selected yet
            this.setState({leader: this.state.userList[0].name})
        }
        // hide the UserList box
        const UserBoxDialog = document.getElementById('UserBoxDialog')
        UserBoxDialog.close();
    }
    render() {
		// predefined names for the columns
		var columns = [{
            Header: 'UserGroups',
            columns: [
            {
                Header: 'Team Name',
                accessor: 'name',
            },
            {
                Header: 'Leader Email',
                accessor: 'email',
            },
            {
                Header: 'Phone Number',
                Cell: ({row}) => {
                    let num = row.original.phoneNumber;
                    let phone = '';
                    if (num !== null && num !== undefined) {
                        // assume the user has a number 
                        num = num.toString(); // convert to string
                        phone = '(' + num.substr(0,3) + ')-' + num.substr(3,3) + '-' + num.substr(6);
                    }
                    return phone;
                }
            },
            {
                Header: 'Client',
                accessor: 'companyID',
            },
            {
                Header: 'Users',
                Cell: ({row}) => {
                    if (row.original.users === undefined || row.original.users === null) {
                        return 0
                    }
                    return row.original.users.length
                }
            },
            {
            // Edit button functionality
                Header: '   ',
                Cell: ({row}) => {
                    return <img title="Edit Team" height={25} width={25} alt="edit" src={Edit} className='icon_button'
                    onClick={() => {
                        let data = row.original;
                        data.index = row.id;
                        this.setState({
                            editGroup: true,
                            index: row.id,
                            dialogTitle: 'Edit Team'
                        })
                        this.populateUserGroupBox(data)
                    }}/>
                }
            },
            {
            // Delete button functionality
                Header: ' ',
                Cell: ({row}) => {
                    return <img title="Delete Team" height={25} width={25} alt="delete" src={TrashCan} className='icon_button'
                    onClick={() => {
                        let res = window.confirm('Are you sure you want to delete the Plan?')
                        if (res) {
                            let data = row.original;
                            data.index = row.id;
                            this.props.onDeleteUserGroup(data)
                        }
                    }}/>
                }
            }]
        }];
        if (this.props.role !== 'Root') {
			// assume either Super Admin or Manager is viewing the table
			columns[0].columns.splice(3,1) // remove the deleted button column
        }

        console.log(this.state.leader);
        return (

            <main>
                <p id="breadCrumbs">{this.props.breadCrumbs}</p>
                <h1>User List</h1>
                <ul className="topnav">
                    <li id="secondaryNavigationItems" onClick={this.props.onViewUserList} className={this.props.highlight[0]}>Users</li>
                    <li id="secondaryNavigationItems" onClick={this.props.onViewUserGroups} className={this.props.highlight[1]}>Teams</li>
                    <li id="secondaryNavigationItems" onClick={this.props.onViewDeletedUsers} className={this.props.highlight[2]}>Deleted</li>
                </ul>
                <nav>
                   <Container fluid> 
                     <Row>
                        <Col>
                            <div className="search">
                                <Select options={this.props.groupList.map((group) => {return { 'value': group.name, 'label': group.name }})} 
                                    id='searchBox' placeholder='Select a Team'
                                    value={this.state.inputText}
                                    onChange={(event) => {
                                        this.props.onfilterTable({
                                            type: 'FILTER_USER_GROUPS',
                                            value: event.value
                                        })
                                        let inputText = {label: event.label, value: event.label}
                                        this.setState({inputText: inputText})
                                    }}/>
                                <img id="resetTableListBtn" src={Reset} alt="Reset" className="icon_button"
                                    onClick={() => {
                                        this.props.onViewUserGroups()
        								this.setState({inputText: null})
                                	}}/>
                            </div>
                        </Col>
                        <Col>
                            <ul className="addItem">
                                <li><button type="button" onClick={this.showUserGroupBox} className="btn">Create Team</button></li>
                            </ul>
                        </Col>
                      </Row>
                    </Container>
                </nav>
                <Styles>
                    {/* gets the user group data */}
                    <Table columns={columns} data={(this.props.viewFilteredList) ? this.props.filteredList : this.props.groupList} />
                </Styles>
                {/* User Group Form for Creating/Editing */}
                <dialog id="addUserGroupDialog" className="bigDialogBox" style={{'height': '100%', 'paddingLeft': '5%'}} >
                    <img id="exit-dialog-icon" src={Exit} height={28} onClick={this.hideUserGroupBox} alt="exit button"/>
                        <h2 style={{'paddingTop': '4%'}}>{this.state.dialogTitle}</h2>
                    <input type="text" value={this.state.name} onChange={this.handleNameChange} placeholder="Team Name" />
                    <br/>
                    <p>User(s)</p>
                    {/* User List Added to Plan */}
                    <ul id="editUserGroupList"></ul>
                    <div>
                        <span onClick={this.showUserBoxModal} className="addItemPlan">+</span>
                        <span className="addItemText">Add New User</span>
                    </div>
                    <br/>
                    <p> Team Leader</p><select id="editLeaderUserGroup" style={{'marginBottom':'20px'}} value={this.state.leader} onChange={this.handleLeaderChange} />
                    <br/>
                    <div className="btn--flex">
                        <button onClick={this.handleUserGroupAction} type="button" className="btn btn--confirm dialog__btn">
                            Confirm
                        </button>
                        <button onClick={this.hideUserGroupBox} type="button" className="btn btn--close dialog__btn">
                            Cancel
                        </button>
                    </div>
                </dialog>
                <AddUserListBox onHideUserBox={this.hideUserBoxModal} dialogHeader={'Team'} 
                    options={this.state.userListBox} selectedUsers={this.state.selectedUsers}
                    handleUserListChange={this.handleUserListChange}/>
            </main>
        );
    }
}
export default UserGroupTable;