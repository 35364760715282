import React, { Component, Fragment } from 'react'
import AddEventDialog from './addEventDialog'
import AddManyEventsDialog from './addManyEventsDialog'
import Select from 'react-select'
import Input from 'react-phone-number-input/input'
import CurrencyInput from 'react-currency-input-field';
import Styles from './Styles'
import Table from './Table'
import TrashCan from './style/delete.png'
import Edit from './style/edit.png'
import ChatBubble from './style/clientChat.png'
import Exit from './style/exit.png'
import Reset from './style/reset_btn.png'
import ItemDefaultImage from './style/default-product-image.png'
import BackArrow from './style/leftArrow.png'
import './style/EventTable.css'

//BOOTSRAP:
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import DatePicker from "react-datepicker";
import DateTimePicker from 'react-datetime-picker';
import "react-datepicker/dist/react-datepicker.css";
import ProgressBar from "@ramonak/react-progress-bar";
import Multiselect from 'multiselect-react-dropdown';

// const db = FBapp.firestore();

class EventTable extends Component {

    constructor() {
        super();
        this.state = {
            index: 0,
            name: '',
            description: '',
            maxParticipants: 0,
            maxSpectators: 0,
            price: 0,
            spectatingCost: 0,
            startDate: new Date(),
            expiration: new Date(),
            eventDetails: false,
            ticketURL:'',
            itemImage:'',
            itemSrc:'',
            virtual:false,
            level:null,
            denial_text:'',
            denial_body:'',
            denial_description:'',
            inputText:''

        }

        this.handleNameChange = this.handleNameChange.bind(this)
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
        this.handleMParticipantsChange = this.handleMParticipantsChange.bind(this)
        this.handleMSpectatorsChange = this.handleMSpectatorsChange.bind(this)
        this.handlePriceChange = this.handlePriceChange.bind(this)
        this.handleSpectatingPriceChange = this.handleSpectatingPriceChange.bind(this)
        this.handleStartDateChange = this.handleStartDateChange.bind(this)
        this.handleLastSignUpDateChange = this.handleLastSignUpDateChange.bind(this)
        this.handleEndDateChange = this.handleEndDateChange.bind(this)
        this.handleAddressChange = this.handleAddressChange.bind(this)
        this.handleStreetChange = this.handleStreetChange.bind(this)
        this.handleCityChange = this.handleCityChange.bind(this)
        this.handleStateChange = this.handleStateChange.bind(this)
        this.handleZipChange = this.handleZipChange.bind(this)
        this.handleImageChange = this.handleImageChange.bind(this)
        this.handleTicketURLChange = this.handleTicketURLChange.bind(this)
        this.toggleVirtual = this.toggleVirtual.bind(this)
        this.handleLevelChange = this.handleLevelChange.bind(this)
        this.handleEditItem = this.handleEditItem.bind(this)
        this.hideModal = this.hideModal.bind(this)

        this.showDenyEventModal = this.showDenyEventModal.bind(this)
        this.hideDenyEventModal = this.hideDenyEventModal.bind(this)

        this.getPrevData = this.getPrevData.bind(this)
        this.createEventLog = this.createEventLog.bind(this)

        this.handleDenialTextChange = this.handleDenialTextChange.bind(this)
        this.handleDenialDescChange = this.handleDenialDescChange.bind(this)
        this.handleDenyEvent = this.handleDenyEvent.bind(this)
        this.setDenialReason = this.setDenialReason.bind(this)
    }

    setDenialReason(obj){
      this.setState({
        inputText:{text:obj.text,body:obj.body},
        denial_text:obj.text,
        denial_body:obj.body
      })
    }

    handleDenialTextChange(text){
      // this.setState({denial_text:text})
    }

    handleDenialDescChange(event){
      this.setState({denial_description:event.target.value})
    }

    handleDenyEvent(){
      this.props.denyEvent({id:this.state.id,text:this.state.denial_text, body:this.state.denial_body, desc:this.state.denial_description})
      this.hideDenyEventModal()
      this.setState({activationStatus:'DENIED',
                      event_denial_body:this.state.denial_body,
                      event_denial_text:this.state.denial_text,
                      event_denial_desc:this.state.denial_description});
    }


    handleNameChange(event) {
        this.setState({ name: event.target.value });

    }
    handleDescriptionChange(event) {
        this.setState({ description: event.target.value });
    }
    handleTicketURLChange(event){
      this.setState({ticketURL:event.target.value});
    }
    handleMParticipantsChange(event) {
        this.setState({ maxParticipants: event.target.value });
    }
    handleMSpectatorsChange(event) {
        this.setState({ maxSpectators: event.target.value });
    }
    handlePriceChange(value) {
        this.setState({ price: value });
    }
    handleSpectatingPriceChange(value) {
        this.setState({ spectatingCost: value },()=>{console.log('')});
    }
    handleStartDateChange(date) {
        this.setState({startDate:date});
    }
    handleEndDateChange(date) {
        let exp = new Date(date.valueOf());
        exp.setDate(exp.getDate() + 3);
        this.setState({
            endDate: date,
            expiration: exp
        });
    }
    handleLastSignUpDateChange(date) {
        this.setState({ lastSignUpDate: date });
    }
    handleAddressChange(event) {
        this.setState({ address: event.target.value })
    }

    handleStreetChange(event){
        this.setState({street:event.target.value});
    }

    handleCityChange(event){
        this.setState({city:event.target.value});
    }

    handleStateChange(event){
        this.setState({state:event.target.value});
    }

    handleZipChange(event){
        this.setState({zipcode:event.target.value});
    }

    handleImageChange(event) {
        event.preventDefault();
        let reader = new FileReader();
        // get the file thats been uploads
        let file = event.target.files[0];
        // after the file finished loading
        reader.onloadend = () => {
            this.setState({
                itemImage: file,
                itemSrc: reader.result
            });
        }
        reader.readAsDataURL(file);
    }

    toggleVirtual(){
      this.setState({
        virtual:!(this.state.virtual)
        })
    }

    showModal(action) {

        this.props.onEditBreadCrumbs({
            type: 'APPEND_BREADCRUMB',
            breadCrumbs: " > Edit Event"
        })


        const editItemDialog = document.getElementById('editItemDialog');
        editItemDialog.style.left = (this.props.toggleMenu) ? '105px' : '330px'
            // display edit user dialog

        let eventCategories = this.props.eventCategories;
        let categoriesToAdd = [];

        for(let e of eventCategories){
          console.log(e.id)
          categoriesToAdd.push(e.id)
        }

        this.setState({
          categoriesToAdd:categoriesToAdd
        });

        editItemDialog.showModal();

    }

    showDenyEventModal(){
      this.props.onEditBreadCrumbs({
          type: 'APPEND_BREADCRUMB',
          breadCrumbs: " > Deny Event"
      })

      const denyEventDialog = document.getElementById('denyEventDialog');
      denyEventDialog.style.left = (this.props.toggleMenu) ? '105px' : '330px'
      denyEventDialog.showModal();
    }

    hideDenyEventModal() {
        this.props.onEditBreadCrumbs({
            type: 'REMOVE_BREADCRUMB',
            breadCrumbs: "Event"
        })
        let denyEventDialog = document.getElementById('denyEventDialog');
        denyEventDialog.close();

    }



    getPrevData(data)
  	{
  		console.log(data);

  		//before edited data
  		let arr = []
  		arr.push({
        name: data.name,
        description: data.description,
        ticketURL: data.ticketURL,
        maxParticipants: data.maxParticipants,
        maxSpectators: data.maxSpectators,
        entryCost: data.price,
        virtual:data.virtual,
        street:data.street,
        city:data.city,
        state:data.state,
        zipcode:data.zipcode,
        spectatingCost: data.spectatingCost,
        startDate: data.startDate,
        endDate: data.endDate,
        expiration: data.expiration,
        address: data.address,
        lastSignUpDate: data.lastSignUpDate,
        itemImage:data.itemImage,
        itemSrc:data.itemSrc,
        level:data.level,
        id: data.id
  		});

  		this.setState({prevData : arr})
  	 }

    createEventLog(){
      if(this.state.prevData.length !== 0){
        if(this.state.prevData[0]['name'] != this.state.name){
          this.props.createEventLog({
            logType: "edited",
    	 			source: "web-console",
            message: "Event name updated",
    	      oldValue: this.state.prevData[0]['name'],
    	      newValue: this.state.name,
    	      document: this.state.prevData[0]['id']
          });
        }

        if(this.state.prevData[0]['description'] != this.state.description){
          this.props.createEventLog({
            logType: "edited",
            source: "web-console",
            message: "Event description updated",
            oldValue: this.state.prevData[0]['description'],
            newValue: this.state.description,
            document: this.state.prevData[0]['id']
          });
        }

        if(this.state.prevData[0]['virtual'] != this.state.virtual){
          this.props.createEventLog({
            logType: "edited",
            source: "web-console",
            message: "Event virtual status updated",
            oldValue: this.state.prevData[0]['virtual'],
            newValue: this.state.virtual,
            document: this.state.prevData[0]['id']
          });
        }

        if(this.state.prevData[0]['ticketURL'] != this.state.ticketURL){
          this.props.createEventLog({
            logType: "edited",
            source: "web-console",
            message: "Event ticket URL updated",
            oldValue: this.state.prevData[0]['ticketURL'],
            newValue: this.state.ticketURL,
            document: this.state.prevData[0]['id']
          });
        }

        if(this.state.prevData[0]['maxParticipants'] != this.state.maxParticipants){
          this.props.createEventLog({
            logType: "edited",
            source: "web-console",
            message: "Event max participants updated",
            oldValue: this.state.prevData[0]['maxParticipants'],
            newValue: this.state.maxParticipants,
            document: this.state.prevData[0]['id']
          });
        }

        if(this.state.prevData[0]['maxSpectators'] != this.state.maxSpectators){
          this.props.createEventLog({
            logType: "edited",
            source: "web-console",
            message: "Event max spectators updated",
            oldValue: this.state.prevData[0]['maxSpectators'],
            newValue: this.state.maxSpectators,
            document: this.state.prevData[0]['id']
          });
        }

        if(this.state.prevData[0]['price'] != this.state.price){
          this.props.createEventLog({
            logType: "edited",
            source: "web-console",
            message: "Event entry cost updated",
            oldValue: this.state.prevData[0]['price'],
            newValue: this.state.price,
            document: this.state.prevData[0]['id']
          });
        }

        if(this.state.prevData[0]['street'] != this.state.street){
          this.props.createEventLog({
            logType: "edited",
            source: "web-console",
            message: "Event street updated",
            oldValue: this.state.prevData[0]['street'],
            newValue: this.state.street,
            document: this.state.prevData[0]['id']
          });
        }

        if(this.state.prevData[0]['city'] != this.state.city){
          this.props.createEventLog({
            logType: "edited",
            source: "web-console",
            message: "Event city updated",
            oldValue: this.state.prevData[0]['city'],
            newValue: this.state.city,
            document: this.state.prevData[0]['id']
          });
        }

        if(this.state.prevData[0]['state'] != this.state.state){
          this.props.createEventLog({
            logType: "edited",
            source: "web-console",
            message: "Event state updated",
            oldValue: this.state.prevData[0]['state'],
            newValue: this.state.state,
            document: this.state.prevData[0]['id']
          });
        }

        if(this.state.prevData[0]['zipcode'] != this.state.zipcode){
          this.props.createEventLog({
            logType: "edited",
            source: "web-console",
            message: "Event zipcode updated",
            oldValue: this.state.prevData[0]['zipcode'],
            newValue: this.state.zipcode,
            document: this.state.prevData[0]['id']
          });
        }

        if(this.state.prevData[0]['spectatingCost'] != this.state.spectatingCost){
          this.props.createEventLog({
            logType: "edited",
            source: "web-console",
            message: "Event spectating cost updated",
            oldValue: this.state.prevData[0]['spectatingCost'],
            newValue: this.state.spectatingCost,
            document: this.state.prevData[0]['id']
          });
        }

        if(this.state.prevData[0]['startDate'] != this.state.startDate){
          this.props.createEventLog({
            logType: "edited",
            source: "web-console",
            message: "Event start date updated",
            oldValue: this.state.prevData[0]['startDate'],
            newValue: this.state.startDate,
            document: this.state.prevData[0]['id']
          });
        }

        if(this.state.prevData[0]['endDate'] != this.state.endDate){
          this.props.createEventLog({
            logType: "edited",
            source: "web-console",
            message: "Event end date updated",
            oldValue: this.state.prevData[0]['endDate'],
            newValue: this.state.endDate,
            document: this.state.prevData[0]['id']
          });
        }

        if(this.state.prevData[0]['lastSignUpDate'] != this.state.lastSignUpDate){
          this.props.createEventLog({
            logType: "edited",
            source: "web-console",
            message: "Event last sign up date updated",
            oldValue: this.state.prevData[0]['lastSignUpDate'],
            newValue: this.state.lastSignUpDate,
            document: this.state.prevData[0]['id']
          });
        }

        if(this.state.prevData[0]['level'] != this.state.level){
          this.props.createEventLog({
            logType: "edited",
            source: "web-console",
            message: "Event sport level updated",
            oldValue: this.state.prevData[0]['level'],
            newValue: this.state.level,
            document: this.state.prevData[0]['id']
          });
        }

      }
    }

    handleLevelChange(event){
      this.setState({level:event.target.value})
    }

    handleEditItem() {
        console.log(this.state);

        if(this.state.name !== '' && this.state.description !== '' && (this.state.virtual == true || (this.state.virtual == false && this.state.street !== '' && this.state.city !== '' && this.state.state !== '' && this.state.zipcode !== '')) && this.state.startDate !== '' && this.state.endDate !== '' ){

        let address = '';

        if(this.state.street != ''){
          address = address + this.state.street + ' ';
        }

        if(this.state.city != ''){
          address = address + this.state.city + ' ';
        }
        if(this.state.state != ''){
          address = address + this.state.state + ' ';
        }
        if(this.state.zipcode != ''){
          address = address + this.state.zipcode + ' ';
        }

        this.createEventLog()
        this.props.onEditEvent({
            name: this.state.name,
            description: this.state.description,
            ticketURL: this.state.ticketURL,
            maxParticipants: this.state.maxParticipants,
            maxSpectators: this.state.maxSpectators,
            entryCost: this.state.price,
            virtual:this.state.virtual,
            street:this.state.street,
            city:this.state.city,
            state:this.state.state,
            zipcode:this.state.zipcode,
            spectatingCost: this.state.spectatingCost,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            expiration: this.state.expiration,
            address: this.state.address,
            lastSignUpDate: this.state.lastSignUpDate,
            itemImage:this.state.itemImage,
            itemSrc:this.state.itemSrc,
            level:this.state.level,
            id: this.state.id
        });
        this.setState({address:address});
        this.hideModal();
        }else{
            alert("Please fill all the fields");
        }
    }

    viewDescription() {
        const descDialog = document.getElementById('descBox');
        descDialog.style.left = (this.props.toggleMenu) ? '105px' : '330px'
            // display edit user dialog
        descDialog.showModal();
    }

    hideDescBox() {
        let descDialog = document.getElementById('descBox');
        descDialog.close();
    }

    hideModal() {
        this.props.onEditBreadCrumbs({
            type: 'REMOVE_BREADCRUMB',
            breadCrumbs: "Event"
        })
        let editItemDialog = document.getElementById('editItemDialog');
        editItemDialog.close();

    }
    render() {

        var category_columns = [
        {
            Header: 'categories',
            columns: [{
                    id:'name',
                    Header: 'Categories',
                    accessor: 'name',
                    Cell: ({row})=>{
                        return <span>{row.original.name}</span>
                    }
                }]
        }];

        var sub_category_columns = [
        {
            Header: 'Sub Categories',
            columns: [{
                    id:'name',
                    Header: 'Sub Categories',
                    accessor: 'name',
                    Cell: ({row})=>{
                        return <span>{row.original.name}</span>
                    }
                }]
        }];

        var columns = [{
            Header: 'Events',
            columns: [{
                    Header: '     ',
                    Cell: ({ row }) => {
                        let img = null;
                        if(row.original.images){
                            img = row.original.images[0];
                        }
                        if (!img) {
                            img = ItemDefaultImage;
                        }
                        return <img style = {{ 'borderRadius': '50%', 'background': 'lightgrey' }} src = {img} width = "40" height = "40" alt = "profile" />
                    }
                },
                {
                    Header: 'Event Name',
                    Cell: ({ row }) => {
                        let name = row.original.name;
                        let rData = row.original;
                        return <span style = {
                            { 'cursor': 'pointer' }
                        }
                        onClick = {
                            (event) => {
                                event.persist();
                                let startDate = null;
                                let startDateText = null;
                                let endDate = null;
                                let endDateText = null;
                                let expiration = null;
                                let expirationText = null;
                                let lastSignUpDate = null;
                                let lastSignUpDateText = null;
                                let eventImg = ItemDefaultImage;
                                let revenue = '';
                                let spectatingCost = 0;
                                let entryCost = '';
                                let spectatingPrice = '';
                                let img = null;
                                let ticketURL = '';
                                let virtual = false;
                                let part_progress = 0;
                                let spect_progress = 0;
                                let level = null;
                                if(row.original.images){
                                    img = row.original.images[0];
                                }
                                if (rData.startDate && rData.startDate != 'null') {
                                    startDate = rData.startDate.toDate();
                                    startDateText = (startDate.getMonth() + 1) + "/" + (startDate.getDate()) + "/" + (startDate.getFullYear())
                                }
                                if (rData.endDate && rData.endDate != 'null') {
                                    endDate = rData.endDate.toDate();
                                    endDateText = (endDate.getMonth() + 1) + "/" + (endDate.getDate()) + "/" + (endDate.getFullYear())
                                }
                                if (rData.lastSignUpDate && rData.lastSignUpDate != 'null') {
                                    lastSignUpDate = rData.lastSignUpDate.toDate();
                                    lastSignUpDateText = (lastSignUpDate.getMonth() + 1) + "/" + (lastSignUpDate.getDate()) + "/" + (lastSignUpDate.getFullYear())
                                }
                                if (rData.expiration && rData.expiration != 'null') {
                                    expiration = rData.expiration.toDate();
                                    expirationText = (expiration.getMonth() + 1) + "/" + (expiration.getDate()) + "/" + (expiration.getFullYear())
                                }
                                let participants = 0;
                                if (rData.participants !== undefined && rData.participants !== '') {
                                    participants = rData.participants.length;
                                }
                                let spectators = 0;

                                if (rData.spectators !== undefined && rData.spectators !== '') {
                                    spectators = rData.spectators.length;
                                }

                                let maxParticipants = '';
                                if (rData.maxParticipants !== undefined && rData.maxParticipants !== '') {
                                    maxParticipants = rData.maxParticipants;
                                }

                                let maxSpectators = '';
                                if (rData.maxSpectators !== undefined && rData.maxSpectators !== '') {
                                    maxSpectators = rData.maxSpectators;
                                }

                                if(rData.spectatingCost !== undefined && rData.spectatingCost !== ''){
                                    spectatingCost = rData.spectatingCost;
                                }
                                if(rData.entryCost !== undefined && rData.entryCost !== ''){
                                    entryCost = rData.entryCost;
                                }
                                if(rData.ticketURL){
                                  ticketURL = rData.ticketURL;
                                }

                                if(rData.virtual != undefined){
                                  virtual = rData.virtual;
                                }

                                if(rData.entryCost !== undefined && rData.spectatingCost !== undefined && rData.entryCost !== '' && rData.spectatingCost !== '' ){
                                  revenue = (participants * entryCost) + (spectators * spectatingCost);
                                }
                                else{
                                  revenue = '';
                                }

                                if(rData.level != undefined && rData.level != ''){
                                  level = rData.level
                                }

                                this.setState({
                                    name: rData.name,
                                    eventImage: img,
                                    maxParticipants: maxParticipants,
                                    maxSpectators: maxSpectators,
                                    participants: participants,
                                    spectators: spectators,
                                    entryCost: rData.entryCost,
                                    spectatingCost: rData.spectatingCost,
                                    prize: rData.prize,
                                    description: rData.description,
                                    ticketURL:ticketURL,
                                    startDate: startDate,
                                    endDate: endDate,
                                    expiration:expiration,
                                    lastSignUpDate: lastSignUpDate,
                                    startDateText: startDateText,
                                    expirationText:expirationText,
                                    endDateText: endDateText,
                                    lastSignUpDateText: lastSignUpDateText,
                                    address: rData.address,
                                    street: rData.street,
                                    city: rData.city,
                                    state: rData.state,
                                    zipcode: rData.zipcode,
                                    revenue: revenue,
                                    virtual:virtual,
                                    level:level,
                                    activationStatus:rData.activationStatus,
                                    event_denial_text:rData.denial_text,
                                    event_denial_body:rData.denial_body,
                                    event_denial_desc:rData.denial_desc,
                                    id: rData.id
                                });

                                this.props.fetchEventCategories(rData.sports);

                                setTimeout(()=>{
                                    this.setState({
                                        eventDetails:true
                                    })
                                },1500)


                                this.props.onEditBreadCrumbs({
                                    type: 'APPEND_BREADCRUMB',
                                    breadCrumbs: " > " + rData.name
                                })

                            }
                        } > {name} </span>
                    },
                    accessor: 'name'
                },
                {
                    Header: 'Activation Status',
                    Cell: ({ row }) => {
                        let activationStatus = row.original.activationStatus
                          return <span>{activationStatus}</span>
                    },
                    accessor:'activationStatus'

                },
                // {
                //     Header: '    ',
                //     Cell: ({ row }) => {
                //         let deactivated = row.original.deactivated
                //         if(deactivated == true){
                //           return <button id="activateEventButton" title="Activates the event." onClick={() => this.props.activateEvent({id :row.original.id})}>ACTIVATE</button>
                //         }else{
                //           return null;
                //         }
                //
                //     },
                //     accessor:'deactivated'
                //
                // },
                {
                    Header: 'Start Date',
                    Cell: ({ row }) => {
                        let rowData = row.original;
                        let start_date = '';
                        let formatted_date = '';
                        if (rowData.startDate) {
                            start_date = rowData.startDate.toDate();
                            formatted_date = (start_date.getMonth() + 1) + "/" + (start_date.getDate()) + "/" + (start_date.getFullYear())
                        }
                        return formatted_date
                    },
                    accessor:'start_date'

                },
                {
                    Header: 'End Date',
                    Cell: ({ row }) => {
                        let rowData = row.original;
                        let end_date = '';
                        let formatted_date = '';
                        if (rowData.endDate != undefined && rowData.endDate != null && rowData.endDate != '' && rowData.endDate != 'null') {
                            end_date = rowData.endDate.toDate();
                            formatted_date = (end_date.getMonth() + 1) + "/" + (end_date.getDate()) + "/" + (end_date.getFullYear())
                        }
                        return formatted_date
                    },
                    accessor:'end_date'
                },
                {
                    Header: 'Participants',
                    Cell: ({ row }) => {
                        let rowData = row.original;
                        let participant_list = rowData.participants;
                        let participants = 0;
                        if (participant_list) {
                            participants = participant_list.length;
                        }
                        let max_participants = '?';
                        if (rowData.maxParticipants) {
                            max_participants = rowData.maxParticipants;
                        }
                        return participants + "/" + max_participants;
                    },
                    accessor: 'participants'
                },
                {
                    Header: 'Spectators',
                    Cell: ({ row }) => {
                        let rowData = row.original;
                        let spectator_list = rowData.spectators;
                        let spectators = 0;
                        if (spectator_list) {
                            spectators = spectator_list.length;
                        }
                        let max_spectators = '?';
                        if (rowData.maxSpectators) {
                            max_spectators = rowData.maxSpectators;
                        }
                        return spectators + "/" + max_spectators;
                    },
                    accessor: 'spectators'
                },
                {
                    Header: 'Participating Cost',
                    Cell: ({ row }) => {
                        let cost = '?'
                        if( row.original.entryCost !== undefined && row.original.entryCost !== '' && row.original.entryCost !== null){
                          cost = "$"+row.original.entryCost
                        }
                        return cost;
                        // return row.original.entryCost
                    },
                    accessor:'cost'
                },
                {
                    Header: 'Total Rev',
                    Cell: ({ row }) => {
                        let cost = row.original.entryCost;
                        let s_cost = row.original.spectatingCost;
                        let total_rev = '?';
                        if( row.original.entryCost !== undefined && row.original.entryCost !== '' && row.original.entryCost !== null && row.original.spectatingCost !== undefined && row.original.spectatingCost !== '' && row.original.spectatingCost !== null){
                          let participants = 0;
                          if (row.original.participants) {
                              participants = row.original.participants.length
                          }
                          let spectators = 0;
                          if (row.original.spectators) {
                              spectators = row.original.spectators.length
                          }
                          total_rev = "$" + ((cost * participants)+(s_cost * spectators));
                        }
                        return total_rev;
                    },
                    accessor:'total_rev'
                },
                {
                    Header: '  ',
                    Cell: ({ row }) => {
                        let rData = row.original
                        return <img title = "Edit Event"
                        height = { 25 }
                        width = { 25 }
                        alt = "edit"
                        className = 'icon_button'
                        src = { Edit }
                        onClick = {
                            (event) => {
                                event.persist();
                                let img = null;
                                if(row.original.images){
                                    img = row.original.images[0];
                                }
                                else{
                                    img = ItemDefaultImage;
                                }
                                let startDate = null;
                                let startDateText = null;
                                let endDate = null;
                                let expiration = null;
                                let lastSignUpDate = null;
                                let spectatingCost = '';
                                let entryCost = '';
                                let spectatingPrice = '';
                                let ticketURL = '';
                                let virtual = false;
                                let level = null;

                                if (rData.startDate && rData.startDate != 'null') {
                                    startDate = rData.startDate.toDate();

                                }
                                if (rData.endDate && rData.endDate != 'null') {
                                    endDate = rData.endDate.toDate();

                                }
                                if (rData.lastSignUpDate && rData.lastSignUpDate != 'null' && rData.lastSignUpDate !== null) {
                                    lastSignUpDate = rData.lastSignUpDate.toDate();

                                }
                                if (rData.expiration && rData.expiration != 'null') {
                                    expiration = rData.expiration.toDate();

                                }

                                let maxParticipants = '';
                                if (rData.maxParticipants !== undefined && rData.maxParticipants !== '' && rData.maxParticipants !== null) {
                                    maxParticipants = rData.maxParticipants;
                                }

                                let maxSpectators = '';
                                if (rData.maxSpectators !== undefined && rData.maxSpectators !== '' && rData.maxSpectators !== null) {
                                    maxSpectators = rData.maxSpectators;
                                }

                                if(rData.spectatingCost !== undefined && rData.spectatingCost !== '' && rData.spectatingCost !== null){
                                    spectatingCost = rData.spectatingCost;
                                }
                                if(rData.entryCost !== undefined && rData.entryCost !== '' && rData.entryCost !== null){
                                    entryCost = rData.entryCost;
                                }
                                if(rData.ticketURL){
                                  ticketURL = rData.ticketURL;
                                }

                                if(rData.virtual != undefined){
                                  virtual = rData.virtual;
                                }

                                if(rData.level != undefined && rData.level != ''){
                                  level = rData.level
                                }

                                let event_to_edit = {
                                    name: rData.name,
                                    eventImage: img,
                                    itemSrc:img,
                                    maxParticipants: maxParticipants,
                                    maxSpectators: maxSpectators,
                                    entryCost: rData.entryCost,
                                    spectatingCost: rData.spectatingCost,
                                    prize: rData.prize,
                                    description: rData.description,
                                    ticketURL:ticketURL,
                                    startDate: startDate,
                                    endDate: endDate,
                                    expiration:expiration,
                                    lastSignUpDate: lastSignUpDate,
                                    address: rData.address,
                                    street: rData.street,
                                    city: rData.city,
                                    state: rData.state,
                                    zipcode: rData.zipcode,
                                    virtual:virtual,
                                    level:level,
                                    activationStatus:rData.activationStatus,
                                    event_denial_text:rData.denial_text,
                                    event_denial_body:rData.denial_body,
                                    event_denial_desc:rData.denial_desc,
                                    id: rData.id
                                }
                                this.setState(event_to_edit);

                                if(this.state.eventDetails !== true){
                                  this.props.fetchEventCategories(rData.sports);
                                }

                                setTimeout(()=>{
                                    this.getPrevData(event_to_edit)
                                    this.showModal();
                                },1500)


                            }
                        }
                        />
                    }
                },
                {
                    Header: ' ',
                    Cell: ({ row }) => {
                        return <img title = "Delete Item"
                        height = { 25 }
                        width = { 25 }
                        src = { TrashCan }
                        alt = "delete"
                        className = 'icon_button'
                        onClick = {
                            (event) => {
                                event.persist();
                                // var rowIndex = row.row.id;
                                let rData = row.original;
                                console.log(rData);
                                var res = window.confirm("Are you sure you want to delete the Event?");
                                if (res) {
                                    // Supposed to send the row ID of the User the admin wants to delete
                                    this.props.onDeleteEvent({ id: rData.id });
                                }
                            }
                        }
                        />
                    }
                }
            ]
        }]


        return (

                <main >
                <p id = "breadCrumbs" > { this.props.breadCrumbs } < /p> <h1> Event </h1>

                {(this.state.eventDetails == false)?
                <nav>
                <Container fluid>
                <Row>
                <Col>
                <div className = "search" >
                <input type = "text"
                value = ''
                value = { this.state.inputText }
                onChange = {
                    (event) => {
                        this.props.onfilterTable({
                            type: 'FILTER_EVENT_LIST',
                            value: event.target.value
                        })
                        let inputText = { value: event.target.value }
                        this.setState({ inputText: event.target.value })
                    }
                }
                placeholder = "Search by Name" />
                  {
                    (this.props.role == 'root') ?
                    <Fragment>
                    <Select options = { this.props.businessUserList.map((b_user) => { return { 'value': b_user.id, 'label': b_user.name } }) }
                    id = 'searchBox'
                    placeholder = 'Search by Owner'
                    value = { this.state.inputText }
                    onChange = {
                        (event) => {
                            this.props.onfilterTable({
                                type: 'FILTER_EVENT_BY_OWNER',
                                value: event.value
                            })
                        }
                    }
                    /> </Fragment> : null
                }
                <img id = "resetTableListBtn"
                src = { Reset }
                alt = "Reset"
                className = "icon_button"
                onClick = {
                    () => {
                        this.props.oneventList()
                        this.setState({ inputText: null })
                    }
                }
                />
                </div></Col>
                <Col>
                <ul className = "addItem" >
                <li >
                <AddEventDialog onAddItem = {
                    (newItem) => this.props.onAddEvent(newItem)
                }
                onEditBreadCrumbs = {
                    (breadCrumbs) => this.props.onEditBreadCrumbs(breadCrumbs)
                }
                toggleMenu = { this.props.toggleMenu }
                businessUserList = { this.props.businessUserList }
                categoryList = {this.props.categoryList}
                role = { this.props.role }
                uid = { this.props.uid }
                /> </li> <li>
                <AddManyEventsDialog onManyAddEvents = { this.props.onAddManyEvents }
                onEditBreadCrumbs = { this.props.onEditBreadCrumbs }
                businessUserList = { this.props.businessUserList }
                role = { this.props.role }
                uid = { this.props.uid }
                /> </li>


                </ul> </Col>

                </Row> </Container> </nav>
                : ''}

            <Styles> { /* gets the user data from the userPage class and fills in the table */ } {
                (this.state.eventDetails == false) ?
                <Table columns = { columns }
                data = {
                    (this.props.viewFilteredList) ? this.props.filteredList : this.props.eventList
                }
                /> :
                <Container fluid>
                <Row>
                <span style = {
                    { 'cursor': 'pointer' }
                }
                onClick = {
                    (event) => {
                        this.setState({ eventDetails: false });
                        this.props.onEditBreadCrumbs({
                            type: 'REMOVE_BREADCRUMB',
                            breadCrumbs: "Event"
                        })
                    }
                }> <img src = { BackArrow }
                />back to list</span>
                </Row>
                <Row>
                    <Col>
                        <h2> { this.state.name } <img title = "Edit Event"
                        height = { 25 }
                        width = { 25 }
                        alt = "edit"
                        className = 'icon_button'
                        src = { Edit }
                        onClick = {
                            (event) => {
                                this.showModal();
                            }
                        }
                        /></h2>
                        <div>
                        <img style = {
                            { 'background': 'lightgrey' }
                        }
                        src = { this.state.eventImage }
                        height = "100"
                        alt = "profile" />
                        </div>
                    </Col>
                    <Col>
                        <span>Participants: <ProgressBar completed={((this.state.participants/this.state.maxParticipants)*100)} bgColor="#1D8451" width="100%" isLabelVisible={false} /> {(this.state.participants !== undefined && this.state.participants !== '' && this.state.maxParticipants !== undefined && this.state.maxParticipants !== '' && this.state.maxParticipants !== null) ? this.state.participants+'/'+this.state.maxParticipants:'?'}</span>
                        <br/>
                        <span>Spectators: <ProgressBar completed={((this.state.spectators/this.state.maxSpectators)*100)} bgColor="#1D8451" width="100%" isLabelVisible={false}/> {(this.state.spectators !== undefined && this.state.spectators !== '' && this.state.maxSpectators !== undefined && this.state.maxSpectators !== '' && this.state.maxSpectators !== null) ? <Fragment>{this.state.spectators+'/'+this.state.maxSpectators}</Fragment>:'?'}</span>
                        <br/>
                        <span>Total Revenue: ${this.state.revenue}</span>

                    </Col>
                    <Col></Col>
                </Row>
                <Row>
                    <Col className='eventDetailBlock'>
                        <span className='detailBlockTitle'>Place And Time:</span><br/>
                        {(this.state.virtual)?
                        <Fragment><h5>Virtual Event</h5></Fragment> : null }
                        {(this.state.address != undefined && this.state.address != '')?
                        <Fragment><h5>{this.state.address}</h5></Fragment> : null}
                        <h5> Begins At : <span> { this.state.startDateText } </span></h5>
                        <h5> Ends At: <span> { this.state.endDateText } </span></h5>
                        <h5> Last Day To Sign Up: <span> { (this.state.lastSignUpDateText)?this.state.lastSignUpDateText:'?' } </span></h5>
                        <h5> Event Expires: <span> { this.state.expirationText } </span></h5>
                    </Col>
                    <Col className='eventDetailBlock'>
                        <Row>
                            <Col>
                                <Table columns = {category_columns} data = {this.props.eventCategories}/>
                            </Col>
                            <Col>
                                <Table columns = {sub_category_columns} data = {this.props.eventSubCategories}/>
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <Row>
                    <Col className='eventDetailBlock'>
                        <span className='detailBlockTitle'>Costs:</span><br/>
                        <h5>Entry Cost:</h5>
                        <h5>&nbsp;&nbsp;&nbsp; Participating: ${(this.state.entryCost)?this.state.entryCost:'?'}</h5>
                        <h5>&nbsp;&nbsp;&nbsp; Spectating: ${(this.state.spectatingCost)?this.state.spectatingCost:'?'}</h5>
                        <h5>Prize Offered: {(this.state.prize)?this.state.prize:'-'}</h5>
                    </Col>
                    <Col className='eventDetailBlock'>
                        <span className='detailBlockTitle'>Description:</span><br/>
                        {(this.state.level != null)?
                        <Fragment>
                        <p><strong>Sport Level: {this.state.level.charAt(0).toUpperCase() + this.state.level.slice(1)}</strong></p>
                        </Fragment>:null}
                        <p>{this.state.description}</p>
                        <p>Ticket Sale URL: <a href={this.state.ticketURL} target='_blank' >{this.state.ticketURL}</a></p>
                    </Col>
                </Row>
                <Row>
                  <Col className='eventDetailBlock'>
                    <span className='detailBlockTitle'>Status</span><br/><br/>
                    <h5>Current Status: {this.state.activationStatus}</h5>

                    {(this.state.activationStatus == 'DENIED' || (this.state.activationStatus == 'PENDING' && this.props.role == 'root'))?
                      <Fragment>
                        <h5>Reason: {this.state.event_denial_text}</h5>
                        <p>{this.state.event_denial_body}</p>
                        <p>{this.state.event_denial_desc}</p>
                      </Fragment>:null
                    }

                    {(this.props.role == 'root' && this.state.activationStatus == 'PENDING')?
                    <Fragment>
                    <button id="activateEventButton" title="Activates the event." onClick={() => {this.props.activateEvent({id :this.state.id})
                    this.setState({activationStatus:'ACTIVE'})}}>ACTIVATE</button>&nbsp;&nbsp;
                    <button id="activateEventButton" title="Activates the event." onClick={() => this.showDenyEventModal()}>DENY</button>
                    </Fragment>:null}

                    {(this.state.activationStatus == 'ACTIVE')?
                    <Fragment>
                    <button id="activateEventButton" title="Deactivates the event." onClick={() => this.showDenyEventModal()}>DENY</button>
                    </Fragment>:null}

                    {(this.state.activationStatus == 'DENIED')?
                    <Fragment>
                    <button id="activateEventButton" title="Activates the event." onClick={() => {this.props.activateEvent({id :this.state.id});
                    this.setState({activationStatus:'ACTIVE'})}}>ACTIVATE</button>
                    </Fragment>:null}

                    {(this.state.activationStatus == 'DENIED' && this.props.role !== 'root')?
                    <Fragment>
                      <button id="activateEventButton" title="Activates the event." onClick={() => {this.props.requestActivationEvent({id :this.state.id})
                      this.setState({activationStatus:'PENDING'})}}>Request Activation</button>
                    </Fragment>:null
                    }


                    <br/><br/>
                  </Col>
                </Row>
                </Container>

            } </Styles>


        { /* Supposed to display when the user clicks on the edit user button */ } {
            (this.props.eventList[this.state.index] !== undefined && this.props.eventList[this.state.index] !== null) ? < dialog id = "editItemDialog"
            className = "bigDialogBox" >
                <img id = "exit-dialog-icon" src = {Exit} height = {28} onClick = { this.hideModal } alt = "close pop up" />
                <h2> Edit Event </h2>

                <Container fluid>
                <Row>
                    <label> Event Image: </label> <br/>
                    <label className = "inputProfile" >
                    <input type = "file" accept = "image/*" id = "uploadImage" onChange = { this.handleImageChange } /> <img src = {(this.state.itemSrc)}alt = "item image" />
                    </label>
                </Row>

                <Row>
                    <Col>
                        <label> Name </label> <br/>
                        <input type = "text" value = { this.state.name } onChange = { this.handleNameChange } placeholder = "Name" />
                        <br/> <br/>
                        <label> Description: </label> <br/>
                        <input type = "text" value = {this.state.description} onChange = {this.handleDescriptionChange} placeholder = "Description" />
                        <br/> <br/>
                        Virtual
                        {(this.state.virtual)?<Fragment>
                        <label className="switch" title="Virtual Toggle"> <input type="checkbox" defaultChecked/><span
                        className="slider round" onClick={(event) => {
                          this.toggleVirtual();
                        }}></span></label>
                        </Fragment>:
                        <label className="switch" title="Virtual Toggle"> <input type="checkbox"/><span
                        className="slider round" onClick={(event) => {
                          this.toggleVirtual();
                        }}></span></label>
                        }

                        <br/> <br/>
                        <label> Address: </label> <br/>
                            <label> Street: </label>
                            <input type = "text" value = { this.state.street } onChange = { this.handleStreetChange } placeholder = "Street" /><br/>
                            <label> City: </label>
                            <input type = "text" value = { this.state.city } onChange = { this.handleCityChange } placeholder = "City" /><br/>
                            <label> State: </label>
                            <input type = "text" value = { this.state.state } onChange = { this.handleStateChange } placeholder = "State" /><br/>
                            <label> Zip: </label>
                            <input type = "text" value = { this.state.zipcode } onChange = { this.handleZipChange } placeholder = "Zip" /><br/>
                            <br/><br/>
                        <label> Ticket Sale URL: </label> <br/>
                        <input type = "text" value = {this.state.ticketURL} onChange = {this.handleTicketURLChange} placeholder = "Ticket Sale URL" />

                    </Col>
                    <Col>
                        <label> Max Participants: </label> <br/>
                            <input type = "text" value = { this.state.maxParticipants } onChange = { this.handleMParticipantsChange } placeholder = "Max Participants" />
                            <br/> <br/>
                            <label > Max Spectators: </label> <br/>
                            <input type = "text" value = { this.state.maxSpectators } onChange = { this.handleMSpectatorsChange } placeholder = "Max Spectators" />
                            <br/> <br/>
                            <label> Entry Cost: </label> <br/>
                            <label> Participating Cost: </label>
                            <CurrencyInput
                                placeholder = "Participating Cost"
                                decimalsLimit = { 2 }
                                prefix = '$'
                                value = { this.state.price }
                                onValueChange = {
                                    (value, name) => this.handlePriceChange(value)
                                }
                                />
                            <br/>
                            <label> Spectating Cost: </label>
                            <CurrencyInput
                                placeholder = "Spectating Cost"
                                decimalsLimit = { 2 }
                                prefix = '$'
                                defaultValue = { this.state.spectatingCost }
                                onValueChange = {
                                    (value, name) => this.handleSpectatingPriceChange(value)
                                }
                                />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label> Start Date: </label> <br/>
                        <DateTimePicker
                            value = { this.state.startDate }
                            onChange = {
                                (date) => this.handleStartDateChange(date)
                            }
                        />
                        <br/> <br/>
                        <label> End Date: </label> <br/>
                        <DateTimePicker
                            value = { this.state.endDate }
                            onChange = {
                                (date) => this.handleEndDateChange(date)
                            }
                        />
                        <br/> <br/>
                        <label> Last Day to Sign Up: </label> <br/>
                        <DateTimePicker
                            value = { this.state.lastSignUpDate }
                            onChange = {
                                (date) => this.handleLastSignUpDateChange(date)
                            }
                        />
                        <br/> <br/>
                    </Col>
                    <Col>
                        <label> Categories: </label> <br/>
                        <Multiselect
                            options={this.props.categoryList} // Options to display in the dropdown
                            selectedValues={this.state.categoriesToAdd} // Preselected value to persist in dropdown
                            displayValue="name" // Property name to display in the dropdown options
                        /><br/><br/>
                        <label>Sport Level </label><br/>
                          <input className='cat_radio' type="radio" value="pro" checked={this.state.level === 'pro'} name="cat_level" onChange={this.handleLevelChange}/> Pro
                          <input className='cat_radio' type="radio" value="amatuer" checked={this.state.level === 'amatuer'} name="cat_level" onChange={this.handleLevelChange}/> Amatuer
                    </Col>
                </Row>
                <Row>
                <div className = "btn--flex" >
                    <button onClick = { this.handleEditItem } type = "button" className = "btn btn--confirm dialog__btn" >Confirm </button>
                    <button onClick = { this.hideModal } type = "button" className = "btn btn--close dialog__btn" > Cancel </button>
                </div >
                </Row>
                </Container>
                </dialog> : null}

                < dialog id = "denyEventDialog" className = "bigDialogBox" >
                    <img id = "exit-dialog-icon" src = {Exit} height = {28} onClick = { this.hideDenyEventModal } alt = "close pop up" />
                    <h2> Deny Event </h2><br/>
                    <label>Reason</label><br/>
                    {
                      <Select options = { this.props.denials_list.map((d) => { return { 'value': {text:d.text,body:d.body}, 'label': d.text } }) }
                      id = 'searchBox'
                      placeholder = 'Select a reason'
                      onChange = {
                          (event) => {
                              this.setDenialReason({
                                  text: event.value.text,
                                  body: event.value.body
                              })
                          }
                      }
                      />
                    }
                    <br/>
                    <label>Body</label><br/>
                    {this.state.denial_body}
                    <br/>
                    <br/>
                    <label>Description</label><br/>
                    <input type = "text" value = {this.state.denial_description} onChange = {this.handleDenialDescChange} placeholder = "Description" />
                    <br/><br/>
                    <div className = "btn--flex" >
                        <button onClick = { this.handleDenyEvent } type = "button" className = "btn btn--confirm dialog__btn" >Confirm </button>
                        <button onClick = { this.hideDenyEventModal } type = "button" className = "btn btn--close dialog__btn" > Cancel </button>
                    </div >
                    </dialog>
                </main>


        );

    }


}
export default EventTable;
