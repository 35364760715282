import React, { Component } from 'react';
import  ProfilePreview from './style/default_profile.png';
import Input from 'react-phone-number-input/input'
import Exit from './style/exit.png';
import "./style/addUserDialog.css";

class AddClient extends Component {
    constructor () {
        super();
        this.state = {
            logo: '',
            name: '',
            domain: '',
            phone: '',
            pocfirst: '',
            poclast: '',
            pocphone: '',
            imgSrc: ProfilePreview
        }
        console.log("AddClient constructor: 1");
        this.handleLogoChange = this.handleLogoChange.bind(this);
        this.handleClientNameChange = this.handleClientNameChange.bind(this);
        this.handleDomainChange = this.handleDomainChange.bind(this);
        this.handlePOCFirstChange = this.handlePOCFirstChange.bind(this);
        this.handlePOCLastChange = this.handlePOCLastChange.bind(this);
        this.handlePOCPhoneChange = this.handlePOCPhoneChange.bind(this)
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        
        this.handleAddClient = this.handleAddClient.bind(this)
        this.showAddClientBox = this.showAddClientBox.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }
    showAddClientBox () {
        this.props.onEditBreadCrumbs({
            type: 'APPEND_BREADCRUMB',
            breadCrumbs: " > New Client",
        })
        console.log("showAddClientBox function: 2");
        const addClientDialog = document.getElementById('addClientDialog');
        addClientDialog.style.left = (this.props.toggleMenu) ? '105px' : '330px'
        addClientDialog.showModal();
        // displays the modal and empties the fields
        this.setState({
                logo: '',
                name: '',
                domain: '',
                phone: '',
                pocfirst: '',
                poclast: '',
                pocphone: ''
            });

        console.log(": 21");
    }
    hideModal () {
        console.log("hideModal function: 3");
        this.props.onEditBreadCrumbs({
            type: 'REMOVE_BREADCRUMB',
            breadCrumbs: "Clients"
        })
        const addClientDialog = document.getElementById('addClientDialog');
        addClientDialog.close();
    }
    handleLogoChange(event) {
        event.preventDefault();
        let reader = new FileReader();
        // get the file thats been uploads
        let file = event.target.files[0];
        // after the file finished loading
        reader.onloadend = () => {
            this.setState({ 
                logo: file,
                imgSrc: reader.result
             });
        }
        reader.readAsDataURL(file);
    }
    handleClientNameChange(event) {
        this.setState({name: event.target.value});
    }
    handleDomainChange(event) {
        console.log("handle Domain change function: 4");
        this.setState({domain: event.target.value});
    }
    handlePhoneChange(num) {
        this.setState({phone: num});
    }
    handlePOCFirstChange(event) {
        this.setState({pocfirst: event.target.value});
    }
    handlePOCLastChange(event) {
        this.setState({poclast: event.target.value});
    }
    handlePOCPhoneChange(event) {
        this.setState({pocphone: event});
    }
    handleAddClient() {
        // check required fields
        if (this.state.name.length < 2) {
            alert('Please make sure to enter a valid Company Name.')
        } else if (this.state.domain.length === 0) {
            alert('Please make sure to enter a valid domain eg: "https://www.tpcm-usa.com". Looks like the domain field is empty.');
        } else if (this.state.phone.length !== 12) {
            alert('Please Input 10 digits for your phone number.');
        } 
        else {
            console.log(": 6");
            this.props.onAddClient({
                logo: this.state.logo,
                name: this.state.name,
                domain: this.state.domain,
                phone: this.state.phone.slice(2),
                pocfirst: this.state.pocfirst,
                poclast: this.state.poclast,
                pocphone: this.state.pocphone
            });
            this.hideModal();
        }
    }
    render() {
        console.log(": 7");
        return (
            <section>
                <nav role="navigation">
                    <button type="button" onClick={this.showAddClientBox} className="btn">
                        Create Client
                    </button>
                </nav>
                <dialog id="addClientDialog" className="bigDialogBox">
                    <img id="exit-dialog-icon" src={Exit} height={28} onClick={this.hideModal} alt="exit button"/>
                    <h2>Create Client</h2>
                    <label>Company Logo:</label>
                    <br/>
                    <label className="inputProfile">
                        <input type="file" accept="image/*" id="uploadImage" onChange={this.handleLogoChange}/>
                        <img src={this.state.imgSrc} alt="profile"/>
                    </label>
                    <br/>
                    <label>Company Details</label>
                    <br/>
                    <input type="text" value={this.state.name} onChange={this.handleClientNameChange} placeholder="Company Name *"/>
                    <br/>
                    <input type="text" value={this.state.domain} onChange={this.handleDomainChange} placeholder="Domain *"/>
                    <br/>
                    <Input country="US" value={this.state.phone} onChange={this.handlePhoneChange} maxLength={14} placeholder="Phone Number *"/>
                    <br/>
                    <br/>
                    <label>Point of Contact</label>
                    <br/>
                    <input type="text" value={this.state.pocfirst} onChange={this.handlePOCFirstChange} placeholder="First Name"/>
                    <br/>
                    <input type="text" value={this.state.poclast} onChange={this.handlePOCLastChange} placeholder="Last Name"/>
                    <br/>
                    <Input country="US" value={this.state.pocphone} onChange={this.handlePOCPhoneChange} maxLength={14} placeholder="Phone Number *"/>
                    <br/>
                    <br/>
                    <div className="btn--flex">
                        <button onClick={this.handleAddClient} type="button" className="btn btn--confirm dialog__btn">
                            Confirm
                        </button>
                        <button onClick={this.hideModal} type="button" className="btn btn--close dialog__btn">
                            Cancel
                        </button>
                    </div>
                </dialog>
            </section>
        )
    }
}
export default AddClient;