import React, { Component, Fragment } from 'react';
import { useTable, useSortBy } from "react-table";
import Styles from './Styles'
import TrashCan from './style/delete.png';
import Edit from './style/edit.png';
import Check from './style/check_icon.png';
import AddTaskBox from './AddTaskBox';
import Select from 'react-select'
import Descend from './style/descendArrow.png'
import Ascend from './style/ascendArrow.png'
import Reset from './style/reset_btn.png'

//BOOTSRAP:
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Print from './style/print.png'
import { CSVLink} from "react-csv";

const Table = ({ columns, data }) => {
	// Use the state and functions returned from useTable to build your UI
	const { 
		getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
	} = useTable({ columns, data }, useSortBy)
	// Render the UI for your table
	return (
		<table {...getTableProps()}>
			<thead>
		  		{headerGroups.map(headerGroup => (
				<tr {...headerGroup.getHeaderGroupProps()}>
					{headerGroup.headers.map(column => {
						return <th {...column.getHeaderProps()}>
								<span {...column.getSortByToggleProps()}>
									{column.render('Header')}
									{/* Add a sort direction indicator */}
									{column.isSorted ? column.isSortedAsc ? <img id="filterArrow" src={Ascend} alt="Ascend"/> : <img id="filterArrow" src={Descend} alt="Descend"/> : ""}
								</span>
							</th>
					})}
				</tr>
		  		))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{rows.map((row) => {
					prepareRow(row);
					let color = {'background': 'white'};
					if (row.original.isComplete) color = {'background': '#99ff99'};
			  		return (
						<tr {...row.getRowProps()} style={color}>
				  			{row.cells.map(cell => {
								return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
				  			})}
						</tr>
					)}
				)}
			</tbody>
		</table>
	)
}
/* selectTaskUser select drop down { text: fullname, value: fullName } */ 
class TaskTable extends Component {
	constructor() {
		super()
		this.state = {
			taskName: '', // {String} name of task
            description: '', // {String} description of task
            assignedUser: '', // {String} name of assigned User
			actionType: 'CREATE_TASK', // {String} CREATE_TASK or EDIT_TASK action dispatcher
			// to determine whether to populate the input fields
			index: 0, // index of the current task being edited
			planUserList: [], // array of Objects {id, fullName}
			assignedRC: '', // {String} (empty str if no assigned Roll Call) assigned Roll Call to the task 
			inputText: null, // {label: string, value: string}
		}
		this.populateTaskBox = this.populateTaskBox.bind(this)
		this.handleTaskAction = this.handleTaskAction.bind(this)
		this.handleTaskNameChange = this.handleTaskNameChange.bind(this)
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
		this.handleAssignedUserChange = this.handleAssignedUserChange.bind(this)
		this.handleAssignedRollCallChange = this.handleAssignedRollCallChange.bind(this)
		this.showTaskModal = this.showTaskModal.bind(this)
		this.hideTaskModal = this.hideTaskModal.bind(this)
		this.hideTaskDetailsModal = this.hideTaskDetailsModal.bind(this)
	}
	handleTaskAction() {

		if (this.state.taskName.length === 0) {
			alert('Please Enter a Valid Task Name')
		} else {
			let UID = ''
			if (this.state.assignedUser !== '') {
				// assume the assigned user was selected
				let idx = this.state.planUserList.findIndex((user) => user.fullName === this.state.assignedUser)
				if (idx !== -1) {
					UID = this.state.planUserList[idx].id
				}
			}

			if (this.state.actionType === 'EDIT_TASK') {
				// assume editing a task
				this.props.onEditTask({
					taskName: this.state.taskName, // string
					description: this.state.description, // string
					assignedUser: UID, 
					assignedRC: this.state.assignedRC,
					index: this.state.index,
					subPlanPager: this.props.subPlanPager
				})
			} else if (this.state.actionType === 'CREATE_TASK') {
				// assume creating a new task
				this.props.onAddTask({
					taskName: this.state.taskName,
					description: this.state.description,
					assignedUser: UID,
					assignedRC: this.state.assignedRC,
					subPlanPager: this.props.subPlanPager
				});

			}
			this.hideTaskModal();
		}
	}
	// handle the user selection of the assignedUser
    handleTaskNameChange(event) {
        this.setState({taskName: event.target.value})
    }
    handleDescriptionChange(event) {
        this.setState({description: event.target.value})
    }
    handleAssignedUserChange(event) {
        this.setState({assignedUser: event.target.value})
	}
	handleAssignedRollCallChange(event) {
		this.setState({assignedRC: event.target.value})
	}
	// Add users to the dropdown menu for the task - depreciated
    addUsersToSelectOptions() {
		// document.getElementById('editSelectUserForTask').innerHTML = ''
		// var selectTaskUser = document.getElementById('editSelectUserForTask')
		// let defOpt = { text: 'Select User', value: '' }
		// selectTaskUser.options.add( new Option(defOpt.text, defOpt.value, defOpt.selected))
		// if (this.props.selectedUsers !== undefined && this.props.selectedUsers !== null) {
		// 	for (let i = 0; i < this.props.selectedUsers.length; i++) {
		// 		let user = this.props.selectedUsers[i]
		// 		let UID = user.id;
		// 		user.get().then((query) => {
		// 			let data = query.data()
		// 			let fullName = data.firstName + ' ' + data.lastName;
		// 			data.id = UID
		// 			data.fullName = fullName
		// 			// update state
		// 			this.state.planUserList.push(data)
		// 			// value of each user is their UID
		// 			// in order to get the UserRef when adding the task
		// 			let currOpt = { text: fullName, value: fullName }
		// 			selectTaskUser.options.add( new Option(currOpt.text, currOpt.value, currOpt.selected) )
		// 		})
		// 	}
		// }
	}
	// on edit Task Box, populate task popup
    populateTaskBox(tdata, idx, info = '') {
    	// console.log("Populate task: "+ idx);
    	// 	console.log(tdata);
    	// 		console.log("Populate task: "+ info );
    	if(info === 'showTaskDetailsDialog'){
    		// console.log("if")
    		this.setState({
					actionType: '',
					taskName: tdata.name,
					description: tdata.description,
					assignedUser: tdata.taskUser,
					index: idx,
					planUserList: this.state.planUserList
			})
    		const addTaskDialog = document.getElementById('addTaskDetailsDialog')
			addTaskDialog.style.left = (this.props.toggleMenu) ? '130px' : '330px'
			addTaskDialog.showModal();
    	}
    	else
    	{
    		// console.log("else")
    		var selectTaskUser = document.getElementById('editSelectUserForTask')
		    if (selectTaskUser !== null & selectTaskUser !== undefined) 
		    {
				document.getElementById('editSelectUserForTask').innerHTML = ''
		    
			const defOpt = { text: 'Select User', value: '' };
			selectTaskUser.options.add( new Option(defOpt.text, defOpt.value, defOpt.selected) )
			}
			if (this.props.selectedUsers === undefined ) {
				// console.log("else if")
				// console.log("[populateTaskBox]error getting the selected Users props")
				this.setState({
					actionType: 'EDIT_TASK',
					taskName: tdata.name,
					description: tdata.description,
					assignedUser: tdata.taskUser,
					index: idx,
					planUserList: this.state.planUserList
				})
				const addTaskDialog = document.getElementById('addTaskDialog')
				addTaskDialog.style.left = (this.props.toggleMenu) ? '130px' : '330px'
				addTaskDialog.showModal();
			} 
			else 
			{
				// console.log("else if else")
				for (let i = 0; i < this.props.selectedUsers.length; i++) 
				{
					let user = this.props.selectedUsers[i]
					let UID = user.id;
					user.get().then((query) => {
						let data = query.data();
						let fullName = data.firstName + ' ' + data.lastName;
						data.id = UID;
						data.fullName = fullName;
						// update state
						this.state.planUserList.push(data)
						// value of each user is their UID
						// in order to get the UserRef when adding the task
						let currOpt = { text: fullName, value: fullName };
						selectTaskUser.options.add( new Option(currOpt.text, currOpt.value, currOpt.selected) );
						if (i === this.props.selectedUsers.length - 1) {
							this.setState({
								actionType: 'EDIT_TASK',
								taskName: tdata.name,
								description: tdata.description,
								assignedUser: tdata.taskUser,
								index: idx,
								planUserList: this.state.planUserList
							})
							const addTaskDialog = document.getElementById('addTaskDialog')
							addTaskDialog.style.left = (this.props.toggleMenu) ? '130px' : '330px'
							addTaskDialog.showModal();
						}
					})
				}
			}
		}
	}
	showTaskModal() {
		const addTaskDialog = document.getElementById('addTaskDialog')
		addTaskDialog.style.left = (this.props.toggleMenu) ? '130px' : '330px'
		addTaskDialog.showModal();
		// this.addUsersToSelectOptions();
        // displays the modal and empties the fields
	}
	hideTaskModal() {
		// Clear all the values for creatation of new Task
		this.setState({ 
			actionType: 'CREATE_TASK',
			taskName: '',
			description: '',
			assignedUser: '',
			assignedRC: ''
		});
		
			const addTaskDialog = document.getElementById('addTaskDialog')
		    addTaskDialog.close();
		  

	}

	hideTaskDetailsModal() {
		// Clear all the values for creatation of new Task
		this.setState({ 
			actionType: 'CREATE_TASK',
			taskName: '',
			description: '',
			assignedUser: '',
			assignedRC: ''
		});
		
			const addTaskDialog = document.getElementById('addTaskDetailsDialog')
		    addTaskDialog.close();
		    
	}



    render() {
		// predefined names for the columns
		var columns = [
			{
				Header: 'Tasks', 
				columns: [
                {
                    Header: 'Name',//1
                    accessor: 'name',
                },
				{
					Header: 'Completed',//2
					Cell: ({row}) => {
						let assignee = "N/A";
						let val = row.original.completedBy
						if (val !== undefined && val.length > 0 && row.original.isComplete) {
							assignee = val;
						}
						return assignee
					},
					accessor: 'assignee'
				},
				{
                    Header: 'Time of Completion',//3
                    Cell: ({row}) => {
                    	// console.log("pp:"+this.props.planPager);
						let time = "N/A"
						let val = row.original.completedDt
						if (val !== undefined && val.length > 0 && row.original.isComplete) {
							time = val
						}
						return time
					}
                },
				{
					Header: 'Check In',//4
					Cell: ({row}) => {
							let body;
							if (row.original.isComplete) {
								body = <img height="20px" src={Check} alt="Check"/>
							}
							return <div id="taskCompletedCheckbox" onClick={() => 
								{
									let res = window.confirm(`Are you sure you want to change the completed status of ${row.original.name}?`); 
									if (res) {
										let assignedRC = ''
										if (row.original.assignedRC !== undefined && row.original.assignedRC !== '') {
											// assume the task has an assigned Roll Call
											assignedRC = row.original.assignedRC
										}
										this.props.onTaskStatusClick({
											isComplete: row.original.isComplete,
											index: row.id,
											assignedRC: assignedRC
										})
								 	 }
								 }
								}>{body}</div>;	
					}
					
				},
				{
				// Edit button functionality
					Header: '   ',//5
					Cell: ({row}) => {
						// console.log("pp:"+this.props.planPager);
							return <img height={25} width={25} alt="edit" className='icon_button'
								src={Edit} 
								onClick={() => {
									this.populateTaskBox(row.original, row.id)
									// Supposed to send the row ID of the User the admin wants to delete
								}}/>
					}
				},
				{
				// Delete button functionality
					Header: ' ',//6
					Cell: ({row}) => {
						
							return <img height={25} width={25} alt="delete" src={TrashCan} className='icon_button'
							onClick={(event) => {
								event.persist()
								var res = window.confirm("Are you sure you want to remove the task from the Plan?");
								if (res) {
									// Supposed to send the row ID of the User the admin wants to delete
									this.props.onDeleteTask({
										index: row.id,
										id: row.original.id,
										isComplete: row.original.isComplete
									});
								}
							}}/>
						
					}
				},
				{
				// View task functionality
					Header: 'Task Details',//7
					Cell: ({row}) => {
						return <button id="taskButton" title="View Tasks Details" onClick={() => this.populateTaskBox(row.original, row.id, 'showTaskDetailsDialog')}>View</button>
						
					}
				},
				{
					Header: 'Print', // Print row functionality: 8
					Cell: ({row}) => 
					{
						
						let csvFields = [];
						let time = "N/A"
						let val = row.original.completedDt
						if (val !== undefined && val.length > 0 && row.original.isComplete) {
							time = val
						}
						
						csvFields.push({
							'Name':row.original.name,
							'Completed': row.original.completedBy,
							'Time of Completion':time
							
						})
						// console.log("csvfields:"+csvFields)
						return <CSVLink data={csvFields}><img height={35} width={35} alt="Print" className='icon_button' src={Print}/></CSVLink>
				    }
				 
				}]
			}];
			if (this.props.planPager === 4 )
			{
				if(this.props.subPlanPager === 1) //checklist
				{					
					columns[0].columns.splice(4,4)
				}
				if(this.props.subPlanPager === 2) //template
				{
				
					columns[0].columns.splice(1,3)
					columns[0].columns.splice(3,2)
				}
			}

			if(this.props.planPager === 6) //Reports
			{
				if(this.props.subPlanPager === 1) //checklist
				{					
					columns[0].columns.splice(3,3)
					columns[0].columns.splice(4,1)
				}
			}
			console.log(this.props.taskList)
		// console.log("Task table > PP: "+ this.props.planPager)
		// console.log("Task table > SPP: "+ this.props.subPlanPager)
            return (

                <main>
					<p onClick={this.props.onBackClick} id="breadCrumbs">{this.props.breadCrumbs}</p>
					<div id="planName-ProgressBar-Div">
						<h1>Task List</h1>
						{(this.props.planPager === 4 || this.props.planPager === 6) ? 
							<div id="progressBar-description">
								<progress id="progressBar" height="40px" value={(this.props.numCompleted/this.props.totalNum)*100} max="100" />
								<span id="progressBarPercentage">{this.props.numCompleted + ' of ' + this.props.totalNum} Tasks are Completed</span>
							</div> : null}
					</div>
					{(this.props.planPager === 4 || this.props.planPager === 6) ?  <Fragment>
						<h4 className="planNameDescription">{this.props.planName}</h4>
						<h4 className="planNameDescription">Plan Leader: {this.props.leaderName}</h4>
					</Fragment> : null}
					<nav>
					 <Container fluid>
                		<Row>
							<Col>
								<div className="search">
									<Select options={this.props.taskList.map((task) => {return { 'value': task.name, 'label': task.name }})} 
										id='searchBox' placeholder='Select a Task'
										value={this.state.inputText}
										onChange={(event) => {
											this.props.onfilterTable({
												type: 'FILTER_TASK_LIST',
												value: event.value
											})
											let inputText = {label: event.label, value: event.label}
		                                	this.setState({inputText: inputText})
										}}/>
									<img id="resetTableListBtn" src={Reset} alt="Reset" className="icon_button"
									onClick={() => {
		                                this.props.onViewTasks()
										this.setState({inputText: null})
		                        	}}/>
								</div>
							</Col>

							{(this.props.planPager === 6) ? null : 
							 <Col>	
							 <ul className="addItem">
								<li>
									<button type="button" onClick={this.showTaskModal} className="btn">Create Task</button>
								</li>
							</ul></Col>
						   }
						   </Row>
						 </Container>
					</nav>
					<Styles>
						{/* gets the user data from the userPage class and fills in the table */}
						<Table columns={columns} data={(this.props.viewFilteredList) ? this.props.filteredList : this.props.taskList} />
					</Styles>
					<AddTaskBox selectedUsers={this.props.selectedUsers} onHideTaskBox={this.hideTaskModal} onHideTaskDetailsBox={this.hideTaskDetailsModal} 
						handleAssignedUserChange={this.handleAssignedUserChange} handleDescriptionChange={this.handleDescriptionChange} 
						handleTaskNameChange={this.handleTaskNameChange} taskName={this.state.taskName} description={this.state.description} 
						assignedUser={this.state.assignedUser} handleTaskAction={this.handleTaskAction} 
						assignedRC={this.state.assignedRC} handleAssignedRollCallChange={this.handleAssignedRollCallChange}/>
                </main>
            );
        }
}
export default TaskTable;