import React, { Component, Fragment } from 'react';
import Exit from './style/exit.png';
import "./style/addUserDialog.css";

class AddTaskBox extends Component {
    render() {
        return (
        <Fragment>
            <dialog className="bigDialogBox" id="addTaskDetailsDialog">
                <img id="exit-dialog-icon" src={Exit} height={28} onClick={this.props.onHideTaskDetailsBox} alt="exit button"/>
                <Fragment>
                    <h2>Task Details</h2>
                    <br/>
                    <div><p className="label">Task Name</p> {this.props.taskName}</div>
                     <div><p className="label">Task Description</p> {this.props.description} </div>
                    <br/>

                    </Fragment>
            </dialog>
            <dialog className="bigDialogBox" id="addTaskDialog">
                <img id="exit-dialog-icon" src={Exit} height={28} onClick={this.props.onHideTaskBox} alt="exit button"/>
                
                    <Fragment>
                        <h2>Create Task</h2>
                        <br/>
                        <input type="text" value={this.props.taskName} onChange={this.props.handleTaskNameChange} placeholder="Task Name"/>
                        <br/>
                        <p>Task Description: {this.props.actionType} </p>
                        <textarea id="taskDescription" type="text" value={this.props.description} 
                            onChange={this.props.handleDescriptionChange} rows="5" cols="58"/>
                       
                        <br/>
                        <br/>
                        <div className="btn--flex">
                            <button onClick={this.props.handleTaskAction} type="button" className="btn btn--confirm dialog__btn">Confirm</button>
                            <button onClick={this.props.onHideTaskBox} type="button" className="btn btn--close dialog__btn">Cancel</button>
                        </div>
                    </Fragment>
            </dialog>
        </Fragment>
        )
    }
}
export default AddTaskBox;