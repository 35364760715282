import React, { Component, Fragment } from 'react'
import AddClientDialog from './addClientDialog'
import Select from 'react-select'
import Input from 'react-phone-number-input/input'
import Styles from './Styles'
import Table from './Table'
import TrashCan from './style/delete.png'
import Edit from './style/edit.png'
import ChatBubble from './style/clientChat.png'
import Exit from './style/exit.png'
import Reset from './style/reset_btn.png'
import './style/EventTable.css'
//BOOTSRAP:
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


class DenialTable extends Component {

	constructor() {
		super();
		this.state = {
			index: 0, // index of the selected client for edit
			denial_index: 0,
			denial_id: 0,
			denial_text: '',
			denial_body:'',
			denial_id: '',

		}
		this.addDenial = this.addDenial.bind(this);
		this.editDenial = this.editDenial.bind(this);
		this.handleAddDenial = this.handleAddDenial.bind(this);
		this.handleEditDenial = this.handleEditDenial.bind(this);
		this.handleAddDenialBody = this.handleAddDenialBody.bind(this);
		this.handleEditDenialBody = this.handleEditDenialBody.bind(this);
		this.showAddDenialBox = this.showAddDenialBox.bind(this);
		this.hideAddDenialBox = this.hideAddDenialBox.bind(this);
		this.showEditDenialBox = this.showEditDenialBox.bind(this);
		this.hideEditDenialBox = this.hideEditDenialBox.bind(this);

	}

	addDenial(){
		this.setState({
			denial_text: '',
			denial_body:'',
		})
		this.props.addDenials({text:this.state.denial_text,body:this.state.denial_body});
		this.hideAddDenialBox();
	}

	editDenial(){
		this.props.editDenials({id:this.state.denial_id,text:this.state.denial_text,body:this.state.denial_body});
		this.hideEditDenialBox();
	}

	handleEditDenial(event){
		this.setState({denial_text: event.target.value});
	}

	handleEditDenialBody(event){
		this.setState({denial_body: event.target.value});
	}

	handleAddDenial(event){
		this.setState({denial_text: event.target.value});
	}

	handleAddDenialBody(event){
		this.setState({denial_body: event.target.value})
	}


	showAddDenialBox(){
		this.props.onEditBreadCrumbs({
            type: 'APPEND_BREADCRUMB',
            breadCrumbs: " > Add Denial"
        })
		const adddenialBox = document.getElementById('adddenialBox');
		adddenialBox.style.left = (this.props.toggleMenu) ? '105px' : '330px'
        adddenialBox.showModal();
	}

	hideAddDenialBox() {
    	console.log(": 12");
		this.props.onEditBreadCrumbs({
            type: 'REMOVE_BREADCRUMB',
            breadCrumbs: "Canned Denials"
        })
		const adddenialBox = document.getElementById('adddenialBox');
        adddenialBox.close();

}
	showEditDenialBox(){
		this.props.onEditBreadCrumbs({
            type: 'APPEND_BREADCRUMB',
            breadCrumbs: " > Edit Denial"
        })
		const editdenialBox = document.getElementById('editdenialBox');
		editdenialBox.style.left = (this.props.toggleMenu) ? '105px' : '330px'
        editdenialBox.showModal();
	}

	hideEditDenialBox() {
    	console.log(": 12");
		this.props.onEditBreadCrumbs({
            type: 'REMOVE_BREADCRUMB',
            breadCrumbs: "Canned Denials"
        })
		const editdenialBox = document.getElementById('editdenialBox');
        editdenialBox.close();
	}


    render() {

		var columns = [
		{
			Header: 'Denials',
			columns:[
			{
				id:"text",
				Header: 'Text',
				accessor: 'text'
			},
			{
				// Edit button functionality
					id:"edit",
					Header: '  ',
					Cell: ({row}) => {
						return <img title="Edit Denial" height={25} width={25} alt="edit" className='icon_button'
						src={Edit}
						onClick={(event) => {
							event.persist();
							const rData = row.original;
							this.setState({
								denial_text: row.original.text,
								denial_body: row.original.body,
								denial_id: row.original.id,
							});
							this.showEditDenialBox();
						}}/>
					}
			},
			{
				// Delete button functionality
					id:"delete",
					Header: ' ',
					Cell: ({row}) => {
						return <img title="Delete Denial" height={25} width={25} alt="delete" src={TrashCan} className='icon_button'
						onClick={(event) => {
							event.persist();
							this.props.deleteDenials(row.original.id)
							// this.showDeleteCategoryBox(row.id);

						}}/>
					}
				}]
		}]



		return (

			<main>
				<p id="breadCrumbs">{this.props.breadCrumbs}</p>
				<h1>Canned Denials</h1>
				<ul className="topnav">


				</ul>
				<nav>
					<Container fluid>
						<Row>
							<Col>
							</Col>
							<Fragment>
							<Col>
				                <ul className = "addItem" >
				                <li onClick={this.showAddDenialBox}><button className="btn" type="button">Add Denial</button></li>
				                </ul>
				            </Col>
				            </Fragment>

						</Row>
					</Container>
				</nav>
				<Styles>
					{/* gets the user data from the userPage class and fills in the table */}

					<Table columns={columns} data={(this.props.viewFilteredList) ? this.props.filteredList : this.props.denials_list} />
				</Styles>

				{/* Supposed to display when the user clicks on the edit user button */}

				<dialog id="adddenialBox" className="bigDialogBox">
					<img id="exit-dialog-icon" src={Exit} height={28} onClick={this.hideAddDenialBox} alt="exit button"/>
                    <h2>Add Denial</h2>

					<br/>
                    <br/>
                     <label>Title</label>
                    <br/>
                    <input type="text" value={this.state.denial_text} onChange={this.handleAddDenial}  placeholder="Title"/>
										<br/><br/>
										<label>Body</label>
									 <br/>
									 <input type="text" value={this.state.denial_body} onChange={this.handleAddDenialBody}  placeholder="Body"/>
                    <br/>
                    <br/>
                    <div className="btn--flex">
                        <button onClick={this.addDenial} type="button" className="btn btn--confirm dialog__btn">
                            Confirm
                        </button>
                        <button onClick={this.hideAddDenialBox} type="button" className="btn btn--close dialog__btn">
                            Cancel
                        </button>
                    </div>

				</dialog>


				<dialog id="editdenialBox" className="bigDialogBox">
					<img id="exit-dialog-icon" src={Exit} height={28} onClick={this.hideEditDenialBox} alt="exit button"/>
                    <h2>Edit Denial</h2>

					<br/>
                    <br/>
                     <label>Text</label>
                    <br/>
                    <input type="text" value={this.state.denial_text} onChange={this.handleEditDenial}  placeholder="Title"/>
										<br/>
										<br/>
                     <label>Body</label>
                    <br/>
                    <input type="text" value={this.state.denial_body} onChange={this.handleEditDenialBody}  placeholder="Body"/>
                    <br/>
                    <br/>
                    <div className="btn--flex">
                        <button onClick={this.editDenial} type="button"  className="btn btn--confirm dialog__btn">
                            Confirm
                        </button>
                        <button onClick={this.hideEditDenialBox} type="button" className="btn btn--close dialog__btn">
                            Cancel
                        </button>
                    </div>

				</dialog>
			</main>


		);

	}


}
export default DenialTable;
