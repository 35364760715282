import React, { Component, Fragment } from 'react';
import  ProfilePreview from './style/default_profile.png';
import Input from 'react-phone-number-input/input';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';
import Exit from './style/exit.png';
import "./style/addUserDialog.css";
import ItemDefaultImage from './style/default-product-image.png'

import DatePicker from "react-datepicker";
import DateTimePicker from 'react-datetime-picker';
import "react-datepicker/dist/react-datepicker.css";
import Multiselect from 'multiselect-react-dropdown';

//BOOTSRAP:
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class AddEvent extends Component {
    constructor () {
        super();
        this.state = {
            index:0,
            name:'',
            description:'',
            ticketURL:'',
            maxParticipants:0,
            maxSpectators:0,
            price:0,
            spectatingPrice:0,
            owner_id:'',
            street:'',
            city:'',
            state:'',
            zipcode:'',
            startDate:new Date(),
            endDate:new Date(),
            lastSignUpDate:new Date(),
            prize:'',
            itemImage:'',
            itemSrc:ItemDefaultImage,
            virtual:false,
            level:null,
            categoriesToAdd : []



        }

        this.handleNameChange = this.handleNameChange.bind(this)
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
        this.handleTicketURLChange = this.handleTicketURLChange.bind(this)
        this.handleMParticipantsChange = this.handleMParticipantsChange.bind(this)
        this.handleMSpectatorsChange = this.handleMSpectatorsChange.bind(this)
        this.handlePriceChange = this.handlePriceChange.bind(this)
        this.handleSpectatingPriceChange = this.handleSpectatingPriceChange.bind(this)
        this.handleStartDateChange = this.handleStartDateChange.bind(this)
        this.handleLastSignUpDateChange = this.handleLastSignUpDateChange.bind(this)
        this.handleEndDateChange = this.handleEndDateChange.bind(this)
        this.handleAddressChange = this.handleAddressChange.bind(this)
        this.handleStreetChange = this.handleStreetChange.bind(this)
        this.handleCityChange = this.handleCityChange.bind(this)
        this.handleStateChange = this.handleStateChange.bind(this)
        this.handleZipChange = this.handleZipChange.bind(this)
        this.handleImageChange = this.handleImageChange.bind(this)
        this.onCategoryChange = this.onCategoryChange.bind(this)
        this.toggleVirtual = this.toggleVirtual.bind(this)
        this.handleLevelChange = this.handleLevelChange.bind(this)
        this.handleAddItem = this.handleAddItem.bind(this)
        this.hideModal = this.hideModal.bind(this)
        this.showAddItemBox = this.showAddItemBox.bind(this)
    }
    showAddItemBox() {
        this.props.onEditBreadCrumbs({
            type: 'APPEND_BREADCRUMB',
            breadCrumbs: " > New Event",
        })
        const addItemDialog = document.getElementById('addItemDialog');
        addItemDialog.style.left = (this.props.toggleMenu) ? '105px' : '330px'
        addItemDialog.showModal();
        // displays the modal and empties the fields
        let exp = new Date();
        exp.setDate(exp.getDate()+3);

        this.setState({
                name:'',
                description:'',
                ticketURL:'',
                maxParticipants:0,
                maxSpectators:0,
                price:0,
                spectatingPrice:0,
                owner_id:'',
                startDate:new Date(),
                endDate:new Date(),
                lastSignUpDate:new Date(),
                expiration:exp,
                prize:'',
                virtual:false,
                level:null,
                owner_id:''
            });

        console.log(": 21");
    }
    hideModal () {
        console.log("hideModal function: 3");
        this.props.onEditBreadCrumbs({
            type: 'REMOVE_BREADCRUMB',
            breadCrumbs: "Events"
        })
        const addItemDialog = document.getElementById('addItemDialog');
        addItemDialog.close();
    }

     handleNameChange(event) {
        this.setState({name: event.target.value});

    }
    handleDescriptionChange(event){
        this.setState({description: event.target.value});
    }
    handleTicketURLChange(event){
      this.setState({ticketURL:event.target.value});
    }
    handleMParticipantsChange(value){
        this.setState({maxParticipants: value});
    }
    handleMSpectatorsChange(value){
        this.setState({maxSpectators: value});
    }
    handlePriceChange(value){
        this.setState({price: value});
    }
    handleSpectatingPriceChange(value) {
        this.setState({ spectatingPrice: value });
    }
    handleStartDateChange(date){
        this.setState({startDate:date});
    }
    handleEndDateChange(date){
        let exp = this.state.expiration

        if(date){
          exp = new Date(date.valueOf());
          exp.setDate(exp.getDate()+3);
        }
        else{
          exp = '';
        }
        this.setState({
                        endDate:date,
                        expiration:exp
                    });
    }
    handleLastSignUpDateChange(date){
        this.setState({lastSignUpDate:date});
    }
    handleAddressChange(event){
        this.setState({address:event.target.value});
    }

    handleStreetChange(event){
        this.setState({street:event.target.value});
    }

    handleCityChange(event){
        this.setState({city:event.target.value});
    }

    handleStateChange(event){
        this.setState({state:event.target.value});
    }

    handleZipChange(event){
        this.setState({zipcode:event.target.value});
    }

    handleLevelChange(event){
      this.setState({level:event.target.value})
    }

    handleImageChange(event) {
        event.preventDefault();
        let reader = new FileReader();
        // get the file thats been uploads
        let file = event.target.files[0];
        // after the file finished loading
        reader.onloadend = () => {
            this.setState({
                itemImage: file,
                itemSrc: reader.result
             });
        }
        reader.readAsDataURL(file);
    }

    onCategoryChange(list,item){
        console.log(list)
        let categoriesToAdd = list;
        this.setState({categoriesToAdd:categoriesToAdd});
    }

    toggleVirtual(){
      this.setState({
        virtual:!(this.state.virtual)
        })
    }

    handleAddItem(){
        let owner_id = this.state.owner_id;

        if(this.props.role !== 'root'){
            console.log("not root")
            owner_id = this.props.uid
        }

        console.log(owner_id)

        if(owner_id == ''){
          alert("Please select a owner");
        }
        else if(owner_id != '' && this.state.name !== '' && this.state.description !== '' && (this.state.virtual == true || (this.state.virtual == false && this.state.street !== '' && this.state.city !== '' && this.state.state !== '' && this.state.zipcode !== '' ))&& this.state.startDate !== '' && this.state.endDate !== '' ){

        let address = '';

        if(this.state.street != ''){
          address = address + this.state.street + ' ';
        }

        if(this.state.city != ''){
          address = address + this.state.city + ' ';
        }
        if(this.state.state != ''){
          address = address + this.state.state + ' ';
        }
        if(this.state.zipcode != ''){
          address = address + this.state.zipcode + ' ';
        }

        let event_to_add = {
            name:this.state.name,
            description:this.state.description,
            ticketURL:this.state.ticketURL,
            maxParticipants:this.state.maxParticipants,
            maxSpectators:this.state.maxSpectators,
            entryCost:this.state.price,
            spectatingPrice:this.state.spectatingPrice,
            address:address,
            street:this.state.street,
            city:this.state.city,
            state:this.state.state,
            zipcode:this.state.zipcode,
            startDate:this.state.startDate,
            endDate:this.state.endDate,
            lastSignUpDate:this.state.lastSignUpDate,
            expiration:this.state.expiration,
            // prize:this.state.prize,
            level:this.state.level,
            itemImage:this.state.itemImage,
            categoriesToAdd:this.state.categoriesToAdd,
            virtual:this.state.virtual,
            owner_id:owner_id,
            id:this.state.id
        }

        this.props.onAddItem(event_to_add);

        this.hideModal();

        }else{
            alert("Please fill all the fields");
        }
    }


    render() {
        return (
            <section>
                <nav role="navigation">
                    <button type="button" onClick={this.showAddItemBox} className="btn">
                        Add Event
                    </button>
                </nav>
                <dialog id="addItemDialog" className="bigDialogBox">
                    <img id="exit-dialog-icon" src={Exit} height={28} onClick={this.hideModal} alt="exit button"/>
                    <h2>Create Event</h2>
                    <br/>

                    {(this.props.role == 'root')?
                    <Fragment>
                    <label>Owner</label>
                    <br/>
                    <Select options={this.props.businessUserList.map((b_user) => {return { 'value': b_user.id, 'label': b_user.name }})}
                                        id='searchBox' placeholder='Select a owner'
                                        value={this.state.inputText}
                                        onChange={(event) => {
                                            let inputText = {label: event.label, value: event.value}
                                            this.setState({
                                                            inputText: inputText,
                                                            owner_id:inputText.value
                                                        }, () => {
                                                      });
                                        }}/>
                    </Fragment>:null }
                    <br/><br/>
                    <Container fluid>
                    <Row>
                        <label> Event Image: </label> <br/>
                        <label className = "inputProfile" >
                        <input type = "file" accept = "image/*" id = "uploadImage" onChange = { this.handleImageChange } /> <img src = {(this.state.itemSrc)}alt = "item image" />
                        </label>
                    </Row>

                    <Row>
                        <Col>
                            <label> Name </label> <br/>
                            <input type = "text" value = { this.state.name } onChange = { this.handleNameChange } placeholder = "Name" />
                            <br/> <br/>
                            <label> Description: </label> <br/>
                            <input type = "text" value = {this.state.description} onChange = {this.handleDescriptionChange} placeholder = "Description" />
                            <br/> <br/>
                            Virtual
                            <label className="switch" title="Virtual Toggle"> <input type="checkbox" defaultChecked={this.state.virtual}/><span
                						className="slider round" onClick={(event) => {
                              this.toggleVirtual();
                						}}></span></label>
                            <br/> <br/>
                            <label> Address: </label> <br/>
                            <label> Street: </label>
                            <input type = "text" value = { this.state.street } onChange = { this.handleStreetChange } placeholder = "Street" /><br/>
                            <label> City: </label>
                            <input type = "text" value = { this.state.city } onChange = { this.handleCityChange } placeholder = "City" /><br/>
                            <label> State: </label>
                            <input type = "text" value = { this.state.state } onChange = { this.handleStateChange } placeholder = "State" /><br/>
                            <label> Zip: </label>
                            <input type = "text" value = { this.state.zipcode } onChange = { this.handleZipChange } placeholder = "Zip" /><br/>
                            <br/><br/>
                            <label> Ticket Sale URL: </label> <br/>
                            <input type = "text" value = {this.state.ticketURL} onChange = {this.handleTicketURLChange} placeholder = "Ticket Sale URL" />
                            <br/> <br/>
                        </Col>
                        <Col>
                            <label> Max Participants: </label> <br/>
                                <CurrencyInput
                                    placeholder = "Max Participants"
                                    decimalsLimit = { 0 }
                                    prefix = ''
                                    defaultValue = { this.state.maxParticipants }
                                    onValueChange = {
                                        (value, name) => this.handleMParticipantsChange(value)
                                    }
                                    />
                                <br/> <br/>
                                <label > Max Spectators: </label> <br/>
                                <CurrencyInput
                                    placeholder = "Max Spectators"
                                    decimalsLimit = { 0 }
                                    prefix = ''
                                    defaultValue = { this.state.maxSpectators }
                                    onValueChange = {
                                        (value, name) => this.handleMSpectatorsChange(value)
                                    }
                                    />
                                <br/> <br/>
                                <label> Entry Cost: </label> <br/>
                                <label> Participating Cost: </label>
                                <CurrencyInput
                                    placeholder = "Participating Cost"
                                    decimalsLimit = { 2 }
                                    prefix = '$'
                                    value = { this.state.price }
                                    onValueChange = {
                                        (value, name) => this.handlePriceChange(value)
                                    }
                                    />
                                <br/>
                                <label> Spectating Cost: </label>
                                <CurrencyInput
                                    placeholder = "Spectating Cost"
                                    decimalsLimit = { 2 }
                                    prefix = '$'
                                    defaultValue = { this.state.spectatingPrice }
                                    onValueChange = {
                                        (value, name) => this.handleSpectatingPriceChange(value)
                                    }
                                    />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label> Start Date: </label> <br/>
                            <DateTimePicker
                                value = { this.state.startDate }
                                onChange = {
                                    (date) => this.handleStartDateChange(date)
                                }
                            />
                            <br/> <br/>
                            <label> End Date: </label> <br/>
                            <DateTimePicker
                                value = { this.state.endDate }
                                onChange = {
                                    (date) => this.handleEndDateChange(date)
                                }
                            />
                            <br/> <br/>
                            <label> Last Day to Sign Up: </label> <br/>
                            <DateTimePicker
                                value = { this.state.lastSignUpDate }
                                onChange = {
                                    (date) => this.handleLastSignUpDateChange(date)
                                }
                            />
                            <br/> <br/>
                        </Col>
                        <Col>
                            <label> Categories: </label> <br/>
                            <Multiselect
                                options={this.props.categoryList} // Options to display in the dropdown
                                displayValue="name" // Property name to display in the dropdown options
                                onSelect={this.onCategoryChange}
                                onRemove={this.onCategoryChange}
                            /><br/><br/>
                            <label>Sport Level </label><br/>
                              <input className='cat_radio' type="radio" value="pro" name="cat_level" onChange={this.handleLevelChange}/> Pro
                              <input className='cat_radio' type="radio" value="amatuer" name="cat_level" onChange={this.handleLevelChange}/> Amatuer
                        </Col>
                    </Row>
                    <Row>
                    <div className = "btn--flex" >
                        <button onClick = { this.handleAddItem } type = "button" className = "btn btn--confirm dialog__btn" >Confirm </button>
                        <button onClick = { this.hideModal } type = "button" className = "btn btn--close dialog__btn" > Cancel </button>
                    </div >
                    </Row>
                    </Container>

                </dialog>

            </section>
        )
    }
}
export default AddEvent;
