import React, { Component, Fragment } from 'react'
import AddClientDialog from './addClientDialog'
import Select from 'react-select'
import Input from 'react-phone-number-input/input'
import Styles from './Styles'
import Table from './Table'
import TrashCan from './style/delete.png'
import Edit from './style/edit.png'
import ChatBubble from './style/clientChat.png'
import Exit from './style/exit.png'
import Reset from './style/reset_btn.png'
import './style/EventTable.css'
//BOOTSRAP:
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


class ClientTable extends Component {

	constructor() {
		super();
		this.state = {
			index: 0, // index of the selected client for edit
			categories: [],
			category_index: 0,
			category_id: 0,
			category_name: '',
			client_id: '',
			delete_confirm: ''

		}
		// this.handleViewCategory = this.handleViewCategory.bind(this);
		this.addCategory = this.addCategory.bind(this);
		this.editCategory = this.editCategory.bind(this);
		this.deleteCategory = this.deleteCategory.bind(this);
		this.handleAddCategory = this.handleAddCategory.bind(this);
		this.handleEditCategory = this.handleEditCategory.bind(this);
		this.handleDeleteCategory = this.handleDeleteCategory.bind(this);
		this.showCategoryBox = this.showCategoryBox.bind(this);
		this.showAddCategoryBox = this.showAddCategoryBox.bind(this);
		this.showEditCategoryBox = this.showEditCategoryBox.bind(this);
		this.hideEditCategoryBox = this.hideEditCategoryBox.bind(this);
		this.hideAddCategoryBox = this.hideAddCategoryBox.bind(this);
		this.hideCategoryBox = this.hideCategoryBox.bind(this);
		this.hideDeleteCategoryBox = this.hideDeleteCategoryBox.bind(this)
		this.fetchSubCategory = this.fetchSubCategory.bind(this)
		this.addSubCategory = this.addSubCategory.bind(this)
		this.showAddSubCategoryBox = this.showAddSubCategoryBox.bind(this)
		this.hideAddSubCategoryBox = this.hideAddSubCategoryBox.bind(this)
	}

	fetchSubCategory(id){

		this.props.fetchSubCategories(id);

		setTimeout(()=>{
		console.log(this.props.subcategories);

		this.showCategoryBox();
		},1500)
	}

	handleDeleteCategory(id){
		this.props.deleteCategory(this.state.client_id,this.state.category_index);
		if(!this.props.inventoryPresent){
			console.log("inventoryPresent");
		}
	}

	showEditCategoryBox(){
		this.props.onEditBreadCrumbs({
            type: 'APPEND_BREADCRUMB',
            breadCrumbs: " > Edit Category"
        })
		const editcategoryBox = document.getElementById('editcategoryBox');
		editcategoryBox.style.left = (this.props.toggleMenu) ? '105px' : '330px'
        editcategoryBox.showModal();
	}

	hideEditCategoryBox() {
    	console.log(": 12");
		this.props.onEditBreadCrumbs({
            type: 'REMOVE_BREADCRUMB',
            breadCrumbs: "Categories"
        })
		const editcategoryBox = document.getElementById('editcategoryBox');
        editcategoryBox.close();
	}

	handleEditCategory(event) {
        this.setState({category_name: event.target.value});
    }

    editCategory(){
    	console.log(this.state.category_to_edit)
    	this.props.editCategory(this.state.category_to_edit,this.state.category_index,this.state.category_name,this.state.is_parent,this.state.category_id);
    	this.hideEditCategoryBox();
    }

	showAddCategoryBox(){
		this.props.onEditBreadCrumbs({
            type: 'APPEND_BREADCRUMB',
            breadCrumbs: " > Add Category"
        })
		const addcategoryBox = document.getElementById('addcategoryBox');
		addcategoryBox.style.left = (this.props.toggleMenu) ? '105px' : '330px'
        addcategoryBox.showModal();
	}

	hideAddCategoryBox() {
    	console.log(": 12");
		this.props.onEditBreadCrumbs({
            type: 'REMOVE_BREADCRUMB',
            breadCrumbs: "Categories"
        })
		const addcategoryBox = document.getElementById('addcategoryBox');
        addcategoryBox.close();
	}

	handleAddCategory(event) {
        this.setState({category_name: event.target.value});
    }

    addCategory(){
    	this.props.addCategory({cat_name:this.state.category_name});
    	this.hideAddCategoryBox();
    }

    addSubCategory(){
    	this.props.addSubCategory({cat_name:this.state.category_name,parent_id:this.state.category_id});
    	this.hideAddSubCategoryBox();
    }

    showDeleteCategoryBox(cat_index){
    	this.setState({category_index: cat_index});
    	this.props.onEditBreadCrumbs({
            type: 'APPEND_BREADCRUMB',
            breadCrumbs: " > Delete Category"
        })
		const deletecategoryBox = document.getElementById('deletecategoryBox');
		deletecategoryBox.style.left = (this.props.toggleMenu) ? '105px' : '330px'
        deletecategoryBox.showModal();
    }

    handleDeleteCategory(event){
    	this.setState({delete_confirm: event.target.value});
    }

    deleteCategory(){
    	let delete_confirm = this.state.delete_confirm.toLowerCase();

    	if(delete_confirm === 'delete'){
    	this.props.deleteCategory(this.state.category_to_delete,this.state.is_parent,this.state.category_id);
    	this.setState({delete_confirm: ''});
    	this.hideDeleteCategoryBox();
    	}
    	else{
    		this.hideDeleteCategoryBox();
    		alert("incorrect input");
    	}
    }

    hideDeleteCategoryBox() {
    	console.log(": 12");
		this.props.onEditBreadCrumbs({
            type: 'REMOVE_BREADCRUMB',
            breadCrumbs: "Categories"
        })
		const deletecategoryBox = document.getElementById('deletecategoryBox');
        deletecategoryBox.close();
	}

	showCategoryBox(){
    	this.props.onEditBreadCrumbs({
            type: 'APPEND_BREADCRUMB',
            breadCrumbs: " > Sub Categories"
        })
		const categoryBox = document.getElementById('categoryBox');
		categoryBox.style.left = (this.props.toggleMenu) ? '105px' : '330px'
        categoryBox.showModal();
    }


    hideCategoryBox () {
    	console.log(": 12");
		this.props.onEditBreadCrumbs({
            type: 'REMOVE_BREADCRUMB',
            breadCrumbs: "Categories"
        })
		const categoryBox = document.getElementById('categoryBox');
        categoryBox.close();
	}

	showAddSubCategoryBox(){
		this.props.onEditBreadCrumbs({
            type: 'APPEND_BREADCRUMB',
            breadCrumbs: " >Add Sub Categories"
        })
		const categoryBox = document.getElementById('addSubcategoryBox');
		categoryBox.style.left = (this.props.toggleMenu) ? '105px' : '330px'
        categoryBox.showModal();
	}

	hideAddSubCategoryBox(){
		console.log(": 12");
		this.props.onEditBreadCrumbs({
            type: 'REMOVE_BREADCRUMB',
            breadCrumbs: "Sub Categories"
        })
		const categoryBox = document.getElementById('addSubcategoryBox');
        categoryBox.close();
	}

	handleToggleChange(index) {
		// sending data to userPage to update the Toggle for the user
		let status_to = "DISABLE";
		let categories = this.props.categories;
		let category_status = categories[index.index].deactivated
		if(category_status === true){
			status_to = "ENABLE"
		}
		if(window.confirm('Are you sure you want to '+status_to+' this sport?')){
			this.props.onToggleCategory(index)
		}

	}

    render() {
    	console.log(": 13");
    	// console.log(newClient);
		// predefined names for the columns


		var columns = [
		{
			Header: 'Categories',
			columns:[
			{
				id:"name",
				Header: 'Category Name',
				Cell:({row}) =>{
					let name = row.original.name
					return <span onClick={(event)=>{
						event.persist();
						this.setState({
							category_id:row.original.id
						},()=>{
							this.fetchSubCategory(row.original.id);
							console.log("fetching for: "+this.state.category_id)
						})
					}}>{name}</span>
				},
				accessor: 'name'
			},
			{
					// deactived field (deleted or not) //6
					// DONOT SHOW IT UNDER DELETED TAB
					Header: 'Enabled',
					Cell: ({row}) => {
						let deactivated = row.original.deactivated;
						// save as string and then do the logic
						return <label className="switch" title="Enable Toggle"><input type="checkbox" defaultChecked={!deactivated}/><span
						className="slider round" onClick={(event) => {
							event.preventDefault();
							this.handleToggleChange({ index: row.id })
						}}></span></label>
					}
				},
			{
				// Edit button functionality
					id:"edit",
					Header: '  ',
					Cell: ({row}) => {
						return <img title="Edit Category" height={25} width={25} alt="edit" className='icon_button'
						src={Edit}
						onClick={(event) => {
							event.persist();
							const rData = row.original;
							this.setState({
								category_index: row.id,
								category_name: row.original.name,
								category_id: row.original.id,
								category_to_edit: row.original.id,
								is_parent: true
							});
							this.showEditCategoryBox();
						}}/>
					}
			},
			{
				// Delete button functionality
					id:"delete",
					Header: ' ',
					Cell: ({row}) => {
						return <img title="Delete Category" height={25} width={25} alt="delete" src={TrashCan} className='icon_button'
						onClick={(event) => {
							event.persist();
							this.setState({
								category_index: row.id,
								category_id: row.original.id,
								category_to_delete: row.original.id,
								is_parent: true
							});

							console.log("row:"+row.id);
							this.showDeleteCategoryBox(row.id);

						}}/>
					}
				}]
		}]

		var subcat_columns = [
		{
			Header: 'Sub Categories',
			columns:[
			{
				id:"name",
				Header: 'Sub Category',
				accessor: 'name'
			},
			{
				// Edit button functionality
					id:"edit",
					Header: '  ',
					Cell: ({row}) => {
						return <img title="Edit Sub Category" height={25} width={25} alt="edit" className='icon_button'
						src={Edit}
						onClick={(event) => {
							event.persist();
							const rData = row.original;
							this.setState({
								category_index: row.id,
								category_name: row.original.name,
								category_to_edit: row.original.id,
								is_parent: false
							});
							this.showEditCategoryBox();
						}}/>
					}
			},
			{
				// Delete button functionality
					id:"delete",
					Header: ' ',
					Cell: ({row}) => {
						return <img title="Delete Sub Category" height={25} width={25} alt="delete" src={TrashCan} className='icon_button'
						onClick={(event) => {
							event.persist();
							this.setState({
								category_to_delete: row.original.id,
								is_parent: true
							});
							console.log("row:"+row.id);
							this.showDeleteCategoryBox(row.id);

						}}/>
					}
				}]
		}]

		return (

			<main>
				<p id="breadCrumbs">{this.props.breadCrumbs}</p>
				<h1>Categories</h1>
				<ul className="topnav">


				</ul>
				<nav>
					<Container fluid>
						<Row>
							<Col>
								<div className="search">
									<input type = "text"
					                value = ''
					                value = { this.state.inputText }
					                onChange = {
					                    (event) => {
					                        this.props.onfilterTable({
					                            type: 'FILTER_CATEGORY_LIST',
					                            value: event.target.value
					                        })
					                        let inputText = { value: event.target.value }
					                        this.setState({ inputText: event.target.value })
					                    }
					                }
					                placeholder = "Search by Name" />

									<img id="resetTableListBtn" src={Reset} alt="Reset" className="icon_button"
										onClick={() => {
			                                this.props.onViewCategoryList()
											this.setState({inputText: null})
			                        	}}/>
								</div>
							</Col>
							<Fragment>
							<Col>
				                <ul className = "addItem" >
				                <li onClick={this.showAddCategoryBox}><button className="btn" type="button">Add Category</button></li>
				                </ul>
				            </Col>
				            </Fragment>

						</Row>
					</Container>
				</nav>
				<Styles>
					{/* gets the user data from the userPage class and fills in the table */}
					{/*props.categories*/}
					<Table columns={columns} data={(this.props.viewFilteredList) ? this.props.filteredList : this.props.categories} />
				</Styles>

				{/* Supposed to display when the user clicks on the edit user button */}
				<dialog id="categoryBox" className="bigDialogBox">
					<img id="exit-dialog-icon" src={Exit} height={28} onClick={this.hideCategoryBox} alt="exit button"/>
                    <h2>Sub Categories</h2>
                    <Fragment>
                    <ul className="topnav">
	                    <li id="secondaryNavigationItems" onClick={this.showAddSubCategoryBox}>Add Sub Category</li>
	                </ul>
                	</Fragment>
					<br/>
                    <br/>
                    <Styles>
                    	<Table columns={subcat_columns} data={this.props.subcategories} />
                    </Styles>

				</dialog>
				<dialog id="addcategoryBox" className="bigDialogBox">
					<img id="exit-dialog-icon" src={Exit} height={28} onClick={this.hideAddCategoryBox} alt="exit button"/>
                    <h2>Add Category</h2>

					<br/>
                    <br/>
                     <label>Name</label>
                    <br/>
                    <input type="text" value={this.state.category_name} onChange={this.handleAddCategory} placeholder="Name"/>
                    <br/>
                    <br/>
                    <div className="btn--flex">
                        <button onClick={this.addCategory} type="button" className="btn btn--confirm dialog__btn">
                            Confirm
                        </button>
                        <button onClick={this.hideAddCategoryBox} type="button" className="btn btn--close dialog__btn">
                            Cancel
                        </button>
                    </div>

				</dialog>

				<dialog id="addSubcategoryBox" className="bigDialogBox">
					<img id="exit-dialog-icon" src={Exit} height={28} onClick={this.hideAddCategoryBox} alt="exit button"/>
                    <h2>Add Sub Category</h2>

					<br/>
                    <br/>
                     <label>Name</label>
                    <br/>
                    <input type="text" value={this.state.category_name} onChange={this.handleAddCategory} placeholder="Name"/>
                    <br/>
                    <br/>
                    <div className="btn--flex">
                        <button onClick={this.addSubCategory} type="button" className="btn btn--confirm dialog__btn">
                            Confirm
                        </button>
                        <button onClick={this.hideAddSubCategoryBox} type="button" className="btn btn--close dialog__btn">
                            Cancel
                        </button>
                    </div>

				</dialog>

				<dialog id="editcategoryBox" className="bigDialogBox">
					<img id="exit-dialog-icon" src={Exit} height={28} onClick={this.hideEditCategoryBox} alt="exit button"/>
                    <h2>Edit Category</h2>

					<br/>
                    <br/>
                     <label>Name</label>
                    <br/>
                    <input type="text" value={this.state.category_name} onChange={this.handleEditCategory} placeholder="Name"/>
                    <br/>
                    <br/>
                    <div className="btn--flex">
                        <button onClick={this.editCategory} type="button" className="btn btn--confirm dialog__btn">
                            Confirm
                        </button>
                        <button onClick={this.hideEditCategoryBox} type="button" className="btn btn--close dialog__btn">
                            Cancel
                        </button>
                    </div>

				</dialog>
				<dialog id="deletecategoryBox" className="bigDialogBox">
					<img id="exit-dialog-icon" src={Exit} height={28} onClick={this.hideDeleteCategoryBox} alt="exit button"/>
                    <h2>Delete Category</h2>

					<br/>
                    <br/>
                     <label>Please type DELETE to delete the category permanently. This is not reversable.</label>
                    <br/>
                    <input type="text" value={this.state.delete_confirm} onChange={this.handleDeleteCategory} placeholder=""/>
                    <br/>
                    <br/>
                    <div className="btn--flex">
                        <button onClick={this.deleteCategory} type="button" className="btn btn--confirm dialog__btn">
                            Confirm
                        </button>
                        <button onClick={this.hideDeleteCategoryBox} type="button" className="btn btn--close dialog__btn">
                            Cancel
                        </button>
                    </div>

				</dialog>
			</main>


		);

	}


}
export default ClientTable;
