import React, { Component , Fragment} from 'react';
import * as XLSX from 'xlsx';
import BulkItemFormat from './style/BulkItemFormat.png'
import Exit from './style/exit.png';
import "./style/addUserDialog.css";
import Select from 'react-select';
import Table from './Table'
import TrashCan from './style/delete.png';
import Edit from './style/edit.png';
import Styles from './Styles';
import CurrencyInput from 'react-currency-input-field';

class AddManyEvents extends Component {
    constructor () {
        super();
        this.state = {
            xlsxData: [],
            client:'',
            id_to_edit:'',
            new_name:'',
            new_description:'',
            new_maxParticipants:'',
            new_maxSpectators:'',
            new_entryCost:'',
            new_owner_id:'',


        }
        this.handleXLSXChange = this.handleXLSXChange.bind(this);
        this.handleAddManyItem = this.handleAddManyItem.bind(this);

        this.handleNameChange = this.handleNameChange.bind(this)
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
        this.handleMParticipantsChange = this.handleMParticipantsChange.bind(this)
        this.handleMSpectatorsChange = this.handleMSpectatorsChange.bind(this)
        this.handlePriceChange = this.handlePriceChange.bind(this)

        this.removeItem = this.removeItem.bind(this)

        this.handleEditPreviewItem = this.handleEditPreviewItem.bind(this)
        this.hideEditPreviewModal = this.hideEditPreviewModal.bind(this)
        this.showEditPreviewModal = this.showEditPreviewModal.bind(this)

        this.showPreview = this.showPreview.bind(this)
        this.hidePreviewModal = this.hidePreviewModal.bind(this)

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

     handleNameChange(event) {
        this.setState({new_name: event.target.value});

    }
    handleDescriptionChange(event){
        this.setState({new_description: event.target.value});
    }
    handleMParticipantsChange(event){
        this.setState({new_maxParticipants: event.target.value});
    }
    handleMSpectatorsChange(event){
        this.setState({new_maxSpectators: event.target.value});
    }
    handlePriceChange(value){
        console.log(value);
        this.setState({price: value});
    }

    showModal () {
        this.props.onEditBreadCrumbs({
            type: 'APPEND_BREADCRUMB',
            breadCrumbs: " > Bulk Item Upload"
        })
        const addManyItemsDialog = document.getElementById('addManyItemsDialog');
        addManyItemsDialog.showModal();
        // displays the modal and empties the fields
        this.setState({ file: '' });
    }

    hideModal () {
        this.props.onEditBreadCrumbs({
            type: 'REMOVE_BREADCRUMB',
            breadCrumbs: "Events"
        })
        this.setState({xlsxData: []})
        document.getElementById('bulkUploadInput').value = '';
        const addManyItemsDialog = document.getElementById('addManyItemsDialog');
        addManyItemsDialog.close();

        this.setState({
            xlsxData: [],
            id_to_edit:'',
            new_name:'',
            new_description:'',
            new_maxParticipants:'',
            new_maxSpectators:'',
            new_entryCost:'',
            new_owner_id:''
        });
    }

    showPreview () {
        if(this.state.xlsxData.length > 0){
        this.props.onEditBreadCrumbs({
            type: 'APPEND_BREADCRUMB',
            breadCrumbs: " > Bulk Item Upload"
        })
        const previewDialog = document.getElementById('itemPreviewDialog');
        previewDialog.showModal();
        }
        else{
            alert("Please upload a file to preview");
        }

    }
    // hidePreviewModal
    hidePreviewModal() {
        this.props.onEditBreadCrumbs({
            type: 'REMOVE_BREADCRUMB',
            breadCrumbs: "Events"
        })
        const previewDialog = document.getElementById('itemPreviewDialog');
        previewDialog.close();
    }

    showEditPreviewModal(){
        this.props.onEditBreadCrumbs({
            type: 'APPEND_BREADCRUMB',
            breadCrumbs: " > Bulk Item Upload"
        })
        const previewEditDialog = document.getElementById('editPreviewItemDialog');
        previewEditDialog.showModal();
    }

    hideEditPreviewModal(){
        this.props.onEditBreadCrumbs({
            type: 'REMOVE_BREADCRUMB',
            breadCrumbs: "Events"
        })
        const previewEditDialog = document.getElementById('editPreviewItemDialog');
        previewEditDialog.close();
    }

    handleEditPreviewItem(){

        var id_to_edit = this.state.id_to_edit;
        var itemList = this.state.xlsxData;

        itemList[id_to_edit].name = this.state.new_name;
        itemList[id_to_edit].description = this.state.new_description;
        itemList[id_to_edit].maxSpectators = this.state.new_maxSpectators;
        itemList[id_to_edit].maxParticipants = this.state.new_maxParticipants;
        itemList[id_to_edit].entryCost = this.state.new_entryCost;

        this.setState({
            xlsxData:itemList,
            new_name:'',
            new_description:'',
            new_maxSpectators:'',
            new_maxParticipants:'',
            new_entryCost:''
        });
        this.hideEditPreviewModal();
    }
    // adding the new user into the table

    handleAddManyItem() {
        let owner_id = this.state.owner_id;
        console.log(owner_id);
        console.log(this.props.role);
        if(this.props.role !== 'root'){
            owner_id = this.props.uid
        }
        if(owner_id !== ''){
            this.props.onManyAddEvents({
                data: this.state.xlsxData,
                owner: owner_id
            })
            // this.showPreview();
            this.hideModal();
        }else{
            alert("Select a owner");
        }
    }

    removeItem(id){
        var itemList = this.state.xlsxData;

        itemList.splice(id,1);
        this.setState({
            xlsxData:itemList
        })
    }

    handleXLSXChange(event) {
        event.preventDefault();
        let f = event.target.files[0];
        var name = f.name;
        console.log(name)
        const reader = new FileReader();
        reader.onload = (evt) => { // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, {type:'binary'});
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Update state */
            let data = XLSX.utils.sheet_to_json(ws)
            this.setState({ xlsxData: data });
        };
        reader.readAsBinaryString(f);
    }
    render () {
        var itemColumns = [
        {
            Header: 'Event Preview',
                columns: [
                {
                    Header: 'Name',
                    accessor: 'name',
                },
                {
                    Header: 'Description',
                    accessor: 'description',
                },
                {
                    Header: 'Max Participants',
                    accessor: 'maxParticipants'
                },
                {
                    Header: 'Max Spectators',
                    accessor: 'maxSpectators',
                },
                {
                    Header: 'Entry Cost',
                    accessor: 'entryCost',
                },
                {
                    Header: '  ',
                    Cell: ({row}) => {
                        return <img title="Edit Item" height={25} width={25} alt="edit" className='icon_button'
                        src={Edit}
                        onClick={(event) => {
                            event.persist();
                            let rData = row.original;
                            console.log(row);
                            this.setState({
                                new_name: rData.name,
                                new_description: rData.description,
                                new_maxParticipants: rData.maxParticipants,
                                new_maxSpectators: rData.maxSpectators,
                                id_to_edit: row.id,
                                new_entryCost: rData.entryCost,
                            },()=>{
                                console.log(this.state)
                                this.showEditPreviewModal();
                            })
                            // this.getPrevData(rData);

                        }}/>
                    }
                },
                {
                    Header: ' ',
                    Cell: ({row}) => {
                        return <img title="Delete Item" height={25} width={25} src={TrashCan} alt="delete" className='icon_button'
                        onClick={(event) => {
                            event.persist();
                            // var rowIndex = row.row.id;
                            let rData = row.original;
                            var res = window.confirm("Are you sure you want to delete the Item?");
                            if (res) {
                                this.removeItem(rData.id);
                            }
                        }}/>
                    }
                }]
        }
        ];
        return (
            <section>
                <nav role="navigation">
                    <button type="button" onClick={this.showModal} className="btn">
                        Bulk Upload
                    </button>
                </nav>
                <dialog id="addManyItemsDialog" className="bigDialogBox">
                    <img id="exit-dialog-icon" src={Exit} height={28} onClick={this.hideModal} alt="exit button"/>
                    <h2>Bulk Item Upload</h2>
                    <p>Please use the format from the image below to upload events in bulk.</p>
                    <img id="bulkItemImg" src={BulkItemFormat} alt="xlsx format example"/>
                    <br/><br/>
                    {(this.props.role == 'root')?
                    <Fragment>
                    <Select options={this.props.businessUserList.map((b_user) => {return { 'value': b_user.id, 'label': b_user.name }})}
                                        id='searchBox' placeholder='Select a owner'
                                        value={this.state.inputText}
                                        onChange={(event) => {
                                            let inputText = {label: event.label, value: event.value}
                                            this.setState({
                                                            inputText: inputText,
                                                            owner_id:inputText.value
                                                        }, () => {
                                                      });
                                        }}/>
                    </Fragment>:
                    null
                    }
                    <br/><br/>
                    <p>Enter the XLSX file:</p>
                    <input type="file" id="bulkUploadInput" onChange={this.handleXLSXChange} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                    <br/>
                    <br/>

                    <div className="btn--flex">
                        <button onClick={this.handleAddManyItem} type="button" className="btn btn--confirm dialog__btn" style={{'width':'800px'}}>
                            Confirm and Upload
                        </button>
                    </div>
                </dialog>

                <dialog id="itemPreviewDialog" className="bigDialogBox">
                <img id="exit-dialog-icon" src={Exit} height={28} onClick={this.hidePreviewModal} alt="exit button"/>
                    <h2>Preview List</h2>
                <Styles>
                    <Table columns={itemColumns} data={this.state.xlsxData} />
                </Styles>
                </dialog>

                <dialog id="editPreviewItemDialog" className="bigDialogBox">
                    <img id="exit-dialog-icon" src={Exit} height={28} onClick={this.hideEditPreviewModal} alt="close pop up"/>
                    <h2>Edit Item</h2>

                    <br/>
                    <label>Name</label>
                    <br/>
                    <input type="text" value={this.state.new_name} onChange={this.handleNameChange} placeholder="Name"/>
                    <br/><br/>
                    <label>Description:</label>
                    <br/>
                    <input type="text" value={this.state.new_description} onChange={this.handleDescriptionChange} placeholder="Description"/>
                    <br/><br/>
                    <label>Max Participants:</label>
                    <br/>
                    <input type="text" value={this.state.new_maxParticipants} onChange={this.handleMParticipantsChange} placeholder="Max Participants"/>
                    <br/><br/>
                    <label>Max Spectators:</label>
                    <br/>
                    <input type="text" value={this.state.new_maxSpectators} onChange={this.handleMSpectatorsChange} placeholder="Max Spectators"/>
                    <br/><br/>
                    <label>Entry Cost:</label>
                    <br/>
                    <CurrencyInput
                        placeholder="Price Per Unit"
                        decimalsLimit={2}
                        prefix='$'
                        value={this.state.new_entryCost}
                        onValueChange={(value,name)=>this.handlePriceChange(value)}
                    />

                    <br/>
                    <br/>
                    <div className="btn--flex">
                        <button onClick={this.handleEditPreviewItem} type="button" className="btn btn--confirm dialog__btn">
                            Confirm
                        </button>
                    </div>
                </dialog>

            </section>
        );
    }
};
export default AddManyEvents;
