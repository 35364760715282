/* High Level: Generates the page that displays all the users, a main
 *             navigation bar, buttons to add new users and a search box.
 * Purpose: Enables the admin to manage the users in case of an emergency
 *          and allows the clients to input their own users in bulk and filter
 *          the users by categories.
 * Approach: Get the users from Firebase to fill up the list. Allow clients
 *           to add their own users through the buttons.
*/
import React, { Component, Fragment } from 'react'
import AddUserDialog from './addUserDialog'
// import ShowQuoteDialog from './ShowQuoteDialog'
import AddManyUsersDialog from './addManyUsersDialog'
import Input from 'react-phone-number-input/input'
import Select from 'react-select'
import Styles from './Styles'
import TrashCan from './style/delete.png'
import Edit from './style/edit.png'
import Exit from './style/exit.png'
import Reset from './style/reset_btn.png'
import Mail from './style/mail.png'
import Table from './Table';
import "./style/UserTable.css"
import Bell from './style/bell.png'
import Check from './style/check-round.png';
import Uncheck from './style/uncheck-round.png';
import Print from './style/print.png'
import { CSVLink} from "react-csv";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
// CSS Modules, react-datepicker-cssmodules.css// 
import 'react-datepicker/dist/react-datepicker-cssmodules.css'; 

//BOOTSRAP:
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// TOOLTIP:
import ReactTooltip from "react-tooltip";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import './style/UserTable.css'
import { withStyles, makeStyles } from '@material-ui/core/styles';
// get the doc reference from a UID when adding user to ERP
// const db = FBapp.firestore();
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: 'black',
    border: '1px solid black',
    boxShadow: theme.shadows[1],
    fontSize: 18,
  },
}))(Tooltip);


class UserTable extends Component {
	// gets the data thats generated from userPage.js which fills up the table
	constructor () {
		super();
		// using these fields to handle the user input
        this.state = {
			index: 0, // index of the selected user for edit
            profilePicture: '', // picture url
			first: '', // User's First Name
			last: '', // User's Last Name
            email: '', // Users Email
			phone: '', // User's phone number,
			password: '', // Users's password
			imgSrc: '', // Stores the profilePicture source
			otherUser: '', // {String} the other users UID
			role: '', // {String} user's role
			inputText: null, // {Obj for React Select} = {label: string, value: string}
			csvArr: [],
			prevData: [],
      address:'',
			startDate: new Date(),
            lastDate:new Date(),
            username:'',
            businessName:'',
            profileName:'',
    		profilePhone:'',
    		profileEmail:'',
    		userEventList: [],
    		userRigList: [],
        userReferalList:[]

		}
		this.handlePicChange = this.handlePicChange.bind(this)
		this.handleEditUser = this.handleEditUser.bind(this)
		this.handleToggleChange = this.handleToggleChange.bind(this)
		this.handleFirstNameChange = this.handleFirstNameChange.bind(this)
		this.handleLastNameChange = this.handleLastNameChange.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleUsernameChange = this.handleUsernameChange.bind(this)
    this.handleAddressChange = this.handleAddressChange.bind(this)
    this.handleBusinessNameChange = this.handleBusinessNameChange.bind(this)
		this.handlePhoneChange = this.handlePhoneChange.bind(this)
    this.handlePaymentDueDateChange = this.handlePaymentDueDateChange.bind(this)
		this.viewUserProfile = this.viewUserProfile.bind(this)
		this.handleClientFilterChange = this.handleClientFilterChange.bind(this)
		this.showModal = this.showModal.bind(this)
		this.hideModal = this.hideModal.bind(this)
		this.hideUserProfileModal = this.hideUserProfileModal.bind(this)
		this.getCSVData = this.getCSVData.bind(this)
		this.getPrevData = this.getPrevData.bind(this)
    this.sendPasswordResetEmail = this.sendPasswordResetEmail.bind(this)


	}

  sendPasswordResetEmail(){
    let email = this.state.email;

    if(email){
      console.log(email);
      this.props.sendForgotPasswordLink(email)
      this.hideModal()
    }

  }

	getPrevData(data)
	{
		console.log(data);

		//before edited data
		let arr = []
		arr.push({
			'profilePicture' : data.profilePicture,
			'first' : data.first,
			'last' : data.last,
			'email' : data.email,
			'phone' : "+1"+data.phone,
			'index' : data.index,
			'password' : data.password,
			'role' : data.role,
			'id':data.id,
		});

		this.setState({prevData : arr})
	 }

   createLog(){
		//console.log(arr[0]['name'])
		 console.log(this.state.prevData[0]['phone']);
		 console.log(this.state.phone);


		if(this.state.prevData.length !== 0 )
		{
			// if(this.state.prevData[0]['profilePicture'] !==  this.state.profilePicture){
	 	// 		this.props.createUserLog({
	 	// 		logType: "edited",
	 	// 		source: "web-console",
	  //     		oldValue: this.state.prevData[0]['profilePicture'],
	  //     		newValue: this.state.profilePicture,
	  //     		document: this.state.prevData[0]['id']

	  //         });
	 	// 	}

	 		if(this.state.prevData[0]['first'] !== this.state.first){
	 			this.props.createUserLog({
	 			logType: "edited",
	 			source: "web-console",
	      		oldValue: this.state.prevData[0]['first'],
	      		newValue: this.state.first,
	      		document: this.state.prevData[0]['id']

	          });
	 		}
	 		if(this.state.prevData[0]['last'] !== this.state.last){
	 			this.props.createUserLog({
	 			logType: "edited",
	 			source: "web-console",
	      		oldValue: this.state.prevData[0]['last'],
	      		newValue: this.state.lastName,
	      		document: this.state.prevData[0]['id']

	          });
	 		}
	 		if(this.state.prevData[0]['email'] !== this.state.email){
	 			this.props.createUserLog({
	 			logType: "edited",
	 			source: "web-console",
	      		oldValue: this.state.prevData[0]['email'],
	      		newValue: this.state.email,
	      		document: this.state.prevData[0]['id']

	          });
	 		}
	 		if(this.state.prevData[0]['phone'] !== this.state.phone){
	 			this.props.createUserLog({
	 			logType: "edited",
	 			source: "web-console",
	      		oldValue: this.state.prevData[0]['phone'],
	      		newValue: this.state.phone,
	      		document: this.state.prevData[0]['id']

	          });
	 		}
	 		if(this.state.prevData[0]['password'] !== this.state.password){
	 			this.props.createUserLog({
	 			logType: "edited",
	 			source: "web-console",
	      		oldValue: this.state.prevData[0]['password'],
	      		newValue: this.state.password,
	      		document: this.state.prevData[0]['id']

	          });
	 		}

	 		if(this.state.prevData[0]['role'] !== this.state.role){
	 			this.props.createUserLog({
	 			logType: "edited",
	 			source: "web-console",
	      		oldValue: this.state.prevData[0]['role'],
	      		newValue: this.state.role,
	      		document: this.state.prevData[0]['id']

	          });
	 		}
	 	}
}

	handleEditUser() {
		// check if the new email already exists in the DB
		let existingEmailCheck = this.props.gUserList.findIndex((user) => (user.email === this.state.email))
    	console.log(this.state.phone.length);
		if (this.state.phone.length < 10) {
			alert('Please Input 10 digits for your phone number.')
		} else if ((this.state.email.indexOf('@') === -1 || this.state.email.length < 7)) {
			alert('Please make sure you enter a valid email')
		} else if (this.props.userList[this.state.index].email !== this.state.email && existingEmailCheck == this.state.index) {
			console.log("found "+this.props.userList[this.state.index].email)
			console.log("new "+this.state.email)
      console.log("existing index "+existingEmailCheck)
      console.log("current index "+this.state.index)
			// assume email has been changed and new email already exists
			alert('Another user already has the same email.')
		}
    else if (this.state.address == undefined || this.state.address == '') {
			alert('Please enter an address.')
		}
    else if (this.state.first == undefined || this.state.first == '') {
			alert('Please enter a first name.')
		}
    else if (this.state.last == undefined || this.state.last == '') {
			alert('Please enter a last name.')
		}
    else if( (this.state.role == 'business') && (this.state.paymentDueDate == undefined || this.state.paymentDueDate == null || this.state.paymentDueDate == '') ){
      alert('Set a payment due date.')
    }
     else {
			// sending data to userPage to update the user list
			this.props.onEditUser({
				first: this.state.first,
				last: this.state.last,
				email: this.state.email,
        		businessName: this.state.businessName,
        		username: this.state.username,
        		paymentDueDate: this.state.paymentDueDate,
				phone: this.state.phone.slice(2), //remove the first two characters of the number
        address: this.state.address,
				index: this.state.index,
        		role:this.state.role
			})
			this.createLog();
			this.hideModal();
		}
	}

	handleClientFilterChange(event) {
		this.props.onClientFilterChange({
			clientID: event.target.value
		})

	}
	handlePasswordChange(event) {
		this.setState({password: event.target.value})

	}
	handleToggleChange(index) {

		// sending data to userPage to update the Toggle for the user
		this.props.onToggleUser(index)


	}
	handlePicChange(event) {
		event.preventDefault();
		let reader = new FileReader();
		// get the file thats been uploads
        let file = event.target.files[0];
        // after the file finished loading
        reader.onloadend = () => {
            this.setState({
                profilePicture: file,
                imgSrc: reader.result
             });
        }
        reader.readAsDataURL(file)
	}
	handleFirstNameChange(event) {
        this.setState({first: event.target.value});


	}
	handleLastNameChange(event) {
        this.setState({last: event.target.value});

    }
    handleEmailChange(event) {
        this.setState({email: event.target.value});

    }
    handleUsernameChange(event){
    	this.setState({username: event.target.value});
    }

    handleAddressChange(event){
    	this.setState({address: event.target.value});
    }

    handleBusinessNameChange(event){
    	this.setState({businessName: event.target.value});
    }

    handlePhoneChange(num) {
        this.setState({phone: num});
    }

    handlePaymentDueDateChange(date){
      this.setState({paymentDueDate:date})
    }

    viewUserEvents(){

    }

    viewUserProfile(index){
    	let idx = index.index;

    	let user = this.props.userList[idx];

    	let num = user.phone.toString(); // convert to string
    	let phone = '';
    	if(num){
			phone = '(' + num.substr(0,3) + ')-' + num.substr(3,3) + '-' + num.substr(6);
		}

		this.props.viewUserEvents(user.id,user.role);
		this.props.viewUserRigs(user.id);
    this.props.viewUserReferals(user.id);

    let paymentDueDate = null
    let paymentDueDateText = null

    if(user.paymentDueDate && user.paymentDueDate != 'null' && user.paymentDueDate != null && user.paymentDueDate != undefined){
      paymentDueDate = user.paymentDueDate.toDate();
      paymentDueDateText = (paymentDueDate.getMonth() + 1) + "/" + (paymentDueDate.getDate()) + "/" + (paymentDueDate.getFullYear())
    }
		setTimeout(()=>{

	    	this.setState({
	    		profileName:user.first+" "+user.last,
	    		profilePhone: phone,
	    		profileEmail: user.email,
	    		profileUsername: user.username,
	    		profileAddress: user.address,
	    		userEventList: this.props.userEventList,
	    		userRigList: this.props.userRigList,
          userReferalList: this.props.userReferalList,
          profileRole:user.role,
          paymentDueDate: paymentDueDate,
          paymentDueDateText: paymentDueDateText,
	    	});

	    		const userProfileDialog = document.getElementById('userProfileDialog');
				userProfileDialog.style.left = (this.props.toggleMenu) ? '105px' : '330px'
		        userProfileDialog.showModal();
    	},2500);
    }

	showModal (action) {
		if(action === 'showQuoteDialog'){


		}
		else{
			this.props.onEditBreadCrumbs({
	            type: 'APPEND_BREADCRUMB',
	            breadCrumbs: " > Edit User"
			})


			const addUserDialog = document.getElementById('addUserDialog');
			addUserDialog.style.left = (this.props.toggleMenu) ? '105px' : '330px'
			// display edit user dialog
	        addUserDialog.showModal();
		}

    }
    hideModal () {
		this.props.onEditBreadCrumbs({
            type: 'REMOVE_BREADCRUMB',
            breadCrumbs: "Users"
        })
		const addUserDialog = document.getElementById('addUserDialog');
        addUserDialog.close();


	}
	hideUserProfileModal () {
		this.props.onEditBreadCrumbs({
            type: 'REMOVE_BREADCRUMB',
            breadCrumbs: "Users"
        })
		const userProfileDialog = document.getElementById('userProfileDialog');
        userProfileDialog.close();

        this.setState({
    		profileName:'',
    		profilePhone:'',
    		profileEmail:'',
    		userEventList: []
    	});

	}

	getCSVData(tdata){
        let len = tdata.length;
        // console.log(tdata);
        let csv = [];
        var iDate = null;
      	var sDate = null;
      	var ini = null;
      	var sec = null;

        // console.log(csv);
        this.setState({csvArr:csv});

    }


	render() {

		var eventscolumns = [
		{
			Header: 'Events',
			columns:[
			{
				Header:'Name',
				Cell:({row})=>{
					let name = row.original.name;
					return name;
				}
			}]
		}];

    var referalcolumns = [
      {
        Header: 'Referals',
        columns:[
          {
            Header:'Email',
            accessor:'email'
          },
          {
            Header:'Phone',
            accessor:'phone',
          },
          {
            Header:'Status',
            Cell:({row})=>{
              let status = row.original.status;
              let statusText = '';
              if(status == 1){
                statusText = 'PENDING';
              }else if(status == 2){
                statusText = 'VIEWED';
              }else if(status == 3){
                statusText = 'JOINED';
              }
              return statusText
            },
            accessor:'statusText'
          }
        ]
      }
    ]

 		var columns = [
			{
				Header: 'Users', //1
				columns: [
				{
					Header: 'Name', //2
					accessor: 'name',
					Cell: ({row}) => {

						let num = row.original.phone;
						let phone = '';
						if (num !== null && num !== undefined) {
							// assume the user has a number
							num = num.toString(); // convert to string
							phone = '(' + num.substr(0,3) + ')-' + num.substr(3,3) + '-' + num.substr(6);
						}

						let email = row.original.email
						let titleDesc = email+ " - "+phone

					  	return  <LightTooltip title={titleDesc} placement="top"><span onClick={() => {this.viewUserProfile({ index: row.id })}} style={{cursor:'pointer'}}>{row.original.name}</span></LightTooltip>



					},

				},
				{
					Header: 'Email', //3
					accessor: 'email',
				},
        {
          Header: 'Business',
          accessor: 'businessName'
        },
				{
					Header: 'Phone Number', //4
					Cell: ({row}) => {
						let num = row.original.phone;
						let phone = '';
						if (num !== null && num !== undefined && num !== '') {
							// assume the user has a number
							num = num.toString(); // convert to string
							phone = '(' + num.substr(0,3) + ')-' + num.substr(3,3) + '-' + num.substr(6);
						}
						return phone;
					}
				},
				{
					Header: 'Role',
					Cell:({row})=>{
						let role = '' ;
						if(row.original.role){
							 role = row.original.role;
						}
							return role;

					},
          accessor:'role'
				},
				{
					Header: 'Last Logged In',
					Cell:({row})=>{
						let lastdate = row.original.lastLoggedIn;
						if(lastdate){
							return lastdate.toDate().toDateString();
						}else{
							return " ";
						}
					}
				},
				{
					// deactived field (deleted or not) //6
					// DONOT SHOW IT UNDER DELETED TAB
					Header: 'Enabled',
					Cell: ({row}) => {
						let deactivated = row.original.deactivated;
						// save as string and then do the logic
						return <label className="switch" title="Enable Toggle"><input type="checkbox" defaultChecked={!deactivated}/><span
						className="slider round" onClick={() => {this.handleToggleChange({ index: row.id })}}></span></label>
					}
				},

				{
					// Edit button functionality //7
					// DONOT SHOW IT TO OTHERS EXCEPT USERS AND TEAMS
					Header: '  ',
					Cell: ({row}) => {
						return <img title="Edit User" height={25} width={25} alt="edit" className='icon_button'
						src={Edit}
						onClick={(event) => {
							event.persist();
							const rData = row.original; // updates the fields to display the current
							let nameArr = rData.name.split(' '); // get the first/last name
							const first = nameArr[0];
							nameArr.shift();
							// console.log("En: "+rData.emergencyName);
							const last = nameArr.join(' ');

              let paymentDueDate = null;

              if(rData.paymentDueDate && rData.paymentDueDate != null && rData.paymentDueDate != undefined){
                paymentDueDate = rData.paymentDueDate.toDate();
              }

							this.setState({
								email: rData.email,
								first: rData.first,
								last: rData.last,
								username:rData.username,
								address:rData.address,
								businessName:rData.businessName,
								imgSrc: rData.profilePicture,
								index: row.id,
								phone: rData.phone,
				                paymentDueDate: paymentDueDate,
				                role:rData.role
							})
							this.getPrevData(rData);
							this.showModal();
						}}/>
					}
				},
				{
				// Delete button functionality //8
				// DONOT show to others except under users and teams tab
					Header: ' ',
					Cell: (row) => {
						return <img title="Delete User" height={25} width={25} src={TrashCan} alt="delete" className='icon_button'
						onClick={(event) => {
							event.persist()
							var rowIndex = row.row.id;
							var res = window.confirm("Are you sure you want to delete the User?");
							if (res) {
								// Supposed to send the row ID of the User the admin wants to delete
								this.props.onDeleteUser({id: rowIndex});
							}
						}}/>
					}
				}


				]



			}];



		//SHOWN ONLY TO DELETED TAB
		if (this.props.viewDeletedUsers) //14
		{

			// assume the deleted Users table is displayed
			columns[0].columns.splice(8,12) // remove the deleted button column and the covid reports button
			columns[0].columns.splice(6,1) // remove the enabled column
			columns[0].columns.push({
				Header: 'Reactivate User',
				Cell: ({row}) => <button id="taskButton" onClick={() => {
					let res = window.confirm("Are you sure you want to reactivate the user?")
					if (res) {

						this.props.reactivateUser({
							index: row.id,
							UID: row.original.id
						})

					}
				}}>Reactivate</button>
			})
		}


		let addUserBtn = null
		let bulkAddBtn = null
		console.log('User table - PP: '+this.props.planPager)
		console.log('User table - SPP: '+this.props.subPlanPager)

		if (this.props.role === 'root') {
			// assume user is Super Admin or Root
			// initilaize the add User Button
			// console.log("cl:"+this.props.clientList);
			addUserBtn = (this.props.viewDeletedUsers) ? null : <li><AddUserDialog onSubmit={this.props.onAddNewUser}
				clientList={this.props.clientList} onEditBreadCrumbs={this.props.onEditBreadCrumbs}
				toggleMenu={this.props.toggleMenu} role={this.props.role} companyID={this.props.companyID} userList={this.props.userList}/></li>


		}

		let header = 'User List'


		return (

	    <main>
			<p id="breadCrumbs">{(this.props.planPager === 6) ? 'Reports' : this.props.breadCrumbs}</p>
            <h1>{header}</h1>
                {
                	<Fragment>
	                <ul className="topnav">
	                    <li id="secondaryNavigationItems" onClick={this.props.onViewUserList} className={this.props.highlight[0]}>Users</li>
	                    <li id="secondaryNavigationItems" onClick={this.props.onViewDeletedUsers} className={this.props.highlight[2]}>Deleted</li>
	                </ul>
                	</Fragment>

            	}




        <nav>
           	<Container fluid>
            	<Row>
        			<Col>
	                    <div className="search">
							<Select options={this.props.userList.map((user) => {return { 'value': user.name, 'label': user.name }})}
								id='searchBox' placeholder='Select a User'
								value={this.state.inputText}
	                            onChange={(event) => {
									this.props.onfilterTable({
										type: 'FILTER_USER_LIST',
										value: event.value
									})
									let inputText = {label: event.label, value: event.label}
									this.setState({inputText: inputText})
								}}/>
							<img id="resetTableListBtn" src={Reset} alt="Reset" className="icon_button"
								onClick={() => {
									if (this.props.viewDeletedUsers) {
										this.props.onViewDeletedUsers()
									} else {
										this.props.onViewUserList({planPager:4 , subPlanPager: this.props.subPlanPager})
									}
									this.setState({inputText: null})
	                        	}}/>
	                    </div>
				    </Col>

                  		<Fragment>
                  		<Col>
		                    <ul className="addItem">
								{addUserBtn}
		                        {bulkAddBtn}
		                    </ul>
		                </Col>
             			</Fragment>

                </Row>
            </Container>
        </nav>



				<Styles>
					{/* gets the user data from the userPage class and fills in the table */}
					<Table columns={columns} data={(this.props.viewFilteredList) ? this.props.filteredList : this.props.userList} />
				</Styles>
				{/* Supposed to display when the user clicks on the edit user button */}
				{(this.props.userList[this.state.index] !== undefined && this.props.userList[this.state.index] !== null) ? <dialog id="addUserDialog" className="bigDialogBox">
					<img id="exit-dialog-icon" src={Exit} height={28} onClick={this.hideModal} alt="close pop up"/>
                    <h2>Edit User</h2>
                    <br/>
					{(this.props.userList[this.state.index].id === this.props.uid) ? <Fragment>
						<input type="email" value={this.state.email} onChange={this.handleEmailChange} placeholder="Email"/>
                    	<br/>
					</Fragment> : null}
					<label>Email</label>
					<br/>
                    <input type="text" value={this.state.email} onChange={this.handleEmailChange} placeholder="Email"/>
                    <br/><br/>
                    <label>Username</label>
					<br/>
                    <input type="text" value={this.state.username} onChange={this.handleUsernameChange} placeholder="Username"/>
                    <br/><br/>
					<label>First Name</label>
					<br/>
                    <input type="text" value={this.state.first} onChange={this.handleFirstNameChange} placeholder="First Name"/>
                    <br/><br/>
                    <label>Last Name:</label>
                    <br/>
                    <input type="text" value={this.state.last} onChange={this.handleLastNameChange} placeholder="Last Name"/>
                    <br/><br/>
                    {(this.state.role == 'business')?
                    <Fragment>
                    <label>Business Name:</label>
                    <br/>
                    <input type="text" value={this.state.businessName} onChange={this.handleBusinessNameChange} placeholder="Business Name"/>
                    <br/><br/>
                    </Fragment>: null}
                    <label>Address:</label>
                    <br/>
                    <input type="text" value={this.state.address} onChange={this.handleAddressChange} placeholder="Address"/>
                    <br/><br/>
                    <label>Phone Number:</label>
                    <br/>
					          <Input country="US" value={this.state.phone} onChange={this.handlePhoneChange} maxLength={14} placeholder="Phone Number"/>
                    <br/><br/>
                    {(this.state.role == 'business')?
                    <Fragment>
                    <label>Payment Due Date</label><br/>
                    <DatePicker
                      selected={this.state.paymentDueDate}
                      onChange={this.handlePaymentDueDateChange} //only when value has changed
                    />
                    <br/>
                    </Fragment> : null }
                    <div className="btn--flex">
						<button onClick={this.handleEditUser} type="button" className="btn btn--confirm dialog__btn">
                            Confirm
                        </button>
            <button style={{'padding-top': '3px'}} onClick={this.sendPasswordResetEmail} type="button" className="btn btn--confirm dialog__btn">
                            <img id="sendMail" src={Mail} height={28} alt="send password reset mail"/>
                        </button>
						<button onClick={this.hideModal} type="button" className="btn btn--close dialog__btn">
                            Cancel
                        </button>
                    </div>
				</dialog> : null}
				<dialog id='userProfileDialog' className='bigDialogBox'>
					<img id="exit-dialog-icon" src={Exit} height={28} onClick={this.hideUserProfileModal} alt="close pop up"/>
					<h2>User Profile</h2>
					<h3>{this.state.profileName}</h3>
					<h4>{this.state.profilePhone}</h4>
					<h4>{this.state.profileEmail}</h4>
          <br/>
          <h4>Payment Due Date: <span></span></h4>
					<br/>
          <h4>Referal List</h4>
          <Styles>
            <Table columns={referalcolumns} data={this.state.userReferalList} />
          </Styles>
          <br/>
					<h4>{(this.state.profileRole == 'business')?'Events Created':'Events Registered'}</h4>
					<Styles>
						<Table columns={eventscolumns} data={this.state.userEventList} />
					</Styles>
					<br/>
					<h4>Rigs</h4>
					<Styles>
						<Table columns={eventscolumns} data={this.state.userRigList} />
					</Styles>

				</dialog>

			</main>
		);
	}

}
export default UserTable;
