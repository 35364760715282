import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import BulkUserFormat from './style/bulkUsersFormat.png';
import Exit from './style/exit.png';
import "./style/addUserDialog.css";

class AddManyUsers extends Component {
    constructor () {
        super();
        this.state = {
            xlsxData: []
        }
        this.handleXLSXChange = this.handleXLSXChange.bind(this);
        this.handleAddManyUser = this.handleAddManyUser.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }
    showModal () {
        this.props.onEditBreadCrumbs({
            type: 'APPEND_BREADCRUMB',
            breadCrumbs: " > Bulk User Upload"
        })
        const addManyUserDialog = document.getElementById('addManyUsersDialog');
        addManyUserDialog.showModal();
        // displays the modal and empties the fields
        this.setState({ file: '' });
    }
    hideModal () {
        this.props.onEditBreadCrumbs({
            type: 'REMOVE_BREADCRUMB',
            breadCrumbs: "Users"
        })
        this.setState({xlsxData: []})
        document.getElementById('bulkUploadInput').value = '';
        const addManyUserDialog = document.getElementById('addManyUsersDialog');
        addManyUserDialog.close();
    }
    // adding the new user into the table
    handleAddManyUser() {
        if (this.props.role !== 'Root') {
            // assume Super Admin
            let idx = this.props.clientList.findIndex((client) => client.companyID === this.props.companyID)
            if (idx === -1 || (this.props.clientList[idx].licensesUsed + this.state.xlsxData.length) >= this.props.clientList[idx].licensesAssigned) {
                // assume they dont have enough licenses to create user
                alert('There are not enough licenses available. Please remove inactive users or contact admin@tpcm-usa.com to purchase additional licenses.')
                return
            }
        }
        this.props.onSubmit({
            data: this.state.xlsxData
        })
        this.hideModal();
    }
    handleXLSXChange(event) {
        event.preventDefault();
        let f = event.target.files[0];
        var name = f.name;
        console.log(name)
        const reader = new FileReader();
        reader.onload = (evt) => { // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, {type:'binary'});
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Update state */
            let data = XLSX.utils.sheet_to_json(ws)
            this.setState({ xlsxData: data });
        };
        reader.readAsBinaryString(f);
    }
    render () {
        return (
            <section>
                <nav role="navigation">
                    <button type="button" onClick={this.showModal} className="btn">
                        Bulk Upload
                    </button>
                </nav>
                <dialog id="addManyUsersDialog" className="bigDialogBox">
                    <img id="exit-dialog-icon" src={Exit} height={28} onClick={this.hideModal} alt="exit button"/>
                    <h2>Bulk User Upload</h2>
                    <p>Please Use the Format From the Image Below to Upload Users in Bulk</p>
                    <img id="bulkUserImg" src={BulkUserFormat} alt="xlsx format example"/>
                    <p>Enter the XLSX file:</p>
                    <input type="file" id="bulkUploadInput" onChange={this.handleXLSXChange} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                    <br/>
                    <div className="btn--flex">
                        <button onClick={this.handleAddManyUser} type="button" className="btn btn--confirm dialog__btn">
                            Confirm
                        </button>
                        <button onClick={this.hideModal} type="button" className="btn btn--close dialog__btn">
                            Cancel
                        </button>
                    </div>
                </dialog>
            </section>
        );
    }
};
export default AddManyUsers;