import React, { Component, Fragment } from 'react'
import Table from './Table'
import Styles from './Styles'

class DashBoard extends Component {
    constructor() {
        super()
        this.state = {
            viewPie: false, // {Bool} Root admin determines whether to display the piechart
            licensesUsed: 0, // {Number} # of used licenses for Client
            licensesAssigned: 0 // {Number} # of assigned licenses for Client
        }
        this.viewPieChart = this.viewPieChart.bind(this)
    }
    componentDidMount() {
        if (this.props.role === 'Super Admin' || this.props.role === 'Manager') {
            let idx = this.props.clientList.findIndex((client) => client.companyID === this.props.companyID)
            let licensesUsed = 0
            let licensesAssigned = 0
            if (idx != -1) {
                licensesUsed = this.props.clientList[idx].licensesUsed
                licensesAssigned = this.props.clientList[idx].licensesAssigned
                this.setState({
                    licensesUsed: licensesUsed,
                    licensesAssigned: licensesAssigned
                })
            }
            this.viewPieChart(licensesUsed, licensesAssigned)
            
        }
    }
    viewPieChart(licensesUsed, licensesAssigned) {
        let status = !this.state.viewPie
        if (status) {
            setTimeout(() => {
                var canvas = document.getElementById("can")
                if (canvas !== undefined && canvas !== null) {
                    var ctx = canvas.getContext("2d")
                    var lastend = 0
                    var data = [(licensesAssigned - licensesUsed), licensesUsed]
                    var myTotal = 0
                    var myColor = ['white', '#22397a']
                    for (var e = 0; e < data.length; e++) {
                        myTotal += data[e]
                    }

                    for (var i = 0; i < data.length; i++) {
                        ctx.fillStyle = myColor[i]
                        ctx.beginPath();
                        ctx.strokeStyle = '#C9C9C9'
                        ctx.lineWidth = 3
                        ctx.moveTo(canvas.width/2,canvas.height/2)
                        ctx.arc(canvas.width/2,canvas.height/2,canvas.height/2,lastend,lastend+(Math.PI*2*(data[i]/myTotal)),false);
                        ctx.lineTo(canvas.width/2,canvas.height/2)
                        ctx.stroke()
                        ctx.fill()
                        lastend += Math.PI*2*(data[i]/myTotal)
                    }
                }
            }, 40)
           
        }
       this.setState({
           viewPie: status,
           licensesUsed: licensesUsed,
           licensesAssigned: licensesAssigned
        })
    }
    render() {
        var columns = [
			{
				Header: 'Users',
				columns: [
                {
                    Header: 'Client ID',
					accessor: 'companyID',
                },
                {
                    Header: 'Name',
					accessor: 'name',
                }]
            }]
        let body = <p>Loading Dashboard</p>
        if (this.props.role === 'Super Admin' || this.props.role === 'Manager') {
            body = <div id="pieChartBoxSuperAdmin">
                    <p id="pieTitle">Licenses Assigned / Used</p>
                    <p id="pieChartStat">{this.state.licensesUsed + '/' + this.state.licensesAssigned}</p>
                    <canvas id="can" width="200" height="200"></canvas>
                </div>
        } else if (this.props.role === 'Root') {
            body = <Fragment>
                    <article id="dashboardTableCont">
                        <Styles>
                            {/* gets the user data from the userPage class and fills in the table */}
                            <Table columns={columns} data={this.props.clientList} />
                        </Styles>
                        {(this.state.viewPie) ? <Fragment>
                        <div id="pieChartBox">
                            <p id="pieTitle">Client Licenses</p>
                            <p id="pieChartStat">{this.state.licensesUsed + '/' + this.state.licensesAssigned}</p>
                            <canvas id="can" width="200" height="200"></canvas>
                        </div>
                        </Fragment> : null}
                    </article>
                </Fragment>
        }
   
        return (
            <main id="dashboardPage"  >
                <p id="breadCrumbs">DashBoard</p>
                <h1>DashBoard</h1>
                {body}
            </main>
        )
    }
}
export default DashBoard