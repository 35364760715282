import React, { Component, Fragment } from 'react';
import  ProfilePreview from './style/default_profile.png';
import Input from 'react-phone-number-input/input'
import Exit from './style/exit.png';
import "./style/addUserDialog.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class AddUser extends Component {
    constructor () {
        super();
        this.state = {
            first: '',
            last: '',
            email: '',
            confirmEmail: '',
            phone: '',
            businessName: '',
            password: '',
            confirmPassword: '',
            role: 'business',
            profilePicture: '',
            username:'',
            address:'',
            paymentDueDate:new Date(),
            imgSrc: ProfilePreview,
        }
        this.handlePicChange = this.handlePicChange.bind(this);
        this.handleFirstnameChange = this.handleFirstnameChange.bind(this);
        this.handleLastnameChange = this.handleLastnameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleConfirmEmailChange = this.handleConfirmEmailChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.handlePaymentDueDateChange = this.handlePaymentDueDateChange.bind(this);
        this.handleAddUser = this.handleAddUser.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this);
        this.handleBusinessNameChange = this.handleBusinessNameChange.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }
    showModal () {
        this.props.onEditBreadCrumbs({
            type: 'APPEND_BREADCRUMB',
            breadCrumbs: " > New User"
        })
        const addNewUserDialog = document.getElementById('addNewUserDialog');
        addNewUserDialog.style.left = (this.props.toggleMenu) ? '105px' : '330px'
        addNewUserDialog.showModal();
    }
    hideModal () {
        this.props.onEditBreadCrumbs({
            type: 'REMOVE_BREADCRUMB',
            breadCrumbs: "Users"
        })
        const addNewUserDialog = document.getElementById('addNewUserDialog');
        addNewUserDialog.close();
        // displays the modal and empties the fields
        this.setState({
            profilePicture: '',
            first: '',
            last: '',
            email: '',
            confirmEmail: '',
            phone: '',
            businessName: '',
            password: '',
            confirmPassword: '',
            role: 'business',
            username:'',
            address:''
        });
    }
    // adding the new user into the table
    handleAddUser() {

      let email_exists = false;

      this.props.userList.map((user)=>{if(user.email == this.state.email){email_exists=true }})
        if (this.state.password !== this.state.confirmPassword) {
            alert('Please make sure that the passwords match.')
        } else if (this.state.password.length <= 7) {
            alert('Please make sure that the password is at least 8 characters.')
        } else if (this.state.email !== this.state.confirmEmail) {
            alert('Please make sure that the emails match.')
        } else if (this.state.phone.length !== 12 ) {
            alert('Please Input 10 digits for your phone number.')
        } else if (this.state.email.indexOf('@') === -1 || this.state.email.length === 0) {
            alert('Please make sure you enter a valid email')
        } else if (this.state.first.length === 0 || this.state.last.length === 0) {
            alert('Please enter the first and last')
        } else if (this.state.businessName.length === 0 || this.state.businessName.length === 0) {
            alert('Please enter the business name')
        }
        else if(email_exists){
          alert('Entered email already exists. Make sure to use a unique Email.')
        }
        else {
            let profilePicture;
            if (!this.state.profilePicture) {
                profilePicture = ''
            } else {
                profilePicture = this.state.profilePicture
            }
        console.log(this.state.companyID)
            // sending the data to UserPage
            this.props.onSubmit({
                profilePicture: profilePicture,
                first: this.state.first,
                last: this.state.last,
                email: this.state.email,
                phone: this.state.phone.slice(2), //remove the first two characters of the number
                businessName: this.state.businessName,
                password: this.state.password,
                username: this.state.username,
                address:this.state.address,
                role: this.state.role,
                paymentDueDate:this.state.paymentDueDate
            }, () => console.log('adding a single user'))
            this.hideModal();
        }
    }
    handlePicChange(event) {
        event.preventDefault();
        console.log("[handlePicChange] triggered")
        let reader = new FileReader();
        // get the file thats been uploads
        let file = event.target.files[0];
        // after the file finished loading
        reader.onloadend = () => {
            console.log("yesss sir")
            this.setState({
                profilePicture: file,
                imgSrc: reader.result
             });
        }
        reader.readAsDataURL(file)
    }
    handleFirstnameChange(event) {
        this.setState({first: event.target.value});
    }
    handleLastnameChange(event) {
        this.setState({last: event.target.value});
    }
    handleEmailChange(event) {
        this.setState({email: event.target.value});
    }
    handleConfirmEmailChange(event) {
        this.setState({confirmEmail: event.target.value});
    }
    handleUsernameChange(event){
        this.setState({username: event.target.value});
    }
    handleAddressChange(event){
        this.setState({address: event.target.value});
    }
    handlePhoneChange(num) {
        this.setState({phone: num});
    }
    handlePasswordChange(event) {
        this.setState({password: event.target.value});
    }
    handleConfirmPasswordChange(event) {
        this.setState({confirmPassword: event.target.value});
    }
    handleBusinessNameChange(event) {
        this.setState({businessName: event.target.value});
    }
    handlePaymentDueDateChange(date){
      this.setState({paymentDueDate: date})
    }
    render () {
        return (
            <section>
                <nav role="navigation">
                    <button type="button" onClick={this.showModal} className="btn">
                        Create User
                    </button>
                </nav>
                <dialog id="addNewUserDialog" className="bigDialogBox">
                    <img id="exit-dialog-icon" src={Exit} height={28} onClick={this.hideModal} alt="exit button"/>
                    <h2>Create User</h2>
                    <label>Profile Picture</label>
                    <br/>
                    <label className="inputProfile">
                        <input type="file" accept="image/*" id="uploadImage" onChange={this.handlePicChange}/>
                        <img src={this.state.imgSrc} alt="profile"/>
                    </label>
                    <br/>
                    <input type="email" value={this.state.email} onChange={this.handleEmailChange} placeholder="Email"/>
                    <br/>
                    <input type="email" value={this.state.confirmEmail} onChange={this.handleConfirmEmailChange} placeholder="Confirm Email" />
                    <br/>
                    <input type="text" value={this.state.first} onChange={this.handleFirstnameChange} placeholder="First Name"/>
                    <br/>
                    <input type="text" value={this.state.last} onChange={this.handleLastnameChange} placeholder="Last Name"/>
                    <br/>
                    <input type="text" value={this.state.businessName} onChange={this.handleBusinessNameChange} placeholder="Business Name"/>
                    <br/>
                    <Input country="US" value={this.state.phone} onChange={this.handlePhoneChange} maxLength={14} placeholder="Phone Number"/>
                    <br/>
                    <input type="text" value={this.state.address} onChange={this.handleAddressChange} placeholder="Address"/>
                    <br/>
                    <input type="text" value={this.state.username} onChange={this.handleUsernameChange} placeholder="Username"/>
                    <br/>
                    <input type="password" value={this.state.password} onChange={this.handlePasswordChange} placeholder="Password"/>
                    <br/>
                    <input type="password" value={this.state.confirmPassword} onChange={this.handleConfirmPasswordChange} placeholder="Confirm Password"/>
                    <br/>
                    <label>Payment Due Date</label><br/>
                    <DatePicker
                      selected={this.state.paymentDueDate}
                      onChange={this.handlePaymentDueDateChange} //only when value has changed
                    />
                    <br/>
                    <div className="btn--flex">
                        <button onClick={this.handleAddUser} type="button" className="btn btn--confirm dialog__btn">
                            Confirm
                        </button>
                        <button onClick={this.hideModal} type="button" className="btn btn--close dialog__btn">
                            Cancel
                        </button>
                    </div>
                </dialog>
            </section>
        );
    }
};

export default AddUser;
