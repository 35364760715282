import React, { Component, Fragment } from 'react'
import DashBoard from './components/DashBoard'
import ClientTable from './components/ClientTable'
import UserTable from './components/UserTable'
import LibraryTable from './components/LibraryTable'
import EventTable from './components/EventTable'
import CategoryTable from './components/CategoryTable'
import TaskTable from './components/TaskTable'
import RollCallTable from './components/RollCallTable'
import UserGroupTable from './components/UserGroupTable'
import ReportsTable from './components/ReportsTable'
import ClientSettingsTable from './components/ClientSettingsTable'
import CompletedFormTable from './components/CompletedFormTable'
import DenialTable from './components/DenialTable'
import Signout from './components/style/exit_app.png'
import Settings from './components/style/clientSettings.png'
import Logo from './components/style/FOMO_logo.png'
import BackArrow from './components/style/backArrow.png'
import ForwardArrow from './components/style/forwardArrow.png'
import ChatMessages from './components/style/chatMessages.png'
import ChatMessagesNotify from './components/style/chatMessagesNotify.png'
import PlayStore from './components/style/play_store.jpeg'
import AppStore from './components/style/app_store.jpeg'
import Exit from './components/style/exit.png'
import firebase from "firebase/app"
import FBAuth from 'firebase/auth'
import Select from 'react-select'
import withFirebaseAuth from 'react-with-firebase-auth'
import FBapp from './firebaseConfig'
import './index.css'
import {  ProSidebar,
		  Menu,
		  MenuItem,
		  SubMenu,
		  SidebarHeader,
		  SidebarFooter,
		  SidebarContent, } from 'react-pro-sidebar';
// import './components/style/custom.scss';

//#403F44

import 'react-pro-sidebar/dist/css/styles.css';
import { MdHome, MdPeople,MdDomain, MdAssignment, MdLibraryBooks,MdInsertChart, MdSettings,MdFormatAlignJustify, } from 'react-icons/md';


//TIMEZONE - DATES
import './components/style/userPage.css'
import Moment from 'react-moment';
import 'moment-timezone';

// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';



import Geocode from "react-geocode";
// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyBgl44H00KQSsScH50dmS8EeaVXs19LTxU");
Geocode.setLanguage("en");
Geocode.setRegion("us");

const camelCase = require('camelcase');
//import envConfig from 'env';

// creates the connection to Storage to fetch files for Knowledge Base
const storageService = firebase.storage()
const storageRef = storageService.ref()
const db = FBapp.firestore()
// const Admin = require('firebase-admin');
// import Admin from 'firebase-admin';
let image = 'http://simpleicon.com/wp-content/uploads/user1-256x256.png'

var date = new Date().toLocaleString();
const initialState = {
	 date: date,
	deletedUsers: [], // array of deleted users from the user list
	viewDeletedUsers: false, // boolean to determine whether to see userList/deletedUserList
	userList: [], // list of all the users in the user list
	clientList: [], // list of all the clients
	repository: [], // list of all the folders/files that are displayed in the Knowledge Base Page
	loggedData:[],
	pageNum: 3, // controls which component is renders (default: User List)
	// controls the style for the main menu and which item is highlighted
	highlight: ['mainItem selectedItem', 'mainItem', 'mainItem'],
	isLoggedIn: false, // keeps track of the login status of the user
	email: '', //updates the email when a user is trying to login
	password: '', // updates the password when a user is trying to login
	role: '', // the current user's role (Root, Super Admin, User)
	companyID: '', // the current user's companyID
	path: 'companies', // current working directory
	folderRepos: [], // all the folders in 'repoInfo'
	planList: [], // all the plans in the 'emergencyPlans'
	eventList: [], //all event list
	viewTasks: false, // view Tasks when the button is pressed
	viewRollCall: false, // view Roll Call
	taskList: [], // list of all the tasks
	planName: '', // displays on the Task List page
	rollCallList: [], // all the users in the plan for roll call
	numCompleted: 0, // # of completed tasks or # of checked in users
	totalNum: 0, // # of tasks in ERP or # of users in ERP
	planID: '', // {String ID} current ERP viewed for rollcall. Needed to change user checkin status
	taskUserList: [], // in task list page, on create task, view users in ERP
	ugCheck: false, // User Group Check in order to view the User Group Table
	groupList: [], // {Array: [ JS Objs ]} all the user groups in 'userGroups' collection
	userHighlight: ['topLine', 'bottomLine', 'bottomLine'], // {Array: [ Strings ]} user page highlighting tabs
	showBackArrow: false, // {boolean} conditional for displaying the back arrow
	leaderName: '', // on view tasklist, display the plan leader
	breadCrumbs: 'Users', // displays the current page the user is viewing
	viewFilteredList: false, // {Bool} display the filtered list
	filteredList: [], // {Array of Objects} the filtered list displayed
	toggleMenu: true, // {Boolean} false: menu is 300px & true: menu is 100px
	planHighlight: ['topLine', 'bottomLine', 'bottomLine'], // {Array of Strings} Response Plans navigation tabs
	viewCheckList: true, // {Boolean} View the checklist by default
	rcList: [], // {Array of Objects} (name, users, withNotifications, isRCActive, id, userStatus)
	rcUserList: [], // {Array of Objects} Users (name, isCheckedIn, status)
	numGreen: 0, // {Number} rollcall user list status for green
	numYellow: 0, // {Number} rollcall user list status for yellow
	numRed: 0, // {Number} rollcall user list status for red
	messages: [], // {Array of Objects} messages
	messageLogs: [], // {Array of Objects} message Logs of current user
	currMessageLog: {}, // {Object} keys: messageUsers, messageOwner, latestTimeStamp, usersRead, title
	uid: '', // {String} UID of the current user
	viewUserChat: false, // {Bool} true: viewing a specific chat, false: viewing all the convos
	newMsg: '', // {String} the users new message
	otherUserName: '', // {String} name of the other user in the chat
	viewSearchForChat: false, // {Bool} determines whether to show the back arrow when search for user
								// to start a convo
	userListBox: [], // {Array of Obejcts} value and label keys for the Searchable dropdown menu
	selectedUsersForNewConvo: [], // {Array of objects} {value: UID, label: name}
	adminName: '', // {string} name of the admin logged in for email template
	planPager: 4, // {int} // 0 = active checklists // 1 = template Lists // 2 = roll Call Columns // 3 = rc User List Columns //4 = Reports
	subPlanPager: 1,//to show any sub page under a page in reports page.
	tmplList: [], // {Array of Objects} template obs populate the tmpl table
	gUserList: [], // {Array of Objs} global userlist to make two users dont have the same email
	viewDeletedClients: false, // {Boolean} true: view deleted clients / false: view active clients
	clientHighlight: ['topLine', 'bottomLine'], // {Array: [ Strings ]} client page highlighting tabs
	deletedClients: [], // list of all the deleted clients
	isClientAccount: false, // {Boolean} determines whether user or client is logged in
	activeNotification: false, // {Boolean} determines whether the user has a active notifcation
	teamName: '', // {String} name of the team when creating a new convo
	domain: '',
	dispalayMessage: '',
	//Completed forms
	cFormsList: [],//store completed forms list
	viewCompletedForms: false,
	reportsHiglight: ['topLine', 'bottomLine', 'bottomLine', 'bottomLine'],
	//Meant for Forms and Client settings for form
	formList:[],
	formSettings:[],
	user_response:[],
	inventoryPresent: false,
	forgotPassword: false,
	userEventList: [],
	userRigList:[],
	userReferalList:[],
	businessUserList:[],
	categories:[],
	subcategories:[],
	eventCategories:[],
	eventSubCategories:[],
	denials_list:[]


}
// NOTES:
// Dashboorad: PlanPager: Set to 1 - BY DEFAULT
// Users: PlanPager: Set to 2 - BY DEFAULT
// Clients: PlanPager: Set to 3 - BY DEFAULT
// Checklist: PlanPager: Set to 4 - BY DEFAULT
// 		Active Checklist - viewPlans()/viewChecklist() - subPlanPager: Set to 1
// 		Template - viewTemplates() - subPlanPager: Set to 2
// 		Flash - viewRollCallList - subPlanPager: Set to 3
// Library: PlanPager: Set to 5 - BY DEFAULT
// Reports: PlanPager: Set to 6 - BY DEFAULT
// 		Active Checklist - viewPlans()/viewChecklist() - subPlanPager: Set to 1
// 			Checklist Tasks - viewTasks - subPlanPager: Set to 1
// 		Flash - viewRollCallList - subPlanPager: Set to 2
// 			Flash-Details - viewTemplateTasks - subPlanPager: Set to 2
// 			ViiewRollCallUsers - subPlanPager: Set to 3
// 		Library - subPlanPager - NA
// 		Covid-19 - subPlanPager - 0
// 		HealhScreen - viewCompletedForms - subPlanPager - NA
// ClientSettings: PlanPager: Set to 7 - BY DEFAULT

class App extends Component {
	constructor() {
		super();
		var startState = {}
		startState = initialState
		this.state = startState
		/* Login Functions */
		this.signIn = this.signIn.bind(this)
        this.signOut = this.signOut.bind(this)
        this.clientSettings = this.clientSettings.bind(this)
        this.vaccineSettings = this.vaccineSettings.bind(this)
        this.handleEmailChange = this.handleEmailChange.bind(this)
		this.handlePasswordChange = this.handlePasswordChange.bind(this)
		/* Page Navigation Functions */
		this.handleToggleMenu = this.handleToggleMenu.bind(this)
		this.viewDashboard = this.viewDashboard.bind(this)
		this.viewUsers = this.viewUsers.bind(this)
		this.viewClients = this.viewClients.bind(this)
		this.viewUserGroups = this.viewUserGroups.bind(this)
		this.viewKnowledge = this.viewKnowledge.bind(this)
		this.viewPlans = this.viewPlans.bind(this)
		this.viewEvents = this.viewEvents.bind(this)
		this.viewTasks = this.viewTasks.bind(this)
		this.viewRollCall = this.viewRollCall.bind(this)
		this.viewReports = this.viewReports.bind(this)
		this.viewCompletedForms = this.viewCompletedForms.bind(this)
		this.viewDeletedUserList = this.viewDeletedUserList.bind(this)
		this.viewSelectedRepo = this.viewSelectedRepo.bind(this)
		this.navigateToChild = this.navigateToChild.bind(this)
		this.navigateToParent = this.navigateToParent.bind(this)
		this.handleBackClick = this.handleBackClick.bind(this)
		this.editBreadCrumbs = this.editBreadCrumbs.bind(this)
		/* Knowledge Page Functions */
		this.deleteFile = this.deleteFile.bind(this)
		this.addFile = this.addFile.bind(this)
		this.addFolder = this.addFolder.bind(this)
		this.downloadFile = this.downloadFile.bind(this)
		this.deleteFolder = this.deleteFolder.bind(this)
		this.editFolderName = this.editFolderName.bind(this)
		this.handleClientFilterChangeForRep = this.handleClientFilterChangeForRep.bind(this)
		/* Response Plans Functions */
		this.createPlan = this.createPlan.bind(this);
		this.activeToggle = this.activeToggle.bind(this)
		this.practiceToggle = this.practiceToggle.bind(this)
		this.deletePlan = this.deletePlan.bind(this)
		this.deactivatePlan=this.deactivatePlan.bind(this)
		this.editPlan = this.editPlan.bind(this)
		this.addTask = this.addTask.bind(this)
		this.editTask = this.editTask.bind(this)
		this.deleteTask = this.deleteTask.bind(this)
		this.handleTaskStatusChange = this.handleTaskStatusChange.bind(this)
		this.handleUserCheckedInChange = this.handleUserCheckedInChange.bind(this)
		this.viewChecklist = this.viewChecklist.bind(this)
		this.createRollCall = this.createRollCall.bind(this)
		this.createRollCallCB = this.createRollCallCB.bind(this)
		this.fetchRollCallData = this.fetchRollCallData.bind(this)
		this.deleteRollCall = this.deleteRollCall.bind(this)
		this.archive = this.archive.bind(this)
		this.editRollCall = this.editRollCall.bind(this)
		this.rcActiveToggle= this.rcActiveToggle.bind(this)
		this.updateUserStatus = this.updateUserStatus.bind(this)
		this.viewRollCallUsers = this.viewRollCallUsers.bind(this)
		this.changeStatusColor = this.changeStatusColor.bind(this)
		this.viewTemplates = this.viewTemplates.bind(this)
		this.updateCompanyID = this.updateCompanyID.bind(this)
		/* User Group Functions */
		this.addUserGroup = this.addUserGroup.bind(this)
		this.editUserGroup = this.editUserGroup.bind(this)
		this.deleteUserGroup = this.deleteUserGroup.bind(this)
		this.filterTable = this.filterTable.bind(this)
		/* Client List Functions */
		this.toggleClient = this.toggleClient.bind(this)
        this.editClient = this.editClient.bind(this)
        this.deleteClient = this.deleteClient.bind(this)
        this.addClient = this.addClient.bind(this)
		this.addClientCallBack = this.addClientCallBack.bind(this)
		this.sendUsersMessage = this.sendUsersMessage.bind(this)
		this.viewClientList = this.viewClientList.bind(this)
		this.viewDeletedClientList = this.viewDeletedClientList.bind(this)
		this.reactivateClient = this.reactivateClient.bind(this)
		/* User List Functions */
		this.addManyNewUsers = this.addManyNewUsers.bind(this)
		this.addNewUser = this.addNewUser.bind(this)
		this.addNewUserCB = this.addNewUserCB.bind(this)
        this.deleteUser = this.deleteUser.bind(this)
        this.editUser = this.editUser.bind(this)
		this.toggleUser = this.toggleUser.bind(this)
		this.reactivateUser = this.reactivateUser.bind(this)
		this.handleClientFilterChange = this.handleClientFilterChange.bind(this)
		this.createUserLog = this.createUserLog.bind(this)
		/* Messages Component */
		this.showChatBox = this.showChatBox.bind(this)
		this.hideChatBox = this.hideChatBox.bind(this)
		this.fetchUserMessages = this.fetchUserMessages.bind(this)
		this.sendNewMsg = this.sendNewMsg.bind(this)
		this.getCurrentMsgs = this.getCurrentMsgs.bind(this)
		this.handleMessageChange = this.handleMessageChange.bind(this)
		this.createNewMessageLog = this.createNewMessageLog.bind(this)
		this.searchUsersForChat = this.searchUsersForChat.bind(this)
		this.getConvoName = this.getConvoName.bind(this)
		this.handleUsersInConvoChange = this.handleUsersInConvoChange.bind(this)
		this.createNewGroupMessageLog = this.createNewGroupMessageLog.bind(this)
		this.handleMessagePicChange = this.handleMessagePicChange.bind(this)
		this.handleNewConvoTeamChange = this.handleNewConvoTeamChange.bind(this)
		this.validateMessageLogExists = this.validateMessageLogExists.bind(this)
		this.sendUserReminder = this.sendUserReminder.bind(this)
		/* Template Functions */
		this.createTemplate = this.createTemplate.bind(this)
		this.fetchTemplateData = this.fetchTemplateData.bind(this)
		this.deleteTemplate = this.deleteTemplate.bind(this)
		this.editTemplate = this.editTemplate.bind(this)
		this.viewTasksFromTemplate = this.viewTasksFromTemplate.bind(this)
		/* Library Functions */
		this.createLibraryLog = this.createLibraryLog.bind(this)

		this.toggleForm = this.toggleForm.bind(this)

		/*User Events*/
		this.viewUserEvents = this.viewUserEvents.bind(this)
		this.viewUserRigs = this.viewUserRigs.bind(this)
		this.viewUserReferals = this.viewUserReferals.bind(this)
		this.deleteEvent = this.deleteEvent.bind(this)
		this.editEvent = this.editEvent.bind(this)
		this.addEvent = this.addEvent.bind(this)
		this.addManyEvents = this.addManyEvents.bind(this)
		this.activateEvent = this.activateEvent.bind(this)
		this.denyEvent = this.denyEvent.bind(this)
		this.requestActivationEvent = this.requestActivationEvent.bind(this)
		this.fetchEventCategories = this.fetchEventCategories.bind(this)
		this.checkNewEvent = this.checkNewEvent.bind(this)

		this.viewCategories = this.viewCategories.bind(this)
		this.fetchCategories = this.fetchCategories.bind(this)
		this.editCategory = this.editCategory.bind(this)
		this.deleteCategory = this.deleteCategory.bind(this)
		this.addCategory = this.addCategory.bind(this)
		this.addSubCategory = this.addSubCategory.bind(this)
		this.fetchSubCategories = this.fetchSubCategories.bind(this)
		this.toggleCategory = this.toggleCategory.bind(this)

		this.fetchDenials = this.fetchDenials.bind(this)
		this.viewDenials = this.viewDenials.bind(this)
		this.addDenials = this.addDenials.bind(this)
		this.editDenials = this.editDenials.bind(this)
		this.deleteDenials = this.deleteDenials.bind(this)



		this.handleForgotPassword = this.handleForgotPassword.bind(this)
		this.forgotPassword = this.forgotPassword.bind(this)

		this.markPartsDeleted = this.markPartsDeleted.bind(this)

	}

	/************************* Login Page functions /*************************/

	markPartsDeleted(){
		// let event = db.collection('event').get().then((snapshot)=>{
		// 	let events = snapshot.docs;
		// 	for(let p of events){
		//
		// 		let p_data = p.data()
		//
		// 		if( p_data.activationStatus  == undefined  ){
		//
		// 					db.collection('event').doc(p.id).update({
		// 						activationStatus:"ACTIVE"
		// 					}).then(()=>{
		// 						console.log(p.id+" "+p_data.name);
		// 					})
		// 		}
		//
		// 	}
		// })
	}

	signIn(email, password) {
		// check to determine whether the user's account has been deactivated
        firebase.auth().signInWithEmailAndPassword(email, password)
        .then(() => {
			// check if the user has been disabled
			const user = firebase.auth().currentUser
			const userRef = db.collection("user").doc(user.uid)
			userRef.get().then((doc) => {
				if (doc.exists) {
					// assuming that the document exists
					if (doc.data().deactivated) {
						// the user has been deactivated
						alert(`${doc.data().first + ' ' + doc.data().last}'s account has been disabled. Please contact your administrator if this is in error.`)
					} else {
						if (!(doc.data().role === 'root' || doc.data().role === 'Super Admin' || doc.data().role === 'business' || doc.data().role === 'Manager')) {
							alert('Please Input Admin Credentials to Login')
							return;
						}
						// the user has not been deactivated
						let firstPath = 'companies';
						//  append to the path if a Super Admin or User
						if (doc.data().role !== 'root') {
							firstPath = firstPath + '/' + doc.data().companyID;
						}
						let adminName = doc.data().first + ' ' + doc.data().last

						if (doc.data().dateOfflineShouldEnd !== undefined && doc.data().dateOfflineShouldEnd !== null) {
							// assume logged in user is not supposed to be offline anymore
							if (doc.data().isOffline !== undefined && doc.data().isOffline !== null && doc.data().isOffline) {
								// assume logged in user is offline
								if (doc.data().dateOfflineShouldEnd < new Date()) {
									var userRef = db.collection("user").doc(user.uid);
									userRef.update({ isOffline: false })
									.then(() => console.log("Document successfully updated!"))
									.catch((error) => console.error("Error updating document: ", error));
								}
							}
						}
						this.setState({
							isLoggedIn: true,
							role: doc.data().role,
							path: firstPath,
							uid: user.uid,
							adminName: adminName
						})
						this.viewEvents()
						this.fetchClientData()
						this.fetchUserData()
						this.fetchUserMessages()
						this.fetchUserGroupData()
						this.fetchActiveCategories()
						this.fetchCategories()
						this.fetchDenials()
						console.log(doc.data().role + ' has Logged in Successfully')
						this.addSnapshotListeners()
						// this.markPartsDeleted()

					}
				} else {
				// user was not found -> check if user is a client
				// iterate through companies collection and check if any of the emails match
					db.collection("client").get().then((querySnapshot) => {
						const data = querySnapshot.docs.map(doc => doc.data())
						for (let item of data) {
							if (item.email === user.email) {
								if (item.deactivated) {
									// user has been deactivated
									alert(`${item.clientName}'s account has been disabled. Please contact your administrator if this is in error.`)
								} else {
									// user has successfully logged in
									this.setState({
										isLoggedIn: true,
										role: 'Super Admin',
										companyID: item.id,
										path: `companies/${item.id}`,
										isClientAccount: true
									})
									this.viewEvents()
									this.fetchUserData()
									this.fetchUserMessages()
									this.fetchClientData()
									this.fetchUserGroupData()
									console.log('Client has Logged in Successfully')
									this.addSnapshotListeners()

								}
								break
							}
						}
					})
				}
			})
			.catch((error) => console.log("Error getting document:", error));
        })
        .catch(() => alert("Please enter a valid Username and Password"));
    }
    signOut() {
		this.setState(initialState);
		firebase.auth().signOut()
		.then(() => console.log('Successfully Signed out'))
		.catch((error) => console.log('Error signing out: ', error));
	}
	handleEmailChange(event) {
        this.setState({email: event.target.value});
    }
    handlePasswordChange(event) {
        this.setState({password: event.target.value});
	}
	handleForgotPassword(){
		if(this.state.forgotPassword){
			this.setState({forgotPassword:false});
		}else{
			this.setState({forgotPassword:true});
		}
	}
	forgotPassword(email = null){
		if(email == null){
			email = this.state.email;
		}
		firebase.auth().sendPasswordResetEmail(email).then(function() {
		  // Email sent.
		  alert('Email has been sent to your registered email address');
		}).catch(function(error) {
		  // An error happened.
		  alert("Email not found");
		});
	}
	/************************* Component Navigation Functions *************************/
	// comment this out when you want to login
	componentDidMount() {
		// enabled offline persistence
		firebase.firestore().enablePersistence()

		firebase.auth().onAuthStateChanged(() => {
			var user = firebase.auth().currentUser
			if (user !== null && user !== undefined) {
				if (user.email !== null && user.email !== undefined) {
					var userRef = db.collection('users').doc(user.uid)
					userRef.get().then((query) => {
						let data = query.data()
						if (data !== undefined && data !== null) {
							this.signIn(user.email, data.password)
						}
					})
				}
			}
		})
	}
	addSnapshotListeners() {
		db.collection("emergencyPlans").onSnapshot((querySnapshot) => {

			var planList = []
			const pList = querySnapshot.docs
			for (let i = 0; i < pList.length; i++) {
				planList.push(pList[i].data())
			}
			let fsSize = planList.length; // firestore messages size
			let stSize = this.state.planList.length; // state messages size
			// stSize == 0 only when the user has just opened the chat
			if (stSize > 0) {
				// assume that the user has already viewed the chat
				if (fsSize !== stSize) {
					this.fetchPlanData()
				}
			}

			//console.log(planList);
		})
		db.collection("rollCalls").onSnapshot((querySnapshot) => {
			var rcList = []
			const rollList = querySnapshot.docs
			for (let i = 0; i < rollList.length; i++) {
				rcList.push(rollList[i].data())
			}
			let fsSize = rcList.length; // firestore messages size
			let stSize = this.state.rcList.length; // state messages size
			// stSize == 0 only when the user has just opened the chat
			if (stSize > 0) {
				// assume that the user has already viewed the chat
				if (fsSize !== stSize) {
					this.fetchRollCallData()
				}
			}

			// console.log("CP2: "+rcList);
		})
		// check the messagelog to see if the new message is sent to the user
		// dynamically update the messages when someone replies
		// adds a listener to the messages collection
		// db.collection("messages").onSnapshot((querySnapshot) => {
		// 	// console.log("CP3: ");
		// 	const messageList = querySnapshot.docs.map((message) => {
		// 		let mid = message.id
		// 		message = message.data()
		// 		message.id = mid
		// 		return message
		// 	})
		// 	messageList.sort((a, b) => a.timeStamp.seconds - b.timeStamp.seconds)
		// 	let lastMessage = messageList[messageList.length - 1]
		// 	let logRef = db.collection("messageLog").doc(lastMessage.logReference.id)
		// 	logRef.get().then((query) => {
		// 		let mLog = query.data()
		// 		// assume messageUsers is not defined
		// 		if (mLog === undefined || mLog.messageUsers === undefined || mLog.messageUsers === null) return
		// 		// assume the current user is not in the convo
		// 		if (mLog.messageUsers.findIndex((user) => user.id === this.state.uid) === -1) return
		// 		if (mLog === undefined || mLog.usersRead === undefined || mLog.usersRead === null) return
		// 		//console.log("message is defined")
		// 		// make sure the usersRead is defined
		// 		if (lastMessage.fromID.id !== this.state.uid && this.validateMessageLogExists(mLog) === false) {
		// 			// assume its a new user thread and not from the current user
		// 			mLog.id = logRef.id
		// 			// check whether to add the html list item
		// 			var convoList = document.getElementById("convoList")
		// 			if (convoList !== null && convoList !== undefined) {
		// 				var item = document.createElement('li')
		// 				let logTitle = this.getConvoName(mLog)
		// 				item.innerHTML = logTitle
		// 				// assume the current user has not seen the message
		// 				let span = document.createElement('span')
		// 				span.setAttribute("class", "dot")
		// 				item.prepend(span)
		// 				item.setAttribute('data-mLogID', mLog.id)
		// 				item.addEventListener('click', (curr) => {
		// 					let mLogID = curr.path[0].getAttribute('data-mLogID')
		// 					let idx = this.state.messageLogs.findIndex((log) => log.id === mLogID)
		// 					if (idx !== -1) {
		// 						// make sure that it exists
		// 						if (this.state.messageLogs[idx].messageUsers.length > 2) {
		// 							// more than 2 users === group chat
		// 							this.getCurrentMsgs(mLogID, this.state.messageLogs[idx].title)
		// 							return
		// 						}
		// 					}
		// 					this.getCurrentMsgs(mLogID, curr.path[0].textContent)
		// 				})
		// 				convoList.appendChild(item)
		// 			}
		// 			this.state.messageLogs.push(mLog)
		// 			this.setState({messageLogs: this.state.messageLogs})
		// 		}
		// 		if (lastMessage.fromID.id !== this.state.uid) {
		// 			// assume the new message is from another user
		// 			let idx = this.state.messageLogs.findIndex((log) => log.messageOwner.id === mLog.messageOwner.id)
		// 			// update the usersRead state
		// 			this.state.messageLogs[idx].usersRead = [lastMessage.fromID]
		// 			this.setState({messageLogs: this.state.messageLogs})
		// 		}
		// 		let notificationCheck = true
		// 		for (let j = 0; j < mLog.usersRead.length; j++) {
		// 			// iterate through all the users in the usersRead
		// 			if (mLog.usersRead[j].id === this.state.uid) {
		// 				// assume user has read the message
		// 				notificationCheck = false
		// 			}
		// 		}
		// 		if (notificationCheck) {
		// 			// assume there is a active unread message
		// 			if (messageList.findIndex((message) => message.logReference.id === logRef.id) !== -1) {
		// 				// assume there is at least one message in the chat
		// 				this.setState({activeNotification: true})
		// 			}
		// 		}
		// 		// make sure there are no duplicates
		// 		let messageIdx = this.state.messages.findIndex((curr) => curr.timeStamp === lastMessage.timeStamp)
		// 		if (this.state.currMessageLog.id === lastMessage.logReference.id) {
		// 			// assume new message is from current messagelog
		// 			if (lastMessage.fromID.id !== this.state.uid && messageIdx === -1) {
		// 				// adding message to the html message list
		// 				var mList = document.getElementById('messageList')
		// 				let idx = this.state.userList.findIndex((curr) => curr.id === lastMessage.fromID.id)
		// 				if (idx !== -1) {
		// 					var itemName = document.createElement('li')
		// 					itemName.setAttribute('class', 'otherUserName')
		// 					itemName.innerHTML = this.state.userList[idx].name
		// 					mList.appendChild(itemName)
		// 				}
		// 				var item = document.createElement('li')
		// 				item.setAttribute('class', 'otherUserMessage')
		// 				if (lastMessage.isImage) {
		// 					// console.log(lastMessage.id)
		// 					// console.log(lastMessage.logReference.id)
		// 					if (lastMessage.imageURL === undefined || lastMessage.imageURL === null || lastMessage.imageURL.length < 10) {
		// 						console.log("Message has an invalid imageURL field")
		// 						return
		// 					}
		// 					item.style.width = '100%'
		// 					let img = document.createElement('img')
		// 					img.setAttribute('src', lastMessage.imageURL)
		// 					img.style.width = '95%'
		// 					img.style.margin = '5px'
		// 					item.appendChild(img)
		// 					mList.appendChild(item)
		// 					// not from the current user
		// 					// add message to messages list
		// 					this.setState({messages: [...this.state.messages, lastMessage]})
		// 				} else {
		// 					item.innerHTML = lastMessage.message
		// 					mList.appendChild(item)
		// 					// not from the current user
		// 					// add message to messages list
		// 					this.setState({messages: [...this.state.messages, lastMessage]})
		// 				}
		// 			}
		// 		} else if (this.state.viewUserChat === false) {
		// 			// assume not viewing the current messagelog
		// 			let convoList = document.getElementById('convoList')
		// 			if (convoList !== undefined && convoList !== null) {
		// 				var convos = convoList.getElementsByTagName('li')
		// 				for (let convo of convos) {
		// 					let mlogID = convo.getAttribute('data-mlogid')
		// 					if (mlogID === lastMessage.logReference.id) {
		// 						// assume li doesnt already have a red dot
		// 						// console.log("Got a new message for curr user")
		// 						if (convo.childElementCount === 0) {
		// 							// console.log("adding dot")
		// 							// assume is first element is a string
		// 							// assume the message doesnt already have a red bubble.
		// 							let span = document.createElement('span')
		// 							span.setAttribute("class", "dot")
		// 							convo.prepend(span)
		// 						}
		// 					}
		// 				}
		// 			}
		// 		}
		// 	})
		// })
		// Get the latest one by sorting the timestamps
		// db.collection("taskLog").onSnapshot((querySnapshot) => {
		// 	const taskLogs = querySnapshot.docs.map((log) => log.data())
		// 	taskLogs.sort((a, b) => a.date.seconds - b.date.seconds)
		// 	let latestLog = taskLogs[taskLogs.length - 1]
		// 	// console.log(latestLog)
		// 	// get the id of the task
		// 	let taskID = latestLog.id;
		// 	// console.log('taskID: ', taskID)
		// 	let idx = this.state.taskList.findIndex((curr) => curr.id === taskID)
		// 	if (idx !== -1) {
		// 		// console.log("its in the current taskID")
		// 		// taskLog is in the taskList
		// 		for (let doc of latestLog.relatedItems) {
		// 			let curr = doc.path.split('/')
		// 			// curr[0] is the collection name
		// 			if (curr[0] === "users") {
		// 				// curr[1] is the user ID
		// 				let i = this.state.userList.findIndex((item) => item.id === curr[1])
		// 				if (i !== -1) {
		// 					// get the data format in the right format
		// 					var ts = new Date(1970, 0, 1) // Epoch
		// 					ts.setSeconds(latestLog.date.seconds - (7 * 60 * 60)) // minus 7 hrs
		// 					const mmddyyyy = ts.getDate()  + "/" + (ts.getMonth()+1) + "/" + ts.getFullYear()
		// 					const timeArr = ts.toLocaleTimeString().split(':')
		// 					let hhmm = timeArr[0] + ':' + timeArr[1] + timeArr[2].slice(2)
		// 					this.state.taskList[idx].completedBy = this.state.userList[i].name
		// 					this.state.taskList[idx].completedDt = mmddyyyy + ' ' + hhmm
		// 					// console.log('Updated tasklist state bc of completion')
		// 					this.setState({taskList: this.state.taskList})
		// 				}
		// 			}
		// 		}
		// 	}
		// })

		this.fetchCompletedFormsData();
		this.fetchformsData();
		setInterval(() => {
			// check if there is anything in local storage every minute
			// {array of objects} rollCall name, update_dt and users array of uids
			if (localStorage.getItem('activatedRollcalls') !== undefined && localStorage.getItem('activatedRollcalls') !== null) {
				let activatedRollcalls = []
				if (this.testJSON(localStorage.getItem('activatedRollcalls'))) {
					activatedRollcalls = JSON.parse(localStorage.getItem('activatedRollcalls').toString())
				}
				var notUpdatedRollCalls = []
				var currTimestamp = new Date().getTime() / 1000
				for (let rollCall of activatedRollcalls) {
					if (rollCall === undefined || rollCall === null) {
						continue
					}
					// iterate through all the rollcalls
					if (currTimestamp > rollCall.update_dt) {
						// assume the 20min is up
						for (let uid of rollCall.users) {
							// iterate through all the
							this.changeStatusColor(uid, rollCall.name)
						}
					} else {
						// assume rollcall was activated less than 20 min ago
						notUpdatedRollCalls.push(rollCall)
					}
				}
				localStorage.setItem('activatedRollcalls', JSON.stringify(notUpdatedRollCalls))
			}
		}, 1000*60)


	}
	testJSON(text) {
		if (/^[\],:{}\s]*$/.test(text.replace(/\\["\\\/bfnrtu]/g, '@').
		replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
		replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
			return true
		//the json is ok

		}else{
			return false
		//the json is not ok

		}
	}

	validateMessageLogExists(newLog) {
		let currArr = newLog.messageUsers.map((docRef) => docRef.id)
		let currStr = currArr.sort().join(',')
		for (let log of this.state.messageLogs) {
			// array of user IDs
			let userArr = log.messageUsers.map((docRef) => docRef.id)
			let userStr = userArr.sort().join(',')
			if (userStr === currStr) {
				return true
			}
		}
		return false
	}

	handleBackClick() {
		if(this.state.planPager === 6){
			this.viewReports({planPager: this.state.planPager,subPlanPager: this.state.subPlanPager});
		}
		else
		{
		 this.viewPlans();
		}

	}
	editBreadCrumbs(action) {
		// console.log("Action: "+action);
		let curr = '';
		if (action.type === 'APPEND_BREADCRUMB') {
			// assume user is viewing a dialog
			curr = this.state.breadCrumbs + action.breadCrumbs
		} else if (action.type === 'REMOVE_BREADCRUMB') {
			// assume user exited from a dialog
			curr = action.breadCrumbs
		}
		this.setState({ breadCrumbs: curr})
	}
	viewDashboard() {
		this.setState({
			highlight: ['mainItem selectedItem','mainItem','mainItem','mainItem', 'mainItem','mainItem','mainItem'],
			pageNum: 5,
		})

	}
	viewUserGroups() {
		this.fetchUserGroupData();
		this.setState({
			ugCheck: true,
			userHighlight: ['bottomLine', 'topLine', 'bottomLine'],
			viewFilteredList: false
		})
	}
	viewDeletedUserList() {
		this.setState({
			ugCheck: false,
			viewDeletedUsers: true,
			userHighlight: ['bottomLine', 'bottomLine', 'topLine'],
			viewFilteredList: false
		})
	}
	// when pageNum is 1, view the UserList
	viewUsers(action) {
		// console.log("viewUsers > SPP: "+action.subPlanPager)
		// console.log("viewUsers > PP: "+action.planPager)

		let userList = this.state.userList
		userList = userList.sort((a, b) => {
			var nameA = a.name.toUpperCase();
			var nameB = b.name.toUpperCase();
			if (nameA < nameB) return -1
			if (nameA > nameB) return 1
			return 0;
		})
		userList = userList.filter((user) => (user.role !== 'root'));
		this.setState({
			highlight: ['mainItem','mainItem selectedItem','mainItem'] ,
			reportsHighlight:(action.subPlanPager == 0 && action.planPager == 6) ? ['bottomLine', 'bottomLine','bottomLine', 'topLine','bottomLine'] : ['bottomLine', 'bottomLine','bottomLine','bottomLine','topLine'],
			pageNum: 1,
			ugCheck: false, // not user groups
			viewDeletedUsers: false, // not deleted users
			userHighlight: ['topLine', 'bottomLine', 'bottomLine'], //user page navigation
			showBackArrow: false, //back arrow
			breadCrumbs: 'Users',
			viewFilteredList: false,
			userList: userList, // sort the userList alphabetically
			planPager: (action.planPager) ? (action.planPager) : 2,
			subPlanPager: (action.subPlanPager) ? (action.subPlanPager) : 0
		})

		// console.log("viewUsers-2 state > SPP: "+this.state.subPlanPager)
		// console.log("viewUsers-2 state > PP: "+this.state.planPager)

		setTimeout(() => {
			var selectClientUserTable = document.getElementById('selectClientUserTable'); // get the select Client tag
			if (selectClientUserTable !== null && selectClientUserTable !== undefined) {
				selectClientUserTable.innerHTML = '';
				let curr = { 'text': 'Select Client', 'value': "" }
				selectClientUserTable.options.add( new Option(curr.text, curr.value, curr.selected) )
				for (let client of this.state.clientList) {
					curr = { 'text': client.name, 'value': client.id }
					selectClientUserTable.options.add( new Option(curr.text, curr.value, curr.selected) )
				}
			}
		}, 50)


	}
	// when pageNum is 2, view the ClientList
	viewClients(){

		// console.log("viewClients > SPP: "+this.state.subPlanPager)
		// console.log("viewClients > PP: "+this.state.planPager)

		this.setState({
			highlight: ['mainItem','mainItem','mainItem selectedItem','mainItem','mainItem','mainItem','mainItem'],
			pageNum: 2,
			showBackArrow: false, // hide the back arrow
			breadCrumbs: 'Clients',
			viewFilteredList: false
		})

		// console.log("viewClients-2 > SPP: "+this.state.subPlanPager)
		// console.log("viewClients-2 state > PP: "+this.state.planPager)
	}
	viewClientList() {

		// console.log("viewClientList > SPP: "+this.state.subPlanPager)
		// console.log("viewClientList > PP: "+this.state.planPager)

		this.setState({
			viewDeletedClients: false,
			clientHighlight: ['topLine', 'bottomLine']
		})
	}
	viewDeletedClientList(){
		this.setState({
			viewDeletedClients: true,
			clientHighlight: ['bottomLine', 'topLine']
		})
	}
	// when pageNum is 3, view the Response Plans
	viewPlans() {
		// console.log("viewPlans > SPP: "+this.state.subPlanPager)
		console.log("viewPlans > PP: "+this.state.planPager)

		// sort the list into ascending order
		let planList = this.state.planList.sort((a,b) => {
			var nameA = a.name.toUpperCase();
			var nameB = b.name.toUpperCase();
			if (nameA < nameB) return -1
			if (nameA > nameB) return 1
		})

		this.fetchPlanData();
		// console.log(this.fetchPlanData());

		this.fetchRollCallData()
		this.fetchTemplateData()

		db.collection("tasks").get().then((querySnapshot) => {
			const taskList = querySnapshot.docs

			// iterate through all the doc refs
			let taskArr = []
			for (let task of taskList) {
				let tid = task.id
				task = task.data()
				task.id = tid
				taskArr.push(task)
			}
			this.setState({taskList: taskArr})
		})
		// console.log("Tasks ended: ");
		// console.log("Plans ended: "+planList);
		this.setState({
			highlight: ['mainItem','mainItem','mainItem', 'mainItem selectedItem','mainItem','mainItem','mainItem'],
			planHighlight: ['topLine', 'BottomLine', 'bottomLine'],
			pageNum: 3,
			viewTasks: false,
			viewRollCall: false,
			showBackArrow: false, // hide the back arrow
			breadCrumbs: 'Checklists',
			viewFilteredList: false,
			planList: planList,
			planPager: 4, //by default
			subPlanPager: 1 //NEW added : by default
		})
		// console.log("viewPlans-2 > SPP: "+this.state.subPlanPager)
		// console.log("viewPlans-2 > PP: "+this.state.planPager)
	}

	viewEvents(){
		let eventArr = [];
		let businessUserArr = [];
		let user_role = this.state.role
		let uid = this.state.uid;



		db.collection("event").get().then((querySnapshot) => {
			const event = querySnapshot.docs

			for (let i of event){
				let i_data = i.data();
				if(i_data.deleted != true ){
					i_data.id = i.id
					if(user_role == 'business'){
						if(i_data.owner != undefined && i_data.owner !== null && i_data.owner !== '' && i_data.owner !== 'null') {
							if(i_data.owner.id == uid){
								eventArr.push(i_data);
							}
						}
					}
					else{
						eventArr.push(i_data);
					}
				}
			}

		});

		db.collection("user").get().then((querySnapshot) => {
			const user = querySnapshot.docs

			for (let i of user){
				let i_data = i.data();
				if(i_data.deleted != true){
					if(i_data.role == 'business'){
						businessUserArr.push({name:i_data.first+" "+i_data.last, id: i.id});

					}
				}
			}

			this.setState({businessUserList: businessUserArr});
		});


		setTimeout(()=>{

			this.setState({
				highlight: ['selectedItem mainItem','mainItem','mainItem'],
				planHighlight: ['topLine', 'BottomLine', 'bottomLine'],
				pageNum: 3,
				breadCrumbs: 'Event',
				viewFilteredList: false,
				eventList: eventArr,
				businessUserList: businessUserArr,
				planPager: 4, //by default
				subPlanPager: 1 //NEW added : by default
			},()=>{
				console.log(this.state.eventList);
			})
		},2000)

	}

	async addEvent(item_obj){
		// if(item_obj.id !== null && item_obj.id !== undefined){
			console.log(item_obj)
			// let client_ref = db.collection("client").doc(item_obj.client);
			let new_id = '';
			let event_to_add = {
		    	name: item_obj.name,
	            description: item_obj.description,
							ticketURL:item_obj.ticketURL,
							virtual:item_obj.virtual,
	            address:item_obj.address,
	            street:item_obj.street,
	            city:item_obj.city,
	            state:item_obj.state,
	            zipcode:item_obj.zipcode,
	            startDate: firebase.firestore.Timestamp.fromDate(item_obj.startDate),
	            endDate: firebase.firestore.Timestamp.fromDate(item_obj.endDate),
	            expiration: firebase.firestore.Timestamp.fromDate(item_obj.expiration),
	            sports:[],
							level:item_obj.level,
							deleted:false,
							deactivated:true,
							activationStatus:'PENDING'
		}

		if(event_to_add.address !== undefined && event_to_add.address != ''){
		await	Geocode.fromAddress(event_to_add.address).then(
			  (response) => {
			    const { lat, lng } = response.results[0].geometry.location;
					event_to_add.location = new firebase.firestore.GeoPoint(lat, lng)
			  },
			  (error) => {
			    // console.error(error);
			  }
			);
		}


		if(item_obj.lastSignUpDate instanceof Date){
			event_to_add.lastSignUpDate = firebase.firestore.Timestamp.fromDate(item_obj.lastSignUpDate)
		}else{
			event_to_add.lastSignUpDate = event_to_add.startDate
		}

		if(item_obj.maxParticipants != undefined && item_obj.maxParticipants != ''){
			event_to_add.maxParticipants = parseInt(item_obj.maxParticipants)
		}else{
			event_to_add.maxParticipants = 1000;
		}

		if(item_obj.maxSpectators != undefined && item_obj.maxSpectators != ''){
			event_to_add.maxSpectators = parseInt(item_obj.maxSpectators)
		}
		else{
			event_to_add.maxSpectators = 1000
		}

		if(item_obj.entryCost != undefined && item_obj.entryCost != ''){
			event_to_add.entryCost = parseFloat(item_obj.entryCost)
			event_to_add.costType = 1;
		}else{
			event_to_add.entryCost = 0;
			event_to_add.costType = 0;
		}

		if(item_obj.spectatingCost != undefined && item_obj.spectatingCost == ''){
			event_to_add.spectatingCost = parseFloat(item_obj.spectatingCost)
		}else{
			event_to_add.spectatingCost = 0
		}

		let searchTerms = [];
		let search_str = event_to_add.name.toLowerCase()
		let arr = search_str.split(" ");
		if(arr.length > 1){
			arr.push(search_str);
		}

		for(let k = 0; k<arr.length; k++){
		  for (let i = 1; i <= arr[k].length; i++) {
		  searchTerms.push(arr[k].slice(0, i));
		  }
		}

		event_to_add.searchTerms = searchTerms;


			const rowref = db.collection("event").add(event_to_add).then((doc_ref)=>{
			new_id = doc_ref.id;
			let new_doc = db.collection("event").doc(new_id);
			let image_url = [];
			let sports = [];
			new_doc.update({
				id:new_id
			});

			let ownerRef = db.collection('user').doc(item_obj.owner_id);
			// console.log(ownerRef.id)
			new_doc.update({
				owner:ownerRef
			});

			if(item_obj.categoriesToAdd){
				for(let cat of item_obj.categoriesToAdd){
					let cat_ref = db.collection("sport").doc(cat.id);
					sports.push(cat_ref);
				}

				new_doc.update({
					sports:sports
				});
			}

			if (item_obj.itemImage !== '')  {
	            const uploadTask = storageRef.child(`event/${doc_ref.id}/${doc_ref.id}.jpeg`).put(item_obj.itemImage);
	            uploadTask.on('state_changed', () => {}, (error) => console.log(error),
	                () => {
	                    // got the URL of the firebase storage image
	                    // console.log("uploading");
	                    uploadTask.snapshot.ref.getDownloadURL().then((url) => {
	                    	// console.log("uploading: "+url);
	                        image_url.push(url);
	                        db.collection('event').doc(doc_ref.id).update({images:image_url}).then(()=>{
	                        	this.viewEvents();
	                        });
	                    });
	                	// console.log('Successfully stored and retrieve the url from the image');
	            	});
	        }
		    // new_doc.update({id:doc_ref.id,itemImage:image_url});

		    else{
					// let ownerdata = ownerRef.get().data()
					// let ownerpicture = ownerdata.profileImages
					// let defaulteventpic = '';
					// if(ownerpicture.length > 0){
					// 	image_url.push(ownerpicture[0])
					// 	db.collection('event').doc(doc_ref.id).update({images:image_url}).then(()=>{
					// 		this.viewEvents();
					// 	});
					// }
					// else{
						this.viewEvents();
					// }
			}
		});

		// }
	}

	checkNewEvent(item) {
        // if (!item.hasOwnProperty('companyID')) return false;
        if (!item.hasOwnProperty('name')){console.log('name'); return false};
        // if (!item.hasOwnProperty('category')) return false;
        if (!item.hasOwnProperty('description')) {console.log('desc'); return false};
        if (!item.hasOwnProperty('maxParticipants')) {console.log('par'); return false};
        if (!item.hasOwnProperty('maxSpectators')) {console.log('spec'); return false};
        if (!item.hasOwnProperty('entryCost')) {console.log('ec'); return false};
        // if (item.companyID.toString().length !== 6) return false;
        return true; // the xlsx form is in the correct format
    }

	addManyEvents(file) {
        // extract all the items from the file
        // add them to the list
        let itemData = file.data;
        let correctFormat = true;
        for (let item of itemData) {
            if (!this.checkNewEvent(item)) {
                //assume that a user does not have all the necessary fields
                correctFormat = false;
                alert('Please upload the xlsx file is the correct format: [Name, Description, Max Participants, Max Spectators, Entry Cost]')
                break;
            }
        }
        if (correctFormat) {
            for (let item of itemData) {
								if(item.virtual == 0){
									item.virtual = false;
								}else{
									item.virtual = true;
								}
								item.startDate = new Date(item.startDate);
								item.endDate = new Date(item.endDate);
								item.lastSignUpDate = new Date(item.lastSignUpDate)

								let exp = new Date(item.endDate.valueOf());
			          exp.setDate(exp.getDate()+3);

								item.expiration = exp;

								let address = '';

				        if(item.street != ''){
				          address = address + item.street + ' ';
				        }

				        if(item.city != ''){
				          address = address + item.city + ' ';
				        }
				        if(item.state != ''){
				          address = address + item.state + ' ';
				        }
				        if(item.zipcode != ''){
				          address = address + item.zipcode + ' ';
				        }
								item.address = address
								item.itemImage = '';
                item.owner_id = file.owner;
								console.log(item);
                this.addEvent(item, () => console.log('I want to add one item at a time'))
            }
        }
	}

	deleteEvent(item_obj){
		console.log(item_obj);
		// mark the user as deleted
        db.collection('event').doc(item_obj.id).set({
            deleted: true,
        }, { merge: true });

        this.viewEvents();

	}

	activateEvent(item_obj){
		console.log(item_obj);
		// mark the user as deleted
        db.collection('event').doc(item_obj.id).set({
						activationStatus:'ACTIVE',
            deactivated: false,
        }, { merge: true });

        this.viewEvents();

	}

	requestActivationEvent(item_obj){
		console.log(item_obj);
		// mark the user as deleted
        db.collection('event').doc(item_obj.id).set({
						activationStatus:'PENDING',
            deactivated: true,
        }, { merge: true });

        this.viewEvents();

	}

	denyEvent(item_obj){
		console.log(item_obj);
		// mark the user as deleted
        db.collection('event').doc(item_obj.id).set({
						activationStatus:'DENIED',
						denial_text:item_obj.text,
						denial_body:item_obj.body,
						denial_desc:item_obj.desc,
            deactivated: false,
        }, { merge: true });

        this.viewEvents();

	}

	async editEvent(item_obj){
		if(item_obj.id !== null && item_obj.id !== undefined){
			console.log(item_obj)

			const event_to_edit = {
					name: item_obj.name,
					description: item_obj.description,
					ticketURL: item_obj.ticketURL,
					virtual:item_obj.virtual,
					address:item_obj.address,
					street:item_obj.street,
					city:item_obj.city,
					state:item_obj.state,
					zipcode:item_obj.zipcode,
					startDate: firebase.firestore.Timestamp.fromDate(item_obj.startDate),
					endDate: firebase.firestore.Timestamp.fromDate(item_obj.endDate),
					expiration: firebase.firestore.Timestamp.fromDate(item_obj.expiration),
					level:item_obj.level
			}

			if(item_obj.lastSignUpDate instanceof Date){
				event_to_edit.lastSignUpDate = firebase.firestore.Timestamp.fromDate(item_obj.lastSignUpDate)
			}else{
				event_to_edit.lastSignUpDate = event_to_edit.startDate
			}

			if(item_obj.maxParticipants != undefined && item_obj.maxParticipants != ''){
					event_to_edit.maxParticipants = parseInt(item_obj.maxParticipants)
			}
			else{
				event_to_edit.maxParticipants = 1000
			}

			if(item_obj.maxSpectators != undefined && item_obj.maxSpectators != ''){
				event_to_edit.maxSpectators = parseInt(item_obj.maxSpectators)
			}else{
				event_to_edit.maxSpectators = 1000
			}

			if(item_obj.entryCost != undefined && item_obj.entryCost != ''){
				event_to_edit.entryCost = parseFloat(item_obj.entryCost)
				event_to_edit.costType = 1;
			}else{
				event_to_edit.entryCost = 0
				event_to_edit.costType = 1;
			}

			if(item_obj.spectatingCost != undefined && item_obj.spectatingCost == ''){
				event_to_edit.spectatingCost = parseFloat(item_obj.spectatingCost)
			}else{
				event_to_edit.spectatingCost = 0
			}

			if(event_to_edit.address !== undefined && event_to_edit.address != ''){
			await	Geocode.fromAddress(event_to_edit.address).then(
				  (response) => {
				    const { lat, lng } = response.results[0].geometry.location;
						event_to_edit.location = new firebase.firestore.GeoPoint(lat, lng)
				  },
				  (error) => {
				    // console.error(error);
				  }
				);
			}

			let searchTerms = [];
			let search_str = event_to_edit.name.toLowerCase()
			let arr = search_str.split(" ");
			if(arr.length > 1){
				arr.push(search_str);
			}
			for(let k = 0; k<arr.length; k++){
			  for (let i = 1; i <= arr[k].length; i++) {
			  searchTerms.push(arr[k].slice(0, i));
			  }
			}

			event_to_edit.searchTerms = searchTerms;

			const eventRef = db.collection("event").doc(item_obj.id);
	        eventRef.update(event_to_edit)
	        .then(() => {

	        	let images = [];
	        	if (item_obj.itemImage !== '')  {
		            const uploadTask = storageRef.child(`event/${item_obj.id}.jpeg`).put(item_obj.itemImage);
		            uploadTask.on('state_changed', () => {}, (error) => console.log(error),
		                () => {
		                    // got the URL of the firebase storage image
		                    console.log("uploading");
		                    uploadTask.snapshot.ref.getDownloadURL().then((url) => {
		                    	console.log("uploading: "+url);
		                        images.push(url);
		                        eventRef.update({images:images}).then(()=>{
		                        	this.viewEvents();
		                        });
		                    });
		                	// console.log('Successfully stored and retrieve the url from the image');
		            	});
		        }else{

	            this.viewEvents();
	        	}
	        })
	        .catch((error) => console.error("Error updating document: ", error));
			}
	}

	createEventLog(row){
	const user = firebase.auth().currentUser
		const rowRef = db.collection('eventLog').add({
					date: new Date(),
					logType: (row.logType) ? row.logType : "There is a problem!",
					message: row.message,
					changesBy: user.email,
					oldValue: (row.oldValue) ? row.oldValue : '-',
					newValue: (row.newValue) ? row.newValue : '-',
					source: (row.source) ? row.source : "-",
					document: (row.document) ? "/event/"+row.document : '-'
		});
}

	fetchEventCategories(sports){
		let eventCategories = [];
		let eventSubCategories = [];

		for(let s_ref of sports){
			s_ref.get().then((doc)=>{
				let s_data = doc.data();

				if(s_data.parents == null || s_data.parents == undefined || s_data.parents.length == 0){
					eventCategories.push({name:s_data.name,id:s_ref.id})
				}else{
					eventSubCategories.push({name:s_data.name,id:s_ref.id})
				}
			})
		}

		this.setState({
			eventCategories: eventCategories,
			eventSubCategories: eventSubCategories
		},()=>{
			console.log(this.state.eventCategories);
			console.log(this.state.eventSubCategories);
		})
	}

	fetchActiveCategories(){
		let categoryArr = [];

		db.collection("sport").get().then((querySnapshot) => {
			const sport = querySnapshot.docs

			for (let i of sport){
				let i_data = i.data();
				if(i_data.deleted == true || i_data.deactivated == true){
					continue;
				}
				if(i_data.parents == null || i_data.parents == undefined || i_data.parents.length == 0){
						categoryArr.push(i_data);
				}
				i_data.id = i.id
			}

		}).then(()=>{
			this.setState({activeCategories:categoryArr});
		})
	}


	fetchCategories(){
		let categoryArr = [];

		db.collection("sport").get().then((querySnapshot) => {
			const sport = querySnapshot.docs

			for (let i of sport){
				let i_data = i.data();
				if(i_data.deleted == true ){
					continue;
				}
				if(i_data.parents == null || i_data.parents == undefined || i_data.parents.length == 0){
						categoryArr.push(i_data);
				}
				i_data.id = i.id
			}

		}).then(()=>{
			categoryArr = categoryArr.sort((a, b) => {
				var nameA = a.name.toUpperCase();
				var nameB = b.name.toUpperCase();
				if (nameA < nameB) return -1
				if (nameA > nameB) return 1
				return 0;
			})
			this.setState({categories:categoryArr});
		})
	}

	fetchSubCategories(parent_id){
		let subcategoryArr = [];

		db.collection("sport").get().then((querySnapshot) => {
			const sport = querySnapshot.docs

			for (let i of sport){
				let i_data = i.data();

				if(i_data.parents == null || i_data.deleted == true || i_data.deactivated == true){
					continue;
				}
				let parent_ref = i_data.parents[0];
				let parent_data = '';
				if(parent_id == parent_ref.id){
					i_data.id = i.id;
					subcategoryArr.push(i_data);
				}
			}

		}).then(()=>{
			this.setState({subcategories:subcategoryArr});
		})
	}

	fetchDenials(){
		let denials_arr = [];

		db.collection("denials").get().then((querySnapshot) => {
			const denials = querySnapshot.docs

			for (let d of denials){
				let d_data = d.data();
				if(d_data.deleted == true ){
					continue;
				}
				d_data.id = d.id
				denials_arr.push(d_data);
			}
		}).then(()=>{
			denials_arr = denials_arr.sort((a, b) => {
				var nameA = a.text.toUpperCase();
				var nameB = b.text.toUpperCase();
				if (nameA < nameB) return -1
				if (nameA > nameB) return 1
				return 0;
			})
			console.log(denials_arr)
			this.setState({denials_list:denials_arr});
		})
	}

	viewDenials(){

		console.log(this.state.denials_list);
		this.setState({
			highlight: ['mainItem','mainItem','selectedItem mainItem','mainItem'],
			planHighlight: ['topLine', 'BottomLine', 'bottomLine'],
			pageNum: 5,
			breadCrumbs: 'Canned Denials',
			viewFilteredList: false,
			planPager: 4, //by default
			subPlanPager: 1//NEW added : by default
		})
	}

	addDenials(obj){
		let den_text = obj.text;
		let den_body = obj.body;
		const rowref = db.collection("denials").add({
		    	text: den_text,
					body: den_body,
	        deleted:false,

		}).then((doc_ref)=>{
			this.fetchDenials();
		})
	}

	editDenials(obj){

		let den_ref = db.collection('denials').doc(obj.id);

		den_ref.update({
			text:obj.text,
			body:obj.body,
		}).then(() => {
				this.fetchDenials();
		});
	}

	deleteDenials(id){
		let den_ref = db.collection('denials').doc(id);

		den_ref.update({
			deleted:true
		}).then(() => {
				this.fetchDenials();
		});
	}


	viewCategories(){
		console.log("viewCategories > PP: "+this.state.planPager);


		this.setState({
			highlight: ['mainItem','mainItem','selectedItem mainItem'],
			planHighlight: ['topLine', 'BottomLine', 'bottomLine'],
			pageNum: 4,
			breadCrumbs: 'Categories',
			viewFilteredList: false,
			planPager: 4, //by default
			subPlanPager: 1//NEW added : by default
		})
	}

	editCategory(cat_id,cat_index,cat_name,is_parent,parent_id){

		let category_list = this.state.categories;

		let cat_ref = db.collection('sport').doc(cat_id);

		cat_ref.update({
			name:cat_name
		}).then(() => {
			if(is_parent){
				this.fetchCategories();
			}else{
				this.fetchSubCategories(parent_id)
			}
		});

	}

	addCategory(obj){
		let cat_name = obj.cat_name;
		const rowref = db.collection("sport").add({
		    	name: cat_name,
	            distance:false,
	            parents: null,
	            tag: "",
	            deleted:false,
	            deactivated:false,

		}).then((doc_ref)=>{
			let new_id = doc_ref.id;
			let new_doc = db.collection("sport").doc(new_id);
			new_doc.update({
				id:new_id
			});

			this.fetchCategories();
		})


	}

	addSubCategory(obj){
		let cat_name = obj.cat_name;
		let parents = [];

		let parent_ref = db.collection('sport').doc(obj.parent_id);

		parents.push(parent_ref);

		const rowref = db.collection("sport").add({
		    	name: cat_name,
	            distance:false,
	            parents: parents,
	            tag: "",
	            deleted:false,
	            deactivated:false

		}).then((doc_ref)=>{
			let new_id = doc_ref.id;
			let new_doc = db.collection("sport").doc(new_id);
			new_doc.update({
				id:new_id
			});

			this.fetchSubCategories(obj.parent_id);
		})


	}

	deleteCategory(cat_id,is_parent,parent_id){

		let cat_ref = db.collection('sport').doc(cat_id);

		cat_ref.update({
			deleted:true
		}).then(() => {
			if(is_parent){
				this.fetchCategories();
			}else{
				this.fetchSubCategories(parent_id)
			}
		});

	}

	 toggleCategory(row) {
        const idx = row.index;
        const ID = this.state.categories[idx].id;
		const toggle = !(this.state.categories[idx].deactivated);
		let categories = this.state.categories;

		categories[idx].deactivated = toggle;
		this.setState({categories: categories})

        var catRef = db.collection("sport").doc(ID);
        catRef.update({ deactivated: toggle })

        .then(() => {console.log("Document successfully updated!")
        	this.fetchCategories()
   		 })
        .catch((error) => console.error("Error updating document: ", error))

    }

	getCategoryEvents(cat_id){

		let cat_ref = db.collection("sport").doc(cat_id);
		let event_present = false;
		let event_under_cat = []

		// db.collection("inventory").get().then((querySnapshot) => {
		// 	const inventory = querySnapshot.docs

		// 	for (let i of inventory){
		// 		let i_data = i.data();
		// 		if(i_data.client === client_ref){
		// 			if(i_data.category === category)
		// 			inventory_present = true;
		// 			break;
		// 		}
		// 	}
		// 	this.setState({inventoryPresent: inventory_present});
		// })
		let other_cat_id =  "27oVs1rCZntINzxRyzuT" ;
		let other_cat_ref = db.collection("sport").doc(other_cat_ref)
		let eventList = this.state.eventList;

		for (let e of eventList){
			let parents = eventList.parents
			if(parents == undefined || !parents){
				continue;
			}

			if(parents.length == 1){
				if(parents[0] == cat_ref)
				{
					parents[0] =other_cat_ref

					db.collection("events").doc(e.id).update({
						parents:parents
					})
				}
			}
		}
		this.viewEvents();
	}

	viewUserEvents(user_id,role){
		let event_list = [];


		db.collection('event').get().then((querySnapshot)=>{
			const events = querySnapshot.docs

			for(let e of events){
				let e_data = e.data();
				if(e_data.deleted != true && e_data.owner != undefined && e_data.owner != '' && e_data.owner != null){
					if(role == 'business'){
						if(e_data.owner.id == user_id){
							event_list.push({name:e_data.name});
						}
					}
					else if(e_data.participants != null){
						for(let p of e_data.participants){
								if(p.id == user_id && e_data.deleted != true ){
									event_list.push({name:e_data.name});
								}
						}
					}
				}
			}
			this.setState({userEventList: event_list},()=>{console.log(this.state.userEventList)});
		})
	}


	viewUserRigs(user_id){

		let rig_list = [];
		db.collection('rig').get().then((querySnapshot)=>{
			const rigs = querySnapshot.docs

			for(let e of rigs){
				let e_data = e.data();
				if(e_data.user != null){
						if(e_data.user.id == user_id && e_data.deleted != true){
							rig_list.push({name:e_data.name});
						}
				}
			}
			this.setState({userRigList: rig_list},()=>{console.log(this.state.userRigList)});
		})
	}

	viewUserReferals(user_id){
		let referalList = [];
		db.collection('referal').get().then((querySnapshot)=>{
			const referals = querySnapshot.docs

			for(let r of referals){
				let r_data = r.data();
				if(r_data.senderid != undefined && r_data.senderid !== ''){
					if(r_data.senderid.id == user_id){
						referalList.push(r_data);
					}
				}
			}
			this.setState({userReferalList:referalList},()=>{console.log(this.state.userReferalList)});
		})
	}




	fetchRollCallData() {
			//console.log("fetch roll call dataa");
		let rollCallList = []
		db.collection("rollCalls").get().then((querySnapshot) => {
			const rollCalls = querySnapshot.docs
			// iterate through all the doc refs
			for (let rollCall of rollCalls) {
				let id = rollCall.id; // get the ID of the rollCall
				rollCall = rollCall.data(); // access the data of the user doc ref
				// assmue not root and filter out non company roll calls
				//console.log("Name: " + rollCall.name + " RCACTIVE: " + rollCall.isRCActive + " ARCHIVED: " + rollCall.isArchived) ;
				if (this.state.role !== 'Root' && rollCall.companyID !== this.state.companyID) continue
				rollCall.id = id;
				if ((rollCall.logo === undefined) || rollCall.logo.length <= 45) {
					rollCall.logo = "https://firebasestorage.googleapis.com/v0/b/crisis-management-15d38.appspot.com/o/companies%2Fflash_mini.png?alt=media&token=940ef3e5-d4a7-44a9-9714-887d2d7610a9"
				}
				// if (this.state.role !== 'Root' && rollCall.isRCActive  && ( !rollCall.isArchived || rollCall.isArchived == undefined))  {
				if (this.state.role !== 'Root'  && ( !rollCall.isArchived || rollCall.isArchived == undefined))  {
						// assume the user is a Root and the ERP is from a different companyID
						rollCallList.push(rollCall)
					}
				// else if(this.state.role === 'Root' && rollCall.isRCActive  && ( !rollCall.isArchived || rollCall.isArchived == undefined))
				else if(this.state.role === 'Root'  && ( !rollCall.isArchived || rollCall.isArchived == undefined))
				{
						rollCallList.push(rollCall)
				}



			}
			rollCallList = rollCallList.sort((a,b) => {
				var nameA = a.name.toUpperCase();
				var nameB = b.name.toUpperCase();
				if (nameA < nameB) return -1
				if (nameA > nameB) return 1
			})
			this.setState({rcList: rollCallList})
		})
	}
	viewTemplates() {
		// console.log("viewTemplates > SPP: "+this.state.subPlanPager)
		// console.log("viewTemplates > PP: "+this.state.planPager)
		this.setState({
			planHighlight: ['bottomLine', 'topLine', 'bottomLine'],
			viewCheckList: false,
			viewFilteredList: false,
			planPager: 4, //Prev was 1, but changed to 4 by dafault
			subPlanPager: 2 ////Prev was 1, but changed to 2 by dafault
		})
//
		// console.log("viewTemplates-2 > SPP: "+this.state.subPlanPager)
		// console.log("viewTemplates-2 > PP: "+this.state.planPager)
	}
	viewChecklist() {
		// console.log("viewChecklist > SPP: "+this.state.subPlanPager)
		// console.log("viewChecklist > PP: "+this.state.planPager)
		//console.log("viewChecklist")
		this.setState({
			reportsHighlight:['topLine', 'bottomLine', 'bottomLine', 'bottomLine','bottomLine'],
			planHighlight: ['topLine', 'bottomLine', 'bottomLine'],
			viewCheckList: true,
			viewFilteredList: false,
			planPager: 4,//Prev was 1, bu changed to 4 by dafault
			subPlanPager: 1, //Prev was 0, bu changed to 1 by dafault
		})

		// console.log("viewChecklist-2 > SPP: "+this.state.subPlanPager)
		// console.log("viewChecklist-2 > PP: "+this.state.planPager)
	}
	// when pageNum is 4, view the Knowledge Base
	viewKnowledge(action) {
		// console.log("viewKnowledge > SPP: "+this.state.subPlanPager)
		// console.log("viewKnowledge > PP: "+this.state.planPager)
		// console.log("viewKnowledge");
		this.fetchKnowledgeData();
		this.fetchLibraryLogData();
		this.setState({
			highlight: (action.planPager === 6) ? ['mainItem','mainItem','mainItem', 'mainItem','mainItem','mainItem selectedItem','mainItem'] : ['mainItem','mainItem','mainItem', 'mainItem','mainItem selectedItem','mainItem','mainItem'],
			pageNum: 4,
			showBackArrow: false, // hide the back arrow
			breadCrumbs: 'Library',
			viewFilteredList: false,
			planPager: (action.planPager) ? (action.planPager) : 5,
			reportsHighlight:['bottomLine','bottomLine', 'topLine', 'bottomLine', 'bottomLine'],
		})
		setTimeout(() => {
			var selectClientUserTable = document.getElementById('selectClientUserTable'); // get the select Client tag
			if (selectClientUserTable !== null && selectClientUserTable !== undefined) {
				selectClientUserTable.innerHTML = '';
				let curr = { 'text': 'Select Client', 'value': "" }
				selectClientUserTable.options.add( new Option(curr.text, curr.value, curr.selected) )
				for (let client of this.state.clientList) {
					curr = { 'text': client.clientName, 'value': client.clientName }
					selectClientUserTable.options.add( new Option(curr.text, curr.value, curr.selected) )
				}
			}
		}, 50)

		// console.log("viewKnowledge-2 > SPP: "+this.state.subPlanPager)
		// console.log("viewKnowledge-2 > PP: "+this.state.planPager)
	}
	// onClick of ERP tasks, view associated tasks. need the ID of the plan
	viewTasks({index}) {
		// console.log("viewTasks > SPP: "+this.state.subPlanPager)
		// console.log("viewTasks > PP: "+this.state.planPager)
		//console.log("viewTasks");
		// userStatus: {} map of JS objs (key: UID, value: {checkedIn: bool, title: String})
		const planID = this.state.planList[index].id;
		// console.log(planID)
		// console.log("planId: "+planID)
		const erpRef = db.collection("emergencyPlans").doc(planID);

		// console.log(erpRef)
		// TODO:
		// get all the task logs and create a map of taskID to {completedBy and completedDt}
		// db.collection("userGroups").where("users", "array-contains", userRef).get()
		// 	.then((querySnapshot) => {


		let tmap = {} // map of taskID to {completedBy and completedDt}
		db.collection("taskLog").where("relatedItems", "array-contains", erpRef).get().then((querySnapshot) => {
			// populate tmap
			const taskLogs = querySnapshot.docs
			// console.log("tasklogs:"+taskLogs);
			for (let tLog of taskLogs) {
				tLog = tLog.data()
				for (let doc of tLog.relatedItems) {
					let curr = doc.path.split('/')
					// curr[0] is the collection name
					if (curr[0] === "users") {
						// curr[1] is the user ID
						let idx = this.state.userList.findIndex((item) => item.id === curr[1])
						if (idx !== -1) {
							// console.log("tLog-date: "+tLog.date)
							// get the data format in the right format
							var ts = new Date(1970, 0, 1) // Epoch
    						ts.setSeconds(tLog.date.seconds - (7 * 60 * 60)) // minus 7 hours to PST
							const mmddyyyy = ts.getDate()  + "/" + (ts.getMonth()+1) + "/" + ts.getFullYear()
							const timeArr = ts.toLocaleTimeString().split(':');
							let hhmm = timeArr[0] + ':' + timeArr[1] + timeArr[2].slice(2);
							let currTask = {
								completedBy: this.state.userList[idx].name,
								completedDt: mmddyyyy + ' ' + hhmm
							}
							// console.log("Current Task: "+currTask)
							tmap[tLog.id.id] = currTask
						}
					}
				}
			}

		})

		erpRef.get().then((doc) => {
			// iterate through all the ERP's tasks
			if (doc.exists) {
				const planName = doc.data().name; // name of the current ERP
				const tasks = doc.data().tasks;
				// console.log("planName: "+planName);
				if (tasks.length === 0) {
					alert('This Emergency Response Plan does not have any tasks.')
					return;
				}
				// console.log("task length:"+tasks.length)
				var leaderName = 'N/A'
				if (doc.data().leader !== undefined && doc.data().leader !== null) {
					doc.data().leader.get().then((query) => {
						// get the name of the Leader from doc Reference
						const data = query.data();
						var fullName = 'N/A'
						if (data !== undefined && data !== null) {
							fullName = data.first + ' ' + data.last;
						}
						leaderName = fullName;
					})
					// console.log("erpRef in appjs");
				}
				var completedTasks = 0;
				let taskArr = []; // array of task objs associated with ERP
				for (let i = 0; i < tasks.length; i++) {
					tasks[i].get().then((query) => {
						let tdata = query.data()
						if (tdata === undefined) {
							return // make sure the task is defined
						}
						if (tmap[tdata.id] !== undefined && tmap[tdata.id] !== null) {
							// assume tmap is defined for the task id
							tdata.completedBy = tmap[tdata.id].completedBy
							tdata.completedDt = tmap[tdata.id].completedDt
						}
						// adding user title from the ERP's userStatus Map
						let title = 'N/A'
						// if (tdata.assignedUser.id !== undefined && tdata.assignedUser.id !== null) {
						// 	title = this.state.planList[index].userStatus[tdata.assignedUser.id].title
						// }
						tdata.title = title;
						if (tdata.isComplete) {
							completedTasks += 1;
						}
						taskArr.push(tdata)
						if (i === tasks.length - 1) {
							this.setState({
								viewTasks: true,
								planID: this.state.planList[index].id,
								showBackArrow: true, // display the back arrow
								taskList: taskArr,
								planName: planName,
								numCompleted: completedTasks,
								totalNum: tasks.length,
								taskUserList: doc.data().users,
								leaderName: leaderName,
								breadCrumbs: 'Checklists > Task List',
								viewFilteredList: false
							})
						}
					})
				}
			}
		})

		// console.log("viewTasks-2 > SPP: "+this.state.subPlanPager)
		// console.log("viewTasks-2 > PP: "+this.state.planPager)
	}


	viewTasksFromTemplate({index}) {

		// console.log("viewTasksFromTemplate > SPP: "+this.state.subPlanPager)
		// console.log("viewTasksFromTemplate > PP: "+this.state.planPager)
		// userStatus: {} map of JS objs (key: UID, value: {checkedIn: bool, title: String})
		const tuid = this.state.tmplList[index].id;
		// console.log(tuid)
		const tmplRef = db.collection("templates").doc(tuid);
		tmplRef.get().then((doc) => {
			// iterate through all the ERP's tasks
			if (doc.exists) {
				const planName = doc.data().name; // name of the current ERP
				const tasks = doc.data().tasks;
				if (tasks.length === 0) {
					alert('This Emergency Response Plan does not have any tasks.')
					return;
				}
				var leaderName = 'N/A'
				var completedTasks = 0;
				let taskArr = []; // array of task objs associated with ERP
				for (let i = 0; i < tasks.length; i++) {
					tasks[i].get().then((query) => {
						let tdata = query.data();
						if (tdata === undefined) {
							return; // make sure the task is defined
						}
						// adding user title from the ERP's userStatus Map
						let title = 'N/A'
						// if (tdata.assignedUser.id !== undefined && tdata.assignedUser.id !== null) {
						// 	title = this.state.planList[index].userStatus[tdata.assignedUser.id].title
						// }
						tdata.title = title;
						if (tdata.isComplete) {
							completedTasks += 1;
						}
						taskArr.push(tdata)
						if (i === tasks.length - 1) {
							this.setState({
								viewTasks: true,
								planID: tuid,
								showBackArrow: true, // display the back arrow
								taskList: taskArr,
								planName: planName,
								numCompleted: completedTasks,
								totalNum: tasks.length,
								taskUserList: doc.data().users,
								leaderName: leaderName,
								breadCrumbs: 'Checklists > Task List',
								viewFilteredList: false
							})
						}
					})
				}
			}
		})
		// console.log("viewTasksFromTemplate-2 > SPP: "+this.state.subPlanPager)/
		// console.log("viewTasksFromTemplate-2 > PP: "+this.state.planPager)
	}
	viewRollCall({id}) {
		// console.log("viewRollCall > SPP: "+this.state.subPlanPager)
		// console.log("viewRollCall > PP: "+this.state.planPager)
		// use the plan ID to get all the users associated
		const planRef = db.collection("emergencyPlans").doc(id);
		planRef.get().then((doc) => {
			if (doc.exists) {
				let userArr = [];
				let planName = doc.data().name; // name of the current ERP
				let users = doc.data().users;
				let tmap = {}; // key = UID / val = # of tasks for user
				let cmap = {}; // key = UID / val = # of completed tasks for user
				let taskList = doc.data().tasks; // tasklist associated to the plan
				for (let task of taskList) { // iterate through all the tasks
					task.get().then((query) => {
						let data = query.data();
						let UID = data.assignedUser.id;
						if (tmap[UID]) {
							// assume tasks exists
							tmap[UID] += 1;
						}
						if (!(UID in tmap)) {
							// assume tasks not in tmap
							tmap[UID] = 1;
						}
						if (data.isComplete && cmap[UID]) {
							// assume the task is completed and in cmap
							cmap[UID] += 1;
						}
						if (data.isComplete && !(UID in cmap)) {
							// assume the task is complete but not in cmap
							cmap[UID] = 1;
						}
					})
				}
				var size = users.length; // get the number of users
				let userStatus = doc.data().userStatus;
				let count = 0;
				let numCheckedIn = 0; // # of checked in users
				// iterate through all the users in the plan to get their title/checkedIn values
				for (let UID in userStatus) {
					// access the User's Doc Ref
					db.collection('users').doc(UID).get().then((query) => { // to get the user's first/last name
						let data = query.data();
						if (userStatus[UID]['isCheckedIn']) {
							numCheckedIn += 1;
						}
						let currUser = {
							name: data.first + ' ' + data.last,
							title: userStatus[UID]['title'],
							isCheckedIn: userStatus[UID]['isCheckedIn'],
							numTasks: tmap[UID],
							numCompleted: cmap[UID],
							UID: UID // needed to update the checkIn Status of User

						};
						userArr.push(currUser)
						if (count === size - 1 ) {
							// assume the last user has been added to the rollcall
							this.setState({
								rollCallList: userArr,
								planName: planName,
								numCompleted: numCheckedIn,
								totalNum: size,
								planID: id, // need to update the correct ERP to be able to change checkinStatus of user
								viewRollCall: true,
								showBackArrow: true, // show the Back Arrow Button
								breadCrumbs: 'Checklists > Roll Call',
								viewFilteredList: false
							});
						}
						count += 1;
					})
				}
			}
		})
		// console.log("viewRollCall-2 > SPP: "+this.state.subPlanPager)
		// console.log("viewRollCall-2 > PP: "+this.state.planPager)
	}

	// when pageNum is 6, view the Reports
	viewReports(action) {

		// console.log("View Reports");
		// console.log("viewReports > SPP: "+action.subPlanPager)
		// console.log("viewReports > PP: "+action.planPager)
		// sort the list into ascending order
		// console.log(typeof(this.state.planList))
		let planList = this.state.planList.sort((a,b) => {
			var nameA = a.name.toUpperCase();
			var nameB = b.name.toUpperCase();
			if (nameA < nameB) return -1
			if (nameA > nameB) return 1
		})

		this.fetchPlanData(1);
		this.fetchRollCallData()
		this.fetchTemplateData()

		db.collection("tasks").get().then((querySnapshot) => {
			const taskList = querySnapshot.docs

			// iterate through all the doc refs
			let taskArr = []
			for (let task of taskList) {
				let tid = task.id
				task = task.data()
				task.id = tid
				taskArr.push(task)
			}
			this.setState({taskList: taskArr})
		})
		// console.log("Tasks ended: ");
		// console.log("Plans ended: "+planList);

		this.setState({
			highlight: ['mainItem','mainItem','mainItem', 'mainItem','mainItem','mainItem selectedItem','mainItem'],
			pageNum: 6,
			viewTasks: false,
			viewRollCall: false,
			showBackArrow: false, // hide the back arrow
			breadCrumbs: 'Reports',
			viewFilteredList: false,
			planList: planList,
			viewCompletedForms:false,
			viewChecklist: true,
			planPager: (action.planPager) ? (action.planPager) : 6, //Prev was 4, but changed to 6 by dafault
			subPlanPager: (action.subPlanPager) ? (action.subPlanPager) : 1,
		    reportsHighlight:(action.subPlanPager == 2 ) ? [ 'bottomLine','topLine', 'bottomLine', 'bottomLine', 'bottomLine'] : [ 'topLine','bottomLine','bottomLine', 'bottomLine', 'bottomLine'],
		})

		//console.log("end of vie reports")
		// console.log("viewReports-2 > SPP: "+this.state.subPlanPager)
		// console.log("viewReports-2 > PP: "+this.state.planPager)

	}

	viewCompletedForms(action) {

		// console.log("viewCompletedForms > SPP: "+this.state.subPlanPager)
		// console.log("viewCompletedForms > PP: "+this.state.planPager)
		// console.log("View Completed Forms");
		// // sort the list into ascending order
		let cFormsList = [];
		// console.log(this.state.cFormsList[0].companyID)
		if(this.state.cFormsList !== undefined){
			for(let i=0; i<this.state.cFormsList.length; i++){
				if(this.state.cFormsList[i].companyID === this.state.companyID && this.state.role !== 'Root'){
					cFormsList.push(this.state.cFormsList[i])
				}

				if(this.state.role == 'Root'){
					cFormsList.push(this.state.cFormsList[i])
				}
			}
		}
		else{
			cFormsList=[...this.state.cFormsList]
		}


		// console.log(typeof(this.state.cFormsList));
		this.setState({
			highlight: ['mainItem','mainItem','mainItem', 'mainItem','mainItem','mainItem','mainItem selectedItem'],
			pageNum: 6,
			showBackArrow: false, // hide the back arrow
			breadCrumbs: 'Reports',
			viewChecklist:false,
			viewRollCall:false,
			viewTasks:false,
			viewFilteredList: false,
			viewCompletedForms:true,
			cFormsList:cFormsList,
			planPager:(action.planPager)?(action.planPager):7,
		    reportsHighlight:['bottomLine', 'bottomLine', 'bottomLine', 'bottomLine', 'topLine'],
		})

		// console.log("viewCompletedForms-2 > SPP: "+this.state.subPlanPager)
		// console.log("viewCompletedForms-2 > PP: "+this.state.planPager)
	}

	fetchCompletedFormsData()
	{
		let cfArr = []
		let cfUserMap = {}
		let cfFormMap = {}
		let i=0, j=0
		let userRef
		db.collection("completedForms").get().then((querySnapshot) => {
			const cfList = querySnapshot.docs
			// console.log(cfList);
			// iterate through all the doc refs
			for (let cf of cfList)
			{
				cf = cf.data()
				cfArr.push(cf)

				cfUserMap[i] = (cf.user !== undefined) ? cf.user.id : 'undefined';
				i++;
				cfFormMap[j] = (cf.form !== undefined) ? cf.form.id : 'undefined';
			    j++;

			}
			//update the array with all needed
			for(i=0; i<cfArr.length; i++){
				cfArr[i]['user'] = cfUserMap[i];
				cfArr[i]['form'] = cfFormMap[i];
			}

			//get the user information from usertable using cfusermap doc reference
			for (let item of cfArr)
			{

				if(item['user'] !== undefined)
				{
				  userRef = db.collection('users').doc(item['user'])
				  userRef.get().then((querySnapshot) =>
				  {
				    if (querySnapshot.exists)
				    {
				    	const ulist = querySnapshot.data()

						// if (this.state.role !== 'Root' && (ulist.companyID == this.state.companyID))  {
						// 		item['name'] = ulist.first+' '+ulist.last
						//     	item['email'] = ulist.email
						//     	item['phone'] = ulist.phone
						//     	item['profilePicture'] = ulist.profilePicture
						//     	item['companyID'] = ulist.companyID

						// }
						// if(this.state.role === 'Root')
						// {
							item['name'] = ulist.first+' '+ulist.last
					    	item['email'] = ulist.email
					    	item['phone'] = ulist.phone
					    	item['profilePicture'] = ulist.profilePicture
					    	item['companyID'] = ulist.companyID

						// }



					}
				   }).catch((error) => {
				    console.log("Error getting document:", error);
				   });
				}
			}



             // /console.log(cfArr);
			// update State of Completed Forms array list
			this.setState({
				cFormsList: cfArr
			})

			// console.log("fetchCompletedFormsData"+typeof(this.state.cFormsList));
		})
	}

	fetchformsData(){
		let formArr = []
		let i=0
		db.collection("forms").get().then((querySnapshot) => {
			const formList = querySnapshot.docs

			for (let form of formList)
			{
				let fId = form.id
				form = form.data()
				form.id = fId
				formArr.push(form)



				// get the index of it in the formArr
				let idx = formArr.indexOf(form)
				if(form.companies !== undefined && form.companies.length !== '')
				{
					for(let companies of form.companies)
					{
				 		formArr[idx]['companies'][i] = companies.id
				 		 i++
					}
				}
			}

			this.setState({
				formList: formArr
			})

		})

	}
	fetchUserData() {
		// console.log("Userdata fetch ");
		var userMap = {}
		let taskArr = []
		db.collection("tasks").get().then((querySnapshot) => {
			const taskList = querySnapshot.docs
			// iterate through all the doc refs
			for (let task of taskList) {
				let tid = task.id
				task = task.data()
				task.id = tid
				taskArr.push(task)
				if (task.assignedUser !== undefined && task.assignedUser.length !== '') {
					// assume the assigned user is a doc ref
					if (!(task.assignedUser.id in userMap)) {
						// assume userMap doesnt contain the user yet
						userMap[task.assignedUser.id] = 1;
					}
					if (task.assignedUser.id in userMap) {
						// assume userMap contains the user
						userMap[task.assignedUser.id] += 1;
					}
				}
			}
		})
		let userArr = []
		let gUserList = []
		let deletedUsersArr = []
		if (this.state.role === 'Manager') {
			// assume current user is a manager
			let umap = {}; // hash map of UIDs so we dont have any duplicates
			let userRef = db.collection('user').doc(this.state.uid)
			// query all users who are in the manager's userGroups
			db.collection("userGroups").where("users", "array-contains", userRef).get()
			.then((querySnapshot) => {
				const userGroups = querySnapshot.docs;
				for (let i = 0; i < userGroups.length; i++) {
					let ulist = userGroups[i].data().users
					for (let j = 0; j <= ulist.length; j++) {
						// iterate through all the userRefs in a group
						let curruser = ulist[j]
						if (curruser !== undefined && !(curruser.id in umap)) {
							// assume user is not a duplicate
							curruser.get().then((query) => {
								let UID = curruser.id; // get the UID of the user
								let user = query.data() // access the data of the user doc ref
								// iterating through the users and normalizing the data
								// so that each user has the necessary key/value pairs
								user.id = UID; // assign the id field to the UID
								gUserList.push(user)
								user.name = user.first + ' ' + user.last
								user.phone = user.phone + ''
								user.phone = user.phone.replace('(', '')
								user.phone = user.phone.replace(')', '')
								user.phone = user.phone.replace(' ', '')
								user.phone = user.phone.replace('--', '-')
								// assume the signed in user is a super admin and the companyID doesnt match
								if (this.state.role !== 'root' && user.companyID !== this.state.companyID) return
								// assume the signed in user is a super admin and the current user is a root
								if (this.state.role !== 'root' && user.role === 'root') return
								user.tasks = 0;
								if (UID in userMap) {
									user.tasks = userMap[UID];
								}
								if (!('role' in user) || user.role.toLowerCase() === 'user' || user.role.length === 0) {
									user.role = 'User';
								}
								// users that either do not have a profile picture, or their profile pciture is a document reference
								if ((!user.profilePicture) || user.profilePicture.length <= 45 || user.profilePicture.substr(0,5) === 'users') {
									user.profilePicture = image
								}
								if (user.deleted) { // user is deleted
									user.role = 'User';
									deletedUsersArr.push(user);
								}
								if (!user.deleted) { // user is not deleted
									userArr.push(user);
								}
								if (i === userGroups.length -1 && j === ulist.length - 1) {

									// assume iterated through all the userGroups
									userArr = userArr.sort((a, b) => {
										var nameA = a.name.toUpperCase();
										var nameB = b.name.toUpperCase();
										if (nameA < nameB) return -1
										if (nameA > nameB) return 1
										return 0;
									})
									// update State of Userlist and deletedUsers
									// console.log(gUserList)
									this.setState({
										userList: userArr,
										deletedUsers: deletedUsersArr,
										gUserList: gUserList,
										taskList: taskArr
									})
								}
							})
							umap[curruser.id] = 1 // map of UIDs
						}
					}
				}
			})
			.catch((error) => console.log("Error getting documents: ", error));
		} else {
			// console.log("Users collection function");
			// assume curr user is super admin or root admin
			db.collection("user").get().then((querySnapshot) => {

				const userList = querySnapshot.docs;
				// iterate through all the doc refs
				for (let user of userList) {

					let UID = user.id // get the UID of the user
					user = user.data() // access the data of the user doc ref
					// iterating through the users and normalizing the data
					// so that each user has the necessary key/value pairs
					if(user.role != null){
					user.id = UID // assign the id field to the UID
					gUserList.push(user)
					user.name = user.first + ' ' + user.last;
					user.phone = user.phone + '';
					user.phone = user.phone.replace('(', '')
					user.phone = user.phone.replace(')', '')
					user.phone = user.phone.replace(' ', '')
					user.phone = user.phone.replace('--', '-')
					// assume the signed in user is a super admin and the companyID doesnt match
					if (this.state.role !== 'root' && user.companyID !== this.state.companyID) continue
					// assume the signed in user is a super admin and the current user is a root
					if (this.state.role !== 'root' && user.role === 'root') continue
					user.tasks = 0;
					if (UID in userMap) {
						user.tasks = userMap[UID];
					}
					if (!('role' in user) || user.role.toLowerCase() === 'user' || user.role.length === 0) {
						user.role = 'User';
					}
					// users that either do not have a profile picture, or their profile pciture is a document reference
					if ((!user.profilePicture) || user.profilePicture.length <= 45 || user.profilePicture.substr(0,5) === 'users') {
						user.profilePicture = image
					}
					if (user.deleted) {
						user.role = 'User';
						deletedUsersArr.push(user);
						continue;
					}

					userArr.push(user);
				}
				}
				userArr = userArr.sort((a, b) => {
					var nameA = a.name.toUpperCase();
					var nameB = b.name.toUpperCase();
					if (nameA < nameB) return -1
					if (nameA > nameB) return 1
					return 0;
				})
				// update State of Userlist and deletedUsers
				this.setState({
					userList: userArr,
					deletedUsers: deletedUsersArr,
					gUserList: gUserList,
					taskList: taskArr
				})
			});
		}
	}
	fetchClientData() {
		let cmap = {}; // get number of checklists for each client
		// db.collection("emergencyPlans").get().then((querySnapshot) => {
		// 	const clist = querySnapshot.docs.map(doc => doc.data());
		// 	for (let item of clist) {
		// 		// console.log("item:"+item.companyID);
		// 		// make sure that the emergencyPlan is a doc ref and the task is complete
		// 		if (item.companyID !== undefined) {
		// 			if (cmap[item.companyID] && item.isActive) {
		// 				cmap[item.companyID] += 1;
		// 			}
		// 			if (!(item.companyID in cmap)) {
		// 				cmap[item.companyID] = 1;
		// 			}
		// 		}

		// 		// console.log("cmap:"+cmap[item.companyID]+" "+item.name+" "+item.isActive);
		// 	}
		// })
		let clientArr = []
		let deletedClientArr = []
		db.collection("client").get().then(querySnapshot => {
			const data = querySnapshot.docs.map(doc => doc.data());
			for (let client of data) {
				console.log(client);
				client.companyID = client.id;
				// client.plans = client.emergencyPlans.length
				client.plans = 0
				if (cmap[client.companyID] !== undefined && cmap[client.companyID] !== null) {
					client.plans = cmap[client.companyID]
				}

				if ((client.logo === undefined) || client.logo.length <= 45) {
					client.logo = image;
				}

				// assume the client has been marked as deleted
				if (client.deleted) {
					deletedClientArr.push(client)
				} else {
					// add client to an array
					clientArr.push(client)
				}

			}
			clientArr = clientArr.sort((a,b) => {
				var nameA = a.name.toUpperCase();
				var nameB = b.name.toUpperCase();
				if (nameA < nameB) return -1
				if (nameA > nameB) return 1
			})
			// console.log("clientArr"+clientArr);
			// update client list state
			this.setState({
				clientList: clientArr,
				deletedClients: deletedClientArr

			})
		})
	}
// console.log("planArr :"+planArr);
	fetchPlanData(reports = 0) {
		let tmap = {} // key: ERP id / value: number of completed tasks
		db.collection("tasks").get().then((querySnapshot) => {
			const tasks = querySnapshot.docs.map(doc => doc.data());
			for (let task of tasks) {
				// make sure that the emergencyPlan is a doc ref and the task is complete
				if (task.emergencyPlan !== undefined && task.isComplete) {
					if (tmap[task.emergencyPlan.id]) {
						tmap[task.emergencyPlan.id] += 1;
					}
					if (!(task.emergencyPlan.id in tmap)) {
						tmap[task.emergencyPlan.id] = 1;
					}
				}
			}
			return tmap
		})
		.then((tmap) => {
			// add the number of complete plans
			let planArr = []
			db.collection("emergencyPlans").get().then(querySnapshot => {
				const plans = querySnapshot.docs;
				for (let i = 0; i < plans.length; i++) {
					let item = plans[i]
					const planID = item.id; // ERP's id
					item = item.data()
					// TODO: not checking be leader fields is missing in some docs
					item.leaderName = 'N/A'
					let idx = this.state.userList.findIndex((user) => user.id === item.leader.id)
					if (idx !== -1) {
						item.leaderName = this.state.userList[idx].name
					}
					let userArr = []
					for (let i = 0; i < item.users.length; i++) {
						let uid = item.users[i].id
						for (let j = 0; j < this.state.userList.length; j++) {
							if (this.state.userList[j].id === uid) {
								userArr.push(this.state.userList[j].name)
							}
							if (i === item.users.length -1 && j === this.state.userList.length - 1) {
								item.userNames = userArr.join(', ')
							}
						}
					}
					// normalize the plans so they have all the necessary fields
					if (!(item.hasOwnProperty('deactivated'))) {
						item.deactivated = false;
					}
					if (!(item.hasOwnProperty('isArchived'))) {
						item.isArchived = false;
					}
					item.completedTasks = tmap[planID]
					// TODO: please make sure craun is adding leaders to the emergencyPlans
					// assume the leader has been defined (Doc Ref)
					if (this.state.role === 'root' && item.isActive && !reports && !item.isArchived) {
						// assume the user is a Root and the ERP is from a different companyID
						planArr.push(item)
					}
					else if(this.state.role === 'root' && reports)
					{
						planArr.push(item)
					}

					if (this.state.role !== 'root' && this.state.companyID === item.companyID && item.isActive && !reports && !item.isArchived) {
						// assume the user is a Super Admin and the ERP is from the same companyID
						planArr.push(item)
					}
					else if(this.state.role !== 'root' && this.state.companyID === item.companyID && reports) {
						// assume the user is a Super Admin and the ERP is from the same companyID
						planArr.push(item)
					}
					if (i === plans.length - 1) {
						planArr = planArr.sort((a,b) => {
							var nameA = a.name.toUpperCase()
							var nameB = b.name.toUpperCase()
							if (nameA < nameB) return -1
							if (nameA > nameB) return 1
						})

						this.setState({planList: planArr});
					}
				}
			})
		})
	}
	fetchUserGroupData() {
		let groupArr = [];
		db.collection("userGroups").get().then(querySnapshot => {
			const data = querySnapshot.docs;
			let groupID; // get the UID of the doc ref
			// iterate through all the doc refs
			for (let group of data) {
				groupID = group.id; // get the UID of the user
				group = group.data(); // access the data of the user doc ref)
				if (this.state.role !== 'root' && group.companyID !== this.state.companyID) {
					continue; // for super admins, filter out groups w diff companyIDs
				}
				// get the id of the group
				group.id = groupID;
				groupArr.push(group)
			}
			groupArr = groupArr.sort((a,b) => {
				var nameA = a.name.toUpperCase()
				var nameB = b.name.toUpperCase()
				if (nameA < nameB) return -1
				if (nameA > nameB) return 1
			})
			this.setState({groupList: groupArr})
		})
	}
	fetchLibraryLogData() {
		db.collection("libraryLog").get().then(querySnapshot => {
			const loggedInfo = querySnapshot.docs.map(doc => doc.data());
			let itemArr = [];

			for (let item of loggedInfo) {
				itemArr.push(item)
			}
			this.setState({ loggedData: itemArr})
			// console.log(itemArr)
		})
	}
	// Iterating through the array and creating JSON elements to populate
	// the table. The path is which repo the user is currently looking at.
	// The fullPath is what the path will look like if the row is clicked.
	fetchKnowledgeData() {
		db.collection("repoInfo").get().then(querySnapshot => {
			const repoInfo = querySnapshot.docs.map(doc => doc.data());
			let itemArr = [];
			let folderArr = []; // all the folders in every client directory used for upload file
			let currPath;
			for (let item of repoInfo) {
				/* assume that the item was marked as deleted and
				 * only display deleted folders for root users */
				// if(item.deleted) continue;

				if (item.deleted && this.state.role !== 'root') continue
				if (item.folder && item.fullPath.split('/').length === 3) folderArr.push(item)
				if (item.fullPath === undefined) continue

				currPath = item.fullPath.split('/');
				currPath.pop();
				currPath = currPath.join('/')
				// ensuring that the correct files are displayed
				if (this.state.path === currPath){
					itemArr.push(item);
					console.log(item.deleted+"--"+item.itemName+"---"+this.state.role);
				}
			}
			this.setState({ repository: itemArr, folderRepos: folderArr })
			// console.log(itemArr)
		})
	}

	vaccineSettings(){


		let clients = this.state.clientList;
		let currentcompany = [];
		for(let c of clients){
			if(c.id == this.state.companyID){
				currentcompany.push(c);
			}
		}


		this.setState({
			highlight: ['mainItem','mainItem','mainItem','mainItem', 'mainItem', 'mainItem','mainItem selectedItem'],
			pageNum: 8,
			vaccineClient:currentcompany
		})
	}

	fetchUserQuotes(id){

		console.log("in fetch "+id);
		var user_reference = db.collection("user").doc(id);
		db.collection("response").where("user", "==", user_reference)
	    .get()
	    .then((querySnapshot) => {
	        querySnapshot.forEach((doc) => {
	            // doc.data() is never undefined for query doc snapshots
	            console.log(doc);
	            let quote_data = doc.data();
	            let user_response = quote_data.responses;
	            let response_arr = [];
	            for(let r of user_response){
	            	let page = null;
	            	let product = null;
	            	let r_arr = [];

	            	 // let page = this.fetchFormPage(r.page)
	            	 r.page.get().then((querySnapshot)=>{
						page = querySnapshot.data();
					r_arr['input'] = r.input;
					r_arr['question'] = page.summaryPane.question;

					if(r.products.length > 0){
						r.products[0].get().then((querySnapshot)=>{
							let product = querySnapshot.data();
							r_arr['product'] = product.name;
						});
					}else{
						r_arr['product'] = null;
					}
					response_arr.push(r_arr);
					});

	            }


	            setTimeout(()=>{
	            	this.setState({
		            	user_response:response_arr
		            });
	            },1000);


	        });
	    })
	    .catch((error) => {
	        console.log("Error getting documents: ", error);
	    });

	}

	fetchFormPage(pageReference){
		pageReference.get().then((querySnapshot)=>{
			let doc = querySnapshot.data();
			console.log(doc);
			return doc;
		})
	}

	fetchInventory(productReference){
		productReference.get().then((querySnapshot)=>{
			let doc = querySnapshot.data();
			return doc;
		})
	}

	clientSettings(){

		let formSettingsArr = [];
		let forms = [];



		this.fetchformsData();
		// console.log(this.state.userList)


		// console.log(this.state.formList);
		// console.log(this.state.clientList);
		// combined list of companies, forms and users under that companies into one
		for(let clientInfo of this.state.clientList){
			for(let form of this.state.formList)
			{
				formSettingsArr.push(clientInfo)
				let idx = parseInt(formSettingsArr.indexOf(clientInfo))

				if(form.companies.includes(clientInfo.companyID) ){
					if(formSettingsArr[idx]['forms'] !== undefined){
						formSettingsArr[idx]['forms'].push(form.id)
					}
					else
					{
						formSettingsArr[idx]['forms'] = [form.id]
					}
				}
			}
		}
				// console.log(formSettingsArr);


		this.setState({
			highlight: ['mainItem','mainItem','mainItem','mainItem', 'mainItem', 'mainItem','mainItem selectedItem'],
			pageNum: 7,
			formSettings:formSettingsArr
		})
	}

	toggleForm(row) {

		 console.log(row)
		const idx = row.id;
     	const compID = this.state.clientList[idx].id;
     	let formList = [...this.state.formList];
     	let formSettings = [...this.state.formSettings];
     	let formId = [{forms: row.formId }];


     		for(let form of formList)
     		{
     			//just add that in the company list of formlist
     			if((form.title === 'Daily Health Screener' || form.id === row.fromId) && row.toggleValue == 1)
 				{
 					if(! form['companies'].includes(compID)){
 						form['companies'].push(compID)
 					}

 				}
 				else if((form.title === 'Daily Health Screener' || form.id === row.fromId) && row.toggleValue == 0)
 				{
 					const index = form['companies'].indexOf(compID);
 					if (index > -1) {
					  form['companies'].splice(index, 1);
					}
				}

     		}
     		//formsettings is not getting populated well: TODO

     		//just add the form id into the formsettings in that specific company id : TODO

     		for(let item of formSettings){
     			// console.log(item)
     			if(row.toggleValue === true){
     				if(item['companyID'] == compID )
     				{
	     				if(item['forms'] !== undefined && !item['forms'].includes(row.formId))
	     				{

	     					item['forms'].push(row.formId)
	     				}
	     				else{
	     					item['forms'] = [row.formId]
	     				}
	     			}


     			}
     			else
     			{
     				if(item['companyID'] == compID && item['forms'] !== undefined ){
	     				for(let id of item['forms']){
	     					if(id == row.formId)
	     					{
	     						const index = item['forms'].indexOf(row.formId);
	     						// console.log(index)
	     						if(index > -1)
	     							item['forms'].splice(index,1)

	     					}
	     				}
     				}

     			}

     		}

      	 	// console.log(formList)


     	this.setState({formList: formList})
		this.setState({formSettings: formSettings})

		console.log(row.toggleValue)
		console.log(this.state.formList)
     	console.log(this.state.formSettings)

		let	refArr = []


		//alter the db with the operaation depending on what the togglevalue is
		    for(let item of this.state.formList)
			{
				for(let comp of item['companies']){
					if(comp !== undefined && comp !== null)
					 refArr.push(db.collection("companies").doc(comp))
				}

			}
			console.log(refArr)
			// console.log(row.formId);
			if((refArr!== undefined || refArr !== null) && refArr.length > 0 && (row.formId !== undefined || row.formId !== null)){
				db.collection("forms").doc(row.formId).update({companies: refArr})
				.then(() => console.log("Document successfully updated!"))
	        	.catch((error) => console.error("Error updating document: ", error))
			}



    }
	/******************************* User List Functions **********************************/
	handleClientFilterChange({clientID}) {
		if (clientID !== "") {
				let userList = []
				for (let i = 0; i < this.state.userList.length; i++) {
					if (this.state.userList[i].clientID === clientID) {
						userList.push(this.state.userList[i])
					}
				}
				this.setState({
					viewFilteredList: true,
					filteredList: userList
				})

		} else {
			this.setState({
				viewFilteredList: false,
				filterecdList: []
			})
		}
	}
	// Remove a user from the list
    // retrieves the index from the UserTable class
    // marks the user as deactived so the user does not show up in the list
    deleteUser(row) {
        const deleteIndex = row.id
        const deletedUser = this.state.userList[deleteIndex]
        // get the UID of the user that is being deleted
        const UID = deletedUser.id;
        this.state.userList.splice(deleteIndex, 1);
        this.setState({
            userList: this.state.userList,
            deletedUsers: [deletedUser, ...this.state.deletedUsers]
        })
        // mark the user as deleted

				let userRef = db.collection('user').doc(UID);

        userRef.set({
            deleted: true,
            deactivated: true,
        }, { merge: true });

				//if the user is business mark the user's event as deleted

				if(deletedUser.role == 'business'){

					let userOwnedEvents = [];

					db.collection('event').get().then((querySnapshot)=>{
						const events = querySnapshot.docs

						for(let e of events){
							let e_data = e.data();
							if(e_data.owner !== undefined){
								let event_owner = e_data.owner.id
								if(event_owner == UID){
									userOwnedEvents.push(e)
								}
							}
						}
					}).then(()=>{
						for(let e of userOwnedEvents){
							e.set({
								deleted: true,
								deactivated: true
							})
						}
					})

				}

        let arr=[];
        arr.push({
        	"document" : this.state.userList[deleteIndex].id,
        	"logType": "deleted",
 			"source": "web-console",
      		"oldValue": false,
      		"newValue": true
        })

    	this.createUserLog(arr[0])


	}
	reactivateUser(row) {
		// properties: index, UID
		const user = this.state.deletedUsers[row.index]
		user.deactivated = false
		this.state.deletedUsers.splice(row.index, 1)
		this.setState({
			deletedUsers: this.state.deletedUsers,
			userList: [user, ...this.state.userList]
		})
		// reactivate the user
        db.collection('users').doc(row.UID).set({
			deleted: false,
			deactivated: false
        }, { merge: true })
	}
	reactivateClient(row) {
		// properties: index, id
		const client = this.state.deletedClients[row.index]
		client.deactivated = false
		this.state.deletedClients.splice(row.index, 1)
		this.setState({
			deletedClients: this.state.deletedClients,
			clientList: [client, ...this.state.clientList]
		})
		// reactivate the user
        db.collection('client').doc(row.id).set({
			deleted: false,
			deactivated: false
        }, { merge: true })
	}
    toggleUser(row) {
        const idx = row.index;
        const UID = this.state.userList[idx].id;
		const toggle = !(this.state.userList[idx].deactivated);
		let userList = this.state.userList;
		userList[idx].deactivated = toggle;
		this.setState({userList: userList})
        var userRef = db.collection("user").doc(UID);
        userRef.update({ deactivated: toggle })
        .then(() => console.log("Document successfully updated!"))
        .catch((error) => console.error("Error updating document: ", error))
        let arr=[];
        arr.push({
        	"document" : this.state.userList[idx].id,
        	"logType": "toggled",
 			"source": "web-console",
      		"oldValue": toggle,
      		"newValue": !toggle
        })
        console.log(this.state.userList[idx].id)
    	this.createUserLog(arr[0])
    }


    // Only updates the data in Cloud Firestore
    // Does not edit the Firebase Auth DB
    editUser(row) {
		const idx = row.index;
		const role = row.role;
		let userList = this.state.userList;
		if (userList[idx].email !== row.email) {
			// assume the email is changed
			var currUser = firebase.auth().currentUser
			if (userList[idx].email === currUser.email) {
				// assume the current user is updating their own email
				currUser.updateEmail(row.email).then(() => console.log("Successfully updated curr user's email"))
				.catch((error) => console.log("Failed to update curr users email", error));
			}
		}
		// console.log(row.emergencyName);
				userList[idx].first = row.first;
				userList[idx].last = row.last;
        userList[idx].email = row.email
        userList[idx].phone = row.phone;
				userList[idx].businessName = row.businessName;
				userList[idx].username = row.username;
				userList[idx].address = row.address;

        // edit the user data in Firestore
		const UID = userList[idx].id;
        const user = db.collection("user").doc(UID);
        // profile picture did not change
        let updatedUser = {
            email: row.email,
            first: row.first,
            last: row.last,
            phone: row.phone,
						username: row.username,
						address: row.address
        };

				if(role == 'business'){
					userList[idx].paymentDueDate = firebase.firestore.Timestamp.fromDate(row.paymentDueDate);
					updatedUser.paymentDueDate = firebase.firestore.Timestamp.fromDate(row.paymentDueDate);

					userList[idx].businessName = row.businessName;
					updatedUser.businessName = row.businessName;
				}
        // if (row.profilePicture === '') {
            // updates the state
			this.setState({userList: userList},()=>{
				user.update(updatedUser)
	            .then(() => this.fetchUserData())
	            .catch((error) => console.error("Error updating document: ", error));
			});

        // }
        // // if the user is changing the profile picture
        // else {
             // uploading the new profile picture to firebase
      //       const uploadTask = storageRef.child(`users/${UID}/${UID}.jpeg`).put(row.profilePicture)
      //       uploadTask.on('state_changed', null, null, () => {
      //               // got the URL of the firebase storage image
      //               uploadTask.snapshot.ref.getDownloadURL().then( (url) => {
      //                   // updates the users profile picture in the UserList
						// userList[idx].profilePicture = url;
      //                   // updates the state
						// this.setState({userList: userList});
      //                   updatedUser.profilePicture = url;
      //                   user.update(updatedUser)
      //                   .then(() => console.log("Document successfully updated!"))
      //                   .catch((error) => console.error("Error updating document: ", error));
      //               });
      //               console.log('Successfully stored and retrieve the url from the image');
      //           });
        // }
    }
    checkNewUser(user) {
        if (!user.hasOwnProperty('companyID')) return false;
        if (!user.hasOwnProperty('first')) return false;
        if (!user.hasOwnProperty('last')) return false;
        if (!user.hasOwnProperty('phone')) return false;
        if (!user.hasOwnProperty('emergencyPhone')) return false;
        if (!user.hasOwnProperty('emergencyName')) return false;
        if (!user.hasOwnProperty('role')) return false;
        if (!user.hasOwnProperty('password')) return false;
        if (!user.hasOwnProperty('email')) return false;
        if (user.companyID.toString().length !== 6) return false;
        return true; // the xlsx form is in the correct format
    }
    // adds many users to the User List
    // Format of data that is retrieved from an XLSX file
    // Must have these keys {email, first, last, phone, companyID, role, password}
    addManyNewUsers(file) {
        // extract all the users from the file
        // add them to the list
        let userData = file.data;
        let correctFormat = true;
        for (let user of userData) {
            if (!this.checkNewUser(user)) {
                //assume that a user does not have all the necessary fields
                correctFormat = false;
                alert('Please upload the xlsx file is the correct format: [companyID, first, last, phone, role, password, email]')
                break;
            }
        }
        if (correctFormat) {
            for (let user of userData) {
                user.profilePicture = ''
                user.phone = user.phone;
                this.addNewUser(user, () => console.log('I want to add one user at a time'))
            }
        }
	}
	addNewUserCB(newuser, timestampDJ, timestampDLL, url, UID) {


        let profileImages = [];

        // assume pic was NOT uploaded
		if (url === '') newuser.profilePicture = image
		// assume pic was uploaded
        else{ newuser.profilePicture = url

    		profileImages.push(newuser.profilePicture)
    	}


		newuser.name = newuser.first + ' ' + newuser.last;
		newuser.deactivated = false;
		newuser.id = UID;
		newuser.tasks = 0;
		// // only Root Users can set a diff companyID for new users
		// // otherwise the companyID is default to the Super Admins companyID

		this.setState({userList: [newuser, ...this.state.userList]});
		// adds a user to the 'users' collection in Cloud Firestore
		db.collection("user").doc(UID).set({
			dateJoined: timestampDJ,
			dateLastLogged: timestampDLL,
			profileImages: profileImages,
			first: newuser.first,
			last: newuser.last,
			password: newuser.password,
			businessName: newuser.businessName,
			email: newuser.email,
			phone: newuser.phone + '', // convert to string
			role: newuser.role,
			username: newuser.username,
			address: newuser.address,
			paymentDueDate: firebase.firestore.Timestamp.fromDate(newuser.paymentDueDate),
			isActive: false,
			deactivated: false,
			followers: [],
			following: [],
			interests: [],
			isOffline: false
		})
		.then(() => console.log("User successfully added!!!"))
		.catch(()=> console.error("Error adding user"))
	}
    // Adds a user to the Admin Console
    // retrieves the new user info from the addUserDialog class
    // 1) Creates a new user in Firebase Auth and creates the UID/dateJoined/dateLastLogged
    // 2) Creates a new directory in Firebase Storage in 'users' called the UID
    // 3) Adds the profile picture into the directory and its called the UID
    // 4) Updates the userList array and rerenders the UserList Component
    // 4) Adds the user to Cloud FireStore to the Users Collection
    addNewUser(newuser, cb) {
		//temp fix
		let adminName = this.state.adminName
		let clientName = '';

		firebase.auth().createUserWithEmailAndPassword(newuser.email,newuser.password )
		// .then(res => res.text())
		.then((data) => {
			// let user  = JSON.parse(data)
			let user = data.user
			console.log(user);
			// assuming that a user was created in Firebase Auth
            const UID = user.uid;
            const dateJoined = new Date(user.metadata.creationTime);
            const timestampDJ = firebase.firestore.Timestamp.fromDate(dateJoined)
            const dateLastLogged = new Date(user.metadata.lastSignInTime)
            const timestampDLL = firebase.firestore.Timestamp.fromDate(dateLastLogged)
			if (newuser.profilePicture === '') {
				this.addNewUserCB(newuser, timestampDJ, timestampDLL, newuser.profilePicture, UID)
			} else {
				// creates a child directory called the UID, and the file is placed inside this directory
				const uploadTask = storageRef.child(`users/${UID}/${UID}.jpeg`).put(newuser.profilePicture);
				uploadTask.on('state_changed', (snapshot) => console.log('state changed'),
					(error) => console.log(error),
					() => {
						// got the URL of the firebase storage image
						uploadTask.snapshot.ref.getDownloadURL().then((url) => {
							this.addNewUserCB(newuser, timestampDJ, timestampDLL, url, UID)
						})
						console.log('Successfully stored and retrieve the url from the image');
					})
			}

			fetch('https://us-central1-fomo-53dbd.cloudfunctions.net/stripeClient/addCustomerToStripe', {
 			method: 'POST',
 			headers: {
 				'Content-Type': 'application/json',
 				'Access-Control-Allow-Origin': 'http://localhost:3000/',
 				'Access-Control-Allow-Methods': 'GET, PUT, POST, OPTIONS',
 				'Access-Control-Allow-Headers': '*'
 			},
 			body: JSON.stringify({
 				user_id: newuser.email
 			}),
 		})


		})
		.catch((error) => {
			alert('Error adding User. Make sure to use a unique Email')
			console.log(error.code + " | " + error.message)
		})

		let arr=[];
        arr.push({
        	"logType": "New User Added",
 			"source": "web-console",
      		"newValue": "Email: "+newuser.email
        })
       console.log(arr);
    	this.createUserLog(arr[0])
		cb()




	}

	toggleVaccineClient(row) {
        // get the index of the selected client
        const idx = row.index;
        // get the companyID of the selected client
        const companyID = this.state.clientList[idx].companyID;
        // get the value of the deactivated key of the client
        const toggle = !(this.state.clientList[idx].vaccineTracking);
		// update the table
		let clientList = this.state.clientList;
		clientList[idx].vaccineTracking = toggle;
		this.setState({clientList: clientList})
        const clientRef = db.collection("companies").doc(companyID);
        clientRef.update({ vaccineTracking: toggle })
        .then(() => console.log("Document successfully updated!"))
        .catch((error) => console.error("Error updating document: ", error));
    }
	/******************************* Client List Functions ***********************************/
	toggleClient(row) {
        // get the index of the selected client
        const idx = row.index;
        // get the companyID of the selected client
        const companyID = this.state.clientList[idx].companyID;
        // get the value of the deactivated key of the client
        const toggle = !(this.state.clientList[idx].deactivated);
		// update the table
		let clientList = this.state.clientList;
		clientList[idx].deactivated = toggle;
		this.setState({clientList: clientList})
        const clientRef = db.collection("client").doc(companyID);
        clientRef.update({ deactivated: toggle })
        .then(() => console.log("Document successfully updated!"))
        .catch((error) => console.error("Error updating document: ", error));
    }
    editClient(row) {
		const idx = row.index;
		var clientList = this.state.clientList;
		// TODO: check if user is trying to change a password then
		// check if theyre changing their password update the password
		clientList[idx].domain = row.domain;
        clientList[idx].name = row.name;
        clientList[idx].phone = row.phone ;
        clientList[idx].pocphone = row.pocphone;
        clientList[idx].pocfirst = row.pocfirst;
        clientList[idx].poclast = row.poclast;
        // const companyID = clientList[idx].companyID;
        // update the firestore document for the client


        const clientRef = db.collection("client").doc(row.id);
        clientRef.update({
            domain: row.domain,
            clientName: row.name,
            phone: row.phone + '',
            pointOfContact: {'first':row.pocfirst,'last':row.poclast,'phone':row.pocphone}
        })
        .then(() => {
        		console.log(clientList);
				// this.setState({clientList: clientList});
				this.fetchClientData();

        })
        .catch((error) => console.error("Error updating document: ", error));
    }
    // marks the client as deleted and physically removes the client from the List
    deleteClient(row) {
		let deleteIndex = row.index
		const client = this.state.clientList[deleteIndex]
		// get the UID of the client that is being deleted
        let companyID = this.state.clientList[deleteIndex].id;
        this.state.clientList.splice(deleteIndex, 1);
		this.setState({
			clientList: this.state.clientList,
			deletedClients: [client, ...this.state.deletedClients]
		});
		db.collection('client').doc(companyID)
		.set({ deleted: true, deactivated: true}, { merge: true })
    }
    // abstracted the function to add clients who do not upload a logo
    addClientCallBack(newClient, timestampDJ, timestampDLL, url) {
    	// console.log(": 14");
    	// console.log(newClient);
        if (url === '') {
            // assume logo was NOT uploaded
			newClient.logo = image
        } else {
            // assume logo was uploaded
			newClient.logo = url
        }
        newClient.deactivated = false;
		newClient.plans = 0
		newClient.licensesAssigned = 50
		newClient.licensesUsed = 1
		newClient.deleted = false
        this.state.clientList.unshift(newClient);
        this.setState({clientList: this.state.clientList})
        db.collection("client").doc(newClient.companyID).set({
            logo: url,
            clientName: newClient.clientName,
            id: newClient.companyID,
            dateJoined: timestampDJ,
            dateLastLogged: timestampDLL,
            companyURL: 'vsam.dev',
            description: 'working on it',
            domain: newClient.domain,
            address: newClient.address,
            city: newClient.city,
            stateName: newClient.stateName,
            phone: newClient.phone + '',
            isActive: false,
            deactivated: false,
            emergencyPlans: [],
            users: [],
			licensesAssigned: 50,
			licensesUsed: 1,
			deleted: false
        })
        .then(() => console.log("User successfully added!!!"))
        .catch(()=> console.error("Error adding user"));
        const ts = new Date();
        // MM/DD/YYY
        const mmddyyyy = ts.getDate()  + "/" + (ts.getMonth()+1) + "/" + ts.getFullYear()
        const timeArr = ts.toLocaleTimeString().split(':');
        // HH:MM AM/PM
        let hhmm = timeArr[0] + ':' + timeArr[1] + timeArr[2].slice(2);
        let folderItem = {
            itemName: newClient.companyID,
            companyID: newClient.companyID,
            path: 'companies',
            fullName: newClient.clientName,
            fullPath: `companies/${newClient.companyID}`,
            uploadTime: mmddyyyy + ' ' + hhmm,
			folder: true
        }
        // adding the client folder to 'repoInfo'
        db.collection("repoInfo").doc(`companies-${newClient.companyID}`).set(folderItem)
        .then(() => console.log("Client folder successfully added the Repository"))
        .catch(()=> console.error("Error adding folder"));
    }


	addClient(newClient){
		// const dateJoined = new Date(user.metadata.creationTime);
        const timestampDJ = firebase.firestore.FieldValue.serverTimestamp();
        let image_url = '';
        console.log(newClient);

		const rowref = db.collection("client").add({
		    name: newClient.name,
		    // logo: url,
            dateAdded: timestampDJ,
            domain: newClient.domain,
            phone: newClient.phone + '',
            pointOfContact:{'first':newClient.pocfirst,'last':newClient.poclast,'phone':newClient.pocphone},
            isActive: false,
            deactivated: false,
			deleted: false
		})
		.then(function(docRef) {
		    console.log("Document written with ID: ", docRef.id);
		    if (newClient.logo !== '')  {
	            const uploadTask = storageRef.child(`client/${docRef.id}/${docRef.id}.jpeg`).put(newClient.logo);
	            uploadTask.on('state_changed', () => {}, (error) => console.log(error),
	                () => {
	                    // got the URL of the firebase storage image
	                    console.log("uploading");
	                    uploadTask.snapshot.ref.getDownloadURL().then((url) => {
	                    	console.log("uploading: "+url);
	                        image_url = url;
	                        db.collection('client').doc(docRef.id).update({logo:image_url});
	                    });
	                	// console.log('Successfully stored and retrieve the url from the image');
	            	});
	        }
		    db.collection('client').doc(docRef.id).update({id:docRef.id,logo:image_url});
		    // this.fetchClientData();
		})
		.catch(function(error) {
		    console.error("Error adding document: ", error);
		});
		setTimeout(() => {
				this.fetchClientData();
			}, 3000)

	}
	sendUsersMessage({companyID}) {
		// filter all the users to match the companyID
		let userList = this.state.userList.filter((user) => (user.companyID === companyID));
		let tokenArr = []; // array of fcmTokens
		for (let user of userList) {
			if (user.fcmToken !== undefined) {
				tokenArr.push(user.fcmToken)
			}
		}
		let res = window.prompt('Send a short message to all the users of the client:')
		if (res !== null && res.length > 0) {
			const message = {
				title: 'Message from the Client',
				message: res,
				userTokens: tokenArr
			}
			// execute the firebase function
			const globalNotification = firebase.functions().httpsCallable('sendGlobalNotification');
			globalNotification(message)
			.then((result) => console.log(result))
			.catch((error) => console.log('Error: ', error))
		}
	}
	/********************** Knowledge Base functions ***************************/
	downloadFile(data) {
		if (!data.folder) {
			// assume its a file
			const fileRef = storageRef.child(data.fullPath);
			fileRef.getDownloadURL().then((url) => {
				var xhr = new XMLHttpRequest();
				xhr.responseType = 'blob';
				xhr.onload = (event) => {
					var blob = xhr.response;
					const urlFromBlob = window.URL.createObjectURL(blob)
					const a = document.createElement('a');
					a.style.display = 'none';
					a.href = urlFromBlob;
					// the itemName you want
					a.download = data.itemName;
					document.body.appendChild(a);
					a.click();
					window.URL.revokeObjectURL(urlFromBlob);
				};
				xhr.open('GET', url);
				xhr.send();
			})
			.catch((error) => console.log(error));
		}
		if (data.folder) {
			// console.log('downloading a folder')
			// Create a reference under which you want to list
			var listRef = storageRef.child(data.fullPath)
			// Find all the prefixes and items.
			listRef.listAll().then(function(res) {
			res.prefixes.forEach(function(folderRef) {
				// console.log('Folder: ', folderRef)
				// All the prefixes under listRef.
				// You may call listAll() recursively on them.
			});
			res.items.forEach(function(itemRef) {
				// console.log('Item: ', itemRef)
				// All the items under listRef.
			});
			}).catch(function(error) {
			// Uh-oh, an error occurred!
			});

		}
	}
	deleteFile(row,splice = true) {
		if (row.folder) alert('Error: A folder can not be deleted')
		if (!row.folder) {
			// delete the file from the array
			if(splice){
				this.state.repository.splice(row.index, 1)
			}
			// update the state
			this.setState({repository: this.state.repository})
			// get the document name of the item that needs to be deleted
			let docName = (row.fullPath).split('/').join('-');
			// delete the document from Firestore
			db.collection("repoInfo").doc(docName).delete()
			.then(() => console.log("Document successfully deleted!"))
			.catch((error) => console.error("Error removing document: ", error));
			// Create a reference to the file in Storage to delete
			var file = storageRef.child(row.fullPath);
			// Delete the file
			file.delete()
			.then(() => console.log('file was successfully deleted'))
			.catch((error) => console.log(error));
		}
	}

	deleteFolder(row,splice = true) {

		if(splice){
			this.state.repository.splice(row.index, 1)
		}
		this.setState({repository: this.state.repository})
		let docName = (row.path).split('/').join('-');
		// mark the folder as deleted
		db.collection('repoInfo').doc(docName)
		.set({ deleted: true}, { merge: true });

		//check if Folder has files
		db.collection('repoInfo').get().then(querySnapshot => {
			const fileInfo = querySnapshot.docs.map(doc => doc.data());
			for (let item of fileInfo) {
				if(item.path == row.path){
					if(item.folder == false){
						this.deleteFile(item,false);
					}
					if(item.folder == true){
						this.deleteFolder(item,false);
					}
				}
			}
		})


	}


	editFolderName(row) {
		const user = firebase.auth().currentUser

		let oldFullPath; // used to discover all the folders current children
		let oldName = row.path.split('/').join('-'); // old folder document name
		const repoRef = db.collection("repoInfo").doc(oldName);
		// get the folder's doc to create the newly updated doc
		repoRef.get().then((doc) => {
			if (doc.exists) {
				// get all the keys from folder doc
				let values = doc.data()
				oldFullPath = values.fullPath.split('/'); //keep track of the old path
				if (!values.hasOwnProperty('alias')) {
					// assuming that the Object does already have an alias
					values.alias = values.fullPath;
				}
				values.itemName = row.folderName
				values.fullPath = this.state.path + '/' + row.folderName;
				values.lastUpdatedBy = user.email
				values.lastUpdatedDate = this.state.date
				// create a new document with the updated name
				const docName = (this.state.path).split('/').join('-') + '-' + row.folderName;
				db.collection("repoInfo").doc(docName).set(values)
				.then(() => console.log("Folder doc successfully added!"))
				.catch(()=> console.error("Error adding folder doc"));
				// change the name in the list
				let repository = this.state.repository;
				repository[row.index].itemName = row.folderName;
				// changes the full path in the table for navigation to child
				repository[row.index].fullPath = this.state.path + '/' + row.folderName;
				// update state
				this.setState({repository: repository})

			} else console.log("No such document!");
			// delete the doc of the old folder
			db.collection("repoInfo").doc(oldName).delete()
			.then(() => console.log("Document successfully deleted!"))
			.catch((error) => console.error("Error removing document: ", error));
		})
		// get the children's document information
		db.collection("repoInfo").get().then(querySnapshot => {
			const data = querySnapshot.docs.map(doc => doc.data());
			for (let item of data) {
				let itemPath = item.fullPath.split('/')
				// check if the curr Item is a child of the folder
				if (itemPath.length > oldFullPath.length) {
					let frontPath = itemPath.slice(0, oldFullPath.length)
					// check if the arrays are equal
					if (JSON.stringify(frontPath) == JSON.stringify(oldFullPath)) {
						// assuming that this doc is a child of the folder
						let childDoc = itemPath.join('-') // child document name
						var childRef = db.collection("repoInfo").doc(childDoc);
						// get the values from the child doc
						childRef.get().then((offspring) => {
							if (offspring.exists) {
								let newVals = offspring.data()
								// update the fullPath
								let newFullPath = newVals.fullPath.replace(row.itemName, row.folderName)
								newVals.alias = newVals.fullPath; // keep track of the Storage path
								newVals.fullPath = newFullPath;
								newVals.path = newVals.path.replace(row.itemName, row.folderName);
								newFullPath = newFullPath.split('/').join('-')
								// add the new updated doc to Firestore
								db.collection("repoInfo").doc(newFullPath).set(newVals)
								.then(() => {console.log("Child doc successfully added!")})
								.catch(()=> {console.error("Error adding child doc")});
							} else console.log("No such child document!");
							// delete the old doc from Firebase
							db.collection("repoInfo").doc(childDoc).delete()
							.then(() => console.log("Child successfully deleted!"))
							.catch((error) => console.error("Error removing child: ", error));
						})
					}
				}
			}
		})
	}
	addFolder(data) {
		if (this.state.uid.length > 0) {
			// in order to get the full name of the user
			var docRef = db.collection("user").doc(this.state.uid)
            docRef.get().then((doc) => {
                if (doc.exists) {
					let fullName = doc.data().first + ' ' + doc.data().last;
					const ts = new Date(); // get the current timestamp
					// MM/DD/YYY
					const mmddyyyy = ts.getDate()  + "/" + (ts.getMonth()+1) + "/" + ts.getFullYear()
					const timeArr = ts.toLocaleTimeString().split(':');
					// HH:MM AM/PM
					let hhmm = timeArr[0] + ':' + timeArr[1] + timeArr[2].slice(2);
					let docName = (this.state.path + '/' + data.itemName).split('/').join('-');
					let folderItem = {
						itemName: data.itemName,
						companyID: this.state.companyID,
						path: this.state.path,
						fullName: fullName,
						fullPath: this.state.path + '/' + data.itemName,
						uploadTime: mmddyyyy + ' ' + hhmm,
						folder: true,
					}
					// add the folderItem to the Repo array
					this.state.repository.push(folderItem);
					// update the state
					this.setState({repository: this.state.repository});
					// add the folderItem to Firestore
					db.collection("repoInfo").doc(docName).set(folderItem)
					.then(() => console.log("Folder successfully added!!!"))
					.catch(() => console.error("Error adding folder"));
				}
			})
		}
		else console.log('Please Log into your account')
	}

	addFileCB(fData, currTime, fullName, subFolderPath) {
		// set the file document name
		const docName = (subFolderPath + '-' + fData.itemName).split('/').join('-');
		let elementName = fData.itemName
		if (fData.type === "image/jpeg" || fData.type === 	"image/jpg" || fData.type === "imgage/jpeg") {
			elementName = fData.itemName.split('.')[0] + '.jpeg'
			fData.type = "image/jpeg"
		}
		if (fData.type === 	"image/png") {
			elementName = fData.itemName.split('.')[0] + '.png'
			fData.type = "image/png"
		}
		const fileDoc = {
			itemName: elementName,
			companyID: this.state.companyID,
			fullName: fullName,
			path: subFolderPath,
			fullPath: subFolderPath + '/' + elementName,
			uploadTime: currTime,
			folder: false,
			fileType: fData.type,
		};
		// add file to the client directory and update the state
		this.setState({
			repository: [fileDoc, ...this.state.repository],
			path: subFolderPath
		});
		// create a document in FireStore under repoInfo
		db.collection("repoInfo").doc(docName).set(fileDoc)
		.then(() => console.log("File successfully added!!!"))
		.catch(()=> console.error("Error adding file"));
		// adding the file to Storage
		storageRef.child(`${subFolderPath}/${elementName}`).put(fData.file)
		.then(() => console.log('Successfully added file to storage'))
		.catch((error) => console.log(error))
	}

	addFile(fData) {
		// console.log('addfile');
		if (this.state.uid.length > 0) {
			const ts = new Date(); // get the timestamp for the table
			// MM/DD/YYY
			const mmddyyyy = ts.getDate()  + "/" + (ts.getMonth()+1) + "/" + ts.getFullYear()
			const timeArr = ts.toLocaleTimeString().split(':');
			// MM/DD/YYY HH:MM AM/PM
			const currTime = mmddyyyy + ' ' + timeArr[0] + ':' + timeArr[1] + timeArr[2].slice(2);
			// get the user collection for the User's name
			const userRef = db.collection("user").doc(this.state.uid);
			// fetch the docs values
            userRef.get().then((doc) => {
                if (doc.exists) {
					// name of the user who uploaded the file
					const fullName = doc.data().first + ' ' + doc.data().last;
					// assume the user did not add subfolder
					this.addFileCB(fData, currTime, fullName, this.state.path)
				} else console.log("No such document!");
			})
			.catch((error) => console.log("Error getting document:", error));
        } else console.log('No user is signed in.')
	}
	// Currently updating the table using FireStore instead of Storage
	navigateToChild(row) {
		// getting all the items repoInfo
		db.collection("repoInfo").get().then(querySnapshot => {
			const repoInfo = querySnapshot.docs.map(doc => doc.data());
			let itemArr = [];
			let currPath;
			for (let item of repoInfo) {
				if (item.deleted) continue;
				if (item.fullPath === undefined) continue
				currPath = item.fullPath.split('/');
				currPath.pop();
				currPath = currPath.join('/')
				// ensuring that the correct files are displayed
				if (row.path === currPath) itemArr.push(item)
			}
			this.setState({
				repository: itemArr,
				path: row.path,
				viewFilteredList: false
			})
		})
	}
	navigateToParent(row) {
		if (row.path === '') {
			// assume user is viewing the companies Repo
			return;
		}
		if (this.state.role !== 'root' && row.path === 'companies') {
			// assume Super Admin is trying to view the companies Repo
			console.log('Only Root Admins can view the companies Repo')
			return;
		}
		// getting all the items repoInfo
		db.collection("repoInfo").get()
		.then(querySnapshot => {
			const repoInfo = querySnapshot.docs.map(doc => doc.data());
			let itemArr = [];
			let itemPath;

			for (let item of repoInfo) {
				if (item.deleted) continue;
				if (item.fullPath === undefined) continue
				itemPath = item.fullPath.split('/');
				itemPath.pop();
				itemPath = itemPath.join('/')
				// ensuring that only parent items are shown
				if (row.path === itemPath) itemArr.push(item)
			}
			this.setState({
				repository: itemArr,
				path: row.path,
				viewFilteredList: false
			 })
		})
	}
	viewSelectedRepo({path}) {
		db.collection("repoInfo").get().then(querySnapshot => {
			const repoInfo = querySnapshot.docs.map(doc => doc.data());
			let itemArr = [];
			let folderArr = []; // all the folders in every client directory used for upload file
			let currPath;
			console.log(this.state.role);
			for (let item of repoInfo) {
				/* assume that the item was marked as deleted and
				 * only display deleted folders for root users */
				if (item.deleted && this.state.role !== 'root') continue;
				if (item.folder && item.fullPath.split('/').length === 3) folderArr.push(item)
				if (item.fullPath === undefined ) continue
				currPath = item.fullPath.split('/');
				currPath.pop();
				currPath = currPath.join('/')
				// ensuring that the correct files are displayed
				if (path === currPath) itemArr.push(item)
			}
			itemArr = itemArr.sort((a,b) => {
				var nameA = a.itemName.toUpperCase();
				var nameB = b.itemName.toUpperCase();
				if (nameA < nameB) return -1
				if (nameA > nameB) return 1
			})
			this.setState({
				repository: itemArr,
				folderRepos: folderArr,
				path: path
			 })
		})
	}
	/**************************** Response Plans Functions **************************/
	createPlan(plan) {
		console.log(plan)
		const planRef = db.collection('emergencyPlans').doc();
		const planID = planRef.id
		let idx = this.state.groupList.findIndex((group) => group.name === plan.teamName)
		if (idx !== -1) {
			// assume a user group was selected
			let umap = {}
			for (let user of plan.users) {
				umap[user.id] = 1
			}
			for (let nuser of this.state.groupList[idx].users) {
				if (!(nuser.id in umap)) {
					umap[nuser.id] = 1
					plan.users.push(nuser)
				}
			}
		}
		var userRef = db.collection('users').doc(this.state.uid)
		const eplan = { // plan added to 'emergencyPlans' collection
			id: planID,
			name: plan.planName,
			companyID: this.state.companyID,
			isActive: true, // Active Column
			isPractice: plan.isPractice, // Practice Column (Drill)
			deactivated: false, //Enabled Column
			users: plan.users,
			tasks: [],
			titleList: plan.titleList,
			userStatus: plan.userStatus,
			leader: userRef,
			createdByRole: this.state.role,
			teamName: plan.teamName
		}
		db.collection("emergencyPlans").doc(planID).set(eplan)
		.then(() => console.log("Plan successfully added!!!"))
		.catch(() => console.error("Error adding plan"))
		eplan.leaderName = this.state.adminName // displays the leaderName in the table
		// set the title for the users column in the active checklist table
		let userArr = []
		for (let i = 0; i < eplan.users.length; i++) {
			let uid = eplan.users[i].id
			for (let j = 0; j < this.state.userList.length; j++) {
				if (this.state.userList[j].id === uid) {
					userArr.push(this.state.userList[j].name)
				}
				if (i === eplan.users.length -1 && j === this.state.userList.length - 1) {
					eplan.userNames = userArr.join(', ')
				}
			}
		}
		// update state for plans and tasks
		this.setState({
			planList: [...this.state.planList, eplan],
		})

		// Create copies of the tasks
		for (let i = 0; i < plan.tasks.length; i++) {
			let taskID = plan.tasks[i].id
			let idx = this.state.taskList.findIndex((task) => task.id === taskID)
			if (idx !== -1) {
				let currTask = this.state.taskList[idx]
				let tdata = {
					taskName: currTask.name,
					assignedRC: '',
					assignedUser: '',
					description: currTask.description,
					taskFromTemplate: true,
					planID: planID
				}
				this.addTask(tdata)

			}
		}


		if (this.state.role === 'root') {
			// change the companyID of root back to normal
			const rootRef = db.collection("user").doc(this.state.uid)
			rootRef.get().then((query) => {
				let data = query.data()
				this.setState({companyID: data.companyID})
			})
		}

		let userTokens = []; // array of user fcmTokens
		let tokensList = [];
		for (let i = 0; i < plan.users.length; i++) {
			let user = plan.users[i];
			user.get().then((query) => {
				let data = query.data();
				console.log(data)
				if (data.fcmToken !== undefined) {

					tokensList = Object.values(data.fcmToken);
				//console.log(tokensList)
					for(let token of tokensList)
					{
						if(token.length>0)
						{
							// assume the fcmToken is defined
							// userTokens.push(token);
							const erpData = {
								erpName: plan.planName,
								userTokens: token,
								erpRef: planID,
								isActive: true,
								isPractice: false,
							}

							console.log("plan-planName" + plan.planName)
							console.log(erpData)
							// execute the firebase function
							const alertERP = firebase.functions().httpsCallable('alertERPUsers');
							alertERP(erpData).then((result) =>
							{
								console.log(plan.planName)
									// add the notification log to firestore
									let curr = "Active: " + plan.planName
									if (plan.isPractice) {
										curr = "Drill: " +  + plan.planName
									}
									const logRef = db.collection('notificationHistory').doc();
									const log = {
										date: new Date(),
										message: curr,
										reference: planRef,
										users: plan.users
									}
								db.collection('notificationHistory').doc(logRef.id).set(log)
								.then(() => console.log('Successfully added the log'))
								.catch((error) => console.log('Error adding log: ', error))
							})
							.catch((error) => console.log('Error: ',error))
						}
					}
				}

				// if (i === plan.users.length - 1) {
				// 	// assume its the last element in the array



				// }
			})
		}
	}
	practiceToggle(row) {
		const index = row.index
		const planID = row.id
		const toggle = !(this.state.planList[index].isPractice)
		if (typeof this.state.planList[index] === 'object') {
			this.state.planList[index].isPractice = toggle
		}
		const plan = db.collection("emergencyPlans").doc(planID)
        plan.update({ isPractice: toggle })
        .then(() => console.log("Document successfully updated!"))
        .catch((error) => console.error("Error updating document: ", error))
	}
	activeToggle({index}) {
		const toggle = !(this.state.planList[index].isActive)
		let planList = this.state.planList
		planList[index].isActive = toggle
		this.setState({planList: planList})
		const planID = this.state.planList[index].id
		const plan = db.collection("emergencyPlans").doc(planID)
		if (toggle) {
			let userTokens = []; // array of user fcmTokens
			for (let i = 0; i < this.state.planList[index].users.length; i++) {
				let user = this.state.planList[index].users[i];
				user.get().then((query) => {
					let data = query.data();
					if (data.fcmToken !== undefined) {
						// assume the fcmToken is defined
						userTokens.push(data.fcmToken);
					}
					if (i === this.state.planList[index].users.length - 1) {
						console.log("Active Toggle: ", this.state.planList[index].name)
						// assume its the last element in the array
						const erpData = {
							erpName: this.state.planList[index].name,
							userTokens: userTokens,
							erpRef: planID,
							isActive: toggle,
							isPractice: this.state.planList[index].isPractice,
						}
						// execute the firebase function
						const alertERP = firebase.functions().httpsCallable('alertERPUsers');
						alertERP(erpData).then((result) => {
							// add the notification log to firestore
							let curr = result.data.message
							if (curr === undefined || curr === null) {
								curr = "Value is undefined"
							}
							const logRef = db.collection('notificationHistory').doc();
							const log = {
								date: new Date(),
								message: curr,
								reference: plan,
								users: this.state.planList[index].users
							}
							db.collection('notificationHistory').doc(logRef.id).set(log)
							.then(() => console.log('Successfully added the log'))
							.catch((error) => console.log('Error adding log: ', error))
						})
						.catch((error) => console.log('Error: ',error))
					}
				})
			}
		}
        plan.update({ isActive: toggle })
        .then(() => console.log("Document successfully updated!"))
        .catch((error) => console.error("Error updating document: ", error));
	}
	editPlan(row) {
		const planID = this.state.planList[row.index].id;
		// the doc reference is saved to each new task
		var ERPref = db.collection("emergencyPlans").doc(planID);
		// add new tasks to Firestore
		for (let currTask of row.newTasks) {
			let taskRef = db.collection('tasks').doc();
			row.taskList.push(taskRef);
			let taskID = taskRef.id;
			let etask = {
				id: taskID,
				name: currTask.taskName,
				emergencyPlan: ERPref,
				assignedUser: '',
				dateCompleted: '',
				isComplete: false,
				description: currTask.description,
				taskUser: '',
				assignedRC: currTask.assignedRC
			}
			// TODOOOO on edit plan is messing up the assignedUser is the name instead of UID
			if (currTask.assignedUser.length > 0) {
				let idx = this.state.userList.findIndex((user) => user.name === currTask.assignedUser)
				let UID = this.state.userList[idx].id
				// assume assigned user was selected for the task
				let userRef = db.collection('users').doc(UID)
				// in order to get the name of the assignedUser
				userRef.get().then((query) => {
					let data = query.data();
					let fullName = data.first + ' ' + data.last
					etask.assignedUser = userRef
					etask.taskUser = fullName
					db.collection("tasks").doc(taskID).set(etask)
					.then(() => console.log("Task successfully added!!!"))
					.catch(() => console.error("Error adding task"));
				})
			} else {
				// assume assigned user was NOT selected for the task
				db.collection("tasks").doc(taskID).set(etask)
				.then(() => console.log("Task successfully added!!!"))
				.catch(() => console.error("Error adding task"))
			}
		}
		let planList = this.state.planList
		planList[row.index].name = row.planName
		planList[row.index].users = row.userList
		planList[row.index].tasks = row.taskList
		planList[row.index].titleList = row.titleList
		this.setState({planList: planList})
		// make changes to existing tasks
		for (let key in row.taskNameChangeMap) {
			let taskRef = db.collection('tasks').doc(key)
			// update the name of the task
			taskRef.update({ name: row.taskNameChangeMap[key] })
			.then(() => console.log("Document successfully updated!"))
        	.catch((error) => console.error("Error updating document: ", error));
		}
		// Update the ERP Document
        ERPref.update({
			userStatus: row.userStatus,
			name: row.planName,
			tasks: row.taskList,
			titleList: row.titleList,
			users: row.userList,
		})
        .then(() => console.log("Document successfully updated!"))
        .catch((error) => console.error("Error updating document: ", error));
	}
	//this function is deppreciated and no more used
	deletePlan(row) {
		const planID = this.state.planList[row.index].id;
		db.collection("emergencyPlans").doc(planID).delete()
		.then(() => console.log("Document successfully deleted!"))
		.catch((error) => console.error("Error removing document: ", error))
		this.state.planList.splice(row.index, 1)
		this.setState({planList: this.state.planList});
	}

	deactivatePlan(row) {
		const planID = this.state.planList[row.index].id;
		const checklist = db.collection("emergencyPlans").doc(planID)
		checklist.update({
				deactivated: true,
				dateDeactivated: new Date()
        		 }) //archived but no deleted completely
        	.then(() => console.log("Checklist Archived successfully!"))
			.catch((error) => console.error("Error updating document: ", error))
	}

	/******************************** Task List Functions *************************/
	addTask(tdata) {
		// console.log(this.state.planID)
		// console.log(tdata.subPlanPager)


		// add task to tasks collection and add the task to the ERP
		var ERPref
		let planID
		if (tdata.taskFromTemplate !== undefined && tdata.taskFromTemplate !== null && tdata.taskFromTemplate) {
		    //No sure why this if stmt exists and when it gets fulfills
			// task from template
			planID = tdata.planID
			ERPref = db.collection("emergencyPlans").doc(tdata.planID)
		}
		else if(tdata.subPlanPager === 2){
			planID = this.state.planID
			var ERPref = db.collection("templates").doc(this.state.planID)
		}
		else {
			planID = this.state.planID
			var ERPref = db.collection("emergencyPlans").doc(this.state.planID)
		}

		const taskRef = db.collection('tasks').doc()
		const taskID = taskRef.id;
		var etask = {
			id: taskID,
			name: tdata.taskName,
			emergencyPlan: ERPref,
			assignedUser: '',
			assignedRC: tdata.assignedRC,
			dateCompleted: '',
			isComplete: false,
			description: tdata.description,
			taskUser: ''
		}
		if (tdata.assignedUser.length > 0) {
			// assume assigned user was selected
			let userRef = db.collection('users').doc(tdata.assignedUser)
			userRef.get().then((query) => {
				let data = query.data();
				let fullName = data.first + ' ' + data.last
				etask.assignedUser = userRef
				etask.taskUser = fullName
				// update state of the taskList
				this.setState({
					taskList: [...this.state.taskList, etask],
					totalNum: this.state.totalNum + 1
				})
				// console.log(this.state.taskList)
				db.collection("tasks").doc(taskID).set(etask)
				.then(() => console.log("Task successfully added!!!"))
				.catch(() => console.error("Error adding task"));
			})
		} else {
			// assume assigned user was NOT selected
			this.setState({
				taskList: [...this.state.taskList, etask],
				totalNum: this.state.totalNum + 1
			})
			db.collection("tasks").doc(taskID).set(etask)
			.then(() => console.log("Task successfully added!!!"))
			.catch(() => console.error("Error adding task"));
		}

		console.log(this.state.planList)
		// Update the ERP Document append the task to the tasks
        ERPref.update({ tasks: firebase.firestore.FieldValue.arrayUnion(taskRef) })
        .then(() => {
			// on creation, update the state of # of tasks
			let idx = this.state.planList.findIndex((plan) => plan.id === planID)
			if (idx !== -1) {

				for(let e of this.state.planList[idx].tasks){
					console.log(e.id)
					console.log(taskID)
					console.log("")

				}
				// this.state.planList[idx].tasks.push(taskRef)
				this.setState({planList: this.state.planList})
				console.log("Document successfully updated!")
			} else {
				console.log("Failed to find active checklist")
			}
		})
		.catch((error) => console.error("Error updating document: ", error))


	}
	editTask(row) {
		const taskID = this.state.taskList[row.index].id
		const taskRef = db.collection('tasks').doc(taskID)
		const taskList = this.state.taskList
		taskList[row.index].name = row.taskName
		taskList[row.index].description = row.description
		taskList[row.index].taskUser = 'N/A'
		taskList[row.index].assignedRC = row.assignedRC
		if (row.assignedUser.length > 0) {
			// assume assigned user is selected
			const userRef = db.collection('users').doc(row.assignedUser)
			let idx = this.state.userList.findIndex((user) => user.id === row.assignedUser)
			let fullName = this.state.userList[idx].name
			// update state
			taskList[row.index].taskUser = fullName;
			this.setState({taskList: taskList})
			// update the firebase document
			taskRef.update({
				name: row.taskName,
				description: row.description,
				assignedUser: userRef,
				assignedRC: row.assignedRC,
				taskUser: fullName,
			})
			.then(() => console.log("Document successfully updated!"))
			.catch((error) => console.error("Error updating document: ", error))
		} else {
			this.setState({taskList: taskList})
			// update the firebase document
			taskRef.update({
				name: row.taskName,
				description: row.description,
				assignedUser: '',
				assignedRC: row.assignedRC,
				taskUser: ''
			})
			.then(() => console.log("Document successfully updated!"))
			.catch((error) => console.error("Error updating document: ", error));
		}
	}
	deleteTask(row) {
		const ERPref = db.collection('emergencyPlans').doc(this.state.planID)
		ERPref.get().then((query) => {
			const data = query.data()
			if (data.isActive) {
				// assume the ERP is active
				alert('Cannot Delete a Task from an Active Plan ')
			} else {
				// assume the plan is deactivated
				this.state.taskList.splice(row.index)
				let numCompleted = this.state.numCompleted;
				if (row.isComplete) {
					// assume the task was completed
					numCompleted = numCompleted - 1;
				}
				this.setState({
					taskList: this.state.taskList,
					totalNum: this.state.totalNum - 1,
					numCompleted: numCompleted
				})
				const taskRef = db.collection('tasks').doc(row.id);
				// update the ERP tasks array of task document references
				ERPref.update({ tasks: firebase.firestore.FieldValue.arrayRemove(taskRef) })
				.then(() => console.log("Successfully removed the Task from the Checklist"))
				.catch((error) => console.error("Error updating document: ", error));
				// delete the task Document
				taskRef.delete()
				.then(() => console.log("Document successfully deleted!"))
				.catch((error) => console.error("Error removing document: ", error));
			}
		})
	}
	handleTaskStatusChange(row) {
		const status = !(row.isComplete);
		let taskList = this.state.taskList;
		taskList[row.index].isComplete = status;
		let numCompleted = this.state.numCompleted;
		if (status) {
			// assume the task has been marked as completed
			numCompleted += 1;
		} else if (!status) {
			numCompleted -= 1;
		}
		// console.log('Assigned RC: ', row.assignedRC)
		if (row.assignedRC !== '') {
			// assume Task has an assigned Roll Call
			let idx = this.state.rcList.findIndex((rollCall) => rollCall.name === row.assignedRC)
			// console.log(this.state.rcList)
			// console.log('index: ', idx)
			if (idx >= 0 && !this.state.rcList[idx].isRCActive) {
				// assume Roll Call exisiting and is not active
				this.rcActiveToggle({index: idx})
			}
		}
		this.setState({
			taskList: taskList,
			numCompleted: numCompleted
		})
		var task = db.collection("tasks").doc(this.state.taskList[row.index].id)
        task.update({ isComplete: status })
        .then(() => console.log("Document successfully updated!"))
		.catch((error) => console.error("Error updating document: ", error))
		if (status === true) {
			var relatedItems = []
			relatedItems.push(this.state.taskList[row.index].emergencyPlan)
			relatedItems.push(db.collection("user").doc(this.state.uid))
			const taskLogID = db.collection("taskLog").doc().id
			let taskLog = {
				date: new Date(),
				id: task,
				logType: "taskCompletionChange",
				message: this.state.adminName + " has changed task " + this.state.taskList[row.index].name + " to complete.",
				relatedItems: relatedItems

			}
			db.collection("taskLog").doc(taskLogID).set(taskLog)
			.then(() => console.log("TaskLog successfully added!!!"))
			.catch(() => console.error("Error adding taskLog"));
		}
	}
	handleUserCheckedInChange(row) {
		const status = !(row.isCheckedIn);
		let rollCallList = this.state.rollCallList;
		rollCallList[row.index].isCheckedIn = status;
		let numCompleted = this.state.numCompleted;
		if (status) {
			numCompleted += 1;
		} else {
			numCompleted -= 1;
		}
		this.setState({
			rollCallList: rollCallList,
			numCompleted: numCompleted,
		});
		var usersUpdate = {};
		usersUpdate[`userStatus.${row.UID}.isCheckedIn`] = status;
		const ERP = db.collection("emergencyPlans").doc(this.state.planID);
        ERP.update(usersUpdate)
        .then(() => console.log("Document successfully updated!"))
        .catch((error) => console.error("Error updating document: ", error));


        // this.fetchRollCallData();
	}

	sendUserReminder(action){

		let userTokens, tokensList = [],info = [];


   		//get the fcm tokens list and user info from users collection using the user id
	   	if(action.row !== null){
	   		console.log('if')
	   		let idx = this.state.userList.findIndex((user) => user.id === action.row.user)
	    	info.push(this.state.userList[idx]);
	    	console.log(info)
	   	}
	   	else if(action.row === null && action.users !== undefined){
	   		console.log('else')
	   		for(let user of action.users)
	   		{
	   			info.push(user);
	   		}

	   	}



	   	for(let user of info)
	   	{
			 // console.log(user)

   			if (user['fcmToken'] !== undefined && user['fcmToken'] !== null )
			{
				// console.log(user['first'])
				// console.log(user['fcmToken'])
				// assume the fcmToken is defined
				tokensList = Object.values(user.fcmToken);
				//console.log(tokensList)
				for(let token of tokensList)
				{
					if(token.length>0)
					{
						let userRef = db.collection('users').doc(user['id'])

					    let log = {
							date: new Date(),
							message: 'Please fill out your heath screening form.',
							users: userRef,
							reference: null
						}

	  					const data =
						{ // sent to firebase func
							name: user['first'],
							tokens: token,
							title: 'Reminder for filling out your Health Form',
							message: "Please fill out your heath screening form",
							collection: 'users',
							reference: user['id'],
							id: user['id'],
						}

						// execute the firebase function
						const send = firebase.functions().httpsCallable('alertUserByAdmin')
						send(data)
						.then((result) => console.log(result))
						.catch((error) => console.log('Error: ',error))

						var logRef = db.collection('notificationHistory').doc()
						db.collection('notificationHistory').doc(logRef.id).set(log)
					}
				}

			}
		}

   }



	addUserGroup(row) {
		// array of user doc refs
		let userArr = [];
		for (let user of row.users) {
			// user reference
			let uref = db.collection('users').doc(user.id)
			userArr.push(uref);
		}
		// get the index of the leader
		const idx = row.users.findIndex((user) => user.name === row.leader);
		db.collection('users').doc(row.users[idx].id).get().then((query) => {
			const data = query.data();
			const email = data.email;
			const phone = data.phone;
			const fullName = data.first + ' ' + data.last
			var userGroup = {
				name: row.name,
				leader: fullName, //leader's name
				email: email, // leader's email
				phone: phone, // leader's phone number
				companyID: this.state.companyID,
				users: userArr
			}
			const gref = db.collection('userGroups').doc();
			db.collection("userGroups").doc(gref.id).set(userGroup)
			.then(() => console.log("User Group successfully added!!!"))
			.catch(() => console.error("Error adding UserGroup"));
			userGroup.id = gref.id;
			this.setState({groupList: [userGroup, ...this.state.groupList]})
		})
	}
	deleteUserGroup(row) {
		this.state.groupList.splice(row.index, 1)
		this.setState({groupList: this.state.groupList});
		db.collection('userGroups').doc(row.id).delete()
		.then(() => console.log('Successfully removed the User Group'))
		.catch((error) => console.log('Error removing the User Group', error))
	}
	editUserGroup(row) {
		let userArr = []; // array of user doc references
		for (let user of row.users) {
			// user reference
			let uref = db.collection('users').doc(user.id)
			userArr.push(uref);
		}
		// get the index of the leader
		let idx = row.users.findIndex((user) => user.name === row.leader);
		// get the document reference of the user
		db.collection('users').doc(row.users[idx].id).get().then((query) => {
			let data = query.data();
			let email = data.email;
			let phone = data.phone;
			let fullName = data.first + ' ' + data.last
			// updateState
			let groupList = this.state.groupList;
			groupList[row.index].name = row.name;
			groupList[row.index].email = email;
			groupList[row.index].phone = phone;
			groupList[row.index].users = userArr;
			this.setState({ groupList: groupList })
			// get the groupID
			const groupID = this.state.groupList[row.index].id;
			// update the group reference
			const groupRef = db.collection('userGroups').doc(groupID)
			groupRef.update({
				name: row.name,
				email: email,
				leader: fullName,
				phone: phone,
				users: userArr
			})
			.then(() => console.log("Document successfully updated!"))
        	.catch((error) => console.error("Error updating document: ", error));
		})
	}
	filterTable(action) {
		console.log("action:"+action.type);
		var fList = [];
		if (action.type === 'FILTER_USER_GROUPS') {
			fList = this.state.groupList.filter((group) => (group.name.search(action.value) !== -1) )
		} else if (action.type === 'FILTER_USER_LIST') {
			if (this.state.viewDeletedUsers) {
				fList = this.state.deletedUsers.filter((user) => (user.name.search(action.value) !== -1) )
			} else {
				fList = this.state.userList.filter((user) => (user.name.search(action.value) !== -1) )
			}
		}else if(action.type === 'FILTER_COMPLETED_FORM_USER_LIST'){
			let tmpUsers = [];
			this.state.cFormsList.map((user) => {
				if(user.name === action.value)
					tmpUsers.push(user)
			})
			fList = tmpUsers

		} else if (action.type === 'FILTER_CLIENT_LIST') {
			let filteredclient = [];
			this.state.clientList.map((client) => {
				if(client.clientName === action.value){
					console.log(client)
					filteredclient.push(client);
				}

			})
			fList = filteredclient;
			// fList = this.state.clientList.filter((client) => ( client.clientName.search(action.value) !== -1) )
		} else if (action.type === 'FILTER_ROLL_CALL_LIST') {
			if (action.filter === '') {
				fList = this.state.rcUserList;
			} else {
				fList = this.state.rcUserList.filter((user) => (user.status === action.filter) )
			}
			this.setState({
				viewFilteredList: true,
				filteredList: fList
			})
			return;
		}else if (action.type === 'FILTER_EVENT_LIST') {
			if (action.value === '') {
				fList = this.state.eventList;
			} else {
				fList = this.state.eventList.filter( (item) => (item.name.toLowerCase().search(action.value.toLowerCase()) >= 0 ) )
			}
			this.setState({
				viewFilteredList: true,
				filteredList: fList
			})
			return;
		}else if (action.type === 'FILTER_CATEGORY_LIST') {
			if (action.value === '') {
				fList = this.state.categories;
			} else {
				fList = this.state.categories.filter( (item) => (item.name.toLowerCase().search(action.value.toLowerCase()) >= 0 ) )
			}
			this.setState({
				viewFilteredList: true,
				filteredList: fList
			})
			return;
		}else if(action.type === 'FILTER_EVENT_BY_OWNER'){
			console.log(this.state.eventList);
			console.log(action.value);
			if (action.value === '') {
				fList = this.state.eventList;
			} else {
				this.state.eventList.map((item) => {
					if(item.owner !== undefined && item.owner !== '' &&  item.owner !== null){
						// item.owner.get().then((doc)=>{
							// if(doc.data()){
								// if(doc.data().role === 'business'){
									if(item.owner.id ===  action.value){
										fList.push(item);
									}
								// }
							// }
						// })
					}
				} )

			}

			setTimeout(() => {
				this.setState({
					viewFilteredList: true,
					filteredList: fList
				},()=>{console.log(fList);})
			}, 1500)

			return;

		} else if (action.type === 'FILTER_CHECK_LIST') {
			fList = this.state.planList.filter((plan) => (plan.name.search(action.value) !== -1))
		}else if(action.type === 'FILTER_CHECK_LIST_BY_COMPANYID'){
				fList = this.state.planList.filter((plan) => (plan.companyID.search(action.value) !== -1))
		}
		else if(action.type === 'FILTER_ROLLCALL_LIST_BY_COMPANYID'){
				fList = this.state.rcList.filter((plan) => (plan.companyID.search(action.value) !== -1))
		}
		else if(action.type === 'FILTER_HEALTHSCREENREPORT_BY_DATE_RANGE'){
			if(action.startdate && action.lastdate){


				let sd = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(action.startdate)
				sd = Date.parse(sd);
				sd = sd/1000;
				// console.log("sd: "+sd)
				let ld = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(action.lastdate)
				ld = Date.parse(ld);
				ld = ld/1000;
				// console.log("ld: "+ld)
			    let tempusers = [];
				 this.state.cFormsList.map(item =>
				{


				 		if(item.date !== undefined && item.date !== null){
				 			if(item.date.seconds !== undefined || item.date.seconds !== null)
					 			if(item.date.seconds >= sd && item.date.seconds <= ld){
					 				tempusers.push(item)
					 			}
				 		}

				})

				 fList = tempusers

			}
		}
		else if(action.type === 'FILTER_LIBRARY_BY_DATE_RANGE'){
			if(action.startdate && action.lastdate){


				let sd = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(action.startdate)
				// console.log("sd: "+sd)
				let ld = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(action.lastdate)
				// console.log("ld: "+ld)
			  let temprepo = [];
				 this.state.repository.map(repo =>
				{
					// console.log(repo)
				 		if(repo.uploadTime!== undefined){
				 			if(repo.uploadTime >= sd && repo.uploadTime <= ld){
				 				temprepo.push(repo)
				 			}
				 		}

				})

				 // console.log(temprepo)
				 fList = temprepo
				 // console.log(fList.length);
			}
		}
		else if (action.type === 'FILTER_CHECK_LIST_BY_DATE_RANGE') {
			if(action.startdate && action.lastdate){
				let sd = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(action.startdate)
				sd = Date.parse(sd);
				sd = sd/1000;
				let ld = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(action.lastdate)
				ld = Date.parse(ld);
				ld = ld/1000;
				// console.log("startdate: "+sd)
				// console.log("lastdate: "+ld)
				// console.log(this.state.planList[11])
				// console.log(this.state.planList[11].dateActivated.seconds)
				// console.log(this.state.planList[11].dateDeactivated.seconds)
				// if(this.state.planList[11].dateActivated.seconds >= sd && this.state.planList[11].dateDeactivated.seconds <= ld)
				// 	console.log("Yes,dateDeactivated <= last date in the range and >= startdate")
				// else
				// 	console.log("No,dateDeactivated > last date in the range")
				// // this.state.planList.map(plan => {console.log(plan)})
				// fList = this.state.planList.filter(function(plan, sd, ld){
				// 	if(plan.dateActivated !== undefined)
				// 	{
				// 		if(plan.dateDeactivated !== undefined){
				// 			if(plan.dateActivated.seconds >= sd && plan.dateDeactivated.seconds <= ld){
				// 				console.log(plan);
				// 				console.log(sd);
				// 				console.log(ld);
				// 				return plan;
				// 			}
				// 		}
				// 	}
				// })
				// 			    fList = this.state.planList.filter(plan => plan.dateActivated.seconds >= sd && plan.dateDeactivated.seconds <= ld)
				let templan = [];
				 this.state.planList.map(plan =>
				{
				 		if(plan.dateActivated !== undefined && plan.dateDeactivated !== undefined){
				 			if(plan.dateActivated.seconds >= sd && plan.dateDeactivated.seconds <= ld){
				 				templan.push(plan)
				 			}
				 		}

				})
				 // console.log(templan)
				 fList = templan
				 // console.log(fList.length);
			}
		}else if (action.type === 'FILTER_ROLLCALL_LIST_BY_DATE_RANGE') {
			if(action.startdate && action.lastdate){
				let sd = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(action.startdate)
				sd = Date.parse(sd);
				sd = sd/1000;
				// console.log(sd);
				let ld = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(action.lastdate)
				ld = Date.parse(ld);
				ld = ld/1000;
				// console.log(ld);
				let temprc = [];
				 this.state.rcList.map(rc =>
				{
					// console.log(rc);

				 		if(rc.dateActivated !== undefined){

				 			if(rc.dateActivated.seconds >= sd && rc.dateActivated.seconds <= ld){
				 				temprc.push(rc)
				 			}
				 		}

				})
				 // console.log(templan)
				 fList = temprc
				 // console.log(fList.length);
		}
		}else if (action.type === 'FILTER_ROLLCALL_LIST') {
			fList = this.state.rcList.filter((rollCall) => (rollCall.name.search(action.value) !== -1) )
		} else if (action.type === 'FILTER_TEMPLATE_LIST') {
			fList = this.state.tmplList.filter((tmpl) => (tmpl.name.search(action.value) !== -1) )
		} else if (action.type === 'FILTER_TASK_LIST') {
			fList = this.state.taskList.filter((task) => (task.name.search(action.value) !== -1) )
		} else if (action.type === 'FILTER_LIBRARY_LIST') {
			fList = this.state.repository.filter((repo) => (repo.itemName.search(action.value) !== -1) )
		}
		this.setState({
				viewFilteredList: true,
				filteredList: fList
			 })

	}
	handleToggleMenu() {
		let status = !this.state.toggleMenu;
		this.setState({toggleMenu: status})
	}
	/** Params: rcName {String} name of the current Roll Call
	 * 			uid {String} unique ID of the current user
	  */
	changeStatusColor(uid, rcName) {
		/*****
		 * There are 3 things I have to update
		 * 1) the rcList state -> an array of RollCalls
		 * 2) the Roll Call stored on firebase -> an object
		 * 3) the rcUserList -> an array of Users (in case the user is view the list)
		 */
		let rcList = this.state.rcList;
		let rcID;
		let idx; // idx to update the rcList element
		for (let i = 0; i < rcList.length; i++) {
			let rc = rcList[i];
			if (rc.name === rcName) {
				// assume its the current Roll Call item
				if (rc.users[uid].status === 'yellow') {
					// assume the status is yellow
					rc.users[uid].status = 'red';
				}
				rcID = rc.id; // get the id of the roll call
				idx = i;
				break;
			}
		}
		// if the rcUserList is open to the current
		// TODOOOOOOOOOOOOOOOOOOOOOOOO make sure youre viewing the correct Roll Call
		let rcUserList = this.state.rcUserList;
		for (let user of rcUserList) {
			if (user.uid === uid && user.status === 'yellow') {
				// assume the current user has a yellow status
				user.status = 'red'
				// update the progress bars
				this.state.numYellow -= 1;
				this.state.numRed += 1;
				break;
			}
		}
		// updates the Roll Call Firebase doc
		if(rcID)
		{
			var rollCall = db.collection('rollCalls').doc(rcID);
			rollCall.update({ users: rcList[idx].users })
			.then(() => console.log('Successfully updated the Roll Call'))
			.catch((error) => console.log('Error: ', error))
			this.setState({
				rcUserList: rcUserList,
				rcList: rcList,
				numYellow: this.state.numYellow,
				numRed: this.state.numRed
			})
			setTimeout(() => {
				this.fetchPlanData()
			}, 10 * 1000)
	    }
	}
	createRollCallCB(url, action, rcID) {
		let isRCActive = false
		if (action.isRCActive !== undefined && action.isRCActive !== null && action.isRCActive) {
			// assume roll call should be active on creation
			isRCActive = action.isRCActive
			isRCActive = true
		}
		const rollCall = {
			name: action.name,
			users: action.userMap,
			isRCActive: isRCActive,
			withNotifications: action.withNotifications,
			rcDescription: action.rcDescription,
			companyID: this.state.companyID,
			createdByRole: this.state.role,
			dateActivated: new Date(),
			isArchived: false,
			hasTimedOut: false,
			type: action.flashType
		}
		if (url === '') {
            // assume logo was NOT uploaded
			rollCall.logo = image
        } else {
            // assume logo was uploaded
            rollCall.logo = url
		}
		rollCall.id = rcID // need the id in the UI state
		this.state.rcList.unshift(rollCall);
		this.setState({rcList: this.state.rcList})
		db.collection('rollCalls').doc(rcID).set(rollCall)
		.then(() => console.log("Roll Call successfully added!!!"))
		.catch(() => console.error("Error adding Roll Call"))
		let userTokens = []; // array of user fcmTokens
		let userArr = [] // array of user doc refs
		let count = 0
		for (let rcUser in action.userMap) {
			let userRef = db.collection('users').doc(rcUser)
			userArr.push(userRef)
			let idx = this.state.userList.findIndex((curr) => curr.id === rcUser)
			let data = this.state.userList[idx]
			if (data.fcmToken !== undefined) {
				// assume the fcmToken is defined
				userTokens.push(data.fcmToken);
			}
			if (count === Object.keys(action.userMap).length -1) {
				// assume its the last element in the array
				const rcData = { // sent to firebase func
					name: action.name,
					users: userTokens,
					isActive: isRCActive,
					id: rcID,
					message: (isRCActive) ? "Please check-in to your Roll Call." : "Roll Call is deactivated."
				}
				// execute the firebase function
				const alertRollCall = firebase.functions().httpsCallable('alertRollCallUsers')
				alertRollCall(rcData)
				.then((result) => console.log(result))
				.catch((error) => console.log('Error: ',error))
			}
			count += 1
		}
		if (this.state.role === 'Root') {
			// change the companyID of root back to normal
			const rootRef = db.collection("user").doc(this.state.uid)
			rootRef.get().then((query) => {
				let data = query.data()
				this.setState({companyID: data.companyID})
			})
		}
		// adds to the users notification history
		var rollRef = db.collection('rollCalls').doc(rcID)
		let log = {
			date: new Date(),
			message: 'Roll Call: '+ action.name + ' was activated.',
			users: userArr,
			reference: rollRef

		}
		var logRef = db.collection('notificationHistory').doc()
		db.collection('notificationHistory').doc(logRef.id).set(log)
	}
	createRollCall(action) {
		var rollRef = db.collection('rollCalls').doc();
		const rcID = rollRef.id;
		let idx = this.state.groupList.findIndex((group) => group.name === action.teamName)
		if (idx !== -1) {
			// assume a user group was selected
			let umap = {}
			for (let user in action.userMap) {
				umap[user] = 1
			}
			for (let nuser of this.state.groupList[idx].users) {
				if (nuser.id !== undefined && !(nuser.id in umap)) {
					umap[nuser.id] = 1
					// console.log("actionMap: ", action.userMap)
					// console.log(nuser.id)
					// get the name of the curr user
					let i = this.state.userList.findIndex((curr) => curr.id === nuser.id)
					if (i !== -1) {
						let item = { name: this.state.userList[i].name }
						action.userMap[nuser.id] = item
					}
				}
			}
		}
		// {array of objects} rollCall name, update_dt and users array of uids
		var activatedRollcalls = []
		if (localStorage.getItem('activatedRollcalls') !== null) {
			if (localStorage.getItem('activatedRollcalls').length > 0) {
				activatedRollcalls = JSON.parse(localStorage.getItem('activatedRollcalls').toString())
			}
		}
		let currRollCall = {
			name: action.name,
			users: [],
			update_dt: new Date().getTime() / 1000 + (60 * 20)
		}
		for (let user in action.userMap) {
			currRollCall.users.push(user)
			action.userMap[user].isCheckedIn = false;
			action.userMap[user].status = 'yellow';
		}
		activatedRollcalls.push(currRollCall)
		// console.log(activatedRollcalls)
		localStorage.setItem('activatedRollcalls', JSON.stringify(activatedRollcalls))
		if (action.logo === '') {
			// assume logo was NOT uploaded
			this.createRollCallCB(action.logo, action, rcID)
		} else {
			// assume logo was uploaded
			// creates a child directory called the UID, and the file is placed inside this directory
			const uploadTask = storageRef.child(`rollCalls/${rcID}/${rcID}.jpeg`).put(action.logo)
			uploadTask.on('state_changed', () => {}, (error) => console.log(error),
				() => {
					// got the URL of the firebase storage image
					uploadTask.snapshot.ref.getDownloadURL().then((url) => {
						this.createRollCallCB(url, action, rcID)
					});
				console.log('Successfully stored and retrieve the url from the image');
			});
		}
	}
	// can only edit the RC name, # of users, description and notification setting
	editRollCall(action) {
		// TODO: make sure youre editing the correct item (filtered or not)
		let rcID = this.state.rcList[action.index].id;
		// status and isCheckedIn cannot be updated here
		let updatedMap = {};
		let userMap = this.state.rcList[action.index].users;
		for (let user in action.userMap) {
			// go through all the users in the edited user map
			if (!(user in userMap)) {
				// assume its a new user
				updatedMap[user] = {
					name: action.userMap[user].name,
					isCheckedIn: false,
					status: 'yellow'
				}
			} else {
				// assume user already exists in RC
				updatedMap[user] = {
					name: userMap[user].name,
					isCheckedIn: userMap[user].isCheckedIn,
					status: userMap[user].status
				}
			}
		}
		let rcList = this.state.rcList;
		rcList[action.index].name = action.name;
		rcList[action.index].users = updatedMap;
		rcList[action.index].withNotifications = action.withNotifications;
		rcList[action.index].rcDescription = action.rcDescription
		// update the Roll Call array of roll Call references
		const rollCallRef = db.collection('rollCalls').doc(rcID);
		rollCallRef.update({
			name: action.name,
			users: updatedMap,
			withNotifications: action.withNotifications,
			rcDescription: action.rcDescription
		})
		.then(() => {
			if (action.logo !== '') {
				// assume that the user changed the logo
                const uploadTask = storageRef.child(`rollCalls/${rcID}/${rcID}.jpeg`).put(action.logo);
                uploadTask.on('state_changed', () => {}, (error) => console.log(error),
                    () => {
                        // got the URL of the firebase storage image
                        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
							rcList[action.index].logo = url
							this.setState({ rcList: rcList });
                            // update the url stored in firebase
							const rollCall = db.collection("rollCalls").doc(rcID);
                            rollCall.update({ logo: url })
                            .then(() => console.log("Document successfully updated!"))
                            .catch((error) => console.error("Error updating document: ", error));
                        });
                        console.log('Successfully stored and retrieve the url from the image');
                    });
			} else {
				this.setState({ rcList: rcList });
			}
		})
		.catch((error) => console.error("Error updating document: ", error))


		let userTokens = []; // array of user fcmTokens
		let userArr = [] // array of user doc refs
		let count = 0
		let isRCActive = this.state.rcList[action.index].isRCActive // active status of flash
		for (let rcUser in updatedMap) {
			let userRef = db.collection('users').doc(rcUser)
			userArr.push(userRef)
			let idx = this.state.userList.findIndex((curr) => curr.id === rcUser)
			let data = this.state.userList[idx]
			if (data.fcmToken !== undefined) {
				// assume the fcmToken is defined
				userTokens.push(data.fcmToken);
			}
			if (count === Object.keys(updatedMap).length -1) {
				// assume its the last element in the array
				const rcData = { // sent to firebase func
					name: action.name + " has been updated",
					users: userTokens,
					isActive: isRCActive,
					id: rcID,
					// message: (isRCActive) ? "Please check-in to your Roll Call." : "Roll Call is deactivated."
					message: action.rcDescription
				}
				// execute the firebase function
				const alertRollCall = firebase.functions().httpsCallable('alertRollCallUsers')
				alertRollCall(rcData)
				.then((result) => console.log(result))
				.catch((error) => console.log('Error: ',error))
			}
			count += 1
		}
	}
	deleteRollCall(rcID) {
	// 	let idx = this.state.rcList.findIndex((curr) => curr.id === rcID)
	// 	if (idx !== -1) {
	// 		let id = this.state.rcList[idx].id;
	// 		db.collection('rollCalls').doc(id).delete()
	// 		.then(() => console.log('Successfully removed the Roll Call'))
	// 		.catch((error) => console.log('Error removing the Roll Call', error))
	// 		this.state.rcList.splice(idx, 1)
	// 		this.setState({rcList: this.state.rcList})
	// 	}
	}

	archive(action){
		console.log("here4 ");
		//let status = this.state.rcList[action.index].isRCActive

		if(action.checklist){
			const ID = this.state.planList[action.index].id;
		    const checklist = db.collection("emergencyPlans").doc(ID)
		    checklist.update({
				isArchived: action.isArchived,
        		notes: action.notes
        		 }) //archived but no deleted completely
        	.then(() => console.log("Checklist Archived successfully!"))
			.catch((error) => console.error("Error updating document: ", error))
		}
		if(action.rollcall){
			const ID = this.state.rcList[action.index].id
			const rollCall = db.collection("rollCalls").doc(ID)
			rollCall.update({
				isArchived: action.isArchived,
        		notes: action.notes
        		 }) //archived but no deleted completely
        	.then(() => console.log("Roll Call Archived successfully!"))
			.catch((error) => console.error("Error updating document: ", error))
		}

		//if isRCActive == false : means that particular item is active.


	}

	rcActiveToggle({index}) {
		// console.log(index);
		const toggle = !(this.state.rcList[index].isRCActive)
		// console.log(this.state.rcList[index].isRCActive);
		// send notification to users
		let userTokens = []; // array of user fcmTokens
		let count = 0
		for (let user in this.state.rcList[index].users) {
			let idx = this.state.userList.findIndex((curr) => curr.id === user)
			let data = this.state.userList[idx]
			if (data !== undefined && data.fcmToken !== undefined) {
				// assume the fcmToken is defined
				userTokens.push(data.fcmToken)
			}
			if (count === Object.keys(this.state.rcList[index].users).length -1) {
				// assume its the last element in the array
				const rcData = { // sent to firebase func
					name: this.state.rcList[index].name,
					users: userTokens,
					isActive: toggle,
					id: this.state.rcList[index].id,
					message: (toggle) ? "Please check-in to your Roll Call." : "Roll Call is deactivated"
				}
				// execute the firebase function
				const alertERollCall = firebase.functions().httpsCallable('alertRollCallUsers');
				alertERollCall(rcData)
				.then((result) => console.log(result))
				.catch((error) => console.log('Error: ',error))
			}
			count += 1
		}
		let rcList = this.state.rcList
		rcList[index].isRCActive = toggle
		this.setState({rcList: rcList})
		const ID = this.state.rcList[index].id;
		const rollCall = db.collection("rollCalls").doc(ID);
        rollCall.update({ isRCActive: toggle })
        .then(() => console.log("Document successfully updated!"))
		.catch((error) => console.error("Error updating document: ", error))

		// {array of objects} rollCall name, update_dt and users array of uids
		var activatedRollcalls = []
		if (localStorage.getItem('activatedRollcalls') !== undefined && localStorage.getItem('activatedRollcalls') !== null) {
			activatedRollcalls = JSON.parse(localStorage.getItem('activatedRollcalls').toString())
		}
		let currRollCall = {
			name: rcList[index].name,
			users: [],
			update_dt: new Date().getTime() / 1000 + (60 * 20)
		}
		if (toggle) {
			for (let user in rcList[index].users) {
				currRollCall.users.push(user)
				// assume the Roll Call just got activated
				setTimeout(() => {
					this.changeStatusColor(user, rcList[index].name)
				}, 20 * 60 * 1000)
			}
			activatedRollcalls.push(currRollCall)
		localStorage.setItem('activatedRollcalls', JSON.stringify(activatedRollcalls))
		}
	}
	updateUserStatus(row) {
		// update the checkIn property
		let userMap = this.state.rcList[row.index].users;
		userMap[row.uid].isCheckedIn = true;
		userMap[row.uid].status = 'green';
		// update state
		let rcList = this.state.rcList;
		rcList[row.index].users = userMap;
		this.setState({ rcList: rcList })
		// update firestore
		const rcID = this.state.rcList[row.index].id;
		var rcRef = db.collection('rollCalls').doc(rcID);
		rcRef.update({ users: userMap })
		.then(() => this.viewRollCallUsers({id: rcID}))
		.catch((error) => console.log('Error: ', error))
		// updated the rcUserList that is displayed
		// for the roll call userList
		// this.viewRollCallUsers({id: row.index})
		// console.log("rcID:"+rcID);
		// console.log("row.index:"+row.index);
		// 	this.viewRollCallUsers({id: rcID});

	}
	viewRollCallUsers(row) {
		console.log("1--:"+row.id);
		// rcList contains all the Roll Calls
		// find the index of the clicked on item
		const rcidx = this.state.rcList.findIndex((curr) => curr.id === row.id)
		console.log("rcidx:"+rcidx)
		let userList = this.state.rcList[rcidx].users
		var rcUserList = []
		let numGreen = 0
		let numYellow = 0
		let numRed = 0
		// console.log(userList);
		for (let user in userList) {
			let color = userList[user].status; // the status of the user
			const idx = this.state.userList.findIndex((curr) => curr.id === user)
			if (idx !== -1 && this.state.userList[idx].isOffline !== undefined && this.state.userList[idx].isOffline) {
				// as long as they are offline, their status will be black
				color = 'black'
			} else if (color === 'green') {
				numGreen += 1
			} else if (color === 'yellow') {
				numYellow += 1
			} else {
				numRed += 1
			}
			let currUser = {
				name: userList[user].name,
				status: color,
				isCheckedIn: userList[user].isCheckedIn,
				uid: user
			}
			rcUserList.push(currUser)
		}
		console.log("rcUserList");
		console.log(rcUserList);
		this.setState({
			rcUserList: rcUserList,
			numGreen: numGreen,
			numYellow: numYellow,
			numRed: numRed,
			viewFilteredList: false,
			// planPager: (row.planPager) ? (row.planPager) : 2,
			planPager: (row.planPager) ? (row.planPager) : 4,
			// subPlanPager: (row.subPlanPager) ? (row.subPlanPager) : 0,
			subPlanPager: (row.subPlanPager) ? (row.subPlanPager) : 4,
		})
	}
	fetchUserMessages() {
		if (this.state.isClientAccount) {
			return
		}
		// get all the messages related to the current user
		let messageLogs = [];
		var userRef = db.collection('users').doc(this.state.uid)
		db.collection("messageLog").where("messageUsers", "array-contains", userRef).get()
		.then((querySnapshot) => {
			const mLogList = querySnapshot.docs;
			for (let i = 0; i < mLogList.length; i++) {
				let mLogID = mLogList[i].id
				let mLog = mLogList[i].data()
				mLog.id = mLogID
				messageLogs.push(mLog)
				if (mLog.usersRead !== undefined && mLog.usersRead !== null) {
					let notificationCheck = true
					for (let j = 0; j < mLog.usersRead.length; j++) {
						// iterate through all the users in the usersRead
						if (mLog.usersRead[j].id === this.state.uid) {
							// assume user has read the message
							notificationCheck = false
						}
					}
					if (notificationCheck) {
						// assume there is a active unread message
						this.setState({activeNotification: true})
					}
				}
				if (i === mLogList.length - 1) {
					this.setState({ messageLogs: messageLogs })
				}
			}
		})
		.catch((error) => console.log("Error getting documents: ", error))


	}
	getCurrentMsgs(mLogID, otherUserName) {
		// assume the message Log exists and populate messages
		if (mLogID === undefined && mLogID === null) {
			console.log("error getting the message log")
			return
		}
		// clear the convo list
		if (document.getElementById('convoList') !== null) {
			document.getElementById('convoList').innerHTML = '';
		}
		let idx = this.state.messageLogs.findIndex((mLog) => mLog.id === mLogID)
		console.log("getting curr messages", idx)
		let currMessageLog = {};
		currMessageLog = this.state.messageLogs[idx]
		// update the state of the messagelog usersDead
		var userRef = db.collection("user").doc(this.state.uid)
		this.state.messageLogs[idx].usersRead.push(userRef)
		this.setState({
			currMessageLog: currMessageLog,
			viewUserChat: true,
			otherUserName: otherUserName,
			activeNotification: false,
			messageLogs:this.state.messageLogs
		})
		let messages = []
		var mLogRef = db.collection('messageLog').doc(mLogID)
		mLogRef.get().then((doc) => {
			if (doc.exists) {
				var userRef = db.collection("user").doc(this.state.uid)
				mLogRef.update({
					usersRead: firebase.firestore.FieldValue.arrayUnion(userRef)
				})

			} else console.log("No such document!")
		})
		.catch((error) => console.log("Error getting document:", error));

		db.collection('messages').where("logReference", "==", mLogRef).get()
		.then((querySnapshot) => {
			const mList = querySnapshot.docs;
			for (let i = 0; i < mList.length; i++) {
				let mid = mList[i].id
				let curr = mList[i].data()
				curr.id = mid
				messages.push(curr)
				if (i === mList.length - 1) {
					messages.sort((a, b) => a.timeStamp.seconds - b.timeStamp.seconds)
					document.getElementById('messageList').innerHTML = ''
					var messageList = document.getElementById('messageList')
					for (let message of messages) {
						var nameItem = document.createElement('li')
						var item = document.createElement('li')
						if (this.state.uid === message.fromID.id) {
							// assume user is the currently signed in user
							item.setAttribute('class', 'currUserMessage')
							nameItem.setAttribute('class', 'currUserName')
							nameItem.innerHTML = this.state.adminName
							messageList.appendChild(nameItem)
						} else {
							let idx = this.state.userList.findIndex((curr) => curr.id === message.fromID.id)
							if (idx !== -1) {
								nameItem.setAttribute('class', 'otherUserName')
								nameItem.innerHTML = this.state.userList[idx].name
								messageList.appendChild(nameItem)
							}
							item.setAttribute('class', 'otherUserMessage')
						}
						if (message.isImage) {
							if (message.imageURL === undefined || message.imageURL === null || message.imageURL.length < 10) {
								console.log("Message has an invalid imageURL field")
								return
							}
							item.style.width = '100%'
							let img = document.createElement('img')
							img.setAttribute('src', message.imageURL)
							img.style.width = '95%'
							img.style.margin = '5px'
							item.appendChild(img)
							if (this.state.messageLogs[idx].messageUsers.length > 2 && this.state.uid !== message.fromID.id) {
								// group chat and not the current user
								console.log("group chat here")
							}
							messageList.appendChild(item)
						} else {
							item.innerHTML = message.message
							if (this.state.messageLogs[idx].messageUsers.length > 2 && this.state.uid !== message.fromID.id) {
								// group chat and not the current user
								console.log("group chat here")
							}
							messageList.appendChild(item)
						}

					}
					this.setState({ messages: messages })
				}
			}
		})
		.catch((error) => console.log("Error getting documents: ", error))
	}
	handleMessageChange(event) {
		this.setState({newMsg: event.target.value})
	}
	// uid {String: uid of the otherUser} / otherUserName {String: name of the otherUser}
	createNewMessageLog(uid, otherUserName) {
		for (let mLog of this.state.messageLogs) {
			// iterating thru all the msgLogs to check if the convo already exists
			if (uid === mLog.messageUsers[0].id || uid == mLog.messageUsers[1].id) {
				// assume the message Log already exists and open chat
				this.getCurrentMsgs(mLog.id, otherUserName)
				return;
			}
		}
		// assume there is no message Log and create the messageLog doc
		var otherUserRef = db.collection('users').doc(uid)
		var currUserRef = db.collection('users').doc(this.state.uid)
		let mLogData = {
			latestTimestamp: new Date(),
			messageOwner: currUserRef,
			messageUsers: [currUserRef, otherUserRef], //update
			usersRead: [currUserRef]
		}
		var mLogRef = db.collection('messageLog').doc()
		db.collection('messageLog').doc(mLogRef.id).set(mLogData)
		.then(() => this.getCurrentMsgs(mLogRef.id, otherUserName))
		.catch((error) => console.log('Failed to upload mLog', error))
		mLogData.id = mLogRef.id
		this.setState({
			messageLogs: [...this.state.messageLogs, mLogData],
			currMessageLog: mLogData
		})
	}

	sendNewMsg(file) {
		if (this.state.newMsg.length === 0 && (file === undefined || file === null)) {
			return
		}
		var userRef = db.collection('users').doc(this.state.uid)
		var mLogRef = db.collection('messageLog').doc(this.state.currMessageLog.id)
		// create the message doc
		let userMessage = {}
		if (file !== undefined && file !== null) {
			userMessage = {
				fromID: userRef,
				imageHeightRatio: 1,
				isImage: true,
				logReference: mLogRef,
				timeStamp: new Date(),
			}
		} else {
			userMessage = {
				fromID: userRef,
				isImage: false,
				logReference: mLogRef,
				message: this.state.newMsg,
				timeStamp: new Date()
			}
		}
		var messageList = document.getElementById('messageList')
		var nameItem = document.createElement('li')
		nameItem.setAttribute('class', 'currUserName')
		nameItem.innerHTML = this.state.adminName
		messageList.appendChild(nameItem)
		var item = document.createElement('li')
		item.setAttribute('class', 'currUserMessage')
		// upload to firestore
		var message = db.collection('messages').doc()
		if (file !== undefined && file !== null) {
			// add image message to DOM
			 // uploading the image to storage
			 const uploadTask = storageRef.child("messages/" + mLogRef.id + "/" + message.id + ".jpeg").put(file)
			 uploadTask.on('state_changed', null, null, () => {
					// got the URL of the firebase storage image
					uploadTask.snapshot.ref.getDownloadURL().then( (url) => {
						userMessage.imageURL = url
						db.collection('messages').doc(message.id).set(userMessage)
						.then(() => console.log('Successfully added the message'))
						.catch((error) => console.log('Failed to upload message', error))
						item.style.width = '100%'
						let img = document.createElement('img')
						img.setAttribute('src', url)
						img.style.width = '95%'
						img.style.margin = '5px'
						item.appendChild(img)
						messageList.appendChild(item)
						// not from the current user
						// add message to messages list
						this.setState({messages: [...this.state.messages, userMessage]})
					})
					console.log('Successfully stored and retrieve the url from the image');
				});
		} else {
			// add text message to DOM
			db.collection('messages').doc(message.id).set(userMessage)
			.then(() => console.log('Successfully added the message'))
			.catch((error) => console.log('Failed to upload message', error))
			item.innerHTML = this.state.newMsg
			messageList.appendChild(item)
			// udpate the local State
			this.setState({ messages: [...this.state.messages, userMessage] })
		}
		// send the notification
		let userTokens = [] // array of user fcmTokens
		let count = 0
		for (let uref of this.state.currMessageLog.messageUsers) {
			let idx = this.state.userList.findIndex((curr) => curr.id === uref.id)
			let data = this.state.userList[idx]
			if (data.fcmToken !== undefined) {
				// assume the fcmToken is defined
				userTokens.push(data.fcmToken);
			}

			if (count === this.state.currMessageLog.messageUsers.length -1) {
				// assume its the last element in the array
				const mdata = { // sent to firebase func
					title: "New Message from " + this.state.adminName,
					message:  this.state.newMsg,
					userTokens: userTokens,
					messageReference: this.state.currMessageLog.id
				}
				console.log(mdata)
				// execute the firebase function
				const sendMessageNotification = firebase.functions().httpsCallable('sendMessageNotification');
				sendMessageNotification(mdata)
				.then((result) => console.log(result))
				.catch((error) => console.log('Error: ',error))
			}
			count += 1
		}
		let title = this.state.currMessageLog.title
		if (title === undefined || title === null || title === '') {
			title = this.getConvoName(this.state.currMessageLog)
		}
		let messageContent = this.state.newMsg
		if (file !== undefined && file !== null) {
			messageContent = "This message is an image"
		}
		if (this.state.currMessageLog.messageUsers.length > 2) {
			// update messageLog
			mLogRef.update({
				lastMessage: messageContent,
				title: title
			})
		} else {
			// update messageLog
			mLogRef.update({ lastMessage: messageContent })
		}

		let resultArr = this.state.currMessageLog.messageUsers.filter((curr) => curr.id !== this.state.uid)
		let notificationRef = db.collection('notificationHistory').doc()
		let notificationID = notificationRef.id
		let noitificationLog = {
			date: new Date(),
			message: this.state.adminName + " has sent you a message",
			readUsers: [],
			reference: mLogRef,
			users: resultArr
		}

		db.collection('notificationHistory').doc(notificationID).set(noitificationLog)
		.then(() => console.log('Successfully added the message'))
		.catch((error) => console.log('Failed to upload message', error))
		this.setState({newMsg: ''})
	}
	searchUsersForChat() {
		let userListBox = []
		document.getElementById('convoList').innerHTML = ''
		var convoList = document.getElementById('convoList')
		for (let user of this.state.userList) {
			if (user.id === this.state.uid) {
				continue // assume its the current user
			}
			userListBox.push({'value': user.id, 'label': user.name});
			var item = document.createElement('li')
			item.innerHTML = user.name
			item.setAttribute('data-uid', user.id)
			item.addEventListener('click',
				// create a convo with only one other person
				(item) => this.createNewMessageLog(item.path[0].getAttribute('data-uid'), item.path[0].innerHTML))
			convoList.appendChild(item)
		}
		// show the back arrow
		this.setState({
			viewSearchForChat: true,
			userListBox: userListBox
		})
		// add teams to the drop down menu
		setTimeout(() => {
			document.getElementById('searchBoxUserList').innerHTML = ''
			let editTeamPlan = document.getElementById('searchBoxUserList')
			let currOpt = { text: "Select Team", value: "" }
			editTeamPlan.options.add( new Option(currOpt.text, currOpt.value, currOpt.selected) );
			for (let ug of this.state.groupList) {
				currOpt = { text: ug.name, value: ug.name }
				editTeamPlan.options.add( new Option(currOpt.text, currOpt.value, currOpt.selected) );
			}
		}, 100)
	}
	handleNewConvoTeamChange(event) {
		this.setState({teamName: event.target.value})
	}
	// when viewing the convoList (different threads)
	// returns the name of the convo
	getConvoName(mLog) {
		let userName = '';
		if (mLog.messageUsers.length > 2) {
			// more than 2 users === group chat
			if (mLog.title !== null && mLog.title !== undefined && mLog.title !== "") {
				userName = mLog.title
			} else {
				// assume 3 or more people in the chat
				let nameArr = []
				for (let i = 0; i < mLog.messageUsers.length; i++) {
					let idx = this.state.userList.findIndex((curr) => curr.id === mLog.messageUsers[i].id)
					if (idx !== -1) {
						nameArr.push(this.state.userList[idx].name)
					}
				}
				userName = nameArr.join(', ')
			}
		} else if (mLog.messageUsers[0].id !== this.state.uid) {
			// 2 person chat
			if (mLog.title !== null && mLog.title !== undefined && mLog.title !== "") {
				userName = mLog.title
			} else {
				let idx = this.state.userList.findIndex((user) => user.id === mLog.messageUsers[0].id)
				userName = (idx === -1) ? 'User' : this.state.userList[idx].name
			}
			// assume not the current user
		} else {
			if (mLog.title !== null && mLog.title !== undefined && mLog.title !== "") {
				userName = mLog.title
			} else {
				// assume not the current user
				let idx = this.state.userList.findIndex((user) => user.id === mLog.messageUsers[1].id)
				userName = (idx === -1) ? 'User' : this.state.userList[idx].name
			}
		}
		return userName
	}


	showChatBox() {
		if (document.getElementById('messageList') !== null) {
			document.getElementById('messageList').innerHTML = '';
		}
		if (document.getElementById('convoList') !== null) {
			document.getElementById('convoList').innerHTML = '';
		}
		var convoList = document.getElementById('convoList')
		var addItem = document.createElement('li')
		addItem.innerHTML = '+ Add Conversation'
		addItem.addEventListener('click' , () => this.searchUsersForChat())
		convoList.appendChild(addItem)
		for (let mLog of this.state.messageLogs) {
			// Each item needs to have an ID and the other user must have a uid
			// add all the exisiting conversations to the
			let userName = this.getConvoName(mLog)
			if (userName !== '') {
				var item = document.createElement('li')
				item.innerHTML = userName
				// check is the user has an unread message
				if (mLog.usersRead !== undefined && mLog.usersRead.findIndex((person) => person.id === this.state.uid) === -1 && this.state.activeNotification) {
					// assume the current user has not seen the message
					let span = document.createElement('span')
					span.setAttribute("class", "dot")
					item.prepend(span)
				}
				item.setAttribute('data-mLogID', mLog.id)
				item.addEventListener('click', (curr) => {
					let mLogID = curr.path[0].getAttribute('data-mLogID')
					let idx = this.state.messageLogs.findIndex((log) => log.id === mLogID)
					if (idx !== -1) {
						// make sure that it exists
						if (this.state.messageLogs[idx].messageUsers.length > 2) {
							// more than 2 users === group chat
							this.getCurrentMsgs(mLogID, this.state.messageLogs[idx].title)
							return
						}
					}
					this.getCurrentMsgs(mLogID, curr.path[0].textContent)
				})
				convoList.appendChild(item)
			}
		}
		this.setState({viewSearchForChat: false})
		var chatBox = document.getElementById('chatBox')
		if (chatBox.getAttribute('open') === null) {
			chatBox.showModal()
		}
	}
	hideChatBox() {
		this.setState({
			viewUserChat: false,
			viewSearchForChat: false,
			messages: [],
			currMessageLog: {}
		})
		var chatBox = document.getElementById('chatBox')
		chatBox.close()
	}
	createTemplate(tdata) {
		const templateRef = db.collection('templates').doc()
		const tmplID = templateRef.id
		var taskArr = []
		var taskStateArr = []
		for (let currTask of tdata.tasks) {
			let taskRef = db.collection('tasks').doc()
			taskArr.push(taskRef)
			let taskID = taskRef.id
			let etask = { //task added to 'tasks' collection
				id: taskID, // need it to toggle the Tasks (complete/not)
				name: currTask.taskName,
				emergencyPlan: templateRef,
				assignedUser: '',
				dateCompleted: '',
				isComplete: false,
				description: currTask.description,
				taskUser: '',
				assignedRC: currTask.assignedRC
			}
			taskStateArr.push(etask)
			db.collection("tasks").doc(taskID).set(etask)
			.then(() => console.log("Task successfully added!!!"))
			.catch(() => console.error("Error adding task"))
		}
		const tmpl = { // plan added to 'emergencyPlans' collection
			id: tmplID,
			name: tdata.templateName,
			companyID: this.state.companyID,
			deactivated: false,
			tasks: taskArr,
			titleList: [],
			createdByRole: this.state.role,
		}
		db.collection("templates").doc(tmplID).set(tmpl)
		.then(() => console.log("Template successfully added!!!"))
		.catch(() => console.error("Error adding plan"))
		this.setState({
			tmplList: [tmpl, ...this.state.tmplList],
			taskList: this.state.taskList.concat(taskStateArr)
		})
		if (this.state.role === 'Root') {
			// change the companyID of root back to normal
			const rootRef = db.collection("user").doc(this.state.uid)
			rootRef.get().then((query) => {
				let data = query.data()
				this.setState({companyID: data.companyID})
			})
		}
	}
	deleteTemplate(index) {
		let id = this.state.tmplList[index].id;
		db.collection('templates').doc(id).delete()
		.then(() => console.log('Successfully removed the Template'))
		.catch((error) => console.log('Error removing the Template', error))
		this.state.tmplList.splice(index, 1)
		this.setState({tmplList: this.state.tmplList})
	}
	editTemplate(row) {
		const tmplID = this.state.tmplList[row.index].id;
		// the doc reference is saved to each new task
		var templateRef = db.collection("templates").doc(tmplID)
		for (let currTask of row.newTasks) {
			let taskRef = db.collection('tasks').doc();
			row.taskList.push(taskRef)
			let taskID = taskRef.id;
			let etask = {
				id: taskID,
				name: currTask.taskName,
				emergencyPlan: templateRef,
				assignedUser: '',
				dateCompleted: '',
				isComplete: false,
				description: currTask.description,
				taskUser: '',
				assignedRC: currTask.assignedRC
			}
			// assume assigned user was NOT selected for the task
			db.collection("tasks").doc(taskID).set(etask)
			.then(() => console.log("Task successfully added!!!"))
			.catch(() => console.error("Error adding task"))
		}

		let tmplList = this.state.tmplList
		tmplList[row.index].name = row.templateName
		tmplList[row.index].tasks = row.taskList
		this.setState({tmplList: tmplList})
		// make changes to existing tasks
		for (let key in row.taskNameChangeMap) {
			let taskRef = db.collection('tasks').doc(key)
			// update the name of the task
			taskRef.update({ name: row.taskNameChangeMap[key] })
			.then(() => console.log("Document successfully updated!"))
        	.catch((error) => console.error("Error updating document: ", error));
		}
		// Update the ERP Document
        templateRef.update({
			name: row.templateName,
			tasks: row.taskList,
		})
        .then(() => console.log("Document successfully updated!"))
        .catch((error) => console.error("Error updating document: ", error));

	}
	fetchTemplateData() {

		let tmplArr = [];
		db.collection("templates").get().then(querySnapshot => {
			const data = querySnapshot.docs.map(doc => doc.data())
			for (let tmpl of data) {
				// add template to an array
				if (this.state.role !== 'Root' && this.state.companyID !== tmpl.companyID) continue
				tmplArr.push(tmpl);
			}
			tmplArr = tmplArr.sort((a,b) => {
				var nameA = a.name.toUpperCase();
				var nameB = b.name.toUpperCase();
				if (nameA < nameB) return -1
				if (nameA > nameB) return 1
			})
			// update tmpl list state
			this.setState({tmplList: tmplArr})
		})
		//console.log("fetchTemplateData");
	}
	updateCompanyID(data) {
		this.setState({
			companyID: data.companyID
		})
	}
	handleClientFilterChangeForRep(data) {
		let name = data.name
		if (name !== "") {
			let idx = this.state.clientList.findIndex((client) => client.clientName === name)
			if (idx !== -1) {
				const companyID = this.state.clientList[idx].id
				console.log(companyID)
				db.collection("repoInfo").get().then(querySnapshot => {
					const repoInfo = querySnapshot.docs.map(doc => doc.data());
					let currPath
					let fileList = []
					for (let i = 0; i < repoInfo.length; i++) {
						let item = repoInfo[i]
						currPath = item.fullPath.split('/')
						let client = currPath[1]
						if (item.folder === false && client === companyID) {
							fileList.push(item)
						}
						if (i === repoInfo.length -1) {
							this.setState({
								viewFilteredList: true,
								filteredList: fileList
							})
						}
					}
				})
			}
		} else {
			this.setState({
				viewFilteredList: false,
				filterecdList: []
			})
		}
	}
	handleUsersInConvoChange(event) {
        this.setState({selectedUsersForNewConvo: event})
	}
	createNewGroupMessageLog() {
		let sArr = this.state.selectedUsersForNewConvo.map((user) => user.value)
		let str1 = sArr.sort().join(',')
		for (let mLog of this.state.messageLogs) {
			// iterating thru all the msgLogs to check if the convo already exists
			if (mLog.messageUsers !== undefined && mLog.messageUsers !== null) {
				let mArr = mLog.messageUsers.map((user) => user.id)
				let str2 = mArr.sort().join(',')
				if (str1 === str2) {
					alert("This group message already exists.")
					return
				}
			}

		}
		var currUserRef = db.collection("user").doc(this.state.uid)
		let messageUsers = [currUserRef]
		for (let uid of sArr) {
			let userRef = db.collection("user").doc(uid)
			messageUsers.push(userRef)
		}
		if (this.state.teamName.length > 0) {
			// admin selected a team as well
			let idx = this.state.groupList.findIndex((group) => group.name === this.state.teamName)
			if (idx !== -1) {
				for (let i = 0; i < this.state.groupList[idx].users.length; i++) {
					messageUsers.push(this.state.groupList[idx].users[i])
				}
			}
		}
		let mLogData = {
			latestTimestamp: new Date(),
			messageOwner: currUserRef,
			messageUsers: messageUsers,
			usersRead: [currUserRef]
		}
		let title = ''
		if (sArr.length > 1) {
			// assume 3 or more people in the chat
			for (let i = 0; i < this.state.selectedUsersForNewConvo.length; i++) {
				title += this.state.selectedUsersForNewConvo[i].label + ', '
			}
			title += this.state.adminName
			mLogData.title = title
		} else {
			title = this.state.selectedUsersForNewConvo[0].label
		}
		var mLogRef = db.collection('messageLog').doc()
		db.collection('messageLog').doc(mLogRef.id).set(mLogData)
		.then(() => {
			this.getCurrentMsgs(mLogRef.id, title)
		})
		.catch((error) => console.log('Failed to upload mLog', error))
		mLogData.id = mLogRef.id
		this.setState({
			messageLogs: [...this.state.messageLogs, mLogData],
			currMessageLog: mLogData,
			selectedUsersForNewConvo: []
		})
	}
	handleMessagePicChange(event) {
		event.preventDefault();
        let reader = new FileReader();
        // get the file thats been uploads
		let file = event.target.files[0]
		// after the file finished loading

        reader.onloadend = () => {
			this.sendNewMsg(file)
        }
        reader.readAsDataURL(file)
    }

    //ALL LOGS:
    //1) Library logs:
    // createLibraryLog(fileDeletedDate = null, fileDownloadedDate = null, fileUploadedDate = null, folderAddedDate = null,folderDeletedDate = null ) {
		createLibraryLog(row){
		const user = firebase.auth().currentUser
  		const rowRef = db.collection('libraryLog').add({
		    fileDeletedDate: (row.fileDeletedDate)? row.fileDeletedDate : null,
		    fileDownloadedDate: (row.fileDownloadedDate) ? row.fileDownloadedDate : null,
		    fileUploadedDate: (row.fileUploadedDate) ? row.fileUploadedDate : null,
			folderAddedDate: (row.folderAddedDate) ? row.folderAddedDate : null,
			folderDeletedDate: (row.folderDeletedDate) ? row.folderDeletedDate : null,
			actionDoneBy: user.email,
			lastFolderName: (row.lastFolderName) ? row.lastFolderName : null,
			currentFolderName:(row.folderName) ? row.folderName : null,
			fileName: (row.fileName) ? row.fileName : null,
			lastUpdatedBy:(row.lastUpdatedBy) ? user.email : null,
			lastUpdatedDate:(row.lastUpdatedDate) ? row.lastUpdatedDate : null,
			filetype: (row.fileType) ? row.fileType : null
		  });
		// console.log(user.email);
	}

	 //2) User logs:
		createUserLog(row){
		const user = firebase.auth().currentUser
  		const rowRef = db.collection('userLog').add({
  			date: new Date(),
            logType: (row.logType) ? row.logType : "There is a problem!",
            message: user.email + " has made the changes",
            oldValue: (row.oldValue) ? row.oldValue : '-',
            newValue: (row.newValue) ? row.newValue : '-',
            source: (row.source) ? row.source : "-",
            document: (row.document) ? "/users/"+row.document : '-'

		  });

	}


	render() {
		let body = <p>Loading</p>;
		let menuWidth = (this.state.toggleMenu) ? '75px': '300px';

		const queryParams = new URLSearchParams(window.location.search);
		const invite_id = queryParams.get('invite');

		const isLoggedIn = this.state.isLoggedIn; // checks if the user is logged in or not
		if (this.state.pageNum === 1) //USERLIST
		{
			// still retrieving the User List from cloud firestore
			if (this.state.userList.length === 0)
			{
				body = <p>Loading User List</p>
			}
			if (this.state.userList.length > 0)
			{
				//console.log("user")
				body = <UserTable
						       userList={(this.state.viewDeletedUsers) ? this.state.deletedUsers : this.state.userList}
							   clientList={this.state.clientList}

							   onDeleteUser={this.deleteUser}
							   onViewDeletedUsers={this.viewDeletedUserList}
							   viewDeletedUsers={this.state.viewDeletedUsers}
							   onEditUser={this.editUser}
							   onToggleUser={this.toggleUser}
							   onViewUserGroups={this.viewUserGroups}
							   onViewUserList={(planPager) => this.viewUsers(planPager)}
							   onAddNewUser={this.addNewUser}
							   onAddManyNewUsers={this.addManyNewUsers}

							   highlight={this.state.userHighlight}
							   reportsHighlight={this.state.reportsHighlight}
							   role={this.state.role}
							   planPager={this.state.planPager}
							   subPlanPager={this.state.subPlanPager}
							   breadCrumbs={this.state.breadCrumbs}
							   onEditBreadCrumbs={(breadCrumbs) => this.editBreadCrumbs(breadCrumbs)}
							   toggleMenu={this.state.toggleMenu}

							   onfilterTable={this.filterTable}
							   viewFilteredList={this.state.viewFilteredList}
							   filteredList={this.state.filteredList}

							   companyID={this.state.companyID}

							   reactivateUser={this.reactivateUser}
							   uid={this.state.uid}
							   email={this.state.email}
							   domain={this.state.domain}
							   gUserList={this.state.gUserList}
							   onClientFilterChange={this.handleClientFilterChange}

							   createUserLog = {this.createUserLog}

							   viewReports = {this.state.viewReports}
						       viewReports = {this.viewReports}

							   onViewLibrary={(planPager) => this.viewKnowledge(planPager)}

							   viewRollCallList={(action) => this.viewRollCallList(action)}

							   cFormsList={this.state.cFormsList}
							   viewCompletedForms={this.state.viewCompletedForms}
							   viewCompletedForms={this.viewCompletedForms}

							   viewUserEvents = {this.viewUserEvents}
							   viewUserRigs = {this.viewUserRigs}
							   userEventList = {this.state.userEventList}
							   userRigList = {this.state.userRigList}
								 viewUserReferals = {this.viewUserReferals}
								 userReferalList = {this.state.userReferalList}

								 sendForgotPasswordLink = {this.forgotPassword}
					/>
			}
			// view the userGroup
			if (this.state.ugCheck)
			{
				body = <UserGroupTable
				        	onViewUserList={this.viewUsers}
				        	userList={this.state.userList}

				        	companyID={this.state.companyID}
							onAddUserGroup={this.addUserGroup}
							groupList={this.state.groupList}
							onDeleteUserGroup={this.deleteUserGroup}
							onEditUserGroup={this.editUserGroup}
							onViewDeletedUsers={this.viewDeletedUserList}
							onViewUserGroups={this.viewUserGroups}


							highlight={this.state.userHighlight}
							breadCrumbs={this.state.breadCrumbs}
							onEditBreadCrumbs={(breadCrumbs) => this.editBreadCrumbs(breadCrumbs)}
							toggleMenu={this.state.toggleMenu} role={this.state.role}

							onfilterTable={this.filterTable} viewFilteredList={this.state.viewFilteredList}
							filteredList={this.state.filteredList}

							createUserLog = {this.createUserLog}/>
			}

		}
		if (this.state.pageNum === 2) //CLIENT LISTS
		{
			//console.log("client")
				body = <ClientTable
								onSendUsersMessage={this.sendUsersMessage}
								clientList={(this.state.viewDeletedClients) ? this.state.deletedClients : this.state.clientList}
								onDeleteClient={this.deleteClient}
								onEditClient={this.editClient}
								onToggleClient={this.toggleClient}
								onAddClient={this.addClient}

								onViewClientList={this.viewClients}
					 			onViewClientListAction={this.viewClientList}

								onViewDeletedClientListAction={this.viewDeletedClientList}
								viewDeletedClients={this.state.viewDeletedClients}
								reactivateClient={this.reactivateClient}
								clientHighlight={this.state.clientHighlight}

								viewFilteredList={this.state.viewFilteredList}
								filteredList={this.state.filteredList}
								onfilterTable={this.filterTable}

								breadCrumbs={this.state.breadCrumbs}
								onEditBreadCrumbs={(breadCrumbs) => this.editBreadCrumbs(breadCrumbs)}
								toggleMenu={this.state.toggleMenu}

						/>
		}
		if (this.state.pageNum === 3) //INVENTORY LIST
		{
			//console.log("inventory")
				body = <EventTable
								eventList={this.state.eventList}
								businessUserList = {this.state.businessUserList}
								viewFilteredList={this.state.viewFilteredList}
								filteredList={this.state.filteredList}
								breadCrumbs={this.state.breadCrumbs}
								onEditBreadCrumbs={(breadCrumbs) => this.editBreadCrumbs(breadCrumbs)}
								toggleMenu={this.state.toggleMenu}
								onfilterTable = {this.filterTable}
								oneventList = {this.viewEvents}

								onDeleteEvent = {this.deleteEvent}
								onEditEvent = {this.editEvent}
								onAddEvent = {this.addEvent}
								onAddManyEvents = {this.addManyEvents}
								activateEvent = {this.activateEvent}
								denyEvent = {this.denyEvent}
								requestActivationEvent = {this.requestActivationEvent}
								denials_list = {this.state.denials_list}
								role = {this.state.role}
								uid = {this.state.uid}

								fetchEventCategories = {this.fetchEventCategories}
								eventCategories = {this.state.eventCategories}
								eventSubCategories = {this.state.eventSubCategories}

								categoryList = {this.state.activeCategories}
								createEventLog = {this.createEventLog}
						/>
		}
		if (this.state.pageNum === 4){
			body = <CategoryTable
						categories = {this.state.categories}
						subcategories = {this.state.subcategories}
						fetchSubCategories = {this.fetchSubCategories}
						addCategory = {this.addCategory}
						addSubCategory = {this.addSubCategory}
						editCategory = {this.editCategory}
						deleteCategory = {this.deleteCategory}
						onToggleCategory = {this.toggleCategory}
						breadCrumbs={this.state.breadCrumbs}
						onEditBreadCrumbs={(breadCrumbs) => this.editBreadCrumbs(breadCrumbs)}
						toggleMenu={this.state.toggleMenu}
						viewFilteredList={this.state.viewFilteredList}
						onViewCategoryList = {this.viewCategories}
						onfilterTable = {this.filterTable}
						filteredList={this.state.filteredList}


				   />
		}
		if (this.state.pageNum === 5)
		{
			body = <DenialTable
						denials_list = {this.state.denials_list}
						addDenials = {this.addDenials}
						editDenials = {this.editDenials}
						deleteDenials = {this.deleteDenials}
						breadCrumbs={this.state.breadCrumbs}
						onEditBreadCrumbs={(breadCrumbs) => this.editBreadCrumbs(breadCrumbs)}
						toggleMenu={this.state.toggleMenu}
						viewFilteredList={this.state.viewFilteredList}
						onViewDenialList = {this.viewDenials}
						onfilterTable = {this.filterTable}
						filteredList={this.state.filteredList}


				   />
		}


		if (this.state.pageNum === 7) //ClientSettings
		{
			body = <ClientSettingsTable


						breadCrumbs={this.state.breadCrumbs}
						onEditBreadCrumbs={this.editBreadCrumbs}
						planHighlight={this.state.planHighlight}
						reportsHighlight={this.state.reportsHighlight}
						planPager={this.state.planPager}
						subPlanPager={this.state.subPlanPager}
						onNavigateToParent={this.navigateToParent}
						onNavigateToChild={this.navigateToChild}
						path={this.state.path}
						role={this.state.role}
						companyID={this.state.companyID}


						formSettings={this.state.formSettings}
						formList={this.state.formList}
						onToggleForm={(action) => this.toggleForm(action)}
						sendReminder = {(action) => this.sendUserReminder(action)}
						userList = {this.state.userList}

						clientSettings={this.clientSettings}
						clientSettings={this.state.clientSettings}
						clientList={this.state.clientList}


				/>



		}


			if (isLoggedIn) {
			return (
				<Fragment>
					{/* conditional rendering for the back arrow (position: absolute) */}

					{ this.state.showBackArrow ? <img onClick={this.handleBackClick} id="backArrow" height={25} width={25} src={BackArrow} alt="back-arrow"/> : null }

					{ this.state.toggleMenu ?
						<img onClick={this.handleToggleMenu} src={ForwardArrow} height={25}
						width={25} id="toggleMenuArrow" alt="toggle-menu-arrow" style={{'left': '80px'}}/>
						:
						<img onClick={this.handleToggleMenu} src={BackArrow} height={25} width={25} id="toggleMenuArrow"
							alt="toggle-menu-arrow" style={{'left': '230px'}}/> }

					<ProSidebar collapsed = {this.state.toggleMenu} collapsedWidth='80px' width='230px' id="mainNav">
					<SidebarHeader>
					</SidebarHeader>
					<SidebarContent>

						<Menu>
					    <MenuItem onClick={this.viewEvents} className={this.state.highlight[0]} icon={<MdAssignment size={25}/>}>
					    {(this.state.toggleMenu) ? '' : ' Events'}</MenuItem>
					   </Menu>
						 {(this.state.role == 'root')?
						 <Fragment>
					   <Menu>
						    <MenuItem onClick={this.viewUsers}  className={this.state.highlight[1]} icon={<MdPeople size={25}/>}>
						    {(this.state.toggleMenu) ? '' : ' Users'}</MenuItem>
					 	</Menu>
						<Menu>
							<SubMenu  title={(this.state.toggleMenu) ? '' : ' Settings'} className={this.state.highlight[2]} icon={<MdSettings size={25}/>}>
							 <MenuItem onClick={this.viewCategories} icon={<MdFormatAlignJustify size={25}/>} >Categories</MenuItem>
							 <MenuItem onClick={this.viewDenials} icon={<MdFormatAlignJustify size={25}/>} >Canned Denials</MenuItem>
							</SubMenu>
						</Menu>
						</Fragment> : null }

					</SidebarContent>
					<SidebarFooter>
					<Menu iconShape="square">
					    <MenuItem onClick={this.signOut} title="Sign Out" className={"mainItem"}>
					    <img src={Signout} height={25} width={25} alt="Sign Out"/>{(this.state.toggleMenu) ? '' : ' Sign Out'}</MenuItem>
					</Menu>
					</SidebarFooter>
					</ProSidebar>

					{body}
					{(this.state.viewDeletedUsers || this.state.isClientAccount) ? null : <img id="chatMessagesIcon" src={(this.state.activeNotification) ? ChatMessagesNotify : ChatMessages} alt="Messages"
							onClick={this.showChatBox}/>}
						<dialog id="chatBox">
							<img id="exit-dialog-icon" src={Exit} height={28} onClick={this.hideChatBox} alt="close pop up"/>
							{(this.state.viewUserChat) ?
								<Fragment>
									<img onClick={() => {
											this.setState({
												viewUserChat: false,
												messages: [],
												currMessageLog: {}
											})
											setTimeout(() => {
												this.showChatBox()
											}, 500)
										}} id="chatBackArrow" src={BackArrow} alt="back-arrow"/>
									<p id="chatTitle">{this.state.otherUserName}</p>
									<section id="messagesBox">
										<ul id="messageList"></ul>
									</section>
									<form onSubmit={(event) => {
													event.preventDefault()
													this.sendNewMsg()
									}}>
										<label id="chatBoxPlusSign">
											<input id="chatBoxSelectImage" type="file" accept="image/*" id="chatBoxSelectImage" onChange={this.handleMessagePicChange}/>
											<span >+</span>
										</label>
										<input type="text" value={this.state.newMsg} onChange={this.handleMessageChange}
											id="chatBoxInput" placeholder="Type a message..."></input>
										<button id="sendChatBtn" type="submit"></button>
								</form>
							</Fragment>
							: <Fragment>
								{(this.state.viewSearchForChat) ? <img onClick={this.showChatBox}
									id="chatBackArrow" src={BackArrow} alt="back-arrow"/> : null}
								<p id="chatTitle">{(this.state.viewSearchForChat) ? 'Select a person' : 'My Conversations'}</p>
								<section id="convoBox">
								{(this.state.viewSearchForChat) ? <Fragment>
									<select id="searchBoxUserList" onChange={this.handleNewConvoTeamChange}></select>
									<div style={{"display":"flex"}}>
										<Select options={this.state.userListBox} id='searchBox' placeholder='Select a User' isMulti
											onChange={this.handleUsersInConvoChange}
											classNamePrefix="select" value={this.state.selectedUsersForNewConvo}/>
										<span onClick={this.createNewGroupMessageLog} className="addItemPlan" style={{"fontSize":"22px", "height": "fit-content"}}>+</span>
									</div>
								</Fragment>: null }
									<ul id="convoList"></ul>
								</section>
							</Fragment>}
					</dialog>
				</Fragment>
			)}
		if (!isLoggedIn) {
			var showSignIn = (this.state.forgotPassword) ? "hidden" : "";
			var showFPassword = (this.state.forgotPassword) ? "" : "hidden";

			//Url is an invite link
			const queryParams = new URLSearchParams(window.location.search);
			const invite_id = queryParams.get('invite');

			if(invite_id != null){

				return(
					<div id="signInBackGround">
						<div id="signInBorder">
							<section className={['signIn', showSignIn].join(' ')}>
								<img className="logo" alt="company" src={Logo} height={95}/>
								<h3 className="lgnTitle">Download the FOMO app</h3>
								<img style = {
										{ 'background': 'lightgrey' }
								}
								src = { PlayStore }
								height = "100"
								alt = "profile" />
								<img style = {
										{ 'background': 'lightgrey' }
								}
								src = { AppStore }
								height = "100"
								alt = "profile" />
							</section>
						</div>
					</div>
				)

			}

			// const unsubscribe_email = queryParams.get('unsubscribe')
			//
			// if(unsubscribe_email != null){
			// 	let unsub_email = decodeURI(unsubscribe_email);
			// 	console.log(unsub_email)
			//
			//
			// }

			return (

				<div id="signInBackGround">
					<div id="signInBorder">
						<section className={['signIn', showSignIn].join(' ')}>
							<img className="logo" alt="company" src={Logo} height={95}/>
							<h3 className="lgnTitle">Administrator Portal</h3>
							<form onSubmit={(event) => {
									event.preventDefault()
									this.signIn(this.state.email, this.state.password)
								}}>
								<div className="inputbox">
									<input className="logInput" type="text" name="email" value={this.state.email} onChange={this.handleEmailChange} placeholder="Username"/>
								</div>
								<div className="inputbox">
									<input className="logInput" type="password" name="password" value={this.state.password} onChange={this.handlePasswordChange} placeholder="Password"/>
								</div>
								<br/>
								{/* on click and on enter, the login function is executed */}
								<button className="signInBtn" type="submit">Log In</button>
							</form>
							<br/>
							<p onClick={this.handleForgotPassword} style={{'cursor':'pointer'}} >Forgot Password</p>
						</section>
						<section className={['signIn', showFPassword].join(' ')} id='forgotPassword'>
							<img className="logo" alt="company" src={Logo} height={95}/>
							<h3 className="lgnTitle">Forgot Password</h3>
							<p>Enter your Email to continue</p>
							<form onSubmit={(event) => {
									event.preventDefault()
									this.forgotPassword(this.state.email)
								}}>
								<div className="inputbox">
									<input className="logInput" type="text" name="email" value={this.state.email} onChange={this.handleEmailChange} placeholder="Email"/>
								</div>
								<br/>
								{/* on click and on enter, the login function is executed */}
								<button className="signInBtn" type="submit">Submit</button>
								<br/>
								<p onClick={this.handleForgotPassword} style={{'cursor':'pointer'}} >Back to Login</p>
							</form>
						</section>
					</div>
				</div>
			)
		}
	}
}
const firebaseAppAuth = FBapp.auth();
export default withFirebaseAuth({ firebaseAppAuth })(App);
