import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: "AIzaSyDs6tEekG1jF60m6h9tw1cTDoodjULlhvE",
  authDomain: "fomo-53dbd.firebaseapp.com",
  databaseURL: "https://fomo-53dbd.firebaseio.com",
  projectId: "fomo-53dbd",
  storageBucket: "fomo-53dbd.appspot.com",
  messagingSenderId: "668375231114",
  appId: "1:668375231114:web:b6b305afc6a60ff9b95b73",
  measurementId: "G-91VTEH1SY4"
};

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigDev = {
  apiKey: "AIzaSyDs6tEekG1jF60m6h9tw1cTDoodjULlhvE",
  authDomain: "fomo-53dbd.firebaseapp.com",
  databaseURL: "https://fomo-53dbd.firebaseio.com",
  projectId: "fomo-53dbd",
  storageBucket: "fomo-53dbd.appspot.com",
  messagingSenderId: "668375231114",
  appId: "1:668375231114:web:b6b305afc6a60ff9b95b73",
  measurementId: "G-91VTEH1SY4"
};

var FBapp = firebase.initializeApp(firebaseConfigDev);

export default FBapp;

