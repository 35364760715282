import React from 'react'
import Descend from './style/descendArrow.png'
import Ascend from './style/ascendArrow.png'
import { useTable, useSortBy } from "react-table"

// Generated the HTML and CSS for the table
// standalone function outside the class
const Table = ({ columns, data }) => {
	// Use the state and functions returned from useTable to build your UI
	const { 
		getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
	} = useTable({ columns, data }, useSortBy)
	// Render the UI for your table
	return (
		<table {...getTableProps()}>
			<thead>
		  		{headerGroups.map(headerGroup => (
				<tr {...headerGroup.getHeaderGroupProps()}>
			  		{headerGroup.headers.map(column => {
						return <th {...column.getHeaderProps()}>
								<span {...column.getSortByToggleProps()}>
									{column.render('Header')}
									{/* Add a sort direction indicator */}
									{column.isSorted ? column.isSortedDesc ? <img id="filterArrow" src={Descend} alt="Descend"/> : <img id="filterArrow" src={Ascend} alt="Ascend"/> : ""}
								</span>
							</th>
			  		})}
				</tr>
		  		))}
			</thead>
			<tbody {...getTableBodyProps()}>
				{rows.map((row) => {
			  		prepareRow(row);
			  		return (
						<tr {...row.getRowProps()}>
				  			{row.cells.map(cell => {
								return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
				  			})}
						</tr>
					)}
				)}
			</tbody>
		</table>
	)
}
export default Table;