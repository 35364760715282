import React, { Component, Fragment } from 'react'
import AddClientDialog from './addClientDialog'
import Select from 'react-select'
import Input from 'react-phone-number-input/input'
import Styles from './Styles'
import Table from './Table'
import TrashCan from './style/delete.png'
import Edit from './style/edit.png'
import ChatBubble from './style/clientChat.png'
import Exit from './style/exit.png'
import Reset from './style/reset_btn.png'

//BOOTSRAP:
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


class ClientTable extends Component {

	constructor() {
		super();
		this.state = {
			index: 0, // index of the selected client for edit
			domain: '', // the domain associated with  the client
			name: '', // the clients name
			
			inputText: null, // {label: string, value: string}
			id:''
		}
		this.handleDomainChange = this.handleDomainChange.bind(this)
		this.handleNameChange = this.handleNameChange.bind(this)
		this.handlePhoneChange = this.handlePhoneChange.bind(this)
		this.handlePOCFirstChange = this.handlePOCFirstChange.bind(this)
		this.handlePOCPhoneChange = this.handlePOCPhoneChange.bind(this)
		this.handleEditClient = this.handleEditClient.bind(this);
		this.hideEditClientBox = this.hideEditClientBox.bind(this);
	}
	// sends the data to the client page
	// triggered when the the confirm button in the dialog is pressed
	handleEditClient() {
		console.log(": 9");
		this.props.onEditClient({
			index: this.state.index,
			id: this.state.id,
			domain: this.state.domain,
			name: this.state.name,
			phone: this.state.phone,
			pocfirst: this.state.pocfirst,
			poclast: this.state.poclast,
			pocphone: this.state.pocphone, //remove the first two characters of the number
			
		});
		this.hideEditClientBox();
	}
	handleLogoChange(event) {
		event.preventDefault();
		let reader = new FileReader();
		// get the file thats been uploads
        let file = event.target.files[0];
        // after the file finished loading
        reader.onloadend = () => {
            this.setState({ 
                logo: file,
                imgSrc: reader.result
             });
        }
        reader.readAsDataURL(file)
	}
	handleDomainChange(event) {
		console.log(":10");
		this.setState({domain: event.target.value});
	}
	handleNameChange(event) {
		this.setState({nameame: event.target.value});
	}
	handlePhoneChange(event) {
		this.setState({phone: event});
	}
	handlePOCFirstChange(event) {
		this.setState({pocfirst: event.target.value});
	}
	handlePOCLastChange(event) {
		this.setState({poclast: event.target.value});
	}
	handlePOCPhoneChange(event) {
		this.setState({pocphone: event});
	}
	
	showEditClientBox() {
		console.log(": 11");
		this.props.onEditBreadCrumbs({
            type: 'APPEND_BREADCRUMB',
            breadCrumbs: " > Edit Client"
        })
		const editClientDialog = document.getElementById('editClientDialog');
		editClientDialog.style.left = (this.props.toggleMenu) ? '105px' : '330px'
        editClientDialog.showModal();
	}
    hideEditClientBox () {
    	console.log(": 12");
		this.props.onEditBreadCrumbs({
            type: 'REMOVE_BREADCRUMB',
            breadCrumbs: "Clients"
        })
		const editClientDialog = document.getElementById('editClientDialog');
        editClientDialog.close();
	}
    render() {
    	console.log(": 13");
    	// console.log(newClient);
		// predefined names for the columns
		var columns = [
			{
				Header: 'Clients',
				columns: [
				{
					Header:'   ',
					Cell: ({row}) => {
						return <img style={{'borderRadius': '50%', 'background': 'lightgrey'}} src={row.original.logo} width="40" height="40"/>
					}
                },
				{
					Header: 'Client Name',
					accessor: 'name',
				},
				{
					Header: 'Domain',
					accessor: 'domain',
				},
				{
					Header: 'POC',
					Cell: ({row}) => {
						let name = row.original.pointOfContact.first+" "+row.original.pointOfContact.last;
						return name;
					}
				},
				{
					Header: 'POC Phone Number',
					Cell: ({row}) => {
						let num = row.original.pointOfContact.phone;
						let phone = '';
						if (num !== null && num !== undefined) {
							// assume the user has a number 
							num = num.toString(); // convert to string
							if(num.length === 12){
								num = num.substr(2);
							}
							phone = '(' + num.substr(0,3) + ')-' + num.substr(3,3) + '-' + num.substr(6);
						}
						return phone;
					}
				},
				{
					Header: 'Phone Number',
					Cell: ({row}) => {
						let num = row.original.phone;
						let phone = '';
						if (num !== null && num !== undefined) {
							// assume the user has a number 
							num = num.toString(); // convert to string
							if(num.length === 12){
								num = num.substr(2);
							}
							phone = '(' + num.substr(0,3) + ')-' + num.substr(3,3) + '-' + num.substr(6);
						}
						return phone;
					}
				},
				{
					Header: 'Enabled',
					// add the Toggle Switch over here
					Cell: ({row}) => {
						let enabled = !row.original.deactivated
						// save as string and then do the logic
						return <label title="Enable Toggle" className="switch"><input type="checkbox" defaultChecked={enabled}/><span className="slider round"
							onClick={() => {this.props.onToggleClient({index: row.id})}}></span></label>
					}
				},
				{
				// Edit button functionality
					Header: '  ',
					Cell: ({row}) => {
						return <img title="Edit Client" height={25} width={25} alt="edit" className='icon_button'
						src={Edit}
						onClick={(event) => {
							event.persist();
							const rData = row.original;
							this.showEditClientBox();
							this.setState({
								// imgSrc: rData.logo[0].props.src, 
								imgSrc: rData.logo, // updating the logo src
								domain: rData.domain,
								name: rData.name,
								phone: rData.phone,
								pocphone: rData.pointOfContact.phone,
								pocfirst: rData.pointOfContact.first,
								poclast: rData.pointOfContact.last,
								index: row.id, // The the index of the selected client
								id: rData.id
							});
						}}/>
					}
				},
				{
				// Delete button functionality
					Header: ' ',
					Cell: (row) => {
						return <img title="Delete Client" height={25} width={25} alt="delete" src={TrashCan} className='icon_button'
						onClick={(event) => {
							event.persist()
							var res = window.confirm("Are you sure you want to delete the Client?");
							if (res) {
								// Supposed to send the row ID of the User the admin wants to delete
								this.props.onDeleteClient({index: row.row.id});
							}
						}}/>
					}
				}]
			}]
		
		
		if (this.props.viewDeletedClients) {
			// assume the deleted clients table is displayed
			columns[0].columns.splice(5,5) // remove the deleted button column
			columns[0].columns.push({
				Header: 'Reactivate CLient',
				Cell: ({row}) => <button id="taskButton" onClick={() => {
					let res = window.confirm("Are you sure you want to reactivate the client?")
					if (res) {
						this.props.reactivateClient({
							index: row.id,
							id: row.original.id
						})
					}
				}}>Reactivate</button>
			})
		}
		return (

			<main>
				<p id="breadCrumbs">{this.props.breadCrumbs}</p>
				<h1>Client List</h1>
				<ul className="topnav">
					<li id="secondaryNavigationItems" onClick={this.props.onViewClientListAction} className={this.props.clientHighlight[0]}>Clients</li>
					<li id="secondaryNavigationItems" onClick={this.props.onViewDeletedClientListAction} className={this.props.clientHighlight[1]}>Deleted</li>
				</ul>
				<nav>
					<Container fluid>	
						<Row>
							<Col>
								<div className="search">
									<Select options={this.props.clientList.map((client) => {return { 'value': client.clientName, 'label': client.clientName }})} 
											id='searchBox' placeholder='Select a Client'
											value={this.state.inputText}
											onChange={(event) => {
												this.props.onfilterTable({
													type: 'FILTER_CLIENT_LIST',
													value: event.value
												})
												let inputText = {label: event.label, value: event.label}
			                                	this.setState({inputText: inputText})
											}}/>
									<img id="resetTableListBtn" src={Reset} alt="Reset" className="icon_button"
										onClick={() => {
			                                this.props.onViewClientList()
											this.setState({inputText: null})
			                        	}}/>
								</div>
							</Col>
							<Col>
								<ul className="addItem">

									<AddClientDialog onAddClient={(newClient) => this.props.onAddClient(newClient)}
										onEditBreadCrumbs={(breadCrumbs) => this.props.onEditBreadCrumbs(breadCrumbs)}
										toggleMenu={this.props.toggleMenu}/> 
								</ul>
							</Col>
						</Row>
					</Container>
				</nav>
				<Styles>
					{/* gets the user data from the userPage class and fills in the table */}
					<Table columns={columns} data={(this.props.viewFilteredList) ? this.props.filteredList : this.props.clientList} />
				</Styles>
				
				{/* Supposed to display when the user clicks on the edit user button */}
				<dialog id="editClientDialog" className="bigDialogBox">
					<img id="exit-dialog-icon" src={Exit} height={28} onClick={this.hideEditClientBox} alt="exit button"/>
                    <h2>Edit Client</h2>
					<br/>
                    <br/>
                    <label>Domain</label><br/>
                    <input type="text" value={this.state.domain} onChange={this.handleDomainChange} />
                    <br/>
                    <label>Name</label><br/>
                    <input type="text" value={this.state.name} onChange={this.handleNameChange} />
                    <br/>
                    <label>Phone Number</label><br/>
					<Input country="US" value={this.state.phone} onChange={this.handlePhoneChange} maxLength={14} placeholder="Phone Number"/>
                    <br/>
                    <label>Point of contact</label><br/>
                    <label>First Name</label><br/>
                    <input type="text" value={this.state.pocfirst} onChange={this.handlePOCFirstChange} />
                    <br/>
                    <label>Last Name</label><br/>
                    <input type="text" value={this.state.poclast} onChange={this.handlePOCLastChange}/>
					<br/>
					<label>Phone Number</label><br/>
					<Input country="US" value={this.state.pocphone} onChange={this.handlePOCPhoneChange} maxLength={14} placeholder="Point of Contact Phone Number"/>
                    <br/>
                    <div className="btn--flex">
						<button onClick={this.handleEditClient} type="button" className="btn btn--confirm dialog__btn">
                            Confirm
                        </button>
						<button onClick={this.hideEditClientBox} type="button" className="btn btn--close dialog__btn">
                            Cancel
                        </button>
                    </div>
				</dialog>
			</main>


		);
		
	}


}
export default ClientTable;