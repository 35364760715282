import React, { Component, Fragment } from 'react';
import AddFileDialog from './addFileDialog';
import AddFolderDialog from './addFolderDialog'
import FileIMG from './style/file.png';
import FolderIcon from './style/folder.png';
import Download from './style/down.png'
import TrashCan from './style/delete.png';
import Edit from './style/edit.png'
import Select from 'react-select'
import { useTable, useSortBy } from "react-table";
import Styles from './Styles';
import Reset from './style/reset_btn.png'
import Descend from './style/descendArrow.png'
import Ascend from './style/ascendArrow.png'
import Print from './style/print.png'
import './style/knowledgePage.css';
import './style/reportsPage.css'

//DATES:
// import Moment from 'react-moment';
// import 'moment-timezone';
// import moment from 'moment';

// CSS Modules, react-datepicker-cssmodules.css// 
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css'; 

//BOOTSRAP:
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { CSVLink} from "react-csv";

function Table({ columns, data, onRowDoubleClick }) {	
	// Use the state and functions returned from useTable to build your UI	
	const {	
		getTableProps,	
		getTableBodyProps,	
		headerGroups,	
		rows,	
		prepareRow,	
	} = useTable({ columns, data }, useSortBy)	
	// Render the UI for your table	
	return (	
		<table {...getTableProps()}>	
			<thead>	
		  		{headerGroups.map(headerGroup => (	
				<tr {...headerGroup.getHeaderGroupProps()}>	
			  		{headerGroup.headers.map(column => {
						return <th {...column.getHeaderProps()}>
                                <span {...column.getSortByToggleProps()}>
                                    {column.render('Header')}
                                    {/* Add a sort direction indicator */}
									{column.isSorted ? column.isSortedDesc ? <img id="filterArrow" src={Descend} alt="Descend"/> : <img id="filterArrow" src={Ascend} alt="Ascend"/> : ""}
                                </span>
                                
                            </th>	
                      })}	
				</tr>	
		  		))}	
			</thead>	
			<tbody style={{'cursor': 'pointer'}} {...getTableBodyProps()}>	
				{rows.map((row, i) => {	
					var clickTimeout = null;	
					prepareRow(row);
			  		return (	
						<tr {...row.getRowProps()} 	
                            // sends index and row Info to RepoTable on double click	
							onClick={(e) => {
								// TODO
								e = e || window.event;
								// get the row html element
                                var target = e.target || e.srcElement
                                
								if (clickTimeout !== null) {	
									onRowDoubleClick({ row: row	})	
									clearTimeout(clickTimeout)	
									clickTimeout = null	
								} 	
								else {	
									clickTimeout = setTimeout(() => {	
										// onRowSingleClick({ row: row	})
                                        // get the row html element
                                        let tbodyArr = []
                                        if (target.tagName !== "TD" || (!row.original.folder)) {
                                            // assume clicking on an img tag or file row
                                            return
                                        } else {
                                            // get the row elements
                                            tbodyArr = target.parentNode.parentNode.getElementsByTagName('tr')
                                        }
                                        // iterate through the row elements
                                        for (let i = 0; i < tbodyArr.length; i++) {
                                            if (i === row.id) continue
                                            // make sure all other rows are white
                                            tbodyArr[i].style.background = 'white'
                                        }
                                        target.parentNode.style.background = '#2196F3'
										clearTimeout(clickTimeout)	
										clickTimeout = null	
									}, 300)	
								}	
							}}>	
				  			{row.cells.map(cell => {	
								return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>	
				  			})}	
						</tr>	
					)}	
				)}	
			</tbody>	
		</table>	
	)	
}
class LibraryTable extends Component {
    constructor() {
        super();
        this.state = {
            folderIndex: '',
            folderPath: '',
            highlight: ['bottomLine', 'topLine'],
            viewUploadBtns: false, /* {boolean} display upload buttons 
                                   * only for Super Admin & Root
                                   * if general, only for Root */
            inputText: null, // {label: string, value: string}
            clientName: 'Client',
            date: new Date().toLocaleString(),
            csvArr: [],
            startDate: new Date(),
            lastDate:new Date(),
        }
        this.deleteFile= this.deleteFile.bind(this)
        this.handleNavigateToChild = this.handleNavigateToChild.bind(this)
        this.handleGoBack = this.handleGoBack.bind(this)
        this.addFolder = this.addFolder.bind(this);
        this.addFile = this.addFile.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.handleSingleClick = this.handleSingleClick.bind(this);
        this.handleDeleteFolder = this.handleDeleteFolder.bind(this);
        this.handleEditFolder = this.handleEditFolder.bind(this);
        this.viewClientRepo = this.viewClientRepo.bind(this);
        this.viewGenRepo = this.viewGenRepo.bind(this)
        this.handleClientFilterChange = this.handleClientFilterChange.bind(this)
        this.getCSVData = this.getCSVData.bind(this)
        this.handleStartDateChange = this.handleStartDateChange.bind(this)
        this.handleLastDateChange = this.handleLastDateChange.bind(this)

    }
    componentDidMount() {
        // if (this.props.role === 'Root') {
        //     this.viewGenRepo()
        // } else 
        let clientName = "Client"
        if (this.props.role === 'Super Admin') {
            let idx = this.props.clientList.findIndex((client) => client.id === this.props.companyID)
            if (idx !== -1) {
                clientName = this.props.clientList[idx].clientName
            }
        }
        if (this.props.role === 'Super Admin' || this.props.role === 'Root' ) {
            this.setState({
                viewUploadBtns: true,
                clientName: clientName
            })
        }

    }
    handleClientFilterChange(event) {
		this.props.onClientFilterChange({
			name: event.target.value
		})
	}
    addFolder(data) {
        this.props.onAddFolder({ itemName: data.folderName })
          this.props.createLibraryLog({
            folderUploadedDate: this.state.date
         });
        //fileDeletedDate = null, fileDownloadedDate = null, fileUploadedDate = null, folderAddedDate = null,folderDeletedDate = null ) {
        
    }
    addFile(fData) {
        this.props.onAddFile({
            itemName: fData.itemName,
            file: fData.file,
            type: fData.type,
            companyID: fData.companyID
        })
        this.props.createLibraryLog({
            fileUploadedDate: this.state.date
         });
        //fileDeletedDate = null, fileDownloadedDate = null, fileUploadedDate = null, folderAddedDate = null,folderDeletedDate = null ) {
        
    }
    viewClientRepo() {
        let rootPath = 'companies';
        if (this.props.role !== 'Root') {
            // assume current user is a Super Admin/User
            rootPath = `companies/${this.props.companyID}`
        }
        this.props.onViewSelectedRepo({path: rootPath});
        let viewUploadBtns = false;
        if (this.props.role === 'Root' || this.props.role === 'Super Admin') {
            viewUploadBtns = true;
        }
        this.setState({
            viewUploadBtns: viewUploadBtns,
            highlight: ['bottomLine', 'topLine'],
        })
    }
    viewGenRepo() {
        this.props.onViewSelectedRepo({path: 'companies/genItems'});
        let viewUploadBtns = false;
        if (this.props.role === 'Root') {
            viewUploadBtns = true;
        }
        this.setState({
            viewUploadBtns: viewUploadBtns,
            highlight: ['topLine', 'bottomLine'],
        })
    }
    handleDeleteFolder(itemName, index, fullPath) {
        let folderName = itemName
        var res = window.confirm(`Do you really want to delete ${folderName}?`)
        if (res) {
            this.props.onDeleteFolder({
                path: fullPath,
                index: index
            })
        }

       this.props.createLibraryLog({
            folderDeletedDate: this.state.date
         });
        //fileDeletedDate = null, fileDownloadedDate = null, fileUploadedDate = null, folderAddedDate = null,folderDeletedDate = null ) {
        
    }
    handleEditFolder(itemName, index, fullPath) {
        var res = window.prompt('Enter New Folder Name:', itemName)
        if (res !== itemName && res !== null && res.length > 0) {
            this.props.onEditFolderName({
                itemName: itemName, // old folder name
                folderName: res, // new folder name
                index: index, //index of item in table
                path: fullPath, // fullPath of item
            })
        }
         this.props.createLibraryLog({
            folderName: res,
            lastFolderName: itemName,
            lastUpdatedBy: 1,
            lastUpdatedDate: this.state.date,
            filetype: 'folder'
         });
    }
    handleSingleClick({row}) {
        const path =  row.original.fullPath
        const folder = row.original.folder
        const index = row.id;
        if (folder && (this.props.role === 'Root' || this.props.role === 'Super Admin')) {
            // assuming its a folder, show the delete folder button
            this.setState({
                folderIndex: index,
                folderPath: path
            })
        }
    }
    handleNavigateToChild({row}) {
        // changes all the backgrounds to white
        let tbodyArr = document.querySelector('tbody').getElementsByTagName('tr')
        for (let i = 0; i < tbodyArr.length; i++) {
            tbodyArr[i].style.background = 'white'
        }
        let currRepo = row.original;
		if (!currRepo.folder) {
			alert('You can not view the contents of a file')
		}
		else {
			// update the topography header that displays the current path
			// view the contents of the folder
			this.props.onNavigateToChild({
				path: currRepo.fullPath,
				companyID: currRepo.companyID,
            })
		}
    }
    handleGoBack() {
        // changes all the backgrounds to white
        let tbodyArr = document.querySelector('tbody').getElementsByTagName('tr')
        for (let i = 0; i < tbodyArr.length; i++) {
            tbodyArr[i].style.background = 'white'
        }
        let size = this.props.path.length
        let formattedPath = this.props.path;
        // go back a repository by cutting off a portion from the end
        // of the path until '/'
        for (let i = size -1; i >= 0; i--) {
            if (this.props.path[i] === '/') {
                formattedPath = formattedPath.slice(0, -1);
                break;
            }
            formattedPath = formattedPath.slice(0, -1);
        }
        this.props.onNavigateToParent({ path: formattedPath })
    }
    deleteFile(row) {
        this.props.onDeleteFile({
            index: row.index,
            fullPath: row.fullPath,
            folder: row.folder,
        })
        this.props.createLibraryLog({
            fileDeletedDate: this.state.date
         });
        //fileDeletedDate = null, fileDownloadedDate = null, fileUploadedDate = null, folderAddedDate = null,folderDeletedDate = null ) {
        
    }
    downloadFile(data) {
        this.props.onDownloadFile({
            fullPath: data.fullPath,
            folder: data.folder,
            itemName: data.itemName
        })

        this.props.createLibraryLog({
            fileDownloadedDate: this.state.date
         });
    }

    handleStartDateChange = date =>{   
        console.log("startDate:"+ date);  
        this.setState({ startDate: date }); 
        
    };
    handleLastDateChange = date =>{ 
        console.log("lastDate:"+ date);     
        this.setState({ lastDate: date });  
    };

    getCSVData(tdata){
        let len = tdata.length;
        let csv = [];
            for(let i = 0; i<len; i++){
               // var lUD = (tdata[i].lastUpdatedDate) ? new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(tdata[i].lastUpdatedDate*1000) : 'Unavailable'
               // var oUD =  (tdata[i].uploadTime)? new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(tdata[i].uploadTime*1000)  : 'Unavailable'
            csv.push({
                'Item Name':tdata[i].itemName,
                'Last Updated By':tdata[i].lastUpdatedBy,
                'Last Updated Time': tdata[i].lastUpdatedDate,
                'Original Uploaded By':tdata[i].fullName,
                'Original Uploaded Time': tdata[i].uploadTime,
                'File Type' : tdata[i].fileType
            });
         }
        // console.log(csv);
        this.setState({csvArr:csv});
        
    }


    render() {
        // {console.log(this.props.planPager);}
        let path = this.props.path.replace('genItems', 'General')

        path = path.split('/')

        if(path[1] !== undefined) {
            // assume not just viewing clients directory
            let idx = this.props.clientList.findIndex((client) => client.id === path[1]) 
            if (idx !== -1) {
                // assume inside a client folder
                path[1] = this.props.clientList[idx].clientName
      
            }
        }

        path = path.join(' / ')
        path = path.replace('companies / ', '')

       
		var columns = [ // predefined names for the Library
			{
				Header: 'Users',
				columns: [
				{
					Header:'  ',
					Cell: ({row}) => {
						let body;						
						if (!row.original.folder) {
							body = <img title="File" className="icon_button" height={25} width={25} alt="descriptor" src={FileIMG} />
						} else {
							body = <img title="Folder" className="icon_button" height={25} width={25} alt="descriptor" src={FolderIcon} />
						}
						return body;
                    }
                },
                {
                    Header: 'Item Name',
                    accessor: 'itemName',
                },
                {
                    Header: 'Last Updated By',
                    Cell: ({row}) => {
                       return (row.original.lastUpdatedBy) ? (row.original.lastUpdatedBy) : 'N/A'
                     }
                },
                {
                    Header: 'Last Updated Time',
                    Cell: ({row}) => { 
                       return (row.original.lastUpdatedDate) ? (row.original.lastUpdatedDate) : 'N/A'
                     }
                },
				{
					Header: (this.props.planPager === 6)? 'Original Uploaded By' : 'Uploaded By',
					accessor: 'fullName',
				},
				{
					Header: (this.props.planPager === 6)? 'Original Uploaded Time' : 'Uploaded Time',
                    Cell: ({row}) => {
                        //Doing something lame! the datatype is string but when passed through any of the formatting code gives invalid time value
                        //Small trick can make wonders and play with people
                        let value = (row.original.uploadTime);
                        let dateTime = value.split('/')
                        return dateTime[1]+"/"+dateTime[0]+"/"+dateTime[2];
                        },
                    accessor: 'uploadTime',
				},
				{
					Header: 'File Type',
					Cell: ({row}) => {
                       
                        if(!row.original.fileType){
                            return 'N/A';
                        }
                        else
                        {
                            let type = 'N/A';
                            if(row.original.fileType === 'application/pdf'){
                                type = 'PDF'
                            }else if(row.original.fileType === 'image/jpeg'){
                                type = 'JPEG'
                            }else if(row.original.fileType === 'image/png'){
                                type = 'PNG'
                            }else if(row.original.fileType === 'text/plain'){
                                type = 'TEXT'
                            }else if(row.original.fileType === 'video/mp4'){
                                type = 'MP4 video'
                            }else if(row.original.fileType === 'video/quicktime'){
                                type = 'Quicktime video'
                            }else if(row.original.fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                                type = 'sheet'
                            }else if(row.original.fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
                                type = 'document'
                            } 
                            return type ;
                       }
                      
                    } 
                    
                },
                {
                    Header: 'Folder',
                    Cell: ({row}) => {
                        if(path == "companies"){
                            return row.original.fullName
                        }
                        else
                            return path
                    }
				},
				{
					Header: '   ',
                    Cell: ({row}) => { // Download button
                        if (row.original.folder) { 
                            return ''
                        }
						return <img title="Download File" height={25} width={25} alt="Download" src={Download} className='icon_button'
						onClick={() => {
							this.downloadFile({
								fullPath: row.original.fullPath,
								folder: row.original.folder,
								itemName: row.original.itemName
							})
						}}
						/>
					}
                },
                {
					Header: '    ', // Edit button functionality
					Cell: ({row}) => {
                        if (row.original.folder) {
                            return <img title="Edit Folder" height={25} width={25} alt="Download" className='icon_button'
                                src={Edit} 
                                onClick={() => this.handleEditFolder(row.original.itemName, row.id, row.original.fullPath)}/>
                        }
                        return ''
					}
				},
				{
					Header: ' ', // Delete button functionality
					Cell: ({row}) => {
						let body;						
						if (!row.original.folder) {
							body = <img title="Delete File" height={25} width={25} alt="Delete" src={TrashCan} className='icon_button'
                                onClick={(event) => {
                                    event.persist()
                                    var res = window.confirm("Are you sure you want to delete the File?");
                                    if (res) {
                                        // Supposed to send the row index and the fullPath of the item
                                        // that the user wants to delete
                                        this.deleteFile({
                                            index: row.id,
                                            fullPath: row.original.fullPath,
                                            folder: row.original.folder
                                        });
                                    }
                                }}/>
						} else {
                            body = <img title="Delete Folder" height={25} width={25} alt="Delete" src={TrashCan} className='icon_button'
                                onClick={() => this.handleDeleteFolder(row.original.itemName, row.id, row.original.fullPath)}/>
						}
						return body;
					}
				}]
			}];
        if(this.props.planPager !== 6)
            {
                columns[0].columns.splice(2,2)
            }
          if(this.props.planPager === 6)
            {
                columns[0].columns.splice(8,3)
            }    
        return (
            <main>
                <p id="breadCrumbs">{(this.props.planPager === 6) ? 'Reports' : this.props.breadCrumbs}</p>
                <h1>Library {(this.props.planPager === 6) ? 'Report' : ''}</h1>
                <h4 className="pathHeader" onClick={this.handleGoBack}><i className="left"></i>{path}</h4>
                {(this.props.planPager === 6) ?
                    <ul className="topnav">
                    <li id="secondaryNavigationItems" className={this.props.reportsHighlight[0]} onClick={() => {
                            this.setState({ 
                                viewUserList: false,
                                inputText: null
                             })
                            this.props.onEditBreadCrumbs({ type: 'REMOVE_BREADCRUMB', breadCrumbs: 'Reports' })
                            this.props.viewReports({planPager: 6, subPlanPager: 1})}}>Checklists
                    </li>
                    <li id="secondaryNavigationItems" className={this.props.reportsHighlight[1]} onClick={() => {
                            this.setState({ 
                                viewUserList: false,
                                inputText: null
                             })
                            this.props.viewReports({planPager: 6, subPlanPager: 2})
                            this.props.onEditBreadCrumbs({ type: 'REMOVE_BREADCRUMB', breadCrumbs: 'Reports' })
                        }} >Flash
                    </li>
                    <li id="secondaryNavigationItems" className={this.props.reportsHighlight[2]} onClick={() => {
                            this.setState({ 
                                viewUserList: false,
                                inputText: null
                             })
                            this.props.onEditBreadCrumbs({ type: 'REMOVE_BREADCRUMB', breadCrumbs: 'Library' })
                            this.props.onViewLibrary({planPager: 6})}}>Library
                    </li>
                    <li id="secondaryNavigationItems" className={this.props.reportsHighlight[3]} onClick={() => {
                            this.setState({ 
                                viewUserList: false,
                                inputText: null
                             })
                            this.props.onEditBreadCrumbs({ type: 'REMOVE_BREADCRUMB', breadCrumbs: 'COVID-19 Vaccines' })
                            this.props.onViewUserList({planPager: 6, subPlanPager: 0})}}>COVID-19 Vaccines
                    </li>
                    
                    <li id="secondaryNavigationItems" className={this.props.reportsHighlight[4]} onClick={() => {
                            this.setState({ 
                                viewUserList: false,
                                inputText: null
                             })
                            this.props.onEditBreadCrumbs({ type: 'REMOVE_BREADCRUMB', breadCrumbs: 'Health Screen' })
                            this.props.viewCompletedForms({planPager: 6})}}>Health Screen
                    </li>

                </ul>
               :
                    <ul className="topnav">
                            <li id="secondaryNavigationItems" onClick={this.viewClientRepo} className={this.state.highlight[1]}>{this.state.clientName}</li>
                            {/* <li id="secondaryNavigationItems" onClick={this.viewGenRepo} className={this.state.highlight[0]}>General</li> */}
                    </ul> 
                }
               
                <nav>
                    <Container fluid>
                        <Row>
                            <Col> 
                                <div className="search">
                                    <Select options={this.props.repository.map((repo) => {return { 'value': repo.itemName, 'label': repo.itemName }})} 
                                            id='searchBox' placeholder='Select an Item'
                                            value={this.state.inputText}
                                            onChange={(event) => {
                                                this.props.onfilterTable({
                                                    type: 'FILTER_LIBRARY_LIST',
                                                    value: event.value
                                                })
                                                let inputText = {label: event.label, value: event.label}
                                                this.setState({inputText: inputText})
                                            }}/>
                                    <img id="resetTableListBtn" src={Reset} alt="Reset" className="icon_button"
                                        onClick={() => {
                                            this.props.onViewLibrary({planPager: 6})
            								this.setState({inputText: null})
                                    	}}/>
                                </div>  
                            </Col>
                            {(this.props.role === "Root") ? 
                            <Col><select id="selectClientUserTable" onChange={this.handleClientFilterChange}></select></Col>
                                : null 
                            }

                    {(this.props.planPager === 6) ? 
                    <Fragment>
                    <Col>
                        <div className="dateRange" >
                            <div className = "startDate"><DatePicker  selected={this.state.startDate}        
                            onChange={this.handleStartDateChange} className="datepickerStyle"/>
                            </div>
                            <div className = "endDate"><DatePicker  selected={this.state.lastDate}        
                            onChange={this.handleLastDateChange} className="datepickerStyle"/>    
                            </div>
                            <button className="go" title="Go" onClick={() => this.props.onfilterTable({
                                            type: 'FILTER_LIBRARY_BY_DATE_RANGE',
                                            startdate:this.state.startDate,
                                            lastdate: this.state.lastDate,
                                        })}>Go</button>
                        </div>
                    </Col>
                    <Col>
                        <div className="printbutton">
                        <CSVLink data={this.state.csvArr} filename={'Library Full Report.csv'}><img height={35} width={35} alt="Print" className='icon_button icon-button-print'
                                    src={Print} 
                                    onClick={() => this.getCSVData((this.props.viewFilteredList) ? this.props.filteredList : this.props.repository)}
                                    /></CSVLink>
                        </div>
                    </Col>
                    </Fragment> : null }

                    {(this.state.viewUploadBtns && this.props.planPager !== 6) ? 
                    <Fragment>
                        <Col>
                            <ul className="addItem">
                                <li><AddFolderDialog onAddFolder={this.addFolder} onEditBreadCrumbs={this.props.onEditBreadCrumbs}/></li>
                                <li><AddFileDialog onAddFile={this.addFile} clientList={this.props.clientList} folderRepos={this.props.folderRepos} 
                                    role={this.props.role} onEditBreadCrumbs={this.props.onEditBreadCrumbs}/></li>
                            </ul>
                        </Col>
                    </Fragment> : null}
            </Row>
          </Container>
        </nav>
                <Styles>
                    {/* gets the user data from knowledgePage class and fills in the table */}
                    <Table columns={columns} data={(this.props.viewFilteredList) ? this.props.filteredList : this.props.repository} onRowDoubleClick={this.handleNavigateToChild} onRowSingleClick={this.handleSingleClick}/>
			    </Styles>
            </main>
        )
    }
}


export default LibraryTable;