import React, { Component } from 'react';
import Select from 'react-select'

import "./style/addUserDialog.css";

class AddUserListBox extends Component {
    constructor() {
        super()
        this.state = {
            selectedUsers: [], // Array of Objs {value: UID, label: name}
        }
        this.getAllSelectedUsers = this.getAllSelectedUsers.bind(this)
        this.handleUserListChange = this.handleUserListChange.bind(this)
    }
    handleUserListChange(event) {
        this.setState({selectedUsers: event})
    }
    // get all the selected users and add them to the ERP and add the 
    // html list item with it too
    getAllSelectedUsers() {
        let selectedArr = []
        for (let user of this.props.selectedUsers) {
            let curr = {
                name: user.label,
                id: user.value
            }
            selectedArr.push(curr)
        }
        this.props.onHideUserBox({ selectedUsers: selectedArr })
    }
    render() {
        return (
            <dialog id="UserBoxDialog" style={{'height': '72%', 'width': '55%', 'padding': '55px','marginLeft': '42%'}}>
                <h4 id="userListBoxTitle">Select user(s) to be assigned to this {this.props.dialogHeader}.</h4>
                <div id="userListSearchandReset">
                    <Select options={this.props.options} id='searchBoxUserList' placeholder='Select a User'
                        value={this.props.selectedUsers}
                        isMulti className="basic-multi-select"
                        onChange={this.props.handleUserListChange}
                        classNamePrefix="select"/>
                </div>
                <ul id="userListOptions"></ul>
                <div className="btn--flex">
                    <button onClick={this.getAllSelectedUsers} type="button" 
                        className="btn btn--confirm dialog__btn">Confirm</button>
                    <button onClick={() => {this.props.onHideUserBox()}} type="button" 
                        className="btn btn--close dialog__btn">Cancel</button>
                </div>
            </dialog>
        );
    }
}
export default AddUserListBox;